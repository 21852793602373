import { useContext, useEffect, useState } from 'react';
import { cloneDeep, groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PORTFOLIO_TYPE } from '../../pages/comparison/portfolio-monitor';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type PFMatrixChartProps = {
  chartInfo: {
    title: string;
    xName: string;
    yName: string;
  };
  loading: boolean;
  graphHeight: number;
  data: any[];
  useDX: boolean;
  filter: any;
  type: PORTFOLIO_TYPE;
  operation: [any, React.Dispatch<any>];
};

const PFMatrixChart = (props: PFMatrixChartProps) => {
  const { sizeMode, color } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);
  const { chartInfo, loading = false, graphHeight = 400, data, useDX, filter, type, operation } = props;

  const { t } = useTranslation();

  const [state, dispatch] = operation;

  const { curChartRef, tarChartRef } = state;

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
      xAxis: 10,
      yAxis: 10,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const tooltipTemplate = (params) => {
    const { marker, value } = params;
    const [mgFalsy, oaFalsy, mh, projectId, projectName, budget, type, accountingYear, mhGross, orderAmount] = value;
    return `
      <h4>${t('aipcmcty.page.portfolio.bubbleSize')}: MH</h4>
      <table>
        <tr>
          <td>${marker}${t('aipcmcty.page.portfolio.projectId')}</td>
          <td style="text-align: right;">${projectId}</td>
        </tr>
        <tr>
          <td>${t('aipcmcty.page.portfolio.projectName')}</td>
          <td style="text-align: right;"><div class="ellipsis" style="max-width: 300px">${projectName}</div></td>
        </tr>
        <tr>
          <td>${t('aipcmcty.page.portfolio.grossProfit')}${useDX ? '(DX)' : ''}</td>
          <td style="text-align: right;">${orderAmount ? Number(orderAmount.toFixed(0)).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>${t('aipcmcty.page.portfolio.pgPerMh')}${useDX ? '(DX)' : ''}</td>
          <td style="text-align: right;">${mhGross ? Number(mhGross.toFixed(0)).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>MH${useDX ? '(DX)' : ''}</td>
          <td style="text-align: right;">${mh ? Number(mh.toFixed(0)).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>${t('aipcmcty.page.portfolio.budgetCategory')}</td>
          <td style="text-align: right;">${budget}</td>
        </tr>
        <tr>
          <td>${t('aipcmcty.page.portfolio.year')}</td>
          <td style="text-align: right;">${accountingYear}</td>
        </tr>
        <tr>
          <td>${t('aipcmcty.page.portfolio.classification')}</td>
          <td style="text-align: right;">${type}</td>
        </tr>
      </table>
    `;
  };

  const [pfMatrixOptions, setPFMatrixOptions] = useState({
    title: {
      value: chartInfo.title,
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    tooltips: [],
    chartOptions: {
      tooltip: {
        confine: true,
        appendToBody: true,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        textStyle: {
          width: 300,
          overflow: 'truncate',
        },
        formatter: (params) => tooltipTemplate(params),
      },
      legend: {},
      grid: {
        left: '6%',
        right: '6%',
        bottom: '6%',
      },
      xAxis: {
        type: 'value',
        name: chartInfo.xName,
        nameRotate: -90,
        nameTextStyle: {
          align: 'right',
        },
        axisLabel: {
          formatter: (value) => value / 10000,
        },
        min: filter.mhGrossMin,
        max: filter.mhGrossMax,
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: chartInfo.yName,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value) => value / 100000000,
        },
        min: filter.grossProfitMin,
        max: filter.grossProfitMax,
      },
      series: [],
    },
  });

  const updatePFMatrix = () => {
    const { grossProfitMin: orMin, grossProfitMax: orMax, mhGrossMin: mrMin, mhGrossMax: mrMax } = filter;
    const curChartData = data.filter((d) =>
      type === PORTFOLIO_TYPE.CURRENT ? d.currentProjectId && d.currentIsEpc : d.targetProjectId && d.targetIsEpc
    );
    const maxMh = Math.max(...curChartData.map((mh) => (type === PORTFOLIO_TYPE.CURRENT ? mh.currentDemandMh : mh.targetDemandMh)));
    const ratio = maxMh / 5000000 >= 1 ? 1 : maxMh / 5000000;
    let divider = 25000 * ratio;
    const projectTypedData = groupBy(curChartData, (d) => (type === PORTFOLIO_TYPE.CURRENT ? d.currentIsEpc : d.targetIsEpc));
    const keySeq =
      type === PORTFOLIO_TYPE.CURRENT
        ? [
            'currentPgPerMh',
            'currentGrossProfit',
            'currentDemandMh',
            'currentProjectId',
            'currentProjectName',
            'currentBudgetCategoryCustom',
            'currentIsEpc',
            'currentAccountingYear',
            'currentPgPerMh',
            'currentGrossProfit',
          ]
        : [
            'targetPgPerMh',
            'targetGrossProfit',
            'targetDemandMh',
            'targetProjectId',
            'targetProjectName',
            'targetBudgetCategoryCustom',
            'targetIsEpc',
            'targetAccountingYear',
            'targetPgPerMh',
            'targetGrossProfit',
          ];
    const colorMap = {
      EPC: '#1A4F99',
      非EPC: '#3299D9',
    };
    const series = Object.keys(projectTypedData).reduce((prev, curK) => {
      prev.push({
        name: curK,
        type: 'scatter',
        symbolSize: (value) => {
          const sizeRatio = Math.abs(value[2] / divider);
          if (sizeRatio < 8) {
            return 8;
          }
          if (sizeRatio > 100) {
            return 100;
          }
          return sizeRatio;
        },
        itemStyle: {
          color: colorMap[curK],
        },
        emphasis: {
          focus: 'self',
          itemStyle: {
            color: color.errorColor,
          },
        },
        data: projectTypedData[curK].map((pd) =>
          keySeq.map((k, i) => {
            let copied = pd[k];
            if (i === 0) {
              if (copied <= mrMin) {
                copied = mrMin + 0.05;
              }
              if (copied >= mrMax) {
                copied = mrMax - 0.05;
              }
            }
            if (i === 1) {
              if (copied <= orMin) {
                copied = orMin + 0.05;
              }
              if (copied >= orMax) {
                copied = orMax - 0.05;
              }
            }
            return copied;
          })
        ),
      });
      return prev;
    }, []);
    pfMatrixOptions.title.value = chartInfo.title;
    pfMatrixOptions.chartOptions.xAxis = {
      ...pfMatrixOptions.chartOptions.xAxis,
      name: chartInfo.xName,
      min: mrMin,
      max: mrMax,
    };
    pfMatrixOptions.chartOptions.yAxis = {
      ...pfMatrixOptions.chartOptions.yAxis,
      name: chartInfo.yName,
      min: orMin,
      max: orMax,
    };
    pfMatrixOptions.chartOptions.series = series;
    pfMatrixOptions.chartOptions.tooltip.formatter = tooltipTemplate;
    setPFMatrixOptions(cloneDeep(pfMatrixOptions));
  };

  useEffect(() => {
    updatePFMatrix();
  }, [data]);

  const handleChartEvent = (e) => {
    const { eventType, chartInstance, event } = e;
    switch (eventType) {
      case ECHART_EVENT.CHART_ITEM_SELECTED:
        if (chartInstance.current === curChartRef.current) {
          dispatch({
            type: 'curFocusAction',
            event,
          });
        }
        if (chartInstance.current === tarChartRef.current) {
          dispatch({
            type: 'tarFocusAction',
            event,
          });
        }
        break;
      case ECHART_EVENT.CHART_GLOBAL_OUT:
        dispatch({
          type: 'clearTip',
        });
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ position: 'relative', height: graphHeight - 15 }}>
      <TableChart
        chartRef={type === PORTFOLIO_TYPE.CURRENT ? curChartRef : tarChartRef}
        showTable={false}
        tooltips={pfMatrixOptions.tooltips}
        chartOptions={pfMatrixOptions.chartOptions}
        title={
          chartInfo.title
            ? {
                value: chartInfo.title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : pfMatrixOptions.title
        }
        height={graphHeight}
        loading={loading}
        onChartEvent={handleChartEvent}
      />
    </div>
  );
};

export default PFMatrixChart;
