import React, { useState, useEffect } from 'react';
import { Row, Col, Popover, Spin } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

const divisionMapping = {
  Engineering: 'Eng.',
  Project: 'PJT',
  Corporate: 'Cor.',
  Procurement: 'Pro.',
  Construction: 'Con.',
};

interface DataItem {
  month: string;
  value: number;
  percentValue: number;
  year: string;
  division: string;
  discipline: string;
  role?: string;
}

interface CombinedDataItem {
  tgc: string;
  division: string;
  discipline: string;
  role?: string;
  data: DataItem[];
}

const ExtractHeatChart = ({ data, startMonth, loading = false }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' });
  useEffect(() => {
    setSortConfig({ key: null, direction: 'none' });
  }, [data]);
  const showRoleColumn = data.some((item) => item.role !== undefined);
  const getColor = (value) => {
    const colorStops = [
      { value: 0, color: 'rgb(240, 217, 156)' },
      { value: 25, color: 'rgb(234, 195, 146)' },
      { value: 50, color: 'rgb(222, 152, 125)' },
      { value: 75, color: 'rgb(216, 130, 115)' },
      { value: 100, color: 'rgb(211, 118, 107)' },
    ];
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (value >= colorStops[i].value && value < colorStops[i + 1].value) {
        const start = colorStops[i];
        const end = colorStops[i + 1];
        const ratio = (value - start.value) / (end.value - start.value);
        const startRGB = start.color.match(/\d+/g).map(Number);
        const endRGB = end.color.match(/\d+/g).map(Number);
        const r = Math.round(startRGB[0] + (endRGB[0] - startRGB[0]) * ratio);
        const g = Math.round(startRGB[1] + (endRGB[1] - startRGB[1]) * ratio);
        const b = Math.round(startRGB[2] + (endRGB[2] - startRGB[2]) * ratio);
        return `rgb(${r}, ${g}, ${b})`;
      }
    }
    return colorStops[colorStops.length - 1].color;
  };

  const renderCol = (content, width, additionalStyle = {}, isHeader = false, withPop = false, onClick = null) => {
    const pop = <div style={{ padding: '10px 5px', borderRadius: 10 }}>{content}</div>;
    return withPop ? (
      <Popover content={pop}>
        <Col
          style={{
            textAlign: isHeader ? 'center' : 'left',
            fontWeight: isHeader ? '600' : '400',
            height: '40px',
            fontSize: '12px',
            width,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: isHeader ? 'pointer' : 'default',
            ...additionalStyle,
          }}
          onClick={onClick}
        >
          {content}
        </Col>
      </Popover>
    ) : (
      <Col
        style={{
          textAlign: isHeader ? 'center' : 'left',
          fontWeight: isHeader ? '600' : '400',
          height: '40px',
          fontSize: '12px',
          width,
          cursor: isHeader ? 'pointer' : 'default',
          ...additionalStyle,
        }}
        onClick={onClick}
      >
        {content}
      </Col>
    );
  };
  const renderBodyCol = (item, width, additionalStyle = {}) => {
    const title = (
      <div>
        <span style={{ marginRight: 5, fontWeight: '600' }}>{`${item.year}/${item.month}`}</span>
        <span style={{ fontWeight: '600' }}>{`${item.division} - ${item.discipline}`}</span>
        <span style={{ fontWeight: '600' }}>{item.role ? `-${item.role}` : ''}</span>
      </div>
    );
    const pop = (
      <div style={{ padding: '10px 5px', borderRadius: 10 }}>
        <p>
          <span style={{ fontWeight: '600', marginRight: 10 }}>需要MH:</span>
          {item.value}
        </p>
        <p>
          <span style={{ fontWeight: '600', marginRight: 10 }}>リソース不足率:</span>
          {item.percentValue + '%'}
        </p>
      </div>
    );
    return (
      <Popover content={pop} title={title}>
        <Col style={{ padding: '10px 4px', textAlign: 'right', height: '40px', width, alignItems: 'center', ...additionalStyle }}>
          {fixed(item.value).toLocaleString()}
        </Col>
      </Popover>
    );
  };

  const headerTitles = ['TGC', 'Division', 'Discipline'];
  if (showRoleColumn) {
    headerTitles.push('Role');
  }
  const monthOrder = [];
  for (let i = 0; i < 12; i++) {
    const currentMonth = ((startMonth - 1 + i) % 12) + 1;
    monthOrder.push(currentMonth);
    headerTitles.push(`${currentMonth}月`);
  }

  const reorderData = (data) => {
    return data
      .map((i) => ({ ...i, division: divisionMapping[i.division] }))
      .map((item) => {
        const sortedData = monthOrder.map((month) => {
          return item.data.find((d) => parseInt(d.month) === month);
        });
        return { ...item, data: sortedData };
      });
  };
  const sortData = (data, key, direction) => {
    if (direction === 'none') return data;

    return [...data].sort((a, b) => {
      const aValue = a[key] || '';
      const bValue = b[key] || '';

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const combineData = (data: any[], startMonth: string): CombinedDataItem[] => {
    const monthIndices = Array.from({ length: 12 }, (_, i) => ((parseInt(startMonth, 10) - 1 + i) % 12) + 1);
  
    const mappedData = data.reduce((acc, item) => {
      const key = `${item.tgc}-${item.division}-${item.discipline}-${item.role}`;
      if (!acc[key]) {
        acc[key] = {
          tgc: item.tgc,
          division: item.division,
          discipline: item.discipline,
          role: item.role,
          data: Array(12).fill(null).map((_, idx) => {
            const month = ((parseInt(startMonth, 10) - 1 + idx) % 12) + 1;
            return {
              month: `${month}`.padStart(2, '0'),
              value: 0,
              percentValue: 0,
              year: '',
              division: item.division,
              discipline: item.discipline,
              role: item.role,
            };
          }),
        };
      }
  
      item.data.forEach((d: DataItem) => {
        if (d.year) {  // yearが空でない場合のみデータを格納する
          const monthIndex = monthIndices.findIndex((m) => m === parseInt(d.month, 10));
          acc[key].data[monthIndex] = {
            ...acc[key].data[monthIndex],
            ...d,
          };
        }
      });
  
      return acc;
    }, {});
    return Object.values(mappedData);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'none';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcons = (key) => {
    const mapping = {
      TGC: 'tgc',
      Division: 'division',
      Discipline: 'discipline',
    };
    const isActive = sortConfig.key === mapping[key];
    const isAscending = isActive && sortConfig.direction === 'ascending';
    const isDescending = isActive && sortConfig.direction === 'descending';

    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 4 }}>
        <CaretUpOutlined
          style={{
            fontSize: 10,
            color: isAscending ? '#eee' : '#888',
            marginBottom: -4,
          }}
        />
        <CaretDownOutlined
          style={{
            fontSize: 10,
            color: isDescending ? '#eee' : '#888',
          }}
        />
      </div>
    );
  };
  const renderHeader = () => (
    <Row gutter={0} style={{ margin: 0, backgroundColor: 'rgb(26, 79, 153)', color: 'white' }}>
      {headerTitles.map((title, index) =>
        renderCol(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {title}
            {index < (showRoleColumn ? 4 : 3) && getSortIcons(title)}
          </div>,
          index === 0 ? '75px' : showRoleColumn && index === 3 ? '60px' : index < (showRoleColumn ? 4 : 3) ? '65px' : '45px',
          { padding: '10px 4px' },
          true,
          false,
          index < (showRoleColumn ? 4 : 3) ? () => handleSort(title.toLowerCase()) : null
        )
      )}
    </Row>
  );

  const renderDataRows = () => {
    const filteredData = reorderData(data).filter((item) => item.division !== null);
    const sortedData = sortData(filteredData, sortConfig.key, sortConfig.direction);
    const conbineData = combineData(sortedData, startMonth)
    
    return conbineData.map((item, index) => (
      <Row
        key={`row-${index}`}
        gutter={0}
        style={{
          margin: 0,
          backgroundColor: index % 2 === 0 ? 'rgb(210, 219, 238)' : 'rgb(250, 250, 250)',
        }}
      >
        {renderCol(item.tgc, '75px', { padding: '10px' })}
        {renderCol(item.division, '65px', { padding: '10px' })}
        {renderCol(item.discipline, '65px', { padding: '10px' })}
        {showRoleColumn && renderCol(item.role, '60px', { padding: '10px' }, false, true)}
        {item.data.map((dataItem, colIndex) => {
          if (!dataItem) {
            return renderCol('', '45px', { padding: '10px 4px' });
          }
          const backgroundColor = getColor(dataItem.percentValue);

          return renderBodyCol(dataItem, '45px', {
            backgroundColor,
            color: dataItem.percentValue > 20 ? 'white' : 'black',
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'right',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          });
        })}
      </Row>
    ));
  };

  return (
    <Spin spinning={loading}>
      <div style={{ maxHeight: 770, overflow: 'scroll' }}>
        {renderHeader()}
        {renderDataRows()}
      </div>
    </Spin>
  );
};

export default ExtractHeatChart;
