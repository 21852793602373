import React from 'react';
import { Table } from 'antd';
const sharedOnCell = (_, index) => {
  if (index === 3) {
    return { colSpan: 0 };
  }

  return {};
};
const extraRow = {
  key: '5',
  category: '連結対象外（持分のみ）',
};
const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));
const formatNumberToMillion = (value) => `${fixed(value / 1000000).toLocaleString()}M`;

const columns = [
  {
    title: '',
    dataIndex: 'category',
    key: 'category',
    onCell: (_, index) => ({
      colSpan: index === 3 ? 9 : 1,
    }),
    render: (value, item) => (item.key === '5' ? <div style={{ fontWeight: 600, fontSize: '16px' }}>{value}</div> : <div>{value}</div>),
  },
  {
    title: '受注金額',
    children: [
      {
        title: '進捗率',
        dataIndex: 'orderAmountProgressActual',
        key: 'orderAmountProgressActual',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => <span style={{ color: 'red' }}> {typeof value === 'number' && `${Math.round(value * 100 * 10) / 10}%`}</span>,
      },
      {
        title: '目標',
        dataIndex: 'orderAmountGoal',
        key: 'orderAmountGoal',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => value && formatNumberToMillion(value),
      },
      {
        title: '実績',
        dataIndex: 'orderAmountActual',
        key: 'orderAmountActual',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => value && formatNumberToMillion(value),
      },
      {
        title: '予測',
        dataIndex: 'orderAmountForecast',
        key: 'orderAmountForecast',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => value && formatNumberToMillion(value),
      },
    ],
  },
  {
    title: '受注粗利',
    children: [
      {
        title: '進捗率',
        dataIndex: 'grossProfitProgressActual',
        key: 'grossProfitProgressActual',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => <span style={{ color: 'red' }}>{typeof value === 'number' && `${Math.round(value * 100 * 10) / 10}%`}</span>,
      },
      {
        title: '目標',
        dataIndex: 'grossProfitGoal',
        key: 'grossProfitGoal',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => value && formatNumberToMillion(value),
      },
      {
        title: '実績',
        dataIndex: 'grossProfitActual',
        key: 'grossProfitActual',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => value && formatNumberToMillion(value),
      },
      {
        title: '予測',
        dataIndex: 'grossProfitForecast',
        key: 'grossProfitForecast',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => value && formatNumberToMillion(value),
      },
      {
        title: '粗利率',
        dataIndex: 'grossMarginActual',
        key: 'grossMarginActual',
        className: 'align-right',
        onCell: sharedOnCell,
        render: (value) => <span style={{ color: 'red' }}>{typeof value === 'number' && `${Math.round(value * 100 * 10) / 10}%`}</span>,
      },
    ],
  },
];

const MonthlyOrderAmountGrossProfit = ({ data, loading = false }) => {
  const modifiedData = loading ? [] : [...data.slice(0, 3), extraRow, ...data.slice(3)];

  return (
    <div>
      <Table columns={columns} dataSource={modifiedData} pagination={false} size="small" loading={loading} />
    </div>
  );
};

export default MonthlyOrderAmountGrossProfit;
