import React from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';

const ExtractResourcesTable = (props) => {
  const { data, loading, onCaseNameClick } = props;
  const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));
  const getDemandForecastTypes = (record) => {
    if (!record.children) {
      return record.demandForecastType || '';
    }

    const types = new Set();
    const traverse = (children) => {
      children.forEach((child) => {
        if (child.demandForecastType) {
          types.add(child.demandForecastType);
        }
        if (child.children) {
          traverse(child.children);
        }
      });
    };

    traverse(record.children);
    return Array.from(types).join(' + ');
  };

  // mhPeakが0でない行だけをフィルタリング
  const filteredData = data.filter((record) => parseFloat(record.mhPeak) !== 0);

  const columns = [
    {
      title: 'P番号',
      dataIndex: 'projectId',
      key: 'projectId',
      width: 150,
      sorter: (a, b) => a.projectId.localeCompare(b.projectId),
      render: (text, record) => {
        return !record.children ? null : text;
      },
    },
    {
      title: '案件名',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 250,
      render: (text, record) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onCaseNameClick(record);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'TGC',
      dataIndex: 'tgc',
      key: 'tgc',
      width: 80,
    },
    {
      title: '受注計画カテゴリ',
      dataIndex: 'budgetCategoryCustom',
      key: 'budgetCategoryCustom',
      width: 130,
    },
    {
      title: '役務範囲',
      dataIndex: 'scope',
      key: 'scope',
      width: 500,
    },
    {
      title: '工数TGC',
      dataIndex: 'mhTgc',
      key: 'mhTgc',
      width: 80,
    },
    {
      title: '受注・未受注',
      dataIndex: 'subBudgetCategory',
      key: 'subBudgetCategory',
      width: 150,
      render: (text, record) => {
        if (!record.children) return text;
        const categories = new Set(record.children.map((child) => child.subBudgetCategory));
        if (categories.has('受注分') && categories.has('未受注分')) {
          return '受注分+未受注分';
        } else if (categories.has('受注分')) {
          return '受注分';
        } else if (categories.has('未受注分')) {
          return '未受注分';
        }
      },
    },
    {
      title: '工数開始日',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 120,
      render: (text, record) => {
        if (!record.children || record.children.length === 0) return text;
        const minStartDate = record.children.reduce((min, child) => {
          const childDate = dayjs(child.startDate);
          return childDate.isBefore(min) ? childDate : min;
        }, dayjs(record.children[0].startDate));
        record.startDate = minStartDate.format('YYYY-MM');
        return record.startDate;
      },
      sorter: (a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)),
    },
    {
      title: '工数終了日',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 120,
      render: (text, record) => {
        if (!record.children || record.children.length === 0) return text;
        const maxEndDate = record.children.reduce((max, child) => {
          const childDate = dayjs(child.endDate);
          return childDate.isAfter(max) ? childDate : max;
        }, dayjs(record.children[0].endDate));
        record.endDate = maxEndDate.format('YYYY-MM');
        return record.endDate;
      },
      sorter: (a, b) => dayjs(a.endDate).diff(dayjs(b.endDate)),
    },
    {
      title: '期間',
      dataIndex: 'duration',
      key: 'duration',
      width: 80,
      sorter: (a, b) => {
        const startDateA = dayjs(a.startDate);
        const endDateA = dayjs(a.endDate);
        const totalMonthsA = endDateA.diff(startDateA, 'month');

        const startDateB = dayjs(b.startDate);
        const endDateB = dayjs(b.endDate);
        const totalMonthsB = endDateB.diff(startDateB, 'month');

        return totalMonthsA - totalMonthsB;
      },
      render: (text, record) => {
        const startDate = dayjs(record.startDate);
        const endDate = dayjs(record.endDate);
        const totalMonths = endDate.diff(startDate, 'month');
        return <div style={{ textAlign: 'right', alignItems: 'center' }}>{totalMonths}</div>;
      },
    },
    {
      title: '需要推計区分',
      dataIndex: 'demandForecastType',
      key: 'demandForecastType',
      width: 150,
      render: (text, record) => {
        const types = getDemandForecastTypes(record);
        return types;
      },
    },
    {
      title: 'フィルタ絞込当該月MH',
      dataIndex: 'mhPeak',
      key: 'mhPeak',
      width: 200,
      sorter: (a, b) => parseFloat(a.mhPeak) - parseFloat(b.mhPeak),
      defaultSortOrder: 'descend' as 'descend',
      render: (text, record) => {
        if (!record.children) return <div style={{ textAlign: 'right' }}>{fixed(text).toLocaleString()}</div>;
        const totalMhPeak = record.children.reduce((sum, child) => {
          return sum + (child.mhPeak || 0);
        }, 0);
        record.mhPeak = totalMhPeak;
        return <div style={{ textAlign: 'right' }}>{fixed(totalMhPeak).toLocaleString()}</div>;
      },
    },
    {
      title: '選択TGC合計MH',
      dataIndex: 'selectedTgcMh',
      key: 'selectedTgcMh',
      width: 150,
      // TODO: Should check the logic
      sorter: (a, b) => parseFloat(a.selectedTgcMh) - parseFloat(b.selectedTgcMh),
      render: (text, record) => {
        if (!record.children) return <div style={{ textAlign: 'right' }}>{fixed(text).toLocaleString()}</div>;
        const totalSelectedTgcMh = record.children.reduce((sum, child) => {
          return sum + (child.selectedTgcMh || 0);
        }, 0);
        record.selectedTgcMh = totalSelectedTgcMh;
        return <div style={{ textAlign: 'right' }}>{fixed(totalSelectedTgcMh).toLocaleString()}</div>;
      },
    },
    {
      title: '他TGC合計MH',
      dataIndex: 'otherMh',
      key: 'otherMh',
      width: 150,
      sorter: (a, b) => {
        const selectMhA = a.children ? 0 : a.selectedMh ?? 0;
        const otherMhA = a.demandMh - selectMhA;

        const selectMhB = b.children ? 0 : b.selectedMh ?? 0;
        const otherMhB = b.demandMh - selectMhB;
        return otherMhA - otherMhB;
      },
      render: (text, record) => {
        const otherMh = record.demandMh - record.selectedTgcMh;
        return <div style={{ textAlign: 'right' }}>{fixed(otherMh).toLocaleString()}</div>;
      },
    },
    {
      title: '合計MH',
      dataIndex: 'demandMh',
      key: 'demandMh',
      width: 120,
      sorter: (a, b) => a.demandMh - b.demandMh,
      render: (text) => <div style={{ textAlign: 'right' }}>{fixed(text).toLocaleString()}</div>,
    },
  ];
  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={filteredData}
      size="small"
      rowKey="projectId"
      pagination={{ pageSizeOptions: [10, 20, 50, 100], showSizeChanger: true }}
      scroll={{
        x: 1130,
        y: 400,
      }}
    />
  );
};

export default ExtractResourcesTable;
