import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ceil, chain, cloneDeep, filter, forEach, includes, max, mergeWith, min, sum } from 'lodash';
import { round } from '../../utils/commonUtil';
import { TableChart } from '@meteor/frontend-core';
import { RATE_TYPE, TransformToFiscalYear } from '../../pages/demo/investment-return';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';

type ChartProps = {
  title: string;
  data: TransformToFiscalYear;
  loading: boolean;
};

const InvestmentReturnChart: React.FC<ChartProps> = (props) => {
  const { data, title, loading } = props;
  const { paramData, setParamData } = useContext(AipcmctyContext);
  const { t } = useTranslation();
  const [legendSelected, setLegendSelected] = useState({
    [t('aipcmcty.page.investAndRepay.forecast')]: false,
    [t('aipcmcty.page.investAndRepay.actual')]: false,
    [`${t('aipcmcty.page.investAndRepay.forecast')}(${t('aipcmcty.page.investAndRepay.contingency')})`]: false,
    [`${t('aipcmcty.page.investAndRepay.actual')}(${t('aipcmcty.page.investAndRepay.contingency')})`]: false,
    [`${t('aipcmcty.page.investAndRepay.dxInvestAmount')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}`]: false,
    [`${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}`]: false,
    [`${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}(${t(
      'aipcmcty.page.investAndRepay.contingency'
    )})`]: false,
  });

  const [options, setOptions] = useState<any>({
    title: {
      value: title,
      styles: {
        fontSize: 18,
        paddingLeft: 0,
      },
    },
    chartOptions: {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        appendToBody: true,
        confine: true,
        trigger: 'axis',
        formatter: (params) => {
          return params
            .filter((p) => p.seriesType !== 'custom')
            .map((element, index) => {
              const [dataIndex, data, showData = []] = element.data;
              return `
              <div style="text-align: left;">
              ${index === 0 ? `<span style="font-size: 16px;font-weight: bold;">${element.name}</span>` : ''}
              <p style="margin: 0">${element.marker} ${element.seriesName}: ${round(data / 100000000)}</p>
              ${showData
                .map(({ label, value, children = [] }) => {
                  return `<p style="margin: 0 30px">
                  ${label}: ${round(value / 100000000)}
                </p>
                ${
                  children &&
                  children
                    .map(({ label, value }) => {
                      return `<p style="margin: 0 45px">
                  ${label}: ${round(value / 100000000)}
                </p>`;
                    })
                    .join('')
                }
              `;
                })
                .join('')}
            </div >
            `;
            })
            .join('');
        },
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        top: 0,
        left: 0,
        bottom: 0,
        textStyle: {
          fontSize: 10,
        },
        itemGap: 5,
        itemWidth: 14,
        formatter: (name) => {
          return `${name}`;
        },
        selected: legendSelected,
      },
      grid: {
        left: 260,
        right: 60,
        bottom: 20,
        top: 40,
      },
      yAxis: [
        {
          type: 'value',
          name: (() => {
            return `${t('aipcmc.charts.hundredMillion')}(棒)`;
          })(),
          axisLine: {
            show: true,
            onZero: true,
          },
          axisLabel: {
            fontSize: 12,
            formatter: (value) => {
              return value / 100000000;
            },
          },
          axisTick: {},
          splitLine: {
            show: false,
          },
          nameTextStyle: {
            fontSize: 12,
          },
          nameGap: 5,
          // alignTicks: true,
        },
        {
          type: 'value',
          name: (() => {
            return `${t('aipcmc.charts.hundredMillion')}(折れ線)`;
          })(),
          axisLine: {
            show: true,
            onZero: true,
          },
          axisLabel: {
            fontSize: 12,
            formatter: (value) => {
              return value / 100000000;
            },
          },
          axisTick: {},
          splitLine: {
            show: false,
          },
          nameTextStyle: {
            fontSize: 12,
          },
          nameGap: 5,
          // alignTicks: true,
        },
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: 12,
        },
        axisLine: {
          show: true,
        },
        // alignTicks: true,
      },
      series: [],
    },
    height: (window.innerHeight - 100) * 0.4,
  });

  /**
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initChartData(legendSelected);
  }, [data, paramData]);

  /**
   * 初期化Chartデータ
   */
  const initChartData = (selectedLegends) => {
    if (!data) {
      return;
    }

    const rateDataFunc = (value, param, type: RATE_TYPE) => {
      const { progressRate, accumulateRate, cuteEate } = param;
      if (type === 0) {
        return value * progressRate * accumulateRate * cuteEate;
      }
      if (type === 1) {
        return value * cuteEate;
      }
      if (type === 2) {
        return value * progressRate * accumulateRate;
      }
      if (type === 3) {
        return value;
      }
      return value;
    };

    const rateDataFuncTotal = (precessData, param, type: RATE_TYPE) => {
      const { progressRate, accumulateRate, cuteEate } = param;
      return Object.entries(precessData).reduce((acc, [key, value]) => {
        acc[key] =
          typeof value === 'number'
            ? (() => {
                if (type === 0) {
                  return value * progressRate * accumulateRate * cuteEate;
                }
                if (type === 1) {
                  return value * cuteEate;
                }
                if (type === 2) {
                  return value * progressRate * accumulateRate;
                }
                if (type === 3) {
                  return value;
                }
                return value;
              })()
            : value;
        return acc;
      }, {});
    };

    const computeSum = (param) => {
      return mergeWith(
        {},
        rateDataFuncTotal(data.dxAllowNetPlanLatestPreSale, param, RATE_TYPE.ALL),
        rateDataFuncTotal(data.dxChargePlanLatestPreSale, param, RATE_TYPE.CRAZY),
        rateDataFuncTotal(data.dxAllowNetPlanLatestProposal, param, RATE_TYPE.CUTE),
        rateDataFuncTotal(data.dxChargePlanLatestProposal, param, RATE_TYPE.NONE),
        rateDataFuncTotal(data.dxAllowNetPlanLatestExecution, param, RATE_TYPE.CUTE),
        rateDataFuncTotal(data.dxChargePlanLatestExecution, param, RATE_TYPE.NONE),
        (objValue, srcValue) => {
          return sum([objValue, srcValue]);
        }
      );
    };

    const sumCumPlanLatest = (dxSum, cumKey: string) => {
      let cachedYear = null;
      const cum = Object.entries<number>(dxSum)
        .sort((a, b) => (a[0] < b[0] ? -1 : 1))
        .reduce((pr, [k, v]) => {
          pr[k] = v + data.dxIPlanLatest[k] + (cachedYear ? pr[cachedYear] : 0);
          cachedYear = k;
          return pr;
        }, {} as any);
      cum.key = cumKey;
      return cum;
    };

    const cumPlanLatest1 = sumCumPlanLatest(computeSum(paramData.param1), 'cum1');
    const cumPlanLatest2 = sumCumPlanLatest(computeSum(paramData.param2), 'cum2');
    const cumPlanLatest3 = sumCumPlanLatest(computeSum(paramData.param3), 'cum3');

    const xAxisData = Object.keys(data.dxAPlan01);
    options.chartOptions.xAxis.data = xAxisData;
    const bar1Data = Object.values(data.dxAPlan01).map((p, i) => [i, p]);
    const bar2Data = Object.values(data.dxAPlan02).map((p, i) => [i, p]);
    const bar3_1Data = Object.entries(data.dxAPlanLatest).map(([k, v], i) => [
      i,
      rateDataFunc(data.dxAllowNetPlanLatestPreSale[k], paramData.param1, RATE_TYPE.ALL) +
        rateDataFunc(data.dxChargePlanLatestPreSale[k], paramData.param1, RATE_TYPE.CRAZY) +
        rateDataFunc(data.dxAllowNetPlanLatestProposal[k], paramData.param1, RATE_TYPE.CUTE) +
        rateDataFunc(data.dxChargePlanLatestProposal[k], paramData.param1, RATE_TYPE.NONE) +
        rateDataFunc(data.dxAllowNetPlanLatestExecution[k], paramData.param1, RATE_TYPE.CUTE) +
        rateDataFunc(data.dxChargePlanLatestExecution[k], paramData.param1, RATE_TYPE.NONE),
      [
        {
          label: t('aipcmcty.page.investAndRepay.preSale'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestPreSale[k], paramData.param1, RATE_TYPE.ALL) +
            rateDataFunc(data.dxChargePlanLatestPreSale[k], paramData.param1, RATE_TYPE.CRAZY),
        },
        {
          label: t('aipcmcty.page.investAndRepay.proposal'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestProposal[k], paramData.param1, RATE_TYPE.CUTE) +
            rateDataFunc(data.dxChargePlanLatestProposal[k], paramData.param1, RATE_TYPE.NONE),
        },
        {
          label: t('aipcmcty.page.investAndRepay.execution'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestExecution[k], paramData.param1, RATE_TYPE.CUTE) +
            rateDataFunc(data.dxChargePlanLatestExecution[k], paramData.param1, RATE_TYPE.NONE),
        },
      ],
    ]);
    const bar3_2Data = Object.entries(data.dxAPlanLatest).map(([k, v], i) => [
      i,
      rateDataFunc(data.dxAllowNetPlanLatestPreSale[k], paramData.param2, RATE_TYPE.ALL) +
        rateDataFunc(data.dxChargePlanLatestPreSale[k], paramData.param2, RATE_TYPE.CRAZY) +
        rateDataFunc(data.dxAllowNetPlanLatestProposal[k], paramData.param2, RATE_TYPE.CUTE) +
        rateDataFunc(data.dxChargePlanLatestProposal[k], paramData.param2, RATE_TYPE.NONE) +
        rateDataFunc(data.dxAllowNetPlanLatestExecution[k], paramData.param2, RATE_TYPE.CUTE) +
        rateDataFunc(data.dxChargePlanLatestExecution[k], paramData.param2, RATE_TYPE.NONE),
      [
        {
          label: t('aipcmcty.page.investAndRepay.preSale'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestPreSale[k], paramData.param2, RATE_TYPE.ALL) +
            rateDataFunc(data.dxChargePlanLatestPreSale[k], paramData.param2, RATE_TYPE.CRAZY),
        },
        {
          label: t('aipcmcty.page.investAndRepay.proposal'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestProposal[k], paramData.param2, RATE_TYPE.CUTE) +
            rateDataFunc(data.dxChargePlanLatestProposal[k], paramData.param2, RATE_TYPE.NONE),
        },
        {
          label: t('aipcmcty.page.investAndRepay.execution'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestExecution[k], paramData.param2, RATE_TYPE.CUTE) +
            rateDataFunc(data.dxChargePlanLatestExecution[k], paramData.param2, RATE_TYPE.NONE),
        },
      ],
    ]);
    const bar3_3Data = Object.entries(data.dxAPlanLatest).map(([k, v], i) => [
      i,
      rateDataFunc(data.dxAllowNetPlanLatestPreSale[k], paramData.param3, RATE_TYPE.ALL) +
        rateDataFunc(data.dxChargePlanLatestPreSale[k], paramData.param3, RATE_TYPE.CRAZY) +
        rateDataFunc(data.dxAllowNetPlanLatestProposal[k], paramData.param3, RATE_TYPE.CUTE) +
        rateDataFunc(data.dxChargePlanLatestProposal[k], paramData.param3, RATE_TYPE.NONE) +
        rateDataFunc(data.dxAllowNetPlanLatestExecution[k], paramData.param3, RATE_TYPE.CUTE) +
        rateDataFunc(data.dxChargePlanLatestExecution[k], paramData.param3, RATE_TYPE.NONE),
      [
        {
          label: t('aipcmcty.page.investAndRepay.preSale'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestPreSale[k], paramData.param3, RATE_TYPE.ALL) +
            rateDataFunc(data.dxChargePlanLatestPreSale[k], paramData.param3, RATE_TYPE.CRAZY),
        },
        {
          label: t('aipcmcty.page.investAndRepay.proposal'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestProposal[k], paramData.param3, RATE_TYPE.CUTE) +
            rateDataFunc(data.dxChargePlanLatestProposal[k], paramData.param3, RATE_TYPE.NONE),
        },
        {
          label: t('aipcmcty.page.investAndRepay.execution'),
          value:
            rateDataFunc(data.dxAllowNetPlanLatestExecution[k], paramData.param3, RATE_TYPE.CUTE) +
            rateDataFunc(data.dxChargePlanLatestExecution[k], paramData.param3, RATE_TYPE.NONE),
        },
      ],
    ]);
    const bar4_1Data = Object.entries(data.dxAForecastSum).map(([k, v], i) => [
      i,
      v,
      [
        { label: t('aipcmcty.page.investAndRepay.preSale'), value: data.dxAForecastPreSale[k] },
        { label: t('aipcmcty.page.investAndRepay.proposal'), value: data.dxAForecastProposal[k] },
        { label: t('aipcmcty.page.investAndRepay.execution'), value: data.dxAForecastExecution[k] },
      ],
    ]);
    const bar4_2Data = Object.entries(data.dxAActual).map(([k, v], i) => [
      i,
      v,
      [{ label: t('aipcmcty.page.investAndRepay.execution'), value: v }],
    ]);
    const bar5_1Data = Object.entries(data.dxAForecastSumCont).map(([k, v], i) => [
      i,
      v,
      [
        { label: t('aipcmcty.page.investAndRepay.preSale'), value: data.dxAForecastPreSaleCont[k] },
        { label: t('aipcmcty.page.investAndRepay.proposal'), value: data.dxAForecastProposalCont[k] },
        { label: t('aipcmcty.page.investAndRepay.execution'), value: data.dxAForecastExecutionCont[k] },
      ],
    ]);
    const bar5_2Data = Object.entries(data.dxAActualCont).map(([k, v], i) => [
      i,
      v,
      [{ label: t('aipcmcty.page.investAndRepay.execution'), value: v }],
    ]);
    const bar1mData = Object.values(data.dxIPlan01).map((p, i) => [i, p]);
    const bar3mData = Object.values(data.dxIPlanLatest).map((p, i) => [i, p]);
    const bar4mData = Object.values(data.dxIForecastActualSum).map((p, i) => [i, p]);
    const line1Data = Object.values(data.cumPlan01).map((p, i) => [i, p]);
    const line2Data = Object.values(data.cumPlan02).map((p, i) => [i, p]);

    const sortedCums = [cumPlanLatest1, cumPlanLatest2, cumPlanLatest3].sort((a, b) => a.FY28 - b.FY28);
    const line3Data = sortedCums.map((sc) => {
      const scNo = sc.key.slice(-1);
      return {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.planLatest')}(${t(
          'aipcmcty.page.paramter'
        )}${scNo})`,
        type: 'line',
        yAxisIndex: 1,
        data: Object.values<string>(sc)
          .filter((v) => typeof v !== 'string')
          .map((p, i) => [i, p]),
      };
    });
    const line3AreaData1 = line3Data[0].data.concat([...line3Data[1].data].reverse());
    const line3AreaData2 = line3Data[1].data.concat([...line3Data[2].data].reverse());

    const line4Data = Object.values(data.cumForecastActualSum).map((p, i) => [i, p]);
    const line5Data = Object.values(data.cumForecastActualSumCont).map((p, i) => [i, p]);
    options.chartOptions.series = [
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.plan01')}`,
        type: 'bar',
        stack: 'bar1',
        // itemStyle: {
        //   color: '#195D87'
        // },
        data: bar1Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.plan02')}`,
        type: 'bar',
        stack: 'bar2',
        // itemStyle: {
        //   color: '#ED7D31'
        // },
        data: bar2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.planLatest')}(${t(
          'aipcmcty.page.paramter'
        )}1)`,
        type: 'bar',
        stack: 'bar3_1',
        // itemStyle: {
        //   color: '#A5A5A5'
        // },
        data: bar3_1Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.planLatest')}(${t(
          'aipcmcty.page.paramter'
        )}2)`,
        type: 'bar',
        stack: 'bar3_2',
        // itemStyle: {
        //   color: '#A5A5A5'
        // },
        data: bar3_2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.planLatest')}(${t(
          'aipcmcty.page.paramter'
        )}3)`,
        type: 'bar',
        stack: 'bar3_3',
        // itemStyle: {
        //   color: '#A5A5A5'
        // },
        data: bar3_3Data,
      },
      {
        name: t('aipcmcty.page.investAndRepay.forecast'),
        type: 'bar',
        stack: 'bar4',
        // itemStyle: {
        //   color: '#FFC000'
        // },
        data: bar4_1Data,
      },
      {
        name: t('aipcmcty.page.investAndRepay.actual'),
        type: 'bar',
        stack: 'bar4',
        // itemStyle: {
        //   color: '#BF9000'
        // },
        data: bar4_2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.forecast')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        type: 'bar',
        stack: 'bar5',
        // itemStyle: {
        //   color: '#9FD9F6'
        // },
        data: bar5_1Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.actual')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        type: 'bar',
        stack: 'bar5',
        // itemStyle: {
        //   color: '#5B9BD5'
        // },
        data: bar5_2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxInvestAmount')}_${t('aipcmcty.page.investAndRepay.plan01')}`,
        type: 'bar',
        stack: 'bar1',
        // itemStyle: {
        //   color: '#195D87'
        // },
        data: bar1mData,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxInvestAmount')}_${t('aipcmcty.page.investAndRepay.planLatest')}`,
        type: 'bar',
        stack: 'bar3',
        // itemStyle: {
        //   color: '#A5A5A5'
        // },
        data: bar3mData,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxInvestAmount')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}`,
        type: 'bar',
        stack: 'bar4',
        // itemStyle: {
        //   color: '#FFC000'
        // },
        data: bar4mData,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.plan01')}`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#195D87'
        // },
        data: line1Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.plan02')}`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#ED7D31'
        // },
        data: line2Data,
      },
      ...line3Data,
      {
        type: 'custom',
        yAxisIndex: 1,
        renderItem: (params, api) => {
          if (params.context.rendered) {
            return;
          }
          params.context.rendered = true;
          let points = [];
          for (let i = 0; i < line3AreaData1.length; i++) {
            points.push(api.coord(line3AreaData1[i]));
          }
          return {
            type: 'polygon',
            transition: ['shape'],
            shape: {
              points: points,
            },
            style: api.style({
              fill: '#9FD9F6',
              stroke: 'transparent',
              opacity: 0.4,
            }),
          };
        },
        clip: true,
        data: line3AreaData1,
      },
      {
        type: 'custom',
        yAxisIndex: 1,
        renderItem: (params, api) => {
          if (params.context.rendered) {
            return;
          }
          params.context.rendered = true;
          let points = [];
          for (let i = 0; i < line3AreaData2.length; i++) {
            points.push(api.coord(line3AreaData2[i]));
          }
          return {
            type: 'polygon',
            transition: ['shape'],
            shape: {
              points: points,
            },
            style: api.style({
              fill: '#9FD9F6',
              stroke: 'transparent',
              opacity: 0.4,
            }),
          };
        },
        clip: true,
        data: line3AreaData2,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#FFC000'
        // },
        data: line4Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}(${t(
          'aipcmcty.page.investAndRepay.contingency'
        )})`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#4472C4'
        // },
        data: line5Data,
      },
    ];
    options.chartOptions.legend.selected = selectedLegends;
    updateYAxis(options.chartOptions.legend.selected, options);
    setOptions(cloneDeep(options));
  };

  const updateYAxis = (selectedLegends: any[], options: any) => {
    const { max, min } = getMaxAndMinFromData(options.chartOptions.series, selectedLegends);
    forEach(options.chartOptions.yAxis, (item) => {
      item.max = max;
      item.min = min;
    });
  };

  const getMaxAndMinFromData = (source: any, unSource: any): { max: number; min: number } => {
    const unSourceKey = chain(unSource)
      .filter((u) => !u)
      .keys()
      .value();
    const nums = chain(source)
      .filter((i) => !includes(unSourceKey, i.name))
      .map('data')
      .flatten()
      .map(1)
      .value();
    const maxValue = max(nums);
    const minValue = min(nums);
    function roundUp(num: number) {
      const base = 10000000000;
      const abs = Math.abs(num / base);
      const value = ceil(abs);
      return num > 0 ? value * base : -value * base;
    }
    return { max: roundUp(maxValue), min: roundUp(minValue) };
  };

  const handleChartChange = (e) => {
    if (!e) {
      return;
    }
    const {
      event: { type, name, selected },
    } = e;
    if (type === 'legendselectchanged') {
      if (['実績', '見込み'].includes(name)) {
        options.chartOptions.legend.selected = {
          ...selected,
          実績: selected[name],
          見込み: selected[name],
        };
        setLegendSelected(options.chartOptions.legend.selected);
        setOptions(cloneDeep(options));
      } else if (['見込み(コンテ込み)', '実績(コンテ込み)'].includes(name)) {
        options.chartOptions.legend.selected = {
          ...selected,
          '見込み(コンテ込み)': selected[name],
          '実績(コンテ込み)': selected[name],
        };
        setLegendSelected(options.chartOptions.legend.selected);
        setOptions(cloneDeep(options));
      } else {
        const paramsSelected = Object.entries(selected)
          .filter(([k]) => k.includes('Cumulative_最新計画'))
          .map(([_, v]) => v);
        if (paramsSelected.some((ps) => !ps)) {
          options.chartOptions.series = options.chartOptions.series.filter((s) => s.type !== 'custom');
          options.chartOptions.legend.selected = selected;
          setOptions(cloneDeep(options));
        } else {
          initChartData(selected);
        }
        setLegendSelected(selected);
      }
    }
  };
  return (
    <>
      <TableChart
        tooltips={[]}
        title={options.title}
        showTable={false}
        chartOptions={options.chartOptions}
        height={options.height}
        loading={loading}
        onChartEvent={handleChartChange}
      />
    </>
  );
};

export default InvestmentReturnChart;
