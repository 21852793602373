import {
  Button,
  Card,
  Table,
  Modal,
  Tag,
} from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import menuRoutes from '../routes';

const PermissionViewer: React.FC<any> = (props: any) => {
  const {
    showPermission,
    setShowPermission,
    modalWidth,
    modalHeight,
  } = props;

  const { authInfo } = useContext(AppContext);
  const { user } = authInfo;
  const { role, groups } = user;
  let defaultModalHeight = 500;
  let defaultModalWidth = 500;

  if (typeof window !== 'undefined') {
    defaultModalHeight = document.body.clientHeight - 200;
    defaultModalWidth = document.body.clientWidth - 100;
  }

  function findPageName(routes, key: any) {
    for (let route of routes) {
      for (let child of route.children) {
        if (child.path === key) {
          return `${route.label} - ${child.label}`;
        }
      }
    }
  }

  return (
    <>
      <Modal
        title="Permission Viewer"
        onOk={() => {
          setShowPermission(false);
        }}
        onCancel={() => {
          setShowPermission(false);
        }}
        width={modalWidth || defaultModalWidth}
        centered
        styles={{
          body: {
            overflow: 'scroll',
            maxHeight: modalHeight || defaultModalHeight,
          },
        }}
        open={showPermission}
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={() => {
              setShowPermission(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <Card style={{ marginTop: '10px' }} title="User Groups">
          {groups?.map((groupName) => (
            <Tag key={`${groupName}`} color="blue">
              {groupName}
            </Tag>
          ))}
        </Card>
        <Card style={{ marginTop: '10px' }} title="Page Authorizations">
          <Table
            size="small"
            pagination={false}
            bordered
            columns={[
              {
                key: 'pageName',
                title: 'Page Name',
                dataIndex: 'pageName',
              },
              {
                key: 'allAuth',
                title: 'All Auth',
                dataIndex: 'allAuth',
                render: (text) => {
                  return text?.split(',')?.map((auth, index) => (
                    <Tag key={`${auth}-${index}`} color="orange">
                      {auth}
                    </Tag>
                  ));
                },
              },
              {
                key: 'auth',
                title: 'Final Auth',
                dataIndex: 'auth',
                render: (text) => <Tag color="red">{text}</Tag>,
              },
              {
                key: 'tgc',
                title: 'Auth TGC',
                dataIndex: 'tgc',
                render: (text, record, index) => {
                  return text.length > 0 ? (
                    record.auth === 'all' ? (
                      <Tag color="green">全体</Tag>
                    ) : (
                      text?.split(',')?.map((tgc) => (
                        <Tag key={tgc} color="cyan">
                          {tgc}
                        </Tag>
                      ))
                    )
                  ) : (
                    '-'
                  );
                },
              },
            ]}
            dataSource={role?.map((item: any) => ({
              ...item,
              tgc: item?.tgc?.map((auth) => (auth ? auth : '-')).join(', '),
              pageName: findPageName(menuRoutes.routes, item.route),
              allAuth: item?.allAuth?.map((auth) => (auth ? auth : 'no')).join(', '),
              auth: item?.auth ? item?.auth : 'no',
            }))}
          />
        </Card>
      </Modal>
    </>
  );
};

export default PermissionViewer;
