import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Radio, Row, Select, Space, Table } from 'antd';
import { groupBy, mergeWith, round, sum, upperFirst } from 'lodash';
import APIList from '../../http/ApiList';
import useVersion from '../../hooks/useVersion';
import { useTranslation } from 'react-i18next';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import Paramter from '../../components/paramter';

type Option = {
  label: ReactNode;
  value: any;
  key?: any;
  [key: string]: any;
};

type GapAnalysisItem = {
  snapshot: string;
  snapshotVersion: string;
  fiscalYear: string;
  /** Scope Type */
  scope: string;
  /** DX Allow. Gross（プロポーザル実績+Forecast） */
  dxInfluence: number;
  /** DX Allow. Gross（プロポーザル前） */
  dxAllowGrossFPPre: number;
  /** DX Allow. Gross（プロポーザル実績） */
  dxAllowGrossPro: number;
  /** プロポーザル積込率（実績） */
  dxAllowGrossProVsFP: number;
  /** DX Allow. Gross（フルポテ、受注済み＋プロポーザル済み） */
  dxAllowGrossFPProExe: number;
  /** 受注済みDXAllow.Gross（フルポテ） */
  dxAllowGrossFPExe: number;
  /** プロポーザル済みDXAllow.Gross（フルポテ） */
  dxAllowGrossFPPro: number;
  /** DX Allow. Gross（フルポテ） */
  dxAllowGrossFP: number;
  /** KPI2’（DX Allow.係数）
  DXによる粗利向上効果 */
  dxGrossVsOdRate: number;
  /** DX適用可能な受注金額 */
  dxAllowOrderAmount: number;
  /** 受注金額 */
  sbOrder: number;
  /** 適用可能率 */
  dxApplicableRatio: number;
  /** DX課金（プロポーザル後） */
  dxAllowChargePro: number;
  /** DX Allow. Net（プロポーザル後） */
  dxAllowNetPro: number;
};

const baseDivider = 1000000;

const GapAnalysisPage: React.FC = () => {
  const { paramData, setParamData } = useContext(AipcmctyContext);
  const { t } = useTranslation();
  const [pageInit, setPageInit] = useState(false);
  const { snapshot, snapshotVersion, compareVersion, setCompareVersion, versionOptCollection, snapshotList } = useVersion(pageInit);

  const [tableLoading, setTableLoading] = useState(false);
  const [filterOpts, setFilterOpts] = useState({
    fyOpts: [],
    tgcOpts: [],
    budgetOpts: []
  });
  const [filter, setFilter] = useState({
    fiscalYear: 'FY24',
    tarSnapshot: compareVersion.snapshot,
    tarVersion: compareVersion.snapshotVersion,
    tgc: [],
    budgets: ['Awarded', 'Budget'/* , 'IF' */]
  });
  const [orgData, setOrgData] = useState<GapAnalysisItem[]>([]);
  const [tableType, setTableType] = useState(TABLE_CATEGORY.TREE);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      tarSnapshot: compareVersion.snapshot,
      tarVersion: compareVersion.snapshotVersion,
    }));
  }, [compareVersion]);

  useEffect(() => {
    if (!tableLoading && compareVersion.snapshot && compareVersion.snapshotVersion) {
      initialize();
    }
  }, [compareVersion]);

  const getBudgetCategoryOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'budgetCategory',
      snapshot: snapshot,
      snapshotVersion: snapshotVersion,
    })) as Option[];
    const budgetOpts = res.filter(r => r.value !== 'Exclusion').map((r) => ({
      key: r.value,
      label: r.value,
      value: r.value,
    }));
    setFilterOpts((prev) => ({
      ...prev,
      budgetOpts,
    }));
    const selectedBudgets = budgetOpts
      .filter((b) => ['Awarded', 'Budget'/* , 'IF' */].includes(b.value))
      .map(b => b.value);
    setFilter((prev) => ({ ...prev, budgets: selectedBudgets }));
    return selectedBudgets;
  };

  const getFiscalYearsOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'accountingYear',
      snapshot: snapshot,
      snapshotVersion: snapshotVersion,
    })) as Option[];
    const fyOpts = res.map((r) => ({
      key: r.value,
      label: r.value,
      value: r.value,
    }));
    setFilterOpts((prev) => ({
      ...prev,
      fyOpts,
    }));
    const selectedFy = fyOpts.find((f) => f.value === 'FY24');
    setFilter((prev) => ({ ...prev, fiscalYear: selectedFy.value }));
    return selectedFy.value;
  };

  const getTgcOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'tgc',
      snapshot,
      snapshotVersion,
    })) as Option[];
    const tgcOpts = res
      .filter((r) => r.attribute1 === 'subsidiary')
      .map((r) => ({
        key: r.value,
        label: r.value,
        value: r.value,
      }));
    setFilterOpts((prev) => ({
      ...prev,
      tgcOpts,
    }));
    const selectedTgc = tgcOpts.map((r) => r.value);
    setFilter((prev) => ({ ...prev, tgc: selectedTgc }));
    return selectedTgc;
  };

  const getTableData = async (
    tgc: string[],
    budgets: string[],
    fiscalYear: string,
    curSnapshot: string,
    curVersion: string,
    tarSnapshot: string,
    tarVersion: string,
  ) => {
    const data = (await APIList.getGapAnalysis().get({
      tgc,
      curSnapshot,
      curVersion,
      tarSnapshot,
      tarVersion,
      fiscalYear,
      budgets
    })) as GapAnalysisItem[];
    setOrgData(data);
  };

  const initialize = async () => {
    setTableLoading(true);
    setPageInit(true);
    const [selectedFy, selectedTgc, selectedBudgets] = await Promise.all([
      getFiscalYearsOptions(),
      getTgcOptions(),
      getBudgetCategoryOptions()
    ]);
    await getTableData(
      selectedTgc,
      selectedBudgets,
      selectedFy,
      snapshot,
      snapshotVersion,
      compareVersion.snapshot,
      compareVersion.snapshotVersion,
    );
    setTableLoading(false);
  };

  const handleRadioChange = (radioType: TABLE_CATEGORY) => {
    setTableType(radioType);
  };

  const handleFilterChange = (e: string | string[], key: string) => {
    setFilter((prev) => ({
      ...prev,
      [key]: e,
    }));
  };

  const handleCompareClick = async () => {
    setCompareVersion({
      snapshot: filter.tarSnapshot,
      snapshotVersion: filter.tarVersion,
    });
    setTableLoading(true);
    await getTableData(
      filter.tgc,
      filter.budgets,
      filter.fiscalYear,
      snapshot,
      snapshotVersion,
      filter.tarSnapshot,
      filter.tarVersion,
    );
    setTableLoading(false);
  };

  const keyMaps = {
    /** DX Allow. Gross（プロポーザル実績+Forecast） */
    dxInfluence: 'DX Allow. Gross（プロポーザル実績+Forecast）',
    /** DX Allow. Gross（プロポーザル前） */
    dxAllowGrossFPPre: 'DX Allow. Gross（プロポーザル前）',
    /** DX Allow. Gross（プロポーザル実績） */
    dxAllowGrossPro: 'DX Allow. Gross（プロポーザル実績）',
    /** プロポーザル積込率（実績） */
    dxAllowGrossProVsFP: 'プロポーザル積込率（実績）',
    /** DX Allow. Gross（フルポテ、受注済み＋プロポーザル済み） */
    dxAllowGrossFPProExe: 'DX Allow. Gross（フルポテ、受注済み＋プロポーザル済み）',
    /** 受注済みDXAllow.Gross（フルポテ） */
    dxAllowGrossFPExe: '受注済みDXAllow.Gross（フルポテ）',
    /** プロポーザル済みDXAllow.Gross（フルポテ） */
    dxAllowGrossFPPro: 'プロポーザル済みDXAllow.Gross（フルポテ）',
    /** DX Allow. Gross（フルポテ） */
    dxAllowGrossFP: 'DX Allow. Gross（フルポテ）',
    /** KPI2’（DX Allow.係数）
    DXによる粗利向上効果 */
    dxGrossVsOdRate: 'KPI2’（DX Allow.係数）',
    /** DX適用可能な受注金額 */
    dxAllowOrderAmount: 'DX適用可能な受注金額',
    /** 受注金額 */
    sbOrder: '受注金額',
    /** 適用可能率 */
    dxApplicableRatio: '適用可能率',
    /** DX課金（プロポーザル後） */
    dxAllowChargePro: 'DX課金（プロポーザル後）',
    /** DX Allow. Net（プロポーザル後） */
    dxAllowNetPro: 'DX Allow. Net（プロポーザル後）',
  };

  const swapAxisToObject = (data: any[], baseKey: string) => {
    if (!data.length) {
      console.log('Data is Empty!');
      return [];
    }
    const transToNumberKeys = [
      'dxInfluence',
      'dxAllowGrossFPPre',
      'dxAllowGrossPro',
      'dxAllowGrossProVsFP',
      'dxAllowGrossFPProExe',
      'dxAllowGrossFPExe',
      'dxAllowGrossFPPro',
      'dxAllowGrossFP',
      'dxGrossVsOdRate',
      'dxAllowOrderAmount',
      'sbOrder',
      'dxApplicableRatio',
      'dxAllowChargePro',
      'dxAllowNetPro',
    ];
    const transformKeys = Object.keys(data[0]).filter((k) => k !== baseKey).concat('dxInfluence');
    const transformedData = transformKeys.reduce((prev, k) => {
      prev[k] = {
        key: k,
        title: keyMaps[k],
      };
      return prev;
    }, {} as any);
    data.forEach((d) => {
      transformKeys.forEach((k) => {
        if (d[baseKey] === snapshot) {
          transformedData[k][`${d[baseKey]}_1`] = transToNumberKeys.includes(k) ? Number(d[k]) : d[k];
          transformedData[k][`${d[baseKey]}_2`] = transToNumberKeys.includes(k) ? Number(d[k]) : d[k];
          transformedData[k][`${d[baseKey]}_3`] = transToNumberKeys.includes(k) ? Number(d[k]) : d[k];
        } else {
          transformedData[k][`${d[baseKey]}`] = transToNumberKeys.includes(k) ? Number(d[k]) : d[k];
        }
      });
    });
    return transformedData;
  };

  const genTreeDataAndCols = (data: GapAnalysisItem[]) => {
    const rateDxAllowGrossFPPre = (dxAllowGrossFPPreObj) => {
      const { progressRate: progressRate1, accumulateRate: accumulateRate1 } = paramData.param1;
      const { progressRate: progressRate2, accumulateRate: accumulateRate2 } = paramData.param2;
      const { progressRate: progressRate3, accumulateRate: accumulateRate3 } = paramData.param3;
      dxAllowGrossFPPreObj[`${snapshot}_1`] = (dxAllowGrossFPPreObj[`${snapshot}_1`] ?? 0) * progressRate1 * accumulateRate1;
      dxAllowGrossFPPreObj[`${snapshot}_2`] = (dxAllowGrossFPPreObj[`${snapshot}_2`] ?? 0) * progressRate2 * accumulateRate2;
      dxAllowGrossFPPreObj[`${snapshot}_3`] = (dxAllowGrossFPPreObj[`${snapshot}_3`] ?? 0) * progressRate3 * accumulateRate3;
    };

    const sumDxInfluence = (data) => {
      const { dxAllowGrossFPPre, dxAllowGrossPro } = data;
      data.dxInfluence = {
        ...data.dxInfluence,
        [compareVersion.snapshot]: dxAllowGrossFPPre[compareVersion.snapshot] + dxAllowGrossPro[compareVersion.snapshot],
        [`${snapshot}_1`]: dxAllowGrossFPPre[`${snapshot}_1`] + dxAllowGrossPro[`${snapshot}_1`],
        [`${snapshot}_2`]: dxAllowGrossFPPre[`${snapshot}_2`] + dxAllowGrossPro[`${snapshot}_2`],
        [`${snapshot}_3`]: dxAllowGrossFPPre[`${snapshot}_3`] + dxAllowGrossPro[`${snapshot}_3`],
      };
    };

    const roiItems = data.filter((o) => o.scope === 'total');
    if (!roiItems.length) {
      return { data: [], cols: [] };
    }
    const treeData = swapAxisToObject(roiItems, 'snapshot');
    rateDxAllowGrossFPPre(treeData.dxAllowGrossFPPre);
    sumDxInfluence(treeData);
    const dataSource = [
      {
        ...treeData.dxInfluence,
        children: [
          treeData.dxAllowGrossFPPre
        ]
      },
      {
        ...treeData.dxAllowGrossPro,
        children: [
          treeData.dxAllowGrossProVsFP,
          {
            ...treeData.dxAllowGrossFPProExe,
            children: [
              treeData.dxAllowGrossFPExe,
              treeData.dxAllowGrossFPPro
            ]
          },
          {
            ...treeData.dxAllowGrossFP,
            children: [
              treeData.dxGrossVsOdRate,
              {
                ...treeData.dxAllowOrderAmount,
                children: [
                  treeData.sbOrder,
                  treeData.dxApplicableRatio,
                ]
              }
            ]
          },
          treeData.dxAllowChargePro,
          treeData.dxAllowNetPro
        ]
      }
    ];
    const cols = [
      {
        title: t('aipcmcty.page.gapAnalyze.logicTree'),
        key: 'title',
        dataIndex: 'title',
        className: 'text-left',
        fixed: 'left',
        width: 460,
        render: (val, item) => {
          const isGray = [
            'dxInfluence',
            'dxAllowGrossFPPre',
            'dxAllowGrossFPExe',
            'dxAllowGrossFPPro',
          ].includes(item.key);
          return <span style={{ color: isGray ? '#a8a8a8' : undefined }}>{val}</span>;
        }
      },
      {
        title: filter.tarSnapshot,
        key: filter.tarSnapshot,
        dataIndex: filter.tarSnapshot,
        className: 'text-right',
        render: (val, item) => {
          const isRate = ['dxAllowGrossProVsFP', 'dxGrossVsOdRate', 'dxApplicableRatio'].includes(item.key);
          if (isRate) {
            return ![undefined, null].includes(val) ? `${(val * 100).toFixed(1)}%` : '-';
          }
          return ![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-';
        },
      },
      {
        title: `${snapshot}(${t('aipcmcty.page.paramter')}1)`,
        key: `${snapshot}_1`,
        dataIndex: `${snapshot}_1`,
        className: 'text-right',
        render: (val, item) => {
          const isRate = ['dxAllowGrossProVsFP', 'dxGrossVsOdRate', 'dxApplicableRatio'].includes(item.key);
          if (isRate) {
            return ![undefined, null].includes(val) ? `${(val * 100).toFixed(1)}%` : '-';
          }
          return ![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-';
        },
      },
      {
        title: `${snapshot}(${t('aipcmcty.page.paramter')}2)`,
        key: `${snapshot}_2`,
        dataIndex: `${snapshot}_2`,
        className: 'text-right',
        render: (val, item) => {
          const isRate = ['dxAllowGrossProVsFP', 'dxGrossVsOdRate', 'dxApplicableRatio'].includes(item.key);
          if (isRate) {
            return ![undefined, null].includes(val) ? `${(val * 100).toFixed(1)}%` : '-';
          }
          return ![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-';
        },
      },
      {
        title: `${snapshot}(${t('aipcmcty.page.paramter')}3)`,
        key: `${snapshot}_3`,
        dataIndex: `${snapshot}_3`,
        className: 'text-right',
        render: (val, item) => {
          const isRate = ['dxAllowGrossProVsFP', 'dxGrossVsOdRate', 'dxApplicableRatio'].includes(item.key);
          if (isRate) {
            return ![undefined, null].includes(val) ? `${(val * 100).toFixed(1)}%` : '-';
          }
          return ![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-';
        },
      },
    ];
    return { data: dataSource, cols };
  };

  const genTableDataAndCols = (data: GapAnalysisItem[]) => {
    if (!data.length) {
      return { data: [], cols: [] };
    }
    const gListItems = groupBy(data, 'scope');
    const dataSource = Object.keys(gListItems).reduce((prev, cur) => {
      const tItem = gListItems[cur].find((g) => g.snapshot === filter.tarSnapshot);
      const cItem = gListItems[cur].find((g) => g.snapshot === snapshot);
      const item = {
        scope: cur,
        tarDxAllowance: tItem?.dxAllowGrossFP,
        curDxAllowance: cItem?.dxAllowGrossFP,
        tarDxAOrderAmount: tItem?.dxAllowOrderAmount,
        curDxAOrderAmount: cItem?.dxAllowOrderAmount,
        tarDxNetVsOdRate: tItem?.dxGrossVsOdRate,
        curDxNetVsOdRate: cItem?.dxGrossVsOdRate,
        tar: filter.tarSnapshot,
        cur: snapshot,
      };
      prev.push(item);
      return prev;
    }, []);
    const cols = [
      {
        title: '',
        key: 'scope',
        dataIndex: 'scope',
        className: 'text-right',
        fixed: 'left',
        render: (val) => upperFirst(val),
      },
      {
        title: t('aipcmcty.page.gapAnalyze.dxAllowance'),
        dataIndex: 'dxAllowance',
        className: 'text-center',
        children: [
          {
            title: filter.tarSnapshot,
            dataIndex: 'tarDxAllowance',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
          {
            title: snapshot,
            dataIndex: 'curDxAllowance',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
        ],
      },
      {
        title: t('aipcmcty.page.gapAnalyze.dxAllowOrderAmount'),
        dataIndex: 'dxAOrderAmount',
        className: 'text-right',
        children: [
          {
            title: filter.tarSnapshot,
            dataIndex: 'tarDxAOrderAmount',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
          {
            title: snapshot,
            dataIndex: 'curDxAOrderAmount',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
        ],
      },
      {
        title: t('aipcmcty.page.gapAnalyze.dxNetVsOdRateDetail'),
        dataIndex: 'dxNetVsOdRate',
        className: 'text-right',
        children: [
          {
            title: filter.tarSnapshot,
            dataIndex: 'tarDxNetVsOdRate',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? `${Number(val * 100).toFixed(1)}%` : '-'),
          },
          {
            title: snapshot,
            dataIndex: 'curDxNetVsOdRate',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? `${Number(val * 100).toFixed(1)}%` : '-'),
          },
        ],
      },
    ];
    return { data: dataSource, cols };
  };

  const render = (data: GapAnalysisItem[], tableType: TABLE_CATEGORY) => {
    let dataSource = [];
    let cols = [];
    let key = null;
    if (tableType === TABLE_CATEGORY.TREE) {
      const res = genTreeDataAndCols(data);
      dataSource = res.data;
      cols = res.cols;
      key = 'key';
    }

    if (tableType === TABLE_CATEGORY.TABLE) {
      const res = genTableDataAndCols(data);
      dataSource = res.data;
      cols = res.cols;
      key = 'scope';
    }

    return (
      <Table
        expandable={{
          defaultExpandedRowKeys: [
            'dxAllowGrossPro',
            'dxAllowGrossFP',
            'dxAllowOrderAmount',
          ],
        }}
        style={{ background: '#FFF' }}
        scroll={{ y: window.innerHeight - 315 }}
        pagination={false}
        columns={cols}
        dataSource={dataSource}
        loading={tableLoading}
        className="scroll-table"
        size={'small'}
        bordered
        rowKey={key}
      />
    );
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row
          justify="space-between"
          className="operation-container"
          style={{ backgroundColor: 'white', padding: '0 16px', marginBottom: 2 }}
        >
          <Col style={{ padding: '5px 0' }}>
            <Space>
              {t('aipcmcty.page.gapAnalyze.fiscalYear')}:
              <Select
                style={{ width: 120 }}
                options={filterOpts.fyOpts}
                value={filter.fiscalYear}
                onChange={(e) => handleFilterChange(e, 'fiscalYear')}
              />
              {t('aipcmcty.page.budgetCategoryCustom')}:
              <Select
                allowClear
                mode="multiple"
                style={{ width: 220 }}
                maxTagCount="responsive"
                options={filterOpts.budgetOpts}
                value={filter.budgets}
                onChange={(e) => handleFilterChange(e, 'budgets')}
              />
              {t('aipcmcty.page.gapAnalyze.compareSnapshot')}:
              <Select
                style={{ width: 180 }}
                options={snapshotList}
                value={filter.tarSnapshot}
                onChange={(e) => handleFilterChange(e, 'tarSnapshot')}
              />
              {t('aipcmcty.page.gapAnalyze.compareVersion')}:
              <Select
                style={{ width: 180 }}
                options={versionOptCollection[filter.tarSnapshot]}
                value={filter.tarVersion}
                onChange={(e) => handleFilterChange(e, 'tarVersion')}
              />
              <Button type="primary" onClick={handleCompareClick}>
                {t('aipcmcty.page.gapAnalyze.compare')}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Card>
              <div style={{ paddingBottom: 30, marginBottom: 20 }}>
                <div style={{ marginRight: 20, float: 'right' }}>
                  <span style={{ marginRight: 15 }}>{t('aipcmcty.page.gapAnalyze.unit')}</span>
                  <Radio.Group buttonStyle="solid" onChange={(e) => handleRadioChange(e.target.value)} value={tableType}>
                    <Radio.Button value={TABLE_CATEGORY.TREE}>DX-ROI</Radio.Button>
                    <Radio.Button value={TABLE_CATEGORY.TABLE}>DX-DETAIL</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              {render(orgData, tableType)}
            </Card>
          </Col>
          <Col span={6}>
            <Paramter
              defaultValue={paramData}
              onChange={(data) => {
                setParamData(data);
              }}
              hasCute={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

enum TABLE_CATEGORY {
  TREE,
  TABLE,
}

export default GapAnalysisPage;
