import { SiderMenuLayout, Header, SelectLang, ChatUIBackend, ThemeSettings, themeList, Menu, Loading } from '@meteor/frontend-core';
import { CHAT_KEYWORDS } from '../consts/chat-keywords';
import { chatMessageConfig, messageMapping } from '../consts/chat-message';
import { defaultTheme } from '../consts/default-configuration';
import AipcmctyContextProvider from '../contexts/provider/aipcmcty.provider.context';
import i18n from '../locales/i18n';
import menuRoutes from '../routes';
import { AppContext } from '../contexts/AppContext';
import { useContext, useState, useMemo, useEffect } from 'react';
import { Badge, Card, Col, ConfigProvider, Divider, InputNumber, Popover, Radio, RadioChangeEvent, Row, Select, Space, Switch } from 'antd';
import useStorage from '../hooks/useStorage';
import { KeyOutlined, LogoutOutlined, RadiusSettingOutlined, SettingOutlined } from '@ant-design/icons';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';
import { isEmpty } from 'lodash';
import PermissionViewer from '../components/permission-viewer';

export default function Layout() {
  const {
    authInfo,
    color,
    setColor,
    setLocale,
    setChatEvent,
    configuration,
    setConfiguration,
    echartTheme,
    setEchartTheme,
    customEchartTheme,
    setCustomEchartTheme,
    themeName,
    showSettings,
    setShowSettings,
    periodSwitch,
    setPeriodSwitch,
    fiscalQuarter,
    setFiscalQuarter,
    setDefaultFiscalQuarter,
    periodList,
    certainty,
    setCertainty,
    demandMhPercent,
    setDemandMhPercent,
    menuCollapsed,
    setMenuCollapsed,
    contextLoading,
    canLeave,
  } = useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();
  const { authRoutes } = useAuth();
  const [isAuthed, setIsAuthed] = useState(false);
  const [showPermission, setShowPermission] = useState(false);

  const { getItem, setItem } = useStorage();
  const { t } = useTranslation();
  const curMenuItem = useMemo(() => location.pathname.split('/').at(-1), [location]);

  // Router Guard
  useEffect(() => {
    if (isEmpty(authRoutes) || !authInfo?.user?.role || location.pathname === '/auth/login') {
      return;
    }
    const curRouteKey = location.pathname;
    // const isAuthed = authInfo.user.role.some(({ route, auth }) => route === curRouteKey && auth);
    const isAuthed = authRoutes['routes'].find((route) =>
      route.children.some((child) => child['authorized'] && child.path === curRouteKey)
    );

    if (isAuthed) {
      setIsAuthed(true);
      return;
    }
    const authedRoute = authRoutes['routes']
      .filter((route) => route.children.some((child) => child['authorized']))
      .flatMap((route) => route.children)
      .find((route) => route['authorized']);
    if (authedRoute) {
      navigate(authedRoute.path, { replace: true });
      return;
    }
  }, [location.pathname, authInfo?.user?.role]);

  const [selectedModule] = useState<any>(menuRoutes);

  const computeSettingContents = [
    {
      header: t('aipcmcty.page.budgetRevenueCalculation'),
      contents: {
        type: 'radio',
        value: certainty,
        onChange: ({ target: { value } }: RadioChangeEvent) => {
          setItem('salesCompute', `${value}`, 'local');
          setCertainty(value);
        },
        data: [
          {
            value: 0,
            label: t('aipcmcty.page.wonPercentageNotConsidered'),
          },
          {
            value: 1,
            label: t('aipcmcty.page.wonPercentageConsidered'),
          },
        ],
      },
      buttons: [],
    },
    {
      header: t('aipcmcty.page.supplyDemandGapAllowed'),
      contents: {
        type: 'number',
        value: demandMhPercent,
        onChange: (value: number) => {
          setItem('demandMhPercent', `${value}`, 'local');
          setDemandMhPercent(value);
        },
      },
    },
  ];

  const computeSetting = () => (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        {computeSettingContents.map((aiRecommendationContent) => (
          <Card key={aiRecommendationContent.header} title={aiRecommendationContent.header} size="small">
            <Row gutter={16} wrap={false}>
              <Col flex="auto">
                <Space direction="vertical" split={<Divider />} style={{ width: '100%' }} size={0}>
                  {aiRecommendationContent.contents.type === 'radio' ? (
                    <>
                      <Radio.Group
                        onChange={aiRecommendationContent.contents.onChange as (e: RadioChangeEvent) => void}
                        value={aiRecommendationContent.contents.value}
                        size="large"
                      >
                        {aiRecommendationContent.contents.data!.map((content) => (
                          <Radio key={content.label} value={content.value}>
                            <span>{content.label}</span>
                          </Radio>
                        ))}
                      </Radio.Group>
                    </>
                  ) : (
                    <></>
                  )}
                  {aiRecommendationContent.contents.type === 'number' ? (
                    <InputNumber
                      addonAfter="%"
                      value={aiRecommendationContent.contents.value}
                      onChange={aiRecommendationContent.contents.onChange as (value: number | null) => void}
                    />
                  ) : (
                    <></>
                  )}
                </Space>
              </Col>
            </Row>
          </Card>
        ))}
      </Space>
    </>
  );

  return authInfo && authInfo.user ? (
    <SiderMenuLayout
      header={
        <Header
          moduleInfo={selectedModule}
          userInfo={authInfo}
          color={color}
          otherOperations={[
            <SelectLang i18n={i18n} color={color} setLocale={setLocale} />,
            <ChatUIBackend
              configuration={{ avatar: process.env.REACT_APP_DOMAIN + '/assets/counsel-gpt.png' }}
              onChatEvent={setChatEvent}
              CHAT_KEYWORDS={CHAT_KEYWORDS}
              chatMessageConfig={chatMessageConfig}
              messageMapping={messageMapping}
              openaiConfig={process.env}
              color={color}
              userId={authInfo.user.attributes.sub}
            />,
            <ThemeSettings
              color={color}
              setColor={setColor}
              configuration={configuration}
              setConfiguration={setConfiguration}
              echartTheme={echartTheme}
              setEchartTheme={setEchartTheme}
              customEchartTheme={customEchartTheme}
              setCustomEchartTheme={setCustomEchartTheme}
              antdTheme={themeName}
              defaultTheme={defaultTheme}
              registerList={Object.keys(themeList)}
              showSettings={showSettings}
              setShowSettings={setShowSettings}
            />,
            <PermissionViewer showPermission={showPermission} setShowPermission={setShowPermission} />,
            <div style={{ float: 'right' }}>
              <div
                style={{
                  // display: 'flex',
                  // alignItems: 'center',
                  float: 'left',
                  marginLeft: 20,
                }}
              >
                <Switch
                  className="record-element-period-switch"
                  defaultChecked
                  checkedChildren={t('aipcmcty.page.quarter')}
                  unCheckedChildren={t('aipcmcty.page.year')}
                  checked={periodSwitch}
                  style={{
                    width: 80,
                    background: color.theme === 'dark' ? color.primaryColor : '#ffffff',
                    margin: '0 8px',
                    boxShadow: '0 0 0 2px #ffffff',
                  }}
                  onChange={(value) => {
                    setPeriodSwitch(value);
                  }}
                />
              </div>
              <div
                style={{
                  // display: 'flex',
                  // alignItems: 'center',
                  float: 'left',
                  marginLeft: 20,
                }}
              >
                <label
                  style={{
                    fontWeight: 700,
                    float: 'left',
                    color: color.theme === 'dark' ? '#ffffff' : color.primaryColor,
                  }}
                >
                  {`${t('aipcmcty.page.period')}: `}
                </label>
                <div style={{ marginLeft: 8, float: 'left' }}>
                  <Select
                    mode="multiple"
                    value={fiscalQuarter}
                    className="record-element-fiscal-switch select-height-32"
                    style={{ width: 160 }}
                    maxTagCount={1}
                    maxTagTextLength={6}
                    onChange={(value) => {
                      if (value.length <= 5) {
                        setFiscalQuarter(value);
                        if (!periodSwitch) {
                          setDefaultFiscalQuarter(value);
                        }
                      }
                    }}
                    options={periodList}
                  />
                </div>
              </div>
            </div>,
            <Popover content={computeSetting} trigger="click">
              <div style={{ float: 'right', paddingLeft: 25 }}>
                <Badge
                  size="small"
                  style={{
                    backgroundColor: color.errorColor,
                  }}
                >
                  <RadiusSettingOutlined
                    style={{
                      fontSize: 24,
                      marginRight: 8,
                      top: 4,
                      position: 'relative',
                      color: color.theme === 'dark' ? '#ffffff' : color.primaryColor,
                    }}
                  />
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      top: 1,
                      position: 'relative',
                      paddingRight: 12,
                      color: color.theme === 'dark' ? '#ffffff' : color.primaryColor,
                    }}
                  >
                    {t('aipcmcty.page.calculationSettings')}
                  </span>
                </Badge>
              </div>
            </Popover>,
          ]}
          userOperations={[
            {
              key: 'config',
              label: (
                <span>
                  <SettingOutlined />
                  <span style={{ padding: 8 }}>Settings</span>
                </span>
              ),
              onSelect: () => {
                setShowSettings(true);
              },
            },
            {
              key: 'permission',
              label: (
                <span>
                  <KeyOutlined />
                  <span style={{ padding: 8 }}>Permission</span>
                </span>
              ),
              onSelect: () => {
                setShowPermission(true);
              },
            },
            {
              key: 'logout',
              label: (
                <span>
                  <LogoutOutlined />
                  <span style={{ padding: 8 }}>Logout</span>
                </span>
              ),
              onSelect: async () => {
                await authInfo.signOut();
              },
            },
          ]}
        />
      }
      menu={
        <Menu
          selectedMenuKey={curMenuItem}
          menuItems={authRoutes['routes']
            .filter((route) => route.children.some((child) => child['authorized']))
            .map((route) => ({
              ...route,
              label: route.labeli18n ? t(route.labeli18n) : route.label,
              children: route.children
                .filter((route) => route['authorized'])
                .map((r) => ({
                  ...r,
                  label: r.labeli18n ? t(r.labeli18n) : r.label,
                })),
            }))}
          color={color}
          menuCollapsed={menuCollapsed}
          canLeave={canLeave}
        />
      }
      content={
        isAuthed && (
          <AipcmctyContextProvider>
            <Outlet />
          </AipcmctyContextProvider>
        )
      }
      contentLoading={contextLoading}
      color={color}
      moduleConfig={selectedModule}
      collapsed={menuCollapsed}
      onCollapsed={setMenuCollapsed}
    />
  ) : (
    <Loading />
  );
}
