import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';

import KpiTreePage from '../pages/dashboard/kpi-tree';
import OverallPage from '../pages/dashboard/overall';
import TypicalKpiPage from '../pages/dashboard/typical-kpi';
import MatchingResultPage from '../pages/simulation/matching-result';
import ProjectSetup from '../pages/simulation/project-setup';
import SupplyValidationPage from '../pages/simulation/supply-validation';
import { Loading, SignIn } from '@meteor/frontend-core';
import ComparisonResourcePage from '../pages/comparison/resource';
import ComparisonPage from '../pages/comparison/project';
import Record from '../pages/management/record';
import ActionPage from '../pages/management/action';
import MasterSetupPage from '../pages/management/master-setup';
import Root from '../pages/Root';
import Layout from '../pages/Layout';
import DXDiffPage from './demo/dx-diff';
import GapAnalysisPage from './demo/gap-analysis';
import MonthlyBusinessReport from './demo/monthly-business-report';

import InvestmentReturnPage from './demo/investment-return';
import ResourceRegulation from './simulation/resource-regulation';
import PortfolioMonitor from './comparison/portfolio-monitor';
import TrinityEvaluationPage from './comparison/trinity-evaluation';
import TrinityComparisonPage from './comparison/trinity-comparison';
import ResourceSimulation from './simulation/resource-simulation';
import SubDiffPage from './comparison/sub-diff';
import PDiffPage from './comparison/p-diff';
import ExtractResources from './demo/extract-resources';
import SalesReport from './report/sales-report';

export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<Root />}>
          <Route
            path="/auth/login"
            element={
              <SignIn
                redirect={!!process.env.REACT_APP_LOGIN_ONLINE_UI}
                federatedIdentifyProvider={process.env.REACT_APP_FEDERATED_IDENTIFY_PROVIDER}
              />
            }
          />
          <Route path="/aipcmcty" element={<Layout />}>
            <Route path="/aipcmcty/dashboard/overall" element={<OverallPage />} />
            <Route path="/aipcmcty/dashboard/kpi-tree" element={<KpiTreePage />} key={'kpi-tree'} />
            <Route path="/aipcmcty/dashboard/typical-kpi" element={<TypicalKpiPage />} />
            <Route path="/aipcmcty/simulation/project-setup" element={<ProjectSetup />} />
            <Route path="/aipcmcty/resource/matching-result" element={<MatchingResultPage />} />
            <Route path="/aipcmcty/simulation/supply-validation" element={<SupplyValidationPage />} />
            <Route path="/aipcmcty/simulation/resource-regulation" element={<ResourceRegulation />} />
            <Route path="/aipcmcty/simulation/resource-simulation" element={<ResourceSimulation />} />
            <Route path="/aipcmcty/comparison/project" element={<ComparisonPage />} />
            <Route path="/aipcmcty/comparison/resource" element={<ComparisonResourcePage />} />
            <Route path="/aipcmcty/comparison/sub-diff" element={<SubDiffPage />} />
            <Route path="/aipcmcty/comparison/p-diff" element={<PDiffPage />} />
            <Route path="/aipcmcty/comparison/portfolio-monitor" element={<PortfolioMonitor />} />
            <Route path="/aipcmcty/dashboard/trinity-evaluation" element={<TrinityEvaluationPage />} />
            <Route path="/aipcmcty/dashboard/trinity-comparison" element={<TrinityComparisonPage />} />
            <Route path="/aipcmcty/management/record" element={<Record />} />
            <Route path="/aipcmcty/management/action" element={<ActionPage />} />
            <Route path="/aipcmcty/management/master-setup" element={<MasterSetupPage />} />
            <Route path="/aipcmcty/demo/dx-diff" element={<DXDiffPage />} />
            <Route path="/aipcmcty/demo/gap-analysis" element={<GapAnalysisPage />} />
            <Route path="/aipcmcty/demo/investment-return" element={<InvestmentReturnPage />} />
            <Route path="/aipcmcty/report/business-report" element={<MonthlyBusinessReport />} />
            <Route path="/aipcmcty/resource/extract-resources" element={<ExtractResources />} />
            <Route path="/aipcmcty/report/monthly-business-report" element={<SalesReport />} />
          </Route>
          <Route path="*" element={<Navigate to="/aipcmcty/dashboard/overall" replace />} />
        </Route>
      </>
    ),
    {
      basename: (window as any).__POWERED_BY_QIANKUN__ ? '/cmc' : '/',
    }
  );

  return <RouterProvider router={router} />;
}
