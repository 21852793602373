import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { TableChart } from '@meteor/frontend-core';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';

type DiffChartProps = {
  data: any;
  loading?: boolean;
  graphLoading?: boolean;
  graphHeight?: number;
  title?: string | ReactNode;
  centerData?: any;
};
const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

const sizeMap = {
  small: {
    gridLeft: 50,
    gridRight: 40,
    splitNumber: 3,
    xAxis: 10,
    yAxis: 10,
  },
  big: {
    gridRight: 40,
    splitNumber: 3,
  },
};

const getChartOptions = (data: any, centerData: any, t: any) => ({
  tooltip: {
    trigger: 'item',
    formatter: (params: any) => {
      const { name, value } = params;
      const percentage = (value * 100).toFixed(1) + '%';
      return `${name}: ${percentage}`;
    },
  },
  legend: {
    top: '5%',
    left: 'center',
  },
  series: [
    {
      name: t('aipcmcty.page.comparison.grossProfitDiffTitle'),
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: false,
      data,
    },
  ],
  graphic: [
    {
      type: 'group',
      left: 'center',
      top: 'center',
      children: [
        {
          type: 'text',
          style: {
            text: '目標(粗利)',
            fontSize: '14px',
            textAlign: 'center',
          },
        },
        {
          type: 'text',
          style: {
            fontWeight: 600,
            text: `${fixed(centerData / 1000000).toLocaleString()}M`,
            fontSize: '18px',
            textAlign: 'center',
            padding: [24, 0, 0, 0],
          },
        },
      ],
    },
  ],
});

const OrderProfitCompositionChart = (props: DiffChartProps) => {
  const { data, loading = false, graphLoading, graphHeight = 340, title, centerData } = props;

  const { sizeMode } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const [chartOptions, setChartOptions] = useState(getChartOptions(data, getChartOptions, t));

  useEffect(() => {
    setChartOptions(getChartOptions(data, centerData, t));
  }, [data, centerData, t]);

  return (
    <>
      <TableChart
        showSeriesLabel={true}
        showTable={false}
        chartOptions={chartOptions}
        loading={loading}
        title={
          title
            ? {
                value: title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, { ...sizeMapBase, ...sizeMap }, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : {
                value: '受注粗利構成比',
                styles: {
                  fontSize: getSizeByMap(sizeMode, { ...sizeMapBase, ...sizeMap }, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
        }
        height={graphHeight}
      />
    </>
  );
};

export default OrderProfitCompositionChart;
