import React, { useEffect, useState } from 'react';
import { TableChart } from '@meteor/frontend-core';

type ChartProps = {
  data: any[];
  height?: number;
  loading?: boolean;
  title?: string;
  mhChartLine: any;
};

const ExtractResourcesChart: React.FC<any> = ({ data, height, mhChartLine, loading = false, title, handleClick }) => {
  const [options, setOptions] = useState<any>({
    title: {
      value: title,
      styles: {
        fontSize: '16px',
        paddingLeft: 15,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      legend: {
        data: [],
        top: 10,
      },
      xAxis: {
        type: 'category',
        data: [],
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: '万',
        axisLabel: {
          formatter: (value: number) => {
            const formattedValue = value / 10000;
            return `${formattedValue.toLocaleString()}`;
          },
        },
      },
      series: [],
      dataZoom: [
        {
          type: 'inside',
          startValue: 0,
          endValue: 59,
        },
        {
          type: 'slider',
          textStyle: {
            fontSize: 11,
          },
          bottom: 24,
          height: 12,
        },
      ],
    },
    height,
  });
  const calculateMonthlySums = (categories = [], rows = []) => {
    const sumByMonth = rows.reduce((acc, row) => {
      const { yearMonth, actualSupplyMH } = row || {};
      if (!acc[yearMonth]) {
        acc[yearMonth] = 0;
      }
      acc[yearMonth] += actualSupplyMH;
      return acc;
    }, {});
    const result = categories.map((month) => {
      const sum = sumByMonth[month] || 0;
      return Math.round(sum);
    });

    return result;
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const categories = Array.from(new Set(data.map((item: any) => item[2])));

      const departments = Array.from(new Set(data.map((item: any) => item[1]))).filter(Boolean);

      if (departments.length === 0) {
        return;
      }
      const nameMap: { [key: string]: string } = {
        IT_Operation: 'ITO', // 将 IT_Operation 映射为 ITO
      };
      const stackOrder = ['一般', 'R&D', 'Proposal', 'ITO', 'Awarded', 'Budget'];
      const colorMap: { [key: string]: string } = {
        一般: '#1A4F99',
        'R&D': '#507FA2',
        Proposal: '#619BC5',
        ITO: '#B0CEDB',
        Awarded: '#093B5E',
        Budget: '#3299D9',
      };
      const seriesData = stackOrder.map((dept) => {
        const dataKey = dept === 'ITO' ? 'IT_Operation' : dept;
        return {
          name: dept,
          type: 'bar',
          stack: 'total',
          data: categories.map((month) => {
            const filteredData = data.filter((item: any) => item[2] === month && item[1] === dataKey);
            return filteredData.reduce((sum, item) => sum + item[3], 0);
          }),
          itemStyle: {
            color: colorMap[dept],
          },
        };
      });

      const totalMHData = calculateMonthlySums(categories, mhChartLine);

      const totalMHSeries = {
        type: 'line',
        name: 'Total MH',
        data: totalMHData,
        lineStyle: {
          color: '#DD6B66',
        },
        symbol: 'circle',
        symbolSize: 1,
        itemStyle: {
          color: '#DD6B66',
        },
        showSymbol: false,
      };
      const sort = ['Total MH', 'Budget', 'Awarded', 'ITO', 'Proposal', 'R&D', '一般', 'OFS', 'IF', 'Others'];

      setOptions((prev) => ({
        ...prev,
        chartOptions: {
          ...prev.chartOptions,
          xAxis: {
            ...prev.chartOptions.xAxis,
            data: categories,
          },
          series: [totalMHSeries, ...seriesData],
          legend: {
            ...prev.chartOptions.legend,
            data: ['Total MH', ...stackOrder],
          },
          tooltip: {
            ...prev.chartOptions.tooltip,
            formatter: (params: any) => {
              const sortedParams = params.sort((a: any, b: any) => {
                const aIndex = sort.findIndex((name) => name.toLowerCase() === a.seriesName.toLowerCase());
                const bIndex = sort.findIndex((name) => name.toLowerCase() === b.seriesName.toLowerCase());
                return aIndex - bIndex;
              });

              const validParams = sortedParams.filter((p: any) => sort.some((name) => name.toLowerCase() === p.seriesName.toLowerCase()));

              let html = `<div style="margin: 0 0 5px;text-align: left">${params[0].axisValue}</div>`;
              validParams.forEach((p: any) => {
                const value = p.seriesType === 'line' ? `${p.value.toLocaleString()}` : `${p.value.toLocaleString()}`;
                html += `
              <div style="display: flex;align-items: center;margin: 3px 0;">
                <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${p.color};margin-right:5px"></span>
                <span style="flex:1">${p.seriesName}</span>
                <span style="margin-left:20px;font-weight:bold">${value}</span>
              </div>
            `;
              });
              return html;
            },
          },
        },
      }));
    }
  }, [data]);

  const handleChartEvent = (params: any) => {
    if (params.eventType !== 'ECHART_EVENT_CHART_ITEM_SELECTED') return;
    handleClick(params.event.name.split('-')[0], params.event.name.split('-')[1]);
  };

  return (
    <TableChart
      chartOptions={options.chartOptions}
      title={options.title}
      height={options.height}
      loading={loading}
      showTable={false}
      onChartEvent={handleChartEvent}
    />
  );
};

export default ExtractResourcesChart;
