import { cloneDeep } from 'lodash';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiffResponse } from '../../pages/comparison/project';
import { AppContext } from '../../contexts/AppContext';
import { TableChart } from '@meteor/frontend-core';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';

type DiffChartProps = {
  data: DiffResponse;
  loading: boolean;
  graphLoading: boolean;
  graphHeight?: number;
  title?: string | ReactNode;
};

const roundToTwoDecimalPlaces = (value) => {
  return (Math.round((value / 100000000) * 100) / 100).toFixed(2);
};

const ProfitConsolidatedDiffChart = (props: DiffChartProps) => {
  const { data, loading, graphLoading, graphHeight = 340, title } = props;

  const { t } = useTranslation();

  const { sizeMode, color } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const [consolidatedGraphOptions, setConsolidatedGraphOptions] = useState({
    title: {
      value: title,
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
      },
      xAxis: {
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
        data: [],
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: t('aipcmc.charts.hundredMillion'),
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          formatter: (value) => value,
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameGap: 5,
        splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
        axisLine: {
          show: true,
        },
      },
      series: [],
    },
    height: 220,
  });

  const updateConsolidatedGraphOptions = () => {
    const {
      consolidatedGoal: { current: goals },
      consolidated,
    } = data;

    const bars = {
      Q1: 'rgb(9,59,94)',
      Q2: 'rgb(26,79,153)',
      Q3: 'rgb(50,153,217)',
      Q4: 'rgb(159,217,246)',
    };

    const xAxisDataSet: Set<string> = new Set();
    const optionsAggregation = {};

    const getLabel = (fiscalYear: string, type: string) => `${fiscalYear}${t(`aipcmcty.page.comparison.${type}Cyd`)}`;

    consolidated.forEach(({ fiscalYear, fiscalQuarter, grossProfitTarget, grossProfitCurrent }) => {
      const targetLabel = getLabel(fiscalYear, 'target');
      const currentLabel = getLabel(fiscalYear, 'current');

      xAxisDataSet.add(targetLabel);
      xAxisDataSet.add(currentLabel);

      if (!optionsAggregation[fiscalQuarter]) {
        optionsAggregation[fiscalQuarter] = [];
      }
      optionsAggregation[fiscalQuarter].push(roundToTwoDecimalPlaces(grossProfitTarget), roundToTwoDecimalPlaces(grossProfitCurrent));
    });
    const xAxisData = Array.from(xAxisDataSet);

    const lineData = xAxisData.map((i) => {
      const fiscalYear = i.slice(0, 4);
      return goals[fiscalYear] ? roundToTwoDecimalPlaces(goals[fiscalYear].grossProfitQ * 4) : '';
    });

    const series = [
      ...Object.keys(optionsAggregation).map((quarter) => ({
        name: quarter,
        type: 'bar',
        stack: 'one',
        data: optionsAggregation[quarter],
        itemStyle: {
          color: bars[quarter],
        },
      })),
      {
        name: t('aipcmcty.page.comparison.goal'),
        type: 'line',
        step: 'middle',
        data: lineData,
        symbol: 'none',
        lineStyle: {
          color: color.warningColor,
          width: 2,
          type: 'solid',
        },
        itemStyle: {
          color: color.warningColor,
        },
      },
    ];
    console.log(xAxisData);

    const mergedConsolidatedOptions = cloneDeep(consolidatedGraphOptions);
    mergedConsolidatedOptions.chartOptions.xAxis.data = xAxisData;
    mergedConsolidatedOptions.chartOptions.series = series;
    setConsolidatedGraphOptions(mergedConsolidatedOptions);
  };

  useEffect(() => {
    updateConsolidatedGraphOptions();
  }, [data]);

  return (
    <>
      <TableChart
        showTable={false}
        chartOptions={consolidatedGraphOptions.chartOptions}
        title={
          title
            ? {
                value: title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : consolidatedGraphOptions.title
        }
        height={graphHeight}
        loading={loading}
        titleLoading={!loading && graphLoading}
      />
    </>
  );
};

export default ProfitConsolidatedDiffChart;
