/* eslint-disable no-template-curly-in-string */
import { CheckOutlined, CloseOutlined, EditOutlined, FileExclamationOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Empty, Form, Input, InputNumber, Popconfirm, Popover, Row, Space, Table, Tooltip } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import { AppContext } from '../../contexts/AppContext';
import { round } from '../../utils/commonUtil';
import dayjs from 'dayjs';
import { exportTableFromExcelTemp } from '../../utils/xlsxReader';
import { localeMapping } from '../../consts/locale.mapping';
import { isArray, isNil } from 'lodash';
import useVersion from '../../hooks/useVersion';
import APIList from '../../http/ApiList';
import useAuth from '../../hooks/useAuth';

export type ProjectAttrsType = {
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
};

export type ProjectKPIType = {
  key: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: string;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  won: number;
};

/* @noflow */
export interface ResourceSimulationTableItem {
  key: string;
  // 案件 UUID
  id: string;
  // 案件ID
  projectId: string;
  // 案件名
  projectName: string;
  // SB案件ID
  subProjectId: string;
  // SB案件名
  subProjectName: string;
  // TGC
  tgc: string;
  // 予算カテゴリ
  budgetCategory: string;
  budgetCategoryDefault: string;
  budgetCategoryCustom: string;
  // 最優先
  priorityMatching: boolean;
  // 優先度
  priorityDefault: number;
  // 受注金額
  orderAmount: number;
  // 受注金額_default
  orderAmountDefault: number;
  orderAccumulation: number;
  // 粗利率
  grossMargin: number;
  grossMarginDefault: number;
  grossProfit: number;
  grossProfitDefault: number;
  profitAccumulation: number;
  // 需要Mh
  demandMh: number;
  // Won%
  won: number;
  // フェーズ / ステージ
  phase: string;
  phaseDefault: string;
  // 確度
  certainty: string;
  // 開始予定日
  projectStartDate: Date;
  projectStartDateDefault: Date;
  // 終了予定日
  projectEndDate: Date;
  projectEndDateDefault: Date;
  // 期間
  duration: number;
  durationDefault: number;
  // Feed開始予定日
  feedStartDate: Date;
  feedStartDateDefault: Date;
  // Feed終了予定日
  feedEndDate: Date;
  feedEndDateDefault: Date;
  // Feed期間
  feedDuration: number;
  feedDurationDefault: number;
  // DX適用
  dxAvailable: boolean;
  dxApplicable: boolean;
  formJvFeedE: number;
  formJvFeedP: number;
  formJvFeedC: number;
  formJvFeedPjt: number;
  formJvEpcE: number;
  formJvEpcP: number;
  formJvEpcC: number;
  formJvEpcPjt: number;
  formTgcFeedE: string;
  formTgcFeedP: string;
  formTgcFeedC: string;
  formTgcFeedPjt: string;
  formTgcEpcE: string;
  formTgcEpcP: string;
  formTgcEpcC: string;
  formTgcEpcPjt: string;
  // アクション / アラート
  action: string[];
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: number;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  demandForecastType: string;
  constructionLocation: string;
  memoCount: number;
  monthDemandMH: number;
  dxDemandMh: number;
  dxGrossProfit: number;
  orderScheduledDate: Date;
  orderScheduledDateDefault: Date;
  isForPpReport?: boolean;
}

const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

const ResourceSimulationTable: React.FC<{
  loading: boolean;
  data: ResourceSimulationTableItem[];
  viewMode?: string;
  onPageSizeChange: (pageSize: number) => void;
  pageSize: number;
  needDisable: boolean;
  onSelected: (record: ResourceSimulationTableItem, selected: boolean) => void;
  selectedProjects: any[];
  demandMonth: string;
  selectedDivDis: [string, string];
  onDemandUpdate: () => void;
  isDxMode: boolean;
  consolidated: 0 | 1;
  getExportData: () => ResourceSimulationTableItem[];
}> = (props) => {
  const { isFinancialInfoVisible } = useAuth();
  // Common Config
  const { data, viewMode, loading, onPageSizeChange, pageSize, needDisable, onSelected, selectedProjects, onDemandUpdate, getExportData } =
    props;

  const { selectorHeight4Table } = useWindowSize({
    selector: '.project-table .cmcty-table-body',
    viewMode,
    watcherSelector: ['.operation-container'],
  });
  const { setIsModalOpen, setCurModalItem, handleTableChange, optOrgData, filtersData, perspectiveState } = useContext(ProjectSetupContext);

  const { t } = useTranslation();
  const { certainty, locale, demandMhPercent } = useContext(AppContext);
  const { snapshot, snapshotVersion } = useVersion(false);

  const [exportLoading, setExportLoading] = useState(false);
  const [form] = Form.useForm();
  const [editId, setEditId] = useState(null);
  const cachedItem = useRef(null);
  const isEditing = (record) => record.id === editId;

  const edit = (record: any) => {
    record.offsetMonths = dayjs(record.offsetMonths);
    form.setFieldsValue({ ...record });
    cachedItem.current = {
      orderAmount: record.orderAmount,
      grossMargin: record.grossMargin,
      offsetMonths: dayjs(record.offsetMonths),
      projectStartDate: dayjs(record.projectStartDate),
    };
    setEditId(record.id);
  };
  const cancel = () => {
    setEditId(null);
  };
  const save = async (id: string) => {
    const {
      orderAmount: orderAmountAfter = 0,
      grossMargin: grossMarginAfter = 0,
      offsetMonths: offsetMonthsAfter,
    } = await form.validateFields();
    const {
      orderAmount: orderAmountBefore = 0,
      grossMargin: grossMarginBefore = 0,
      offsetMonths: offsetMonthsBefore,
      projectStartDate,
    } = cachedItem.current;
    if (orderAmountAfter !== orderAmountBefore) {
      handleTableChange('orderAmount', orderAmountAfter, id);
    }
    if (grossMarginAfter !== grossMarginBefore) {
      handleTableChange('grossMargin', grossMarginAfter, id);
    }
    if (!offsetMonthsAfter.isSame(offsetMonthsBefore, 'month')) {
      handleTableChange('orderScheduledDate', offsetMonthsAfter.date(offsetMonthsBefore.date()), id);
    }
    setEditId(null);
  };

  // Table Config
  const columns = [
    {
      title: `P ${t('aipcmcty.page.projectCaseId')}`,
      dataIndex: 'projectId',
      key: 'projectId',
      width: 90,
      fixed: 'left',
      className: 'align-right small-column',
      sorter: (a, b) => a.projectId.localeCompare(b.projectId),
      render: (val) => {
        const ids = val ? val.split(',') : '-';
        return ids.map((id) => (
          <div key={id} style={{ lineHeight: '16px' }}>
            {id}
          </div>
        ));
      },
    },
    {
      title: t('aipcmcty.page.projectCaseName'),
      dataIndex: 'subProjectName',
      key: 'subProjectName',
      fixed: 'left',
      width: 240,
      className: 'align-left small-column',
      sorter: (a, b) => a.subProjectName.localeCompare(b.subProjectName),
      render(val, item) {
        const attrSource = {
          accountingYear: item.accountingYear ?? '-',
          scope: item.scope ?? '-',
          isExisted: item.isExisted ?? '-',
          isEpc: item.isEpc ?? '-',
          businessType: item.businessType ?? '-',
          goodsType: item.goodsType ?? '-',
          strategic: item.strategic ?? '-',
          customerRelationship: item.customerRelationship ?? '-',
        };
        const grossProfitPerMh =
          !!Number(item.grossProfitPerMh) && Number(item.grossProfitPerMh) !== 0
            ? Number(item.grossProfitPerMh).toFixed(2)
            : item.grossProfitPerMh;
        const kpiSource = {
          grossProfitPerMh: grossProfitPerMh ? Number(Number(grossProfitPerMh).toFixed(0)).toLocaleString() : '-',
          npvGrossMargin: item.npvGrossMargin ? `${round(item.npvGrossMargin * 100).toFixed(1)}%` : '-',
          totalWorkload: item.totalWorkload ? Number(item.totalWorkload).toLocaleString() : '-',
          expectedProductivity: item.expectedProductivity ? Number(item.totalWorkload).toLocaleString() : '-',
          resourceDemandMh: item.resourceDemandMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          resourceSupplyMh: item.resourceSupplyMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          imbalancedMh: item.imbalancedMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          dxFullPotential: item.dxFullPotential
            ? certainty === 1
              ? Math.round(item.dxFullPotential * item.won)
              : Number(item.dxFullPotential).toLocaleString()
            : '-',
          dxDiscountRate: item.dxDiscountRate ?? '-',
          dxDiscounted: item.dxDiscounted
            ? certainty === 1
              ? Math.round(item.dxDiscounted * item.won)
              : Number(item.dxDiscounted).toLocaleString()
            : '-',
          won: item.won,
        };
        const contentPop = (
          <div className="project-info-pop">
            <h4 style={{ fontWeight: 'bold' }}>{t('aipcmcty.page.projectAttribute')}</h4>
            <div className="grid-table">
              <div className="table-name">
                <div>{t('aipcmcty.page.fiscalYear')}</div>
                <div>{t('aipcmcty.page.serviceScope')}</div>
                <div>{`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`}</div>
                <div>{`${t('aipcmcty.page.nonEPC')}/EPC`}</div>
                <div>{t('aipcmcty.page.businessType')}</div>
                <div>{t('aipcmcty.page.productType')}</div>
                <div>{t('aipcmcty.page.strategic')}</div>
                <div>{t('aipcmcty.page.customerRelationship')}</div>
              </div>
              <div className="table-value">
                <div style={{ maxWidth: 240 }}>{attrSource.accountingYear}</div>
                <Tooltip title={attrSource.scope}>
                  <div style={{ maxWidth: 240 }}>{attrSource.scope}</div>
                </Tooltip>
                <div style={{ maxWidth: 240 }}>{attrSource.isExisted}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.isEpc}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.businessType}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.goodsType}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.strategic}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.customerRelationship}</div>
              </div>
            </div>
            <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>{t('aipcmcty.page.projectKpi')}</h4>
            <div className="grid-table" style={{ width: '100%' }}>
              <div className="table-name" style={{ minWidth: 200 }}>
                <div>{t('aipcmcty.page.grossProfitPerMH')}</div>
                <div>{t('aipcmcty.page.NPVgrossProfitRate')}</div>
                <div>{t('aipcmcty.page.totalWorkVolume')}</div>
                <div>{t('aipcmcty.page.assumedProductivity')}</div>
                <div>{t('aipcmcty.page.resourceDemandMH')}</div>
                <div>{t('aipcmcty.page.resourceSupplyMH')}</div>
                <div>{t('aipcmcty.page.demandSupplyGapMH')}</div>
                <div>{t('aipcmcty.page.dxAllowanceFullPot')}</div>
                <div>{t('aipcmcty.page.DXallowanceDiscountRate')}</div>
                <div>{t('aipcmcty.page.dxAllowanceAmount')}</div>
              </div>
              <div className="table-value">
                <div style={{ maxWidth: 240 }}>{kpiSource.grossProfitPerMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.npvGrossMargin}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.totalWorkload}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.expectedProductivity}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.resourceDemandMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.resourceSupplyMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.imbalancedMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxFullPotential}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxDiscountRate}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxDiscounted}</div>
              </div>
            </div>
          </div>
        );
        return (
          <Row align="middle" style={{ width: '100%' }}>
            <Col span={22}>
              <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val} placement="topLeft">
                <Button
                  className="project-name multi-ellipsis-btn"
                  type="link"
                  onClick={() => {
                    setIsModalOpen(true);
                    setCurModalItem({ ...item });
                  }}
                >
                  {val}
                </Button>
              </Tooltip>
            </Col>
            <Col span={2}>
              <Popover content={contentPop} trigger="hover" placement="right">
                <FileExclamationOutlined />
              </Popover>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t('aipcmcty.page.year'),
      dataIndex: 'accountingYear',
      key: 'accountingYear',
      width: 60,
      className: 'align-left small-column',
      sorter: (a, b) => a.accountingYear.localeCompare(b.accountingYear),
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.resSimulation.orderScheduledDate'),
      dataIndex: 'orderScheduledDate',
      key: 'orderScheduledDate',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => {
        if (dayjs(a.orderScheduledDate).isBefore(b.orderScheduledDate)) {
          return -1;
        }
        if (dayjs(a.orderScheduledDate).isAfter(b.orderScheduledDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const defaultMoment = dayjs(item.orderScheduledDateDefault);
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}>
            <div style={{ textAlign: 'center' }}>{dayjs(val).format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: 'TGC',
      dataIndex: 'tgc',
      key: 'tgc',
      width: 80,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aTgc = a.tgc ?? '';
        const bTgc = b.tgc ?? '';
        return aTgc.localeCompare(bTgc);
      },
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.resSimulation.budgetCategory'),
      dataIndex: 'budgetCategoryCustom',
      key: 'budgetCategoryCustom',
      width: 110,
      className: 'small-column',
      sorter: (a, b) => a.budgetCategoryCustom.localeCompare(b.budgetCategoryCustom),
      render(val, item) {
        // const curOrder = optOrgData?.budgetCategory.find(
        //   (p) => p.value === (val ?? item.budgetCategory)
        // )?.order;
        // const defaultOrder = optOrgData?.budgetCategory.find(
        //   (p) => p.value === item.budgetCategoryDefault
        // ).order;
        // let classNames = 'project-select left';
        // if (!needDisable) {
        //   classNames += ' table-item-disable';
        // }
        // if (curOrder > defaultOrder) {
        //   classNames = classNames.replace(' table-item-disable', '');
        //   classNames += ' sfdc-select greater';
        // }
        // if (curOrder < defaultOrder) {
        //   classNames = classNames.replace(' table-item-disable', '');
        //   classNames += ' sfdc-select less';
        // }
        const content = (
          <>
            <span>{`SFDC: ${item.budgetCategoryDefault}`}</span>
            <br />
            <span>{`CMC: ${item.budgetCategory}`}</span>
          </>
        );
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={content}>
            <div style={{ textAlign: 'center' }}>{val ?? item.budgetCategory}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.orderAmount'),
      dataIndex: 'orderAmount',
      key: 'orderAmount',
      width: 110,
      className: 'align-right small-column',
      sorter: (a, b) => a.orderAmount - b.orderAmount,
      editable: true,
      onCell: (item) => ({
        item,
        inputType: 'amount',
        editing: isEditing(item),
        title: t('aipcmcty.page.orderAmount'),
        dataIndex: 'orderAmount',
      }),
      render(val, item, index) {
        const toolTip = (
          <>
            <span>{`SFDC: ${fixed(item.orderAmountDefault).toLocaleString()}`}</span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={toolTip} trigger="hover" placement="top">
            <div>{fixed(val).toLocaleString()}</div>
          </Tooltip>
        );
      },
    },
    {
      title: '受注金額(明細)',
      dataIndex: 'orderAmountStandalone',
      key: 'orderAmountStandalone',
      width: 120,
      className: 'align-right small-column',
      sorter: (a, b) => a.orderAmountStandalone - b.orderAmountStandalone,
      render(val, item, index) {
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return <div>{fixed(val).toLocaleString()}</div>;
      },
    },
    {
      title: t('aipcmcty.page.grossProfitAmt'),
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      width: 110,
      className: 'align-right small-column',
      sorter: (a, b) => a.grossProfit - b.grossProfit,
      render: (val, item) => {
        let classNames = 'project-number';
        const toolTip = (
          <>
            <span>{`SFDC: ${fixed(item.grossProfitDefault).toLocaleString()}`}</span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={toolTip} trigger="hover" placement="top">
            <span className={classNames}>{fixed(val).toLocaleString()}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.grossProfitRate'),
      dataIndex: 'grossMargin',
      key: 'grossMargin',
      width: 90,
      className: 'align-right small-column',
      sorter: (a, b) => a.grossMargin - b.grossMargin,
      editable: true,
      onCell: (item) => ({
        item,
        inputType: 'percent',
        editing: isEditing(item),
        title: t('aipcmcty.page.grossProfitRate'),
        dataIndex: 'grossMargin',
      }),
      render(val, item, index) {
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.grossMarginDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.grossMarginDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${round(item.grossMarginDefault * 100).toFixed(1)}%`}>
            <div>
              <span>{`${round(val * 100).toFixed(1)}%`}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: `${t('aipcmcty.page.demand')}MH`,
      dataIndex: 'demandMh',
      key: 'demandMh',
      className: 'align-right small-column',
      width: 100,
      sorter: (a, b) => a.demandMh - b.demandMh,
      render: (val) => <div>{val ? Number(Number(val).toFixed(0)).toLocaleString() : val || '-'}</div>,
    },
    {
      title: t('aipcmcty.page.resSimulation.stackMH'),
      dataIndex: 'monthDemandMH',
      key: 'monthDemandMH',
      className: 'align-right small-column',
      width: 100,
      sorter: (a, b) => Number(a.monthDemandMH ?? 0) - Number(b.monthDemandMH ?? 0),
      render: (val) => <div>{val ? Number(Number(val).toFixed(0)).toLocaleString() : val || '-'}</div>,
    },
    {
      title: t('aipcmcty.page.startDate'),
      dataIndex: 'projectStartDate',
      key: 'projectStartDate',
      width: 130,
      className: 'align-right small-column',
      sorter: (a, b) => {
        if (dayjs(a.projectStartDate).isBefore(b.projectStartDate)) {
          return -1;
        }
        if (dayjs(a.projectStartDate).isAfter(b.projectStartDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const curMoment = dayjs(val);
        const defaultMoment = dayjs(item.projectStartDateDefault);
        let classNames = '';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (curMoment.isBefore(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date less';
        }
        if (curMoment.isAfter(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date greater';
        }
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}>
            <div style={{ textAlign: 'center' }}>{curMoment.format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.endDate'),
      dataIndex: 'projectEndDate',
      key: 'projectEndDate',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => {
        if (dayjs(a.projectEndDate).isBefore(b.projectEndDate)) {
          return -1;
        }
        if (dayjs(a.projectEndDate).isAfter(b.projectEndDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const defaultMoment = dayjs(item.projectEndDateDefault);
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}>
            <div style={{ textAlign: 'center' }}>{dayjs(val).format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.duration'),
      dataIndex: 'duration',
      key: 'duration',
      width: 70,
      className: 'align-right small-column',
      sorter: (a, b) => a.duration - b.duration,
      render(val, item) {
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }

        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${item.durationDefault}`}>
            <div>{val}</div>
          </Tooltip>
        );
      },
    },
    {
      title: `SB ${t('aipcmcty.page.projectCaseId')}`,
      dataIndex: 'subProjectId',
      key: 'subProjectId',
      width: 90,
      fixed: 'left',
      className: 'align-right small-column',
      sorter: (a, b) => a.subProjectId.localeCompare(b.subProjectId),
      render: (val) => {
        const ids = val ? val.split(',') : '-';
        return ids.map((id) => (
          <div key={id} style={{ lineHeight: '16px' }}>
            {id}
          </div>
        ));
      },
    },
    {
      title: '受注月調整',
      dataIndex: 'offsetMonths',
      key: 'offsetMonths',
      fixed: 'right',
      width: 100,
      className: 'small-column',
      editable: true,
      onCell: (item) => {
        item.offsetMonths = item.orderScheduledDate;
        return {
          item,
          inputType: 'datepicker',
          editing: isEditing(item),
          title: '受注月調整',
          dataIndex: 'offsetMonths',
        };
      },
      render: (_, item) => <div style={{ textAlign: 'center' }}>{dayjs(item.orderScheduledDate).format('YYYY-MM')}</div>,
    },
    {
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      width: 60,
      className: 'small-column',
      render: (_, item) => {
        let operations = null;
        if (item.budgetCategoryDefault === 'Awarded') {
          return operations;
        }
        const editable = isEditing(item);
        if (editable) {
          operations = (
            <>
              <Button size="small" type="link" icon={<CheckOutlined />} shape="circle" onClick={() => save(item.id)} />
              <Button size="small" type="link" icon={<CloseOutlined />} shape="circle" onClick={() => cancel()} />
            </>
          );
        } else {
          operations = (
            <>
              <Button
                size="small"
                type="link"
                icon={<EditOutlined />}
                shape="circle"
                disabled={editId !== null && editId !== item.id}
                onClick={() => edit(item)}
              />
              <Popconfirm
                placement="topRight"
                title={t('aipcmcty.page.resetProjectDefault').replace('${item.projectId}', item.subProjectId)}
                onConfirm={() => {
                  handleTableChange('reset', null, item.id);
                }}
                okText={t('aipcmcty.page.yes')}
                cancelText={t('aipcmcty.page.cancel')}
                disabled={editId !== null && editId !== item.id}
              >
                <Button
                  size="small"
                  type="link"
                  icon={<RollbackOutlined />}
                  shape="circle"
                  disabled={editId !== null && editId !== item.id}
                />
              </Popconfirm>
            </>
          );
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            {operations}
          </div>
        );
      },
    },
  ];

  const excelAoaDataSetup = () => {
    const filterList: any[] = [];
    const certaintyOpt = [
      {
        value: 0,
        label: t('aipcmcty.page.wonPercentageNotConsidered'),
      },
      {
        value: 1,
        label: t('aipcmcty.page.wonPercentageConsidered'),
      },
    ];
    const getValue = (value, SP = ', ') => {
      const EMPTY = '-';
      if (isArray(value)) {
        if (value.length === 0 || value.every((item) => isNil(item) || item === '')) {
          return EMPTY;
        }
        return value.join(SP);
      }
      if (isNil(value) || value === '') {
        return EMPTY;
      }
      return value;
    };
    // snapshot version
    filterList.push(['Snapshot Version', 'Snapshot', getValue(snapshot)]);
    filterList.push(['', 'Snapshot Version', getValue(snapshotVersion)]);
    // 算出設定
    filterList.push([
      t('aipcmcty.page.calculationSettings'),
      t('aipcmcty.page.budgetRevenueCalculation'),
      getValue(certaintyOpt.find((item) => item.value === certainty)?.label),
    ]);
    filterList.push(['', t('aipcmcty.page.supplyDemandGapAllowed'), getValue(demandMhPercent.toLocaleString())]);
    // Header Filter
    filterList.push([
      'Header Filter',
      'ユースケース',
      getValue(optOrgData.useCaseOpts.find((item) => filtersData.useCase === item.value)?.label),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.accountingPerspective'),
      getValue(optOrgData.perspectiveOptions.find((item) => filtersData.accountingPerspective === item.value)?.label),
    ]);
    filterList.push(['', 'TGC', getValue(filtersData.tgc)]);
    filterList.push(['', '本部', getValue(filtersData.departments)]);
    // MH
    filterList.push([
      'MH',
      '需給年月',
      getValue(optOrgData.dhYearMonthOpts.find((item) => item.value === filtersData.demandYearMonth)?.label),
    ]);
    const mh1 = optOrgData.mhDepartmentOpts.find((item) => item.value === filtersData.mhDepartment[0]);
    const mh2 = mh1.children.find((item) => item.value === filtersData.mhDepartment[1]);
    filterList.push(['', 'MH部門', [mh1.value, mh2.value].join(', ')]);
    // 受注粗利
    filterList.push([
      '受注粗利',
      '受注年月',
      getValue(optOrgData.orderYMOpts.find((item) => item.value === filtersData.orderScheduledDate)?.value),
    ]);
    // 案件
    filterList.push([
      '案件',
      `SB${t('aipcmcty.page.projectCaseName')} / SB${t('aipcmcty.page.projectCaseId')}`,
      getValue(filtersData.search),
    ]);
    filterList.push(['', 'PP報告用', getValue(optOrgData.ppReportOpts.find((item) => item.value === filtersData.isForPpReport)?.label)]);
    filterList.push(['', t('aipcmcty.page.resSimulation.fiscalYears'), getValue(filtersData.accountingYear)]);
    filterList.push(['', t('aipcmcty.page.serviceScope'), getValue(filtersData.scope)]);
    filterList.push([
      '',
      `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`,
      getValue(locale.locale === 'ja' ? filtersData.isExisted : localeMapping[filtersData.isExisted]),
    ]);
    filterList.push(['', `${t('aipcmcty.page.nonEPC')}/${t('aipcmcty.page.EPC')}`, getValue(filtersData.isEpc)]);
    filterList.push([
      '',
      t('aipcmcty.page.businessType'),
      getValue(filtersData.businessType.map((businessType) => (locale.locale === 'ja' ? businessType : localeMapping[businessType]))),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.productType'),
      getValue(filtersData.goodsType.map((goodsType) => (locale.locale === 'ja' ? goodsType : localeMapping[goodsType]))),
    ]);
    filterList.push(['', t('aipcmcty.page.priorityLevel'), getValue(filtersData.priorityDefault, ' ～ ')]);
    filterList.push(['', t('aipcmcty.page.budgetCategory'), getValue(filtersData.budgetCategoryCustom)]);
    filterList.push(['', t('aipcmcty.page.orderAmount'), getValue(filtersData.orderAmount)]);
    filterList.push(['', t('aipcmcty.page.grossProfitRate'), getValue(filtersData.grossMargin, ' ～ ')]);
    filterList.push([
      '',
      t('aipcmcty.page.demandSupplyFilterMH'),
      getValue(optOrgData.imbalancedMhOpt.find((item) => item.value === filtersData.imbalancedMh)?.label),
    ]);
    filterList.push(['', 'Won%', getValue(filtersData.won, ' ～ ')]);
    filterList.push([
      '',
      t('aipcmcty.page.phase'),
      getValue(filtersData.phase.map((phase) => (locale.locale === 'ja' ? phase : localeMapping[phase]))),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.accuracy'),
      getValue(filtersData.certainty.map((certainty) => (locale.locale === 'ja' ? certainty : localeMapping[certainty]))),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.startDate'),
      getValue(filtersData.projectStartDate ? dayjs(filtersData.projectStartDate).format('YYYY-MM-DD') : null),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.endDate'),
      getValue(filtersData.projectEndDate ? dayjs(filtersData.projectEndDate).format('YYYY-MM-DD') : null),
    ]);
    return filterList;
  };

  /**
   * Table高度Map
   */
  const tableHeightMap = {
    'chart-table': selectorHeight4Table - 40,
    'table-only': selectorHeight4Table,
    'chart-only': 0,
  };
  const scrollX = columns.reduce((prev: number, curCol) => prev + (curCol.width as number), 0);

  const financialColumns = [
    'orderAmount',
    'grossProfit',
    'dxGrossProfit',
    'grossProfitPerMh',
    'grossMargin',
    'grossMarginDefault',
    'grossProfitDefault',
    'profitAccumulation',
    'orderAmountStandalone',
    'won',
    'certainty',
    'proposalDxAllowGrossAllJpy',
    'DXAllow',
  ];

  return (
    <div className="table-container" style={{ position: 'relative' }}>
      <Form form={form} component={false}>
        <Table
          components={{
            body: { cell: EditTableCell },
          }}
          loading={loading}
          className="project-table"
          style={{ marginTop: 5, paddingBottom: 40, backgroundColor: '#fff' }}
          // columns={(columns as any[]).filter((c) => !financialColumns.includes(c.key as string) || isFinancialInfoVisible())}
          columns={columns as any[]}
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: selectedProjects.map((s) => s.subProjectId),
            getCheckboxProps: (record) => ({
              disabled: record.budgetCategoryCustom === 'Awarded',
            }),
            onSelect: onSelected,
          }}
          dataSource={data}
          scroll={{ x: scrollX, y: tableHeightMap[viewMode] }}
          rowKey="subProjectId"
          size="small"
          pagination={{
            position: ['bottomRight'],
            pageSize: pageSize,
            pageSizeOptions: [50, 100, 200, 500, 1000],
          }}
          rowClassName={(record) => {
            if (selectedProjects.map((s) => s.subProjectId).includes(record.subProjectId)) {
              return 'light-selected';
            }
            return '';
          }}
          locale={{
            emptyText: (
              <Empty
                style={{ height: selectorHeight4Table - 105 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('aipcmcty.page.noData')}
              />
            ),
          }}
          onChange={(pagination) => {
            onPageSizeChange(pagination.pageSize);
          }}
        />
      </Form>
      <Space
        style={{
          position: 'absolute',
          left: 4,
          zIndex: 100,
          bottom: 6,
        }}
      >
        <Button type="primary" disabled={!needDisable || loading} onClick={onDemandUpdate}>
          {t('aipcmcty.page.resSimulation.saveChanges')}
        </Button>
        <Button
          type="primary"
          disabled={loading || exportLoading}
          loading={exportLoading}
          onClick={async () => {
            setExportLoading(true);
            const filterList = excelAoaDataSetup();
            const exportData = getExportData();
            const res = await APIList.getSubSimExport().post({
              snapshot,
              snapshotVersion,
              consolidated: Boolean(perspectiveState.consolidated),
              subIds: exportData.map((d) => d.subProjectId),
            });
            const dataList = exportData.map((r) => {
              const d = res.find((d) => d.subProjectId === r.subProjectId);
              return { ...d, ...r };
            });
            await exportTableFromExcelTemp({
              fileName: `sub_project_list_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`,
              templateFilePath: '/assets/simulation_sb_template.xlsx',
              sheetData: [
                {
                  sheet: 'サブ案件一覧',
                  dataType: 'json',
                  data: dataList,
                  options: {
                    templateMappingKeyRowIndex: 2,
                  },
                },
                {
                  sheet: 'フィルタ設定',
                  dataType: 'aoa',
                  data: filterList,
                  options: {
                    merge: [
                      {
                        start: [0, 0],
                        end: [1, 0],
                      },
                      {
                        start: [2, 0],
                        end: [3, 0],
                      },
                      {
                        start: [4, 0],
                        end: [7, 0],
                      },
                      {
                        start: [8, 0],
                        end: [9, 0],
                      },
                      {
                        start: [10, 0],
                        end: [10, 0],
                      },
                      {
                        start: [11, 0],
                        end: [28, 0],
                      },
                    ],
                  },
                },
              ],
            });
            setExportLoading(false);
          }}
        >
          Export to Excel
        </Button>
      </Space>
    </div>
  );
};

const EditTableCell = (props: any) => {
  const { inputType, editing, dataIndex, children, ...restProps } = props;
  let inputNode = null;
  switch (inputType) {
    case 'datepicker':
      inputNode = <DatePicker allowClear={false} minDate={dayjs() as any} picker="month" />;
      break;
    case 'amount':
      inputNode = (
        <InputNumber
          controls={false}
          formatter={(value) => fixed(value).toLocaleString()}
          parser={(value) => value!.replace(/(,*)/g, '')}
        />
      );
      break;
    case 'percent':
      inputNode = (
        <InputNumber
          controls={false}
          min={0}
          max={100}
          formatter={(value) => `${round(value * 100).toFixed(1)}%`}
          parser={(value) => (Number(value.replace('%', '')) / 100) as any}
        />
      );
      break;
    default:
      inputNode = <Input />;
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default ResourceSimulationTable;
