import { END_POINT_NAME } from '../consts/endpoint';
import { apiCommon, API_MODE } from '@meteor/frontend-core';

const APIList = {
  // master
  getTgc: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/tgc'),
  // category = all will fetch all options
  // specify category will fetch the specify options as category = scope
  getUserAuth: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/user-auth'),
  getCmcOptions: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-options'),
  // version
  getVersions: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-versions'),
  createVersions: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-versions'),
  updateVersions: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-versions'),
  getVersionStatus: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/version-status'),
  createSnapshotVersions: apiCommon(END_POINT_NAME.WSS_END_POINT, '/cmc/api/cmc-snapshot-versions', API_MODE.WEBSOCKET),

  // kpi
  getCmcKpis: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-kpis'),
  getCmcDsKpis: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-ds-kpis', API_MODE.LONG_RESPONSE),
  getOrderAmount: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/order-amount'),
  getGrossProfitComposition: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/gross-profit-composition'),
  getHumanResourcesTurnover: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/human-resources-turnover'),
  getGrossProfitMargin: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/gross-profit-margin'),
  getSupplyDemand: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/supply-demand'),
  getRiskMap: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/risk-map'),
  // chart
  getMhPilesChart: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/mh-piles-chart'),
  // table
  getTypicalKpi: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/typical-kpis', API_MODE.LONG_RESPONSE),
  getMhPilesTable: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/kpi/mh-piles-table'),
  getProjectCase: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/table/project-case'),
  getProjectItem: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc/project'),
  updateProjectCase: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/table/project/:id'),
  resetProjectCase: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-project-case/reset'),
  getSubProjectCase: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc/sub-projects'),
  getExportProjectSetup: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/export-project-setup'),
  // re-calculation
  demandForecast: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-demand-forecast/:id'),
  budgetCategoryUpdate: apiCommon(END_POINT_NAME.WSS_END_POINT, '/cmc/api/budget-category/update', API_MODE.WEBSOCKET),
  // project memo
  getProjectMemo: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-project-memo'),
  createProjectMemo: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/create-cmc-project-memo'),
  updateProjectMemo: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/update-cmc-project-memo/:id'),
  deleteProjectMemo: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/delete-cmc-project-memo/:id'),

  // project formation
  getProjectFormation: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-project-formation', API_MODE.LONG_RESPONSE),
  updateProjectFormation: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/update-cmc-project-formation'),

  // ds-matching
  getDsMatchingTableData: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-ds-matching-table'),
  getDsMatchingChartData: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-ds-matching-chart'),
  dsSimulationAction: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/ds-simulation-action'),
  demandForecastAction: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/demand-forecast-action'),
  getSimulationStatus: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/simulation-status'),

  // cmc difference
  getComparison: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/version-comparison'),
  getComparisonResource: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/version-comparison-resource', API_MODE.LONG_RESPONSE),

  // cmc action
  getCmcActions: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/get-cmc-actions'),
  createCmcAction: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/create-cmc-action'),
  updateCmcAction: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/update-cmc-action'),
  deleteCmcAction: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/delete-cmc-action'),
  getCmcActionActivities: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/get-cmc-action-activities'),
  createCmcActionActivity: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/create-cmc-action-activity'),
  updateCmcActionActivity: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/update-cmc-action-activity'),
  deleteCmcActionActivity: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/delete-cmc-action-activity'),
  // cmc management
  getOperationHistory: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-operation-history'),
  postOperationHistory: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc-operation-history'),

  // new api --- 24/10
  getTrinityEvaluationViz: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/trinity-evaluation-viz'),
  getPortfolio: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/get-portfolio'),
  getDemandProjects: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/demand/projects'),
  updateDemandProjects: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/demand/projects'),
  updateProjectForCase: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/update-project-for-case'),
  getInvestsAndRetrieves: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/invests-and-retrieves'),
  getDxDiffs: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/dx-diffs'),
  getGapAnalysis: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/gap-analysis'),
  getSimSubProjects: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/sim-sub-projects'),
  updateDemandSubProjects: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/demand/sub-projects'),

  // new api --- 24/12
  getProjectUpdatesMsg: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/cmc_project_updates_msg'),

  // DEMO
  getChart: apiCommon(END_POINT_NAME.EDGE_API, '/api/demo/chart'),
  getTable: apiCommon(END_POINT_NAME.EDGE_API, '/api/demo/table'),
  getExtractTable: apiCommon(END_POINT_NAME.EDGE_API, '/api/demo/extractTable'),

  // export-typical-kpis
  exportTypicalKpis: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/export-typical-kpis'),
  // new api --- 24/23
  getTrinityEvaluationCompare: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/trinity-evaluation-compare'),

  getSubDiffCompare: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/sub-diff-compare'),
  // export sim table --- 25/01/20
  getSubSimExport: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/sub-sim-export'),
  getResourceSimulation: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/resource-simulation'),
  getMonthlyReport: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/monthly-report'),
  getEXtractResources: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/extract-resources'),
  getEXtractResourcesTable: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/extract-resources-table'),
  saveLogsToDb: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/user-logs'),
  getProjectAndSubExportInfo: apiCommon(END_POINT_NAME.EDGE_API, '/cmc/api/project-sub-export-info'),
};

export default APIList;
