import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Select, Space, Switch, Table, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import useVersion from '../../hooks/useVersion';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import APIList from '../../http/ApiList';
import {
  chain,
  eq,
  forEach,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  pick,
  debounce as _debounce,
  filter,
  set,
  round,
  values,
  flatten,
  find,
  keys,
  toNumber,
  isArray,
  orderBy,
  groupBy,
  cloneDeep,
} from 'lodash';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { useWindowSize } from '../../hooks/useWindowSize';
import { departmentOpts as dOpts } from '../simulation/resource-simulation';
import axios from 'axios';
import Excel from 'exceljs';
import { AppContext } from '../../contexts/AppContext';

const departmentOpts = dOpts.map((d) => {
  const n = { ...d } as any;
  if (d.value !== 'その他: 過去分') {
    n.isSelected = true;
  }
  if (d.value === 'その他: 過去分') {
    n.label = '-';
  }
  if (d.value === 'OFS') {
    n.value = 'OFS本部';
  }
  return n;
});

/**
 * create FY number
 * * FY25, FY26....
 * @param beforeDay before now year number
 * @param afterDay  after now year number
 */
const createFyNum = (beforeDay: number, afterDay: number) => {
  const start = toNumber(dayjs().add(-beforeDay, 'year').format('YY'));
  const end = toNumber(dayjs().add(afterDay, 'year').format('YY'));
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

const kpiOpts = [
  {
    label: '受注金額',
    value: '受注金額',
    key: '受注金額',
    dataIndex: 'orderAmount',
    isSelected: true,
    width: 80,
  },
  {
    label: '受注金額(明細)',
    value: '受注金額(明細)',
    key: '受注金額(明細)',
    dataIndex: 'orderAmountStandalone',
    isSelected: true,
    width: 120,
  },
  {
    label: '受注粗利',
    value: '受注粗利',
    key: '受注粗利',
    dataIndex: 'orderGrossProfit',
    isSelected: true,
    width: 80,
  },
  {
    label: '粗利率',
    value: '粗利率',
    key: '粗利率',
    width: 70,
  },
  ...map(createFyNum(1, 3), (v) => ({
    label: `FY${v}粗利`,
    value: `年度跳ね返り粗利FY${v}`,
    key: `年度跳ね返り粗利FY${v}`,
    dataIndex: `grossProfitFy${v}`,
    isSelected: `${dayjs().format('YY')}` == `${v}`,
    width: 80,
  })),
  {
    label: '需要MH',
    value: '需要MH',
    key: '需要MH',
    dataIndex: 'demandMh',
    width: 90,
  },
];

const attrOpts = [
  { label: '受注予定日', value: 'orderScheduledDate', isSelected: true },
  { label: '案件名', value: 'projectName', isSelected: true },
  { label: 'TGC', value: 'tgc', isSelected: true },
  { label: '役務範囲', value: 'scope', isSelected: true },
  { label: '会計年度', value: 'fiscalYear', isSelected: true },
  { label: '予算カテゴリ', value: 'categoryCustom', isSelected: true },
];

const modeTarget = map(kpiOpts, 'dataIndex').filter((i) => i);
const grossProfitArray = chain(kpiOpts)
  .filter((v) => includes(v.value, '年度跳ね返り粗利'))
  .map('value')
  .value();

const categoryCustomOpts = [
  { label: 'Awarded', value: 'Awarded', key: 'Awarded' },
  { label: 'Budget', value: 'Budget', key: 'Budget', isSelected: true },
  { label: 'IF', value: 'IF', key: 'IF' },
  { label: 'Others', value: 'Others', key: 'Others' },
];

/**
 * handle table column by snapshot
 * @param snapshot type of key: { 'snapshot', 'snapshotVersion' }
 */
const handleCellBySnapshot = (snapshot: any[], kpisSelected: any[], versionMode: any, defaultCols) => {
  const columns = [...defaultCols];
  // const leftSelectedSnapshot = find(snapshot, ['target', true]);
  const sortSnapshot = chain(snapshot)
    .filter((i) => !i.target)
    .orderBy(['snapshot'], ['asc'])
    // .push(leftSelectedSnapshot)
    .value();
  forEach(sortSnapshot, (i) => {
    const snapshotVersionName = find(get(versionMode, i.snapshot), ['value', i.snapshotVersion])?.label;
    columns.push({
      title: (
        <>
          <div style={{ textAlign: 'center' }}>{i.snapshot}</div>
          <div style={{ textAlign: 'center' }}>[{snapshotVersionName}]</div>
        </>
      ),
      className: 'border-left',
      children: map(kpiOpts, (kpi) => {
        // only one
        const key = kpi.value;
        const target = `${i.snapshot} ${i.snapshotVersion}`;
        const hidden = isEmpty(kpisSelected) ? false : !includes(kpisSelected, key);
        const configure = {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>{kpi.label}</div>
            </>
          ),
          key: kpi.key,
          hidden,
          width: kpi.width,
        };
        if (kpisSelected[0] === kpi.value) {
          set(configure, 'className', 'border-left');
        }
        if (includes(['受注金額', '受注金額(明細)', '受注粗利', ...grossProfitArray], key)) {
          set(configure, 'dataIndex', [kpi.dataIndex, `${i.snapshot} ${i.snapshotVersion}`]);
          set(configure, 'render', (value: any) => {
            if (isNil(value))
              return (
                <>
                  <div style={{ textAlign: 'right' }}>-</div>
                </>
              );
            const show = round(value / 1000000, 0);
            return (
              <>
                <div style={{ textAlign: 'right' }}>{show.toLocaleString()}</div>
              </>
            );
          });
        } else if (eq('粗利率', key)) {
          set(configure, 'render', (_, data) => {
            if (isNil(data.orderAmount?.[target]))
              return (
                <>
                  <div style={{ textAlign: 'right' }}>-</div>
                </>
              );
            const show = (((data.orderGrossProfit?.[target] ?? 0) / data.orderAmount[target]) * 100).toFixed(1);
            return (
              <>
                <div style={{ textAlign: 'right' }}>{data.orderAmount[target] == 0 ? '-' : `${show}%`}</div>
              </>
            );
          });
        } else if (eq('需要MH', key)) {
          set(configure, 'render', (_, data) => {
            if (isNil(data.demandMh?.[target]))
              return (
                <>
                  <div style={{ textAlign: 'right' }}>-</div>
                </>
              );
            const show = data.demandMh?.[target];
            return (
              <>
                <div style={{ textAlign: 'right' }}>{show.toLocaleString()}</div>
              </>
            );
          });
        }
        return configure;
      }),
    });
  });
  return columns;
};

/**
 * set table columns hidden by selected kip options
 * @param kipsSelected selected kip options
 */
const hiddenForCellKips = (tableColumns: any, kpisSelected: string[]) => {
  return map(tableColumns, (item, index) => {
    if (!index) return item;
    if (index) {
      forEach(item.children, (row) => {
        row.hidden = !includes(kpisSelected, row.key);
      });
    }
    return item;
  });
};

/** Notice: This function is only affect on p-diff page and sub-diff page's exportation */
/** Notice: Do not use in other pages exportation */
export const projectCompareExport = async (
  copyColStart: number,
  copyColEnd: number,
  templatePath: string,
  snapshots: any[],
  [tableData, tableData1]: any[],
  filterGenMethod: (snapshots: string[]) => any[],
  [mergeData0, mergeData1]: any[],
  fileNamePrefix = 'project_compare'
) => {
  // Helper functions to reduce duplication
  const setupWorksheet = (sheet: Excel.Worksheet, snapNameStr: string[], snapVersionStr: string[]) => {
    sheet.eachRow((row, i) => {
      if (i === 1) {
        const sourceCell = row.getCell(copyColStart);
        for (let j = 0; j < snapNameStr.length; j++) {
          const targetCell = row.getCell(copyColStart + copyColLength * j);
          targetCell.value = snapNameStr[j];
          targetCell.style = sourceCell.style;
          if (j > 0) {
            sheet.mergeCells(i, copyColStart + copyColLength * j, i, copyColStart + copyColLength * j + copyColLength - 1);
          }
        }
      }
      if (i === 2) {
        for (let j = 0; j < copyColLength; j++) {
          const sourceCell = row.getCell(copyColStart + j);
          for (let k = 0; k < snapVersionStr.length; k++) {
            const targetCell = row.getCell(copyColStart + j + copyColLength * k);
            targetCell.value = sourceCell.value;
            targetCell.style = sourceCell.style;
          }
        }
      }
      if (i === 3) {
        for (let j = 0; j < copyColLength; j++) {
          const sourceCell = row.getCell(copyColStart + j);
          const cachedSourceVal = sourceCell.value;
          for (let k = 0; k < snapNameStr.length; k++) {
            const targetCell = row.getCell(copyColStart + j + copyColLength * k);
            targetCell.value = `${cachedSourceVal}_${snapVersionStr[k]}`;
            targetCell.style = sourceCell.style;
          }
        }
      }
    });
  };

  const setColumnWidths = (sheet: Excel.Worksheet) => {
    for (let i = 0; i < copyColLength; i++) {
      const colWidth = sheet.getColumn(copyColStart + i).width;
      const colStyle = sheet.getColumn(copyColStart + i).style;
      for (let j = 0; j < snapNameStr.length; j++) {
        const tarCol = sheet.getColumn(copyColStart + i + copyColLength * j);
        tarCol.width = colWidth;
        tarCol.style = colStyle;
      }
    }
  };

  const insertTableData = (sheet: Excel.Worksheet, data: any[], rowKeys: any[]) => {
    const mappedData = data.reduce((acc, sd) => {
      const row = (rowKeys as string[]).map((k) => sd[k]);
      acc.push(row);
      return acc;
    }, []);
    sheet.spliceRows(3, 1, ...mappedData);
    sheet.eachRow((row, rowIndex) => {
      if (rowIndex >= 3) {
        row.eachCell((cell) => {
          if (data[rowIndex - 3]?.numFmt) {
            cell.numFmt = data[rowIndex - 3].numFmt;
          }
        });
      }
    });
  };

  const processMergeData = (sheet: Excel.Worksheet, mergeData: any[], notMergeList: string[], data: any[]) => {
    let mergeStart = 3;
    mergeData.forEach((i) => {
      const flag = i.some((e) => e.includes('小計')) || notMergeList.includes(i[0]);
      if (!notMergeList.includes(i[0]) && flag) {
        sheet.mergeCells(mergeStart, 1, i.length + mergeStart - 1, 1);
        const mergedCell = sheet.getCell(mergeStart, 1);
        mergedCell.alignment = { vertical: 'middle' };
      }
      const rowNum = i.length + mergeStart - 1;
      const row = sheet.getRow(rowNum);
      const thirdCell = sheet.getCell(rowNum, 3);
      const cells = [];
      for (let col = 2; col < Object.keys(data[0]).length - 2; col++) {
        const cell = row.getCell(col);
        cells.push(row.getCell(col));
        if (flag && col === 2) {
          cell.value = thirdCell.value;
        }
      }

      if (flag) {
        cells.forEach((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE5E5E5' },
          };
          cell.font = {
            bold: true,
            color: { argb: 'FF000000' },
          };
          cell.alignment = { horizontal: 'right' };
        });
        sheet.mergeCells(i.length + mergeStart - 1, 2, i.length + mergeStart - 1, 8);
      }
      mergeStart += i.length;
    });
  };

  // Main execution
  const copyColLength = copyColEnd - copyColStart + 1;
  const tempResp = await axios.get(templatePath, { responseType: 'arraybuffer' });
  const tempBuffer = new Uint8Array(tempResp.data);
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(tempBuffer as any);

  // Get worksheets
  const compareSheet = workbook.worksheets[0];
  const compareSheet1 = workbook.worksheets[1];
  const filterSheet = workbook.worksheets[2];

  // Prepare snapshot strings
  const snapNameStr = snapshots.map((s) => `${s.snapshot}_${s.snapshotVersionName}`);
  const snapVersionStr = snapshots.map((s) => `${s.snapshot}_${s.snapshotVersion}`);

  // Setup worksheets
  setupWorksheet(compareSheet, snapNameStr, snapVersionStr);
  setupWorksheet(compareSheet1, snapNameStr, snapVersionStr);

  // Set column widths
  setColumnWidths(compareSheet);
  setColumnWidths(compareSheet1);

  // Insert table data
  const rowKeys = compareSheet.getRow(3).values;
  const rowKeys1 = compareSheet1.getRow(3).values;
  insertTableData(compareSheet, tableData, rowKeys as []);
  insertTableData(compareSheet1, tableData1, rowKeys1 as []);

  // Process merge data
  const notMergeList = ['[A] Toyo-J(協業)合計', '[B]拠点(独自)合計', '[A + B]連結合計', '[A + B + C]'];
  const notMergeList1 = ['[A] 独自', '[B] 協業', '[A + B] 合計', '[C] OFS(合計)', '[A + B + C] 合計'];
  processMergeData(compareSheet, mergeData0, notMergeList, tableData);
  processMergeData(compareSheet1, mergeData1, notMergeList1, tableData1);

  // Process filter sheet
  const filterList = filterGenMethod(snapshots.map((s) => `${s.snapshot} ${s.snapshotVersion}`));
  filterSheet.spliceRows(2, 1, ...filterList);

  let snapshotMergeStart = 2;
  filterSheet.mergeCells(2, 1, (snapshotMergeStart = snapshotMergeStart + snapshots.length - 1), 1);
  filterSheet.mergeCells((snapshotMergeStart = snapshotMergeStart + 1), 1, (snapshotMergeStart = snapshotMergeStart + 1), 1);
  filterSheet.mergeCells((snapshotMergeStart = snapshotMergeStart + 1), 1, (snapshotMergeStart = snapshotMergeStart + 5), 1);

  // Export the file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${fileNamePrefix}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const sortDirections = [null, 'asc', 'desc'];

export const businessUnitSort1 = {
  CN本部: 0,
  海外: 1,
  国内: 2,
  OFS本部: 3,
  '[A] Toyo-J(協業)合計': 4,
  'Toyo-I': 5,
  'Toyo-K': 6,
  'Toyo-M': 7,
  'Toyo-C': 8,
  'Toyo-USA': 9,
  IKPT: 10,
  TPS: 11,
  TSPI: 12,
  '[B]拠点(独自)合計': 13,
  '[A + B]連結合計': 14,
  OFS: 15,
  '[A + B + C]': 16,
  'その他: 過去分': 17,
};

export const businessUnitSort3 = {
  'Toyo-J': 0,
  'Toyo-I': 1,
  'Toyo-K': 2,
  'Toyo-M': 3,
  'Toyo-C': 4,
  'Toyo-USA': 5,
  IKPT: 6,
  TPS: 7,
  TSPI: 8,
};

export const accPerspectiveOpts = [
  { label: '連結＋持分', value: '連結＋持分', key: '連結＋持分' },
  { label: '拠点単体', value: '拠点単体', key: '拠点単体' },
];

const PDiffPage: React.FC = () => {
  /** about common config */
  const { t } = useTranslation();
  const { snapshot, snapshotVersion, allSnapshotList, allVersionOptCollection } = useVersion(false);
  const { certainty, demandMhPercent, color } = useContext(AppContext);
  const { selectorHeight4Table } = useWindowSize({
    selector: '.sub-diff-table .cmcty-table-body',
    viewMode: 'table-only',
    watcherSelector: ['.operation-container'],
  });
  /** 变量定义 */
  // 検索条件
  const [sifter, setSifter] = useState({
    fiscalYear: [`FY${dayjs().format('YY')}`],
    kpis: [...chain(kpiOpts).filter('isSelected').map('value').value()],
    attrs: [...chain(attrOpts).filter('isSelected').map('value').value()],
    departments: [...chain(departmentOpts).filter('isSelected').map('value').value()],
    allMode: true,
    tgcs: [],
    category: [...chain(categoryCustomOpts).filter('isSelected').map('value').value()],
    accPerspective: accPerspectiveOpts[0].value,
    search: '',
  });
  // 年度
  const [fiscalYearOpts, setFiscalYearOpts] = useState([]);
  // tgc options
  const [tgcOpts, setTgcOpts] = useState([]);
  // 比較バージョンを追加
  const [form] = Form.useForm();
  // 「比較バージョンを追加」の Modal
  const [modalOpen, setModalOpen] = useState(false);
  // loading
  const [loading, setLoading] = useState(false);
  // table show data
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  // table data cache
  const [cacheTableSource, setCacheTableSource] = useState({
    table1: [],
    table21: [],
    table22: [],
    table23: [],
  });
  // table length
  const [tableLength, setTableLength] = useState({
    ['拠点単体']: 0,
    ['連結＋持分']: 0,
  });
  // table columns
  const [tableColumn, setTableColumn] = useState([]);
  // table width
  const [tableWidth, setTableWidth] = useState(1000);

  // specified sort columns
  const sortReducer = (state, action) => {
    const { sortKey, sortDir } = state;
    switch (action.type) {
      case 'projectId':
        if (sortKey !== 'projectId') {
          return { sortKey: 'projectId', sortDir: 1 };
        }
        return { sortKey: 'projectId', sortDir: sortDir + 1 > 2 ? 0 : sortDir + 1 };
      case 'orderScheduledDate':
        if (sortKey !== 'orderScheduledDate') {
          return { sortKey: 'orderScheduledDate', sortDir: 1 };
        }
        return { sortKey: 'orderScheduledDate', sortDir: sortDir + 1 > 2 ? 0 : sortDir + 1 };
      default:
        return state;
    }
  };
  const [sortState, sortDispatch] = useReducer(sortReducer, {
    sortKey: null,
    sortDir: 0,
  });
  useEffect(() => {
    console.log('-------- sortState --------');
    handleFilterChange1(sifter);
    handleFilterChange2(sifter);
    const snapshots = getSnapshotVersion();
    const columnInfo = handleCellBySnapshot(snapshots, sifter.kpis, allVersionOptCollection, defTableColumns);
    const columns = hiddenForCellKips(columnInfo, sifter.kpis);
    setTableColumn(columns);
  }, [sortState]);
  const defTableColumns: ColumnsType = [
    {
      className: 'border-right-none',
      children: [
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>ビジネスユニット</div>
            </>
          ),
          dataIndex: 'businessUnit',
          fixed: 'left',
          width: 180,
          onCell: (item) => {
            const rowSpan = get(item, 'DEPARTMENT_ROWSPAN', 0);
            return { rowSpan };
          },
          render(value, item) {
            if (value === 'OFS' && item?.type?.startsWith('OFS')) {
              return item.type;
            }
            if (value === 'その他: 過去分') {
              return '-';
            }
            return value;
          },
        },
        {
          title: (
            <div onClick={() => sortDispatch({ type: 'orderScheduledDate' })} style={{ textAlign: 'center' }}>
              受注予定日
            </div>
          ),
          dataIndex: 'orderScheduledDate',
          fixed: 'left',
          width: 110,
          sorter: () => 0,
          sortOrder: sortState.sortKey === 'orderScheduledDate' ? ([null, 'ascend', 'descend'][sortState.sortDir] as any) : null,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
              return { colSpan: 7, className: 'bac' };
            }
            return { colSpan: 1 };
          },
          render: (value, item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL')) {
              return <div style={{ textAlign: 'right' }}> {value} </div>;
            }
            if (type?.startsWith('CATE_TOTAL')) {
              return <div style={{ textAlign: 'right' }}></div>;
            }
            return value;
          },
        },
        {
          title: (
            <div onClick={() => sortDispatch({ type: 'projectId' })} style={{ textAlign: 'center' }}>
              案件ID
            </div>
          ),
          dataIndex: 'projectId',
          fixed: 'left',
          width: 78,
          sorter: () => 0,
          sortOrder: sortState.sortKey === 'projectId' ? ([null, 'ascend', 'descend'][sortState.sortDir] as any) : null,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
          render: (value, item) => {
            const type = get(item, 'type');
            const ids = value.split(',');
            if (type?.startsWith('DATA_TOTAL')) {
              return <div style={{ textAlign: 'right' }}> {value} </div>;
            }
            if (type?.startsWith('CATE_TOTAL')) {
              return <div style={{ textAlign: 'right' }}></div>;
            }
            return (
              <div>
                {ids.map((id) => (
                  <div key={id}>{id}</div>
                ))}
              </div>
            );
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>案件名</div>
            </>
          ),
          dataIndex: 'projectName',
          fixed: 'left',
          width: 240,
          ellipsis: true,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>TGC</div>
            </>
          ),
          dataIndex: 'tgc',
          fixed: 'left',
          width: 80,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>役務範囲</div>
            </>
          ),
          dataIndex: 'scope',
          width: 180,
          ellipsis: true,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>会計年度</div>
            </>
          ),
          dataIndex: 'fiscalYear',
          width: 75,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
        {
          title: (
            <>
              <div style={{ textAlign: 'center' }}>{t('aipcmcty.page.orderPlanningCategory')}</div>
            </>
          ),
          dataIndex: 'categoryCustom',
          className: 'border-right-none',
          width: 130,
          onCell: (item) => {
            const type = get(item, 'type');
            if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
              return { colSpan: 0 };
            }
            return { colSpan: 1 };
          },
        },
      ],
    },
  ];

  const saveListToLocalStorage = (list) => {
    if (!isEmpty(list)) {
      localStorage.setItem('snapshot-p-diff', JSON.stringify(list));
    }
  };
  /** init data */
  // init of snapshot default value
  const defSnapshot = useMemo(() => {
    const formValue = {
      list: [
        { snapshot: '2024-02-28', snapshotVersion: 'default' },
        { snapshot: '2024-08-14', snapshotVersion: 'default' },
        { snapshot, snapshotVersion },
      ],
    };
    // TODO:
    // Instance created by `useForm` is not connected to any Form element. Forget to pass `form` prop?
    // 问题出现在这里
    let localSnapshotList = localStorage.getItem('snapshot-p-diff');
    if (!localSnapshotList && !JSON.parse(localSnapshotList)) {
      form.setFieldsValue(formValue);
      return formValue;
    } else {
      form.setFieldsValue({
        list: JSON.parse(localSnapshotList),
      });
      return {
        list: JSON.parse(localSnapshotList),
      };
    }
    // }, [orgAllSnapshots]);
  }, []);
  // init of fiscal year options
  useEffect(() => {
    APIList.getCmcOptions()
      .get({
        category: 'accountingYear',
        snapshot,
        snapshotVersion,
      })
      .then((res) => {
        setFiscalYearOpts(
          map(res, (i) => ({
            ...pick(i, ['value', 'label']),
            key: i.value,
          }))
        );
      })
      .catch(() => {
        message.error('init account year data error');
      });
    APIList.getCmcOptions()
      .get({
        category: 'tgc',
        snapshot,
        snapshotVersion,
      })
      .then((res) => {
        setTgcOpts(res);
        sifter.tgcs = map(res, 'value');
      })
      .catch(() => {
        message.error('init tgc data error');
      });
    // }, [snapshot, snapshotVersion]);
  }, []);

  /** public function */
  // get selected snapshot version list
  const getSnapshotVersion = () => {
    return (
      chain(
        // 当不打开弹窗时，form值不会被定义，为空，此时从数据中计算version信息
        get(isEmpty(form.getFieldsValue()) ? defSnapshot : form.getFieldsValue(), 'list', [])
      )
        .filter((i) => !isNil(i))
        .map((i) => pick(i, ['snapshot', 'snapshotVersion', 'getSnapshotVersionName']))
        // .push({ snapshot, snapshotVersion, target: true })
        .uniqBy((i) => `${i.snapshot} ${i.snapshotVersion}`)
        .value()
    );
  };

  const attrChange = (selected: string[]) => {
    const nSelected = [...selected, 'businessUnit', 'projectId'];
    const newCols = [
      {
        ...defTableColumns[0],
        children: [...(defTableColumns[0] as any).children],
      },
    ];
    const attrCols = (newCols[0] as any).children.filter((c) => nSelected.includes(c.dataIndex));
    const flag = selected.includes('orderScheduledDate');
    const dateCols = attrCols.find((c) => c.dataIndex === 'orderScheduledDate');
    const idCols = attrCols.find((c) => c.dataIndex === 'projectId');
    if (flag) {
      dateCols.onCell = (item) => {
        const type = get(item, 'type');
        if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
          return { colSpan: selected.length + 1, className: 'bac' };
        }
        return { colSpan: 1 };
      };
    } else {
      idCols.onCell = (item) => {
        const type = get(item, 'type');
        if (type?.startsWith('DATA_TOTAL') || type?.startsWith('CATE_TOTAL')) {
          return { colSpan: selected.length + 1, className: 'bac' };
        }
        return { colSpan: 1 };
      };
    }
    (newCols[0] as any).children = attrCols;
    setSifter((prev) => ({ ...prev, attrs: selected }));
    const snapshots = getSnapshotVersion();
    const columnInfo = handleCellBySnapshot(snapshots, sifter.kpis, allVersionOptCollection, newCols);
    const columns = hiddenForCellKips(columnInfo, sifter.kpis);
    setTableColumn(columns);
    // setTitleDataSize(tableLength[sifter.accPerspective]);
  };

  /** selector change function */
  // kpi selector change fun.
  const kpiSelectorChange = (selected: string[]) => {
    const kpiOrder = kpiOpts.map((opt, i) => opt.value);
    let orderSelected = kpiOrder.reduce((prev, kpi) => {
      if (selected.includes(kpi)) {
        prev.push(kpi);
      }
      return prev;
    }, []);
    // update kip selector values.
    setSifter((value) => ({ ...value, kpis: orderSelected }));
    // set table column by kpi select
    if (isEmpty(orderSelected)) {
      orderSelected = map(kpiOpts, 'value');
    }
    const snapshots = getSnapshotVersion();
    const columnInfo = handleCellBySnapshot(snapshots, orderSelected, allVersionOptCollection, defTableColumns);
    const columns = hiddenForCellKips(columnInfo, orderSelected);
    setTableColumn(columns);
  };

  const splitVersionVals = (verVals: any, key: string, splitItem: any) => {
    Object.keys(verVals).forEach((k) => {
      const [s, sv] = k.split(' ');
      splitItem[`${key}_${s}_${sv}`] = verVals[k];
      if (key === 'orderGrossProfit') {
        splitItem[`grossMargin_${s}_${sv}`] =
          !isNil(splitItem[`orderGrossProfit_${s}_${sv}`]) && !!splitItem[`orderAmount_${s}_${sv}`]
            ? splitItem[`orderGrossProfit_${s}_${sv}`] / splitItem[`orderAmount_${s}_${sv}`]
            : null;
      }
    });
  };

  const genExportTableData = () => {
    const processTableData = (tableData) => {
      return tableData.map((t) => {
        const {
          businessUnit: department,
          projectId,
          projectName,
          tgc,
          scope,
          fiscalYear,
          orderScheduledDate,
          categoryCustom,
          orderAmount,
          orderAmountStandalone,
          orderGrossProfit,
          demandMh,
          grossProfitFy24,
          grossProfitFy25,
          grossProfitFy26,
          grossProfitFy27,
          grossProfitFy28,
        } = t;

        const eItem = {
          department,
          projectId,
          projectName,
          tgc,
          scope,
          fiscalYear,
          orderScheduledDate,
          budgetCategoryCustom: categoryCustom,
        };

        // Process all versioned values
        [
          { value: orderAmount, key: 'orderAmount' },
          { value: orderAmountStandalone, key: 'orderAmountStandalone' },
          { value: orderGrossProfit, key: 'orderGrossProfit' },
          { value: demandMh, key: 'demandMh' },
          { value: grossProfitFy24, key: 'gp24' },
          { value: grossProfitFy25, key: 'gp25' },
          { value: grossProfitFy26, key: 'gp26' },
          { value: grossProfitFy27, key: 'gp27' },
          { value: grossProfitFy28, key: 'gp28' },
        ].forEach(({ value, key }) => splitVersionVals(value, key, eItem));

        return eItem;
      });
    };

    return [processTableData(tableData1), processTableData(tableData2)];
  };

  const filterDataSetup = (snapshots: string[]) => {
    const filterList: any[] = [];
    const certaintyOpt = [
      {
        value: 0,
        label: t('aipcmcty.page.wonPercentageNotConsidered'),
      },
      {
        value: 1,
        label: t('aipcmcty.page.wonPercentageConsidered'),
      },
    ];
    const getValue = (value, SP = ', ') => {
      const EMPTY = '-';
      if (isArray(value)) {
        if (value.length === 0 || value.every((item) => isNil(item) || item === '')) {
          return EMPTY;
        }
        return value.join(SP);
      }
      if (isNil(value) || value === '') {
        return EMPTY;
      }
      return value;
    };
    // Compare snapshot versions
    snapshots.forEach((s, i) => {
      filterList.push([i === 0 ? '比較バージョン' : '', 'Version', s]);
    });
    // 算出設定
    filterList.push([
      t('aipcmcty.page.calculationSettings'),
      t('aipcmcty.page.budgetRevenueCalculation'),
      getValue(certaintyOpt.find((item) => item.value === certainty)?.label),
    ]);
    filterList.push(['', t('aipcmcty.page.supplyDemandGapAllowed'), getValue(demandMhPercent.toLocaleString())]);
    // Header Filter
    filterList.push(['Header Filter', '本部', getValue(sifter.departments)]);
    filterList.push(['', 'TGC', getValue(sifter.tgcs)]);
    filterList.push(['', '会計年度', getValue(sifter.fiscalYear)]);
    filterList.push(['', '予算カテゴリ', getValue(sifter.category)]);
    filterList.push(['', 'モード', sifter.allMode ? '全量' : '差分']);
    filterList.push(['', '案件名/案件ID', getValue(sifter.search)]);
    return filterList;
  };

  /** export button click function */
  const handleExportClick = async () => {
    setLoading(true);
    // Step1: Generate base data in table
    const [exportTables0, exportTables1] = genExportTableData();
    // Step2: Set Export Excel Table Data and Configurations
    const snapshots = getSnapshotVersion().map((s) => {
      const ver = allVersionOptCollection[s.snapshot].find((l) => l.value === s.snapshotVersion);
      return { ...s, snapshotVersionName: ver.label };
    });
    const notMergeList0 = ['[A] Toyo-J(協業)合計', '[B]拠点(独自)合計', '[A + B]連結合計', '[A + B + C]'];
    const notMergeList1 = ['[A] 独自', '[B] 協業', '[A + B] 合計', '[C] OFS(合計)', '[A + B + C] 合計'];

    const sheetMergeData0 = exportTables0
      .map((i) => i.projectId)
      .reduce((acc, current) => {
        if (acc.length === 0) acc.push([]);
        acc[acc.length - 1].push(current);
        if (current.includes('小計')) acc.push([]);
        if (current.includes('[A] Toyo-J(協業)合計')) acc.push([]);
        if (current.includes('[B]拠点(独自)合計')) acc.push([]);
        if (current.includes('[A + B]連結合計')) acc.push([]);
        if (current.includes('[A + B + C]')) acc.push([]);
        return acc;
      }, [])
      .filter((arr) => arr.length > 0);
    const sheetMergeData1 = exportTables1
      .map((i) => (i.department === '[C] OFS(合計)' || i.department === '[A + B + C] 合計' ? i.department : i.projectId))
      .reduce((acc, current) => {
        if (acc.length === 0) acc.push([]);
        acc[acc.length - 1].push(current);
        if (current.includes('小計')) acc.push([]);
        if (current.includes('[A] 独自')) acc.push([]);
        if (current.includes('[B] 協業')) acc.push([]);
        if (current.includes('[A + B] 合計')) acc.push([]);
        if (current.includes('[C] OFS(合計)')) acc.push([], []);
        if (current.includes('[A + B + C] 合計')) acc.push([]);
        return acc;
      }, [])
      .filter((arr) => arr.length > 0);
    const transformLastItems = (arr: any[]) => {
      if (arr.length < 2) return arr;

      const lastTwo = arr.slice(-2);
      const rest = arr.slice(0, -2);
      const secondLast = lastTwo[0];
      const ofsIndex = secondLast.indexOf('[C] OFS(合計)');
      const normalItems = ofsIndex > -1 ? secondLast.slice(0, ofsIndex) : secondLast;
      const ofsItem = ofsIndex > -1 ? [secondLast[ofsIndex]] : [];
      const lastItem = lastTwo[1];

      return [...rest, normalItems, ...(ofsItem.length ? [ofsItem] : []), lastItem];
    };
    console.log(transformLastItems(sheetMergeData1));
    const data0 = exportTables0.map((i) => ({ ...i, projectId: notMergeList0.includes(i.projectId) ? '' : i.projectId }));
    const data1 = exportTables1.map((i) => ({ ...i, projectId: notMergeList1.includes(i.projectId) ? '' : i.projectId }));
    await projectCompareExport(9, 17, '/assets/projects_compare_template.xlsx', snapshots, [data0, data1], filterDataSetup, [
      sheetMergeData0,
      transformLastItems(sheetMergeData1),
    ]);
    setLoading(false);
  };

  /** compare button click function */
  const handleCompareClick = () => {
    const snapshots = getSnapshotVersion();
    const columnInfo = handleCellBySnapshot(snapshots, sifter.kpis, allVersionOptCollection, defTableColumns);
    const columns = hiddenForCellKips(columnInfo, sifter.kpis);
    setTableColumn(columns);
    let width = 0;
    forEach(columnInfo, (item: any) => {
      forEach(item.children, (i) => {
        width += i.hidden ? 0 : i.width;
      });
    });
    setTableWidth(width);
    setModalOpen(false);
    findTableDataAjax();
  };

  /** about filters */
  // selector change func
  const filterSelectorChangeCallBack = (fKey: string, changedValues: string | string[] | boolean) => {
    console.log('------- selector --------');
    const newSifter = { ...sifter, [fKey]: changedValues };
    setSifter(newSifter);
    handleFilterChange1(newSifter);
    handleFilterChange2(newSifter);
  };

  const genTagTotal = (tags, target, data) => {
    const selectedSnapshotVersionKeyList = map(getSnapshotVersion(), (i) => `${i.snapshot} ${i.snapshotVersion}`);
    tags.forEach((dk) => {
      if (data[dk]) {
        data[dk].forEach((s) => {
          selectedSnapshotVersionKeyList.forEach((k) => {
            target.demandMh[k] = (target.demandMh[k] ?? 0) + (s.demandMh[k] ?? 0);
            target.grossProfitFy24[k] = (target.grossProfitFy24[k] ?? 0) + (s.grossProfitFy24[k] ?? 0);
            target.grossProfitFy25[k] = (target.grossProfitFy25[k] ?? 0) + (s.grossProfitFy25[k] ?? 0);
            target.grossProfitFy26[k] = (target.grossProfitFy26[k] ?? 0) + (s.grossProfitFy26[k] ?? 0);
            target.grossProfitFy27[k] = (target.grossProfitFy27[k] ?? 0) + (s.grossProfitFy27[k] ?? 0);
            target.grossProfitFy28[k] = (target.grossProfitFy28[k] ?? 0) + (s.grossProfitFy28[k] ?? 0);
            target.orderAmount[k] = (target.orderAmount[k] ?? 0) + (s.orderAmount[k] ?? 0);
            target.orderAmountStandalone[k] = (target.orderAmountStandalone[k] ?? 0) + (s.orderAmountStandalone[k] ?? 0);
            target.orderGrossProfit[k] = (target.orderGrossProfit[k] ?? 0) + (s.orderGrossProfit[k] ?? 0);
          });
        });
      }
    });
  };

  const genTableSource = (newSifter: any, table: any[]) => {
    const selectedSnapshotVersionNum = getSnapshotVersion().length;
    const defTgcFilterList = map(tgcOpts, 'value');
    // clear rowSpan setting
    chain(sifter.accPerspective === '拠点単体' ? tableData2 : tableData1)
      .groupBy('businessUnit')
      .forEach((v) => {
        set(v, [0, 'DEPARTMENT_ROWSPAN'], undefined);
      })
      .value();

    const amountKeys = [
      'orderAmount',
      'orderAmountStandalone',
      'orderGrossProfit',
      'grossProfitFy24',
      'grossProfitFy25',
      'grossProfitFy26',
      'grossProfitFy27',
      'grossProfitFy28',
      'demandMh',
    ];
    const versionList = getSnapshotVersion().map((s) => `${s.snapshot} ${s.snapshotVersion}`);
    const allKeys = Object.keys(table[0]);
    const temp1 = table.reduce((prev, cur) => {
      const mKey = cur.type
        ? cur.projectId + cur.fiscalYear + cur.projectName + cur.scope + cur.categoryCustom + cur.type + cur.businessUnit
        : cur.projectId + cur.fiscalYear + cur.projectName + cur.scope + cur.categoryCustom + cur.businessUnit;
      if (!prev[mKey]) {
        prev[mKey] = [];
      }
      prev[mKey].push(cur);
      return prev;
    }, {} as any);
    const temp2 = Object.entries<any>(temp1).reduce((prev, [k, vs]) => {
      prev[k] = vs.reduce((pr, v) => {
        allKeys.forEach((k) => {
          if (!pr[k]) {
            if (amountKeys.includes(k)) {
              pr[k] = Object.fromEntries(versionList.map((vk) => [vk, null]));
            } else {
              pr[k] = v[k];
            }
          }
          if (amountKeys.includes(k)) {
            versionList.forEach((vk) => {
              if (typeof v[k][vk] === 'number') {
                pr[k][vk] = Number(pr[k][vk]) + v[k][vk];
              }
            });
          }
        });
        return pr;
      }, {} as any);
      return prev;
    }, {});
    return chain(Object.values<any>(temp2))
      .filter((i) => {
        // 年度
        const fiscalYearFilter = isEmpty(newSifter.fiscalYear) ? true : includes(newSifter.fiscalYear, i.fiscalYear);
        // 本部
        const departmentsArr = isEmpty(newSifter.departments) ? departmentOpts.map((d) => d.value) : newSifter.departments;
        const tgcArr = isEmpty(newSifter.tgcs) ? defTgcFilterList : newSifter.tgcs;
        const businessUnitFilter = [...departmentsArr, ...tgcArr].includes(i.businessUnit);
        // const departmentFilter = isEmpty(newSifter.departments) ? true : includes(newSifter.departments, i.businessUnit);
        // TGC
        // !includes(['OFS', 'TPS'], i.tgc)
        // const tgcFilter = isEmpty(newSifter.tgcs) ? true : includes(newSifter.tgcs, i.tgc);
        const tgcFilter = includes(isEmpty(newSifter.tgcs) ? defTgcFilterList : newSifter.tgcs, i.tgc);
        const searchFilter = i.projectName?.includes(newSifter.search) || i.projectId.includes(newSifter.search);

        // モード
        // let modeFilter = true;
        let modeFilter = newSifter.allMode;
        if (!newSifter.allMode) {
          for (const key of modeTarget) {
            // 从数据中获取到，每一个和kpi相关值对应的Map
            const target = get(i, key, null);
            // 判断Map的`键`是否与snapshot的list数一致
            const keyNumIsSame = keys(target).length === selectedSnapshotVersionNum;
            if (!keyNumIsSame) {
              modeFilter = true;
              break;
            }
            const hasDiff =
              chain(target)
                .values()
                .map((i) => {
                  return i ? Number((i / 1000000).toFixed(1)) : i;
                })
                .uniq()
                .value().length !== 1;
            if (hasDiff) {
              modeFilter = true;
              break;
            }
          }
        }
        // 予算カテゴリ
        const categoryFilter = isEmpty(newSifter.category) ? true : includes(newSifter.category, i.categoryCustom);
        return fiscalYearFilter && businessUnitFilter && tgcFilter && searchFilter && categoryFilter && modeFilter;
      })
      .groupBy('businessUnit')
      .mapValues((items) => {
        const { sortDir, sortKey } = sortState;
        if (sortDir > 0) {
          return orderBy(items, [sortKey], [sortDirections[sortDir] as any]);
        }
        return items;
      })
      .value();
  };

  const genAppendCountData = (source: { [key: string]: any[] }, mode: '拠点単体' | '連結＋持分') => {
    const snapshotKeys = map(getSnapshotVersion(), (i) => `${i.snapshot} ${i.snapshotVersion}`);
    return chain(source)
      .map((data, businessUnit) => {
        // 处理小计
        if (businessUnit.startsWith('[')) {
          data[0] = { ...data[0], orderScheduledDate: `${businessUnit}`, projectId: `${businessUnit}`, type: 'CATE_TOTAL' };
          set(data, [0, 'DEPARTMENT_ROWSPAN'], data.length);
          return data;
        } else if (businessUnit === 'OFS' && mode === '拠点単体') {
          const groupData = groupBy(data, (e) => e.type);
          const tempData = Object.values(groupData);
          tempData.forEach((d) => {
            set(d, [0, 'DEPARTMENT_ROWSPAN'], d.length);
            set(d, 'ofsLast', true);
          });
          const nData = [...(tempData[0] ?? []), ...(tempData[1] ?? [])];
          const countRow = { businessUnit: '[C] OFS(合計)', projectId: '', type: 'DATA_TOTAL_OFS', DEPARTMENT_ROWSPAN: 1 } as any;
          if (!nData.length) {
            countRow.demandMh = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.grossProfitFy24 = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.grossProfitFy25 = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.grossProfitFy26 = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.grossProfitFy27 = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.grossProfitFy28 = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.orderAmount = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.orderAmountStandalone = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
            countRow.orderGrossProfit = snapshotKeys.reduce((pr, c) => ((pr[c] = 0), pr), {});
          }
          forEach(nData, (row) => {
            forEach(snapshotKeys, (key) => {
              forEach([...modeTarget], (t) => {
                const oldNum = get(countRow, [t, key]) ?? 0;
                const inputNum = get(row, [t, key]) ?? 0;
                set(countRow, [t, key], oldNum + inputNum);
              });
            });
          });
          nData.push(countRow);
          return nData;
        } else {
          const bUnit = businessUnit === 'その他: 過去分' ? '-' : businessUnit;
          const type = data[0].type ? `DATA_TOTAL-${data[0].type}` : `DATA_TOTAL`;
          const countRow = {
            businessUnit,
            orderScheduledDate: `小計 ${data[0].type ?? ''}-${bUnit}`,
            projectId: `小計 ${data[0].type ?? ''}-${bUnit}`,
            type,
          };
          forEach(data, (row) => {
            forEach(snapshotKeys, (key) => {
              forEach([...modeTarget], (t) => {
                const oldNum = get(countRow, [t, key]) ?? 0;
                const inputNum = get(row, [t, key]) ?? 0;
                set(countRow, [t, key], oldNum + inputNum);
              });
            });
          });
          data.push(countRow);
          // 原始数据以`本部`为单位的数据结束位置，用于合并列;
          set(data, [0, 'DEPARTMENT_ROWSPAN'], data.length);
          return data;
        }
      })
      .value();
  };

  // handle data by filter
  const handleFilterChange1 = (newSifter: any) => {
    if (!cacheTableSource.table1.length) {
      return;
    }
    console.log('table', cacheTableSource.table1);
    const source = genTableSource(newSifter, cacheTableSource.table1);
    console.log('source', source);
    // 每组值中加入合计
    const departments = ['CN本部', 'OFS本部', '国内', '海外', 'その他: 過去分'];
    const tgcs = ['Toyo-I', 'Toyo-K', 'Toyo-M', 'Toyo-C', 'Toyo-USA', 'IKPT', 'TPS', 'TSPI'];
    const aAndBTotal = ['[A] Toyo-J(協業)合計', '[B]拠点(独自)合計'];
    const aAndBAndCTotal = ['[A] Toyo-J(協業)合計', '[B]拠点(独自)合計', 'OFS'];
    const japanTotal = {
      businessUnit: '[A] Toyo-J(協業)合計',
      demandMh: {},
      grossProfitFy24: {},
      grossProfitFy25: {},
      grossProfitFy26: {},
      grossProfitFy27: {},
      grossProfitFy28: {},
      orderAmount: {},
      orderAmountStandalone: {},
      orderGrossProfit: {},
      projectId: '',
    };
    genTagTotal(departments, japanTotal, source);
    source['[A] Toyo-J(協業)合計'] = [japanTotal];
    const tgcTotal = {
      businessUnit: '[B]拠点(独自)合計',
      demandMh: {},
      grossProfitFy24: {},
      grossProfitFy25: {},
      grossProfitFy26: {},
      grossProfitFy27: {},
      grossProfitFy28: {},
      orderAmount: {},
      orderAmountStandalone: {},
      orderGrossProfit: {},
      projectId: '',
    };
    genTagTotal(tgcs, tgcTotal, source);
    source['[B]拠点(独自)合計'] = [tgcTotal];
    const consolidatedTotal = {
      businessUnit: '[A + B]連結合計',
      demandMh: {},
      grossProfitFy24: {},
      grossProfitFy25: {},
      grossProfitFy26: {},
      grossProfitFy27: {},
      grossProfitFy28: {},
      orderAmount: {},
      orderAmountStandalone: {},
      orderGrossProfit: {},
      projectId: '',
    };
    genTagTotal(aAndBTotal, consolidatedTotal, source);
    source['[A + B]連結合計'] = [consolidatedTotal];
    const allTotal = {
      businessUnit: '[A + B + C]',
      demandMh: {},
      grossProfitFy24: {},
      grossProfitFy25: {},
      grossProfitFy26: {},
      grossProfitFy27: {},
      grossProfitFy28: {},
      orderAmount: {},
      orderAmountStandalone: {},
      orderGrossProfit: {},
      projectId: '',
    };
    genTagTotal(aAndBAndCTotal, allTotal, source);
    source['[A + B + C]'] = [allTotal];
    const appendCountData = genAppendCountData(source, '連結＋持分');
    const filterData = flatten(values(appendCountData)).sort(
      (a, b) => businessUnitSort1[a.businessUnit] - businessUnitSort1[b.businessUnit]
    );
    setTableLength((l) => ({
      ...l,
      ['連結＋持分']: (filterData.length ?? 0) - appendCountData.length,
    }));
    setTableData1(filterData);
  };

  const handleFilterChange2 = (newSifter: any) => {
    if (!cacheTableSource.table21.length) {
      return;
    }
    const tgcs = ['Toyo-J', 'Toyo-I', 'Toyo-K', 'Toyo-M', 'Toyo-C', 'Toyo-USA', 'IKPT', 'TPS', 'TSPI'];
    const source1 = genTableSource(newSifter, cacheTableSource.table21);
    const source2 = genTableSource(newSifter, cacheTableSource.table22);
    const source3 = genTableSource(newSifter, cacheTableSource.table23);
    if (!source3.OFS) {
      source3.OFS = [];
    }
    const individualTotal = {
      businessUnit: '[A] 独自',
      demandMh: {},
      grossProfitFy24: {},
      grossProfitFy25: {},
      grossProfitFy26: {},
      grossProfitFy27: {},
      grossProfitFy28: {},
      orderAmount: {},
      orderAmountStandalone: {},
      orderGrossProfit: {},
      projectId: '',
    };
    genTagTotal(tgcs, individualTotal, source1);
    source1['[A] 独自'] = [individualTotal];
    const cooperationTotal = {
      businessUnit: '[B] 協業',
      demandMh: {},
      grossProfitFy24: {},
      grossProfitFy25: {},
      grossProfitFy26: {},
      grossProfitFy27: {},
      grossProfitFy28: {},
      orderAmount: {},
      orderAmountStandalone: {},
      orderGrossProfit: {},
      projectId: '',
    };
    genTagTotal(tgcs, cooperationTotal, source2);
    source2['[B] 協業'] = [cooperationTotal];
    const keys = map(getSnapshotVersion(), (i) => `${i.snapshot} ${i.snapshotVersion}`);
    const aAndBTotal = {
      businessUnit: '[A + B] 合計',
      demandMh: keys.reduce((pr, c) => ((pr[c] = (individualTotal.demandMh[c] ?? 0) + (cooperationTotal.demandMh[c] ?? 0)), pr), {}),
      grossProfitFy24: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.grossProfitFy24[c] ?? 0) + (cooperationTotal.grossProfitFy24[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy25: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.grossProfitFy25[c] ?? 0) + (cooperationTotal.grossProfitFy25[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy26: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.grossProfitFy26[c] ?? 0) + (cooperationTotal.grossProfitFy26[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy27: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.grossProfitFy27[c] ?? 0) + (cooperationTotal.grossProfitFy27[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy28: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.grossProfitFy28[c] ?? 0) + (cooperationTotal.grossProfitFy28[c] ?? 0)), pr),
        {}
      ),
      orderAmount: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.orderAmount[c] ?? 0) + (cooperationTotal.orderAmount[c] ?? 0)), pr),
        {}
      ),
      orderAmountStandalone: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.orderAmountStandalone[c] ?? 0) + (cooperationTotal.orderAmountStandalone[c] ?? 0)), pr),
        {}
      ),
      orderGrossProfit: keys.reduce(
        (pr, c) => ((pr[c] = (individualTotal.orderGrossProfit[c] ?? 0) + (cooperationTotal.orderGrossProfit[c] ?? 0)), pr),
        {}
      ),
      projectId: '',
    };
    source2['[A + B] 合計'] = [aAndBTotal];
    // 每组值中加入合计
    const appendCountData1 = genAppendCountData(source1, '拠点単体');
    const appendCountData2 = genAppendCountData(source2, '拠点単体');
    const appendCountData3 = genAppendCountData(source3, '拠点単体');
    const aAndBAndC = {
      businessUnit: '[A + B + C] 合計',
      demandMh: keys.reduce((pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).demandMh[c] ?? 0) + (aAndBTotal.demandMh[c] ?? 0)), pr), {}),
      grossProfitFy24: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).grossProfitFy24[c] ?? 0) + (aAndBTotal.grossProfitFy24[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy25: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).grossProfitFy25[c] ?? 0) + (aAndBTotal.grossProfitFy25[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy26: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).grossProfitFy26[c] ?? 0) + (aAndBTotal.grossProfitFy26[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy27: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).grossProfitFy27[c] ?? 0) + (aAndBTotal.grossProfitFy27[c] ?? 0)), pr),
        {}
      ),
      grossProfitFy28: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).grossProfitFy28[c] ?? 0) + (aAndBTotal.grossProfitFy28[c] ?? 0)), pr),
        {}
      ),
      orderAmount: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).orderAmount[c] ?? 0) + (aAndBTotal.orderAmount[c] ?? 0)), pr),
        {}
      ),
      orderAmountStandalone: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).orderAmountStandalone[c] ?? 0) + (aAndBTotal.orderAmountStandalone[c] ?? 0)), pr),
        {}
      ),
      orderGrossProfit: keys.reduce(
        (pr, c) => ((pr[c] = (appendCountData3[0]?.at(-1).orderGrossProfit[c] ?? 0) + (aAndBTotal.orderGrossProfit[c] ?? 0)), pr),
        {}
      ),
      projectId: '',
      type: 'CATE_TOTAL',
      DEPARTMENT_ROWSPAN: 1,
    };
    const filterData1 = flatten(values(appendCountData1)).sort(
      (a, b) => businessUnitSort3[a.businessUnit] - businessUnitSort3[b.businessUnit]
    );
    const filterData2 = flatten(values(appendCountData2)).sort(
      (a, b) => businessUnitSort3[a.businessUnit] - businessUnitSort3[b.businessUnit]
    );
    const filterData3 = flatten(values(appendCountData3));
    const filterData = [...filterData1, ...filterData2, ...filterData3, aAndBAndC];
    const appendCountLength = [...appendCountData1, ...appendCountData2, ...appendCountData3, aAndBAndC].length;
    setTableLength((l) => ({
      ...l,
      ['拠点単体']: (filterData.length ?? 0) - appendCountLength,
    }));
    setTableData2(filterData);
  };

  useEffect(() => {
    setTitleDataSize(tableLength[sifter.accPerspective]);
  }, [tableLength, sifter.attrs]);

  /** change table title for data size number */
  const setTitleDataSize = (num: number) => {
    if (isEmpty(tableColumn)) return;
    const columns = [...tableColumn];
    set(
      columns,
      [0, 'title'],
      <>
        <div>　</div>
        <div style={{ width: '100%' }}>
          <span style={{ float: 'left' }}>件数：{num}件</span>
          <span style={{ float: 'right' }}>単位：百万円</span>
        </div>
      </>
    );
    setTableColumn(columns);
  };

  /** about snapshot version modal */
  const handleFormFieldChange = (changed) => {
    const isEq = chain(changed).get([0, 'name', 2]).eq('snapshot').value();
    if (!isEq) return;
    const index = chain(changed).get([0, 'name', 1]).value();
    form.setFieldValue(['list', index, 'snapshotVersion'], 'default');
  };

  /** about table data */
  useEffect(() => {
    if (!tgcOpts.length) {
      handleCompareClick();
    }
  }, [tgcOpts]);
  // after table data load, execute filter function.
  useEffect(() => {
    console.log('-------- initial --------');
    handleFilterChange1(sifter);
    handleFilterChange2(sifter);
  }, [cacheTableSource]);
  const findTableDataAjax = () => {
    setLoading(true);
    const snapshots = getSnapshotVersion().map((i) => ({
      snapshot: i.snapshot,
      snapshotVersion: i.snapshotVersion,
    }));
    const body = { snapshots, isPMode: true };
    APIList.getSubDiffCompare()
      .post(body)
      .then((data) => {
        console.log(data);
        setCacheTableSource(data);
      })
      .catch(() => {
        message.error('sub diff Data Error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableRowClass = (record: any) => {
    const countRow = get(record, 'type');
    const isOFSLast = get(record, 'ofsLast');
    const span = get(record, 'DEPARTMENT_ROWSPAN');
    if (countRow?.startsWith('DATA_TOTAL')) {
      if (span === 1) {
        return 'ofs-total-row border-buttom-line';
      }
      return 'count-row border-buttom-line';
    }
    if (countRow?.startsWith('CATE_TOTAL')) {
      return 'cate-row border-buttom-line';
    }
    if (isOFSLast) {
      return 'ofs-row border-buttom-line';
    }
    const startDepartment = get(record, 'DEPARTMENT_ROWSPAN');
    if (startDepartment) {
      return 'border-buttom-line';
    }
    return '';
  };

  return (
    <div className="sub-diff-container">
      <Row className="operation-container" justify="space-between">
        <Col style={{ padding: '5px 0' }} span={24}>
          <Space>
            {t('aipcmcty.page.trinityComparison.department')}:
            <Select
              allowClear
              disabled={loading}
              mode="multiple"
              style={{ width: 360 }}
              options={departmentOpts}
              value={sifter.departments}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('departments', e)}
            />
            TGC:
            <Select
              allowClear
              disabled={loading}
              mode="multiple"
              style={{ width: 260 }}
              options={tgcOpts}
              value={sifter.tgcs}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('tgcs', e)}
            />
            会計観点:
            <Select
              disabled={loading}
              style={{ width: 120 }}
              options={accPerspectiveOpts}
              value={sifter.accPerspective}
              onChange={(e) => filterSelectorChangeCallBack('accPerspective', e)}
            />
            会計年度:
            <Select
              allowClear
              disabled={loading}
              mode="multiple"
              style={{ width: 240 }}
              options={fiscalYearOpts}
              value={sifter.fiscalYear}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('fiscalYear', e)}
            />
            {t('aipcmcty.page.orderPlanningCategory')}:
            <Select
              allowClear
              disabled={loading}
              mode="multiple"
              style={{ width: 240 }}
              options={categoryCustomOpts}
              value={sifter.category}
              maxTagCount="responsive"
              onChange={(e) => filterSelectorChangeCallBack('category', e)}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px 0' }} span={24}>
          <Space>
            案件属性:
            <Select
              allowClear
              disabled={loading}
              mode="multiple"
              style={{ width: 340 }}
              options={attrOpts}
              value={sifter.attrs}
              maxTagCount="responsive"
              onChange={attrChange}
            />
            {t('aipcmcty.page.trinityComparison.kpi')}:
            <Select
              allowClear
              disabled={loading}
              mode="multiple"
              style={{ width: 340 }}
              options={kpiOpts}
              value={sifter.kpis}
              maxTagCount="responsive"
              onChange={kpiSelectorChange}
            />
            <Input.Search
              allowClear
              disabled={loading}
              placeholder={`${t('aipcmcty.page.projectCaseName')} / ${t('aipcmcty.page.projectCaseId')}`}
              style={{ width: 300 }}
              onChange={(e) => filterSelectorChangeCallBack('search', e.target.value)}
            />
            {t('aipcmcty.page.trinityComparison.mode')}:
            <Switch
              style={{ background: color.primaryColor }}
              disabled={loading}
              checkedChildren="全量"
              unCheckedChildren="差分"
              checked={sifter.allMode}
              onChange={(e) => filterSelectorChangeCallBack('allMode', e)}
            />
            <Button type="dashed" block disabled={loading} icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
              {t('aipcmcty.page.trinityComparison.compareVersionAdd')}
            </Button>
            <Button type="primary" onClick={handleCompareClick} disabled={loading}>
              {t('aipcmcty.page.trinityComparison.compare')}
            </Button>
            <Button type="primary" onClick={handleExportClick} disabled={loading}>
              Export
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        rowKey={(row) => `${row.id ?? ''}-${row.businessUnit}-${row.type ?? ''}`}
        className="sub-diff-table"
        size="small"
        scroll={{ x: tableWidth, y: selectorHeight4Table }}
        dataSource={sifter.accPerspective === '拠点単体' ? tableData2 : tableData1}
        columns={tableColumn}
        loading={loading}
        pagination={false}
        rowClassName={handleTableRowClass}
      />
      <Modal
        width={650}
        title={t('aipcmcty.page.trinityComparison.compareVersionAdd')}
        open={modalOpen}
        footer={null}
        onCancel={() => {
          setModalOpen(false);
        }}
      >
        <Form name="snapshotVersions" form={form} onFieldsChange={handleFormFieldChange}>
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                {map(fields, ({ key, name, ...restField }) => {
                  return (
                    <Space key={key} style={{ display: 'flex' }} align="baseline">
                      <Form.Item
                        {...restField}
                        label={t('aipcmcty.page.trinityComparison.compareSnapshot')}
                        name={[name, 'snapshot']}
                        style={{ marginBottom: 12 }}
                      >
                        <Select style={{ width: 150 }} options={allSnapshotList} />
                      </Form.Item>
                      <Form.Item shouldUpdate style={{ marginBottom: 12 }}>
                        {() => (
                          <Form.Item
                            {...restField}
                            label={t('aipcmcty.page.trinityComparison.compareVersion')}
                            name={[name, 'snapshotVersion']}
                            style={{ marginBottom: 12 }}
                          >
                            <Select
                              style={{ width: 150 }}
                              options={get(allVersionOptCollection, get(form.getFieldValue('list'), [name, 'snapshot']))}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                      {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                    </Space>
                  );
                })}
                {fields?.length < 4 && (
                  <Form.Item>
                    <Button type="dashed" style={{ width: 510 }} onClick={() => add()} block icon={<PlusOutlined />}>
                      Add field
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item style={{ marginTop: 30 }} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                handleCompareClick();
                const snapshots = getSnapshotVersion();
                saveListToLocalStorage(snapshots);
              }}
            >
              {t('aipcmcty.page.trinityComparison.compare')}
            </Button>
            <Button
              htmlType="button"
              style={{ margin: '0 8px' }}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t('aipcmcty.page.cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PDiffPage;
