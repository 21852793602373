/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-shadow */
import {
  Button,
  Modal,
  Space,
  message,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Slider,
  Tabs,
  AutoComplete,
  Input,
  Tag,
  InputNumber,
  Tooltip,
  Divider,
  Card,
  Cascader,
  Table,
} from 'antd';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { BarsOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import {
  chain,
  cloneDeep,
  debounce,
  eq,
  filter,
  find,
  forEach,
  get,
  groupBy,
  has,
  includes,
  isEmpty,
  isNil,
  keyBy,
  keys,
  map,
  omit,
  set,
  size,
  sumBy,
  uniq,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import APIList from '../../http/ApiList';
import ResourceRegulationTable, { ResourceRegulationTableItem } from '../../components/tables/resource-regulation.table';
import DrawerContainer, { DrawerProperties } from '../../components/widget/drawer-container';
import { localeMapping } from '../../consts/locale.mapping';
import CustomerScrollBar from '../../components/widget/customer-scrollbar';
import { RecordItem } from '../../contexts/provider/aipcmcty.provider.context';
import { convertToJSON } from '../../utils/commonUtil';
import ResourceSimulationChart from '../../components/charts/resource-simulation.chart';
import TurnoverResource from '../../components/charts/turnover-resource.chart';
import usePageLeaveConfirm from '../../hooks/usePageLeaveConfirm';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import { ChartTableLayout } from '@meteor/frontend-core';
import ProjectSetupDetail from '../../components/project-setup-detail';
import TurnoverFlowChart from '../../components/charts/turnover-flow.chart';
import { ColumnsType } from 'antd/lib/table';
import { departmentOpts } from './resource-simulation';
import MemoContainer from '../../components/widget/memo-container';
import dayjs, { isDayjs } from 'dayjs';
const timeout = 360000;

// generate demand year month options
const genYearMonths = () => {
  const currentYear = Number(moment().subtract(1, 'year').format('yyyy'));
  return Array.from({ length: 5 }, (x, i) => (currentYear + i).toString()).reduce((prev, cur) => {
    prev.push(
      ...Array.from({ length: 12 }, (_, i) => {
        if (i + 1 < 10) {
          return `${cur}-0${i + 1}`;
        }
        return `${cur}-${i + 1}`;
      })
    );
    return prev;
  }, [] as string[]);
};

const StandaloneKeyMapping = {
  // 受注金额
  ORDER_AMOUNT: {
    Standalone: 'orderAmountStandalone',
    UnStandalone: 'orderAmount',
  },
  // 粗利额
  GROSS_PROFIT: {
    Standalone: 'grossProfitStandalone',
    UnStandalone: 'grossProfit',
  },
  // 粗利率
  GROSS_MARGIN: {
    Standalone: 'grossMarginStandalone',
    UnStandalone: 'grossMargin',
  },
};

const ChangeKeyMapping = {
  budgetCategoryCustom: 'BUDGET_CHANGE',
  grossMarginStandalone: 'GROSS_MARGIN_CHANGE',
  grossMargin: 'GROSS_MARGIN_CHANGE',
  orderAmountStandalone: 'ORDER_AMOUNT_CHANGE',
  orderAmount: 'ORDER_AMOUNT_CHANGE',
  orderScheduledDate: 'DATE_CHANGE',
};

// generate fiscal month range
// sample: FY24 Apr ~ Dec, FY25 Jan ~ Mar
export const orderMonthRange = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

// filter rules for table fields
const filterMethods = {
  search: (item, v = '') => item.projectName?.includes(v) || item.projectId.includes(v),
  accountingYear: (item, v) => !v?.length || v.some((i) => i.includes(item.accountingYear)),
  // tgc: (item, v) => !v?.length || v.includes(item.tgc),
  japanInvolved: (item, v) => {
    if (v) {
      return item.japanInvolved === 'Toyo-J関与';
    }
    return true;
  },
  scope: (item, v) => !v?.length || v.includes(item.scope),
  isExisted: (item, v) => (v ? item.isExisted === v : true),
  isEpc: (item, v) => (v ? item.isEpc === v : true),
  businessType: (item, v) => !v?.length || v.includes(item.businessType),
  goodsType: (item, v) => !v?.length || v.includes(item.goodsType),
  budgetCategoryCustom: (item, v) => !v?.length || v.includes(item.budgetCategoryCustom ?? item.budgetCategory),
  priorityMatching: (item, v, k) => [undefined, null].includes(v) || item[k] === v,
  priorityDefault: (item, v, k) => !v || (item[k] >= v[0] && item[k] <= v[1]),
  orderAmount: (item, v, k) => {
    const minLimit = 5000000000;
    const maxLimit = 60000000000;
    const sortedVString = v?.sort().join('');
    switch (sortedVString) {
      case 'lt50':
        return item[k] < minLimit;
      case 'gt600':
        return item[k] > maxLimit;
      case '50_600':
        return item[k] >= minLimit && item[k] <= maxLimit;
      case '50_600gt600':
        return item[k] >= minLimit;
      case '50_600lt50':
        return item[k] <= maxLimit;
      case 'gt600lt50':
        return item[k] > maxLimit || item[k] < minLimit;
      default:
        return true;
    }
  },
  // grossMargin: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
  imbalancedMh: (item, v, k) => {
    switch (v) {
      case '0':
        return item[k] === 0;
      case '1':
        return item[k] > 0;
      default:
        return true;
    }
  },
  won: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
  phase: (item, v) => !v?.length || v.includes(item.phase),
  certainty: (item, v) => !v?.length || v.includes(item.certainty),
  projectStartDate: (item, v) => {
    if (!v) {
      return true;
    }
    const { projectStartDate } = item;
    return moment(projectStartDate).isSameOrAfter(moment(v));
  },
  projectEndDate: (item, v) => {
    if (!v) {
      return true;
    }
    const { projectEndDate } = item;
    return moment(projectEndDate).isSameOrBefore(moment(v));
  },
  dxApplicable: (item, v, k) => !v || item[k] === Boolean(Number(v)),
  orderScheduledDate: (item, v, k) => !v || item[k].startsWith(v),
  isForPpReport: (item, v, k) => [undefined, null].includes(v) || item[k] === v,
  department: (item, v, k) => !v?.length || v.includes(item[k]),
  fiscalYearStandalone: (item, v) => !v?.length || v.some((i) => i.includes(item.fiscalYearStandalone)),
  fiscalYearConsolidated: (item, v) => !v?.length || v.some((i) => i.includes(item.fiscalYearConsolidated)),
};

const initialKpiOrder = [33, 35, 36];

const ResourceRegulation: React.FC = () => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('chart-table');
  // about drawer
  const { fiscalQuarter, periodSwitch, setMenuCollapsed, color, locale, canLeave, setCanLeave } = useContext(AppContext);

  const { snapshot, snapshotVersion, tableCanOperation, user, getOrgKpiData } = useContext(AipcmctyContext);
  const [tableDataChangeCache, setTableDataChangeCache] = useState({});

  const [filterCollapsed, setFilterCollapsed] = useState(true);
  const drawerOpts: Omit<DrawerProperties, 'collapsed' | 'children'> = {
    maxWidth: 320,
    minWidth: 0,
  };
  // about drawer form
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  /**
   * 根据权限过滤默认TGC
   * @param tgc 有权限的TGC
   * @param target 目标设定的TGC
   */
  const handleTgcType = (tgc: any[], target: string[], useDefault?: boolean, useIgnore?: boolean) => {
    const mapping = map(tgc, 'value');
    const f = filter(target, (v) => includes(mapping, v));
    if (useDefault && !size(f)) {
      if (useIgnore) {
        return map(
          filter(tgc, (item: any) => item.attribute1 !== 'equity'),
          'value'
        );
      }
      return mapping;
    }
    return f;
  };

  // initialize the table data list and options
  const initializeTableData = async () => {
    const [data, sbData, options, creators] = await Promise.all([
      APIList.getProjectCase().get({
        snapshot,
        snapshotVersion,
        budgetRange: ['Awarded', 'Budget', 'IF', 'Others'].join(','),
        ...searchData,
      }) as Promise<ResourceRegulationTableItem[]>,
      APIList.getSimSubProjects().post({
        snapshot,
        snapshotVersion,
        budgetRange: ['Awarded', 'Budget', 'IF', 'Others'],
      }) as Promise<ResourceRegulationTableItem[]>,
      APIList.getCmcOptions().get({
        category: 'all',
        snapshot,
        snapshotVersion,
      }) as Promise<any>,
      APIList.getCmcOptions().get({
        category: 'memoCreators',
        snapshot,
        snapshotVersion,
      }) as Promise<any>,
    ]);

    setTableDataNeedFilter(data);
    setSBTableData(sbData);
    setDateTransferProjects({ p: [], sb: [] });
    setProfitTransferProjects({ p: [], sb: [] });
    setUnselectBudgets([]);
    setSelectedProjects([]);
    setTableDataChangeCache({});
    setResSimCacheData({});

    // 日本関与有無
    const japanInvolved = [
      {
        key: 1,
        value: 1,
        label: t('aipcmcty.page.have'),
      },
      {
        key: 0,
        value: 0,
        label: t('aipcmcty.page.no'),
      },
    ];

    // 会計対象
    const consolidated = [
      {
        key: t('aipcmcty.page.consolidated'),
        value: 1,
        label: t('aipcmcty.page.consolidated'),
      },
      {
        key: t('aipcmcty.page.single'),
        value: 0,
        label: t('aipcmcty.page.single'),
      },
    ];

    // memo tags
    const memoTag = [
      {
        key: '課題',
        value: '課題',
        label: '課題',
      },
      {
        key: 'TODO',
        value: 'TODO',
        label: 'TODO',
      },
      {
        key: '共有',
        value: '共有',
        label: '共有',
      },
    ];

    // memo creators
    const memoCreators = creators.map((creator) => ({
      key: creator.userId,
      value: creator.userId,
      label: creator.userName,
    }));

    // const initialFiltersData = { ...filtersData, tgc: ['Toyo-J', 'Toyo-I'] };
    const initialFiltersData = {
      ...filtersData,
      tgc: handleTgcType(options.tgc, ['Toyo-J', 'Toyo-I']),
      accountingYear: ['FY24', 'FY25', 'FY26'],
      // budgetCategoryCustom: ['Awarded', 'Budget'],
      budgetCategoryCustom: ['Awarded', 'Budget', 'IF', 'Others'],
    };
    const sliderProps = data.reduce(
      (prev, cur, index) => {
        prev.priorityDefault.push(cur.priorityDefault ?? index + 1);
        prev.grossMargin.push(cur.grossMargin * 100);
        prev.won.push(cur.won * 100);
        return prev;
      },
      {
        priorityDefault: [],
        grossMargin: [],
        won: [],
      }
    );
    const sliderOpts = Object.keys(sliderProps).reduce((prev, k) => {
      let min = 0;
      let max = 100;
      if (k === 'priorityDefault') {
        const range = sliderProps[k].length > 0 ? sliderProps[k] : [1, 100];
        min = Math.round(Math.min(...range));
        max = Math.round(Math.max(...range));
      }
      const stepLen = max.toFixed(0).length - 2;
      const step = ['won', 'grossMargin'].includes(k) ? 1 : Number(1 + Array.from({ length: stepLen }, () => 0).join(''));
      const markMin = ['won', 'grossMargin'].includes(k) ? `${min}%` : min;
      const markMax = ['won', 'grossMargin'].includes(k) ? `${max}%` : max;
      initialFiltersData[k] = [min, max];
      prev[`${k}SliderOpts`] = {
        min,
        max,
        range: true,
        step,
        marks: {
          [min]: markMin,
          [max]: markMax,
        },
      };
      return prev;
    }, {});
    initialFiltersData.mhDepartment = ['All', 'All'];
    initialFiltersData.demandYearMonth = null;
    setFiltersData(initialFiltersData);
    form.setFieldsValue(initialFiltersData);
    setOptOrgData({
      ...options,
      japanInvolved,
      consolidated,
      ...sliderOpts,
      memoTag,
      memoCreators,
    });
  };

  const [tableDataNeedFilter, setTableDataNeedFilter] = useState([]);
  const [sbTableData, setSBTableData] = useState([]);

  const [optOrgData, setOptOrgData] = useState(null);
  const [optData, setOptData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  // 会計観点
  const [accountingPerspective, setAccountingPerspective] = useState<number>(4);
  const tableChangeMap = useCallback(() => {
    return {
      // 受注金额 (!4)
      orderAmount: (record: ResourceRegulationTableItem) => {
        record.grossProfit = Math.round(record.orderAmount * record.grossMargin);
        // 修改P的粗利/MH = P的粗利额 / P的需要MH
        record.grossProfitPerMh = null;
        if (record.demandMh) {
          record.grossProfitPerMh = Math.round(record.grossProfit / record.demandMh);
        }
      },
      // 受注金额 (4)
      orderAmountStandalone: (record: ResourceRegulationTableItem) => {
        record.grossProfitStandalone = Math.round(record.orderAmountStandalone * record.grossMarginStandalone);
      },
      // 粗利率 (!4)
      grossMargin: (record: ResourceRegulationTableItem) => {
        record.grossProfit = Math.round(record.orderAmount * record.grossMargin);
        // 修改P的粗利/MH = P的粗利额 / P的需要MH
        record.grossProfitPerMh = null;
        if (record.demandMh) {
          record.grossProfitPerMh = Math.round(record.grossProfit / record.demandMh);
        }
      },
      // 粗利率 (4)
      grossMarginStandalone: (record: ResourceRegulationTableItem) => {
        record.grossProfitStandalone = Math.round(record.orderAmountStandalone * record.grossMarginStandalone);
      },
      offsetMonths: (record: ResourceRegulationTableItem, cache: ResourceRegulationTableItem) => {
        // const startDate = dayjs(record.orderScheduledDateDefault);
        const startDate = dayjs(cache.orderScheduledDate).format('YYYY-MM-01');
        const offsetMonths = record.offsetMonths.format('YYYY-MM-01');
        const diffMonths = dayjs(offsetMonths).diff(dayjs(startDate), 'month');
        record.orderScheduledDate = dayjs(cache.orderScheduledDate).add(diffMonths, 'month').format('YYYY-MM-DD');
        record.projectStartDate = dayjs(cache.projectStartDate).add(diffMonths, 'month').format('YYYY-MM-DD');
        record.projectEndDate = dayjs(cache.projectEndDate).add(diffMonths, 'month').format('YYYY-MM-DD');
        // 会计年度 accounting_year
        const accountingYearDiffMonth = includes(['Toyo-J', 'Toyo-I', 'TPS'], record.tgc) ? -3 : 0;
        record.accountingYear = 'FY' + dayjs(record.orderScheduledDate).add(accountingYearDiffMonth, 'month').format(`YY`);
      },
    };
  }, [accountingPerspective]);
  const [accountingPerspectiveOpt, setAccountingPerspectiveOpt] = useState([
    {
      key: 1,
      value: 1,
      label: `${t('aipcmcty.page.consolidated')}＋${t('aipcmcty.page.equity')}`,
    },
    {
      key: 2,
      value: 2,
      label: t('aipcmcty.page.consolidated'),
    },
    {
      key: 3,
      value: 3,
      label: t('aipcmcty.page.toyoJConsolidated'),
    },
    {
      key: 4,
      value: 4,
      label: t('aipcmcty.page.baseUnit'),
    },
  ]);
  // 会計対象
  const [consolidated, setConsolidated] = useState();
  // PP連絡報告
  // Notes: 这么循环必须遵循P类型值为0, S类型操作值为1
  const useCaseOpts = useMemo(() => {
    return [
      /** 事業ポートフォリオ委員会 */
      t('aipcmcty.page.resSimulation.useCaseItem1'),
      /** PP連絡会議 */
      // t('aipcmcty.page.resSimulation.useCaseItem2'),
    ].map((u, i) => ({
      label: u,
      value: i,
    }));
  }, []);
  const [useCase, setUseCase] = useState(useCaseOpts[0].value);
  const handleUseCaseChange = (v: (typeof useCaseOpts)[number]['value']) => {
    setUseCase(v);
    switch (v) {
      case 1:
        setAccountingPerspective(2);
        setKpiOrder([33, 37, 36]);
        setFiltersData((prev) => ({
          ...prev,
          accountingYear: orderFiscalYear.map((oy) => `FY${oy.toString().slice(-2)}`),
          budgetCategoryCustom: ['Awarded', 'Budget'],
          department: [],
        }));
        form.setFieldsValue({
          accountingYear: orderFiscalYear.map((oy) => `FY${oy.toString().slice(-2)}`),
          budgetCategoryCustom: ['Awarded', 'Budget'],
        });
        setDefalutSelectedWithSbInfo();
        break;
      case 0:
      default:
        setAccountingPerspective(4);
        setKpiOrder(initialKpiOrder);
        setFiltersData((d) => ({
          ...d,
          orderScheduledDate: null,
          department: [],
        }));
        form.setFieldsValue({
          orderScheduledDate: null,
        });
        setSelectedProjects(
          tableDataNeedFilter.filter(
            (d) =>
              d.budgetCategoryCustom === 'Awarded' ||
              (d.budgetCategoryCustom === 'Budget' && !unselectBudgets.map((u) => u.projectId).includes(d.projectId)) ||
              selectedProjects.map((s) => s.projectId).includes(d.projectId)
          )
        );
        break;
    }
  };

  const useCaseTableColsMap = {
    0: [
      'accountingYear',
      'tgc',
      'relatedTgc',
      'budgetCategoryCustom',
      'budgetCategoryCustom2',
      'orderScheduledDate',
      'demandForecastType',
      'monthDemandMH',
      'demandMh',
      'dxDemandMh',
      'orderAmount',
      'grossProfit',
      'dxGrossProfit',
      'grossMargin',
      'grossProfitPerMh',
      'projectStartDate',
      'projectEndDate',
      'duration',
      'won',
      'certainty',
      'constructionLocation',
      'orderAmountStandalone',
      'isEpc',
      'isExisted',
      'scope',
      'proposalDxAllowGrossAllJpy',
      'DXAllow',
    ],
    1: [
      'accountingYear',
      'tgc',
      'relatedTgc',
      'budgetCategoryCustom',
      'budgetCategoryCustom2',
      'orderScheduledDate',
      'demandForecastType',
      'monthDemandMH',
      'demandMh',
      'dxDemandMh',
      'orderAmount',
      'grossProfit',
      'dxGrossProfit',
      'grossMargin',
      'grossProfitPerMh',
      'projectStartDate',
      'projectEndDate',
      'duration',
      'won',
      'certainty',
      'constructionLocation',
      'orderAmountStandalone',
      'isEpc',
      'isExisted',
      'scope',
      'proposalDxAllowGrossAllJpy',
      'DXAllow',
    ],
  };
  const tableColsToShow = useMemo(() => useCaseTableColsMap[useCase], [useCase]);
  const [filtersData, setFiltersData] = useState({
    // search: 'P01718',
    // search: 'P01850',
    search: '',
    tgc: [],
    japanInvolved: null,
    consolidated: null,
    accountingYear: [],
    scope: [],
    isExisted: null,
    isEpc: null,
    businessType: [],
    goodsType: [],
    priorityMatching: null,
    priorityDefault: [],
    budgetCategoryCustom: [],
    orderAmount: [],
    // grossMargin: [],
    imbalancedMh: null,
    won: [],
    phase: [],
    certainty: [],
    projectStartDate: null,
    projectEndDate: null,
    dxApplicable: null,
    demandYearMonth: null,
    mhDepartment: ['All', 'All'],
    orderScheduledDate: null,
    isForPpReport: null,
    department: [],
  });

  const demandYearMonthRange = genYearMonths();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [unselectBudgets, setUnselectBudgets] = useState([]);
  const [dateTransferProjects, setDateTransferProjects] = useState({
    p: [],
    sb: [],
  });
  const [profitTransferProjects, setProfitTransferProjects] = useState({
    p: [],
    sb: [],
  });
  const [profitTransferSubProjects, setProfitTransferSubProjects] = useState([]);
  const [listNeedRender, setListNeedRender] = useState([]);
  const handleTableRowSelected = (record, selected) => {
    setSelectedProjects(selected ? selectedProjects.concat(record) : selectedProjects.filter((s) => s.projectId !== record.projectId));
    if (record.budgetCategoryCustom === 'Budget') {
      if (selected) {
        setUnselectBudgets((prev) => prev.filter((p) => p.projectId !== record.projectId));
      } else {
        setUnselectBudgets((prev) => [...prev, record]);
      }
    }
  };

  const handleDemandProjects = async (yearMonth: string) => {
    setFiltersData((fData) => ({ ...fData, demandYearMonth: yearMonth }));
    form.setFieldsValue({ demandYearMonth: yearMonth });
    changeRenderList({
      demandYearMonth: yearMonth,
      mhDepartment: filtersData.mhDepartment as [string, string],
    });
  };

  const [orderFiscalYear, setOrderFiscalYear] = useState([moment().year()]);
  const orderYearMonths = orderMonthRange.map((m) => `FY${orderFiscalYear[0].toString().slice(-2)}-${m}`);

  const orderYMOpts = orderYearMonths.map((ym) => {
    const [year, month] = ym.replace('FY', '20').split('-');
    let numYear = Number(year);
    if (Number(month) <= 3) {
      numYear += 1;
    }
    return {
      label: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
      key: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
      value: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
    };
  });
  const handleOrderFYChange = (e: number[]) => {
    setOrderFiscalYear(e);
    setFiltersData((prev) => ({
      ...prev,
      accountingYear: [`FY${e[0].toString().slice(-2)}`],
      orderScheduledDate: null,
    }));
    form.setFieldsValue({
      accountingYear: [`FY${e[0].toString().slice(-2)}`],
      orderScheduledDate: null,
    });
  };
  const handleOrderScheduledDateChange = (yearMonth: string) => {
    setFiltersData((fData) => ({ ...fData, orderScheduledDate: yearMonth }));
    form.setFieldsValue({ orderScheduledDate: yearMonth });
    setMenuCollapsed(true);
    setFilterCollapsed(false);
  };

  /** with memo collapsed component */
  const [memoCollapsed, setMemoCollapsed] = useState(true);
  const [memoInfo, setMemoInfo] = useState({
    projectId: null,
    projectName: '',
  });
  const [searchData, setSearchData] = useState({
    memoKeyWord: '',
    creator: [],
    createdAtStart: '',
    createdAtEnd: '',
    memoTag: [],
  });

  useEffect(() => {
    const getTableData = async () => {
      setTableLoading(true);
      await initializeTableData();
      setTableLoading(false);
    };
    getTableData();
  }, [snapshot, snapshotVersion, searchData]);

  useEffect(() => {
    if (!optOrgData) {
      return;
    }
    const optOrgDataCopy = cloneDeep(optOrgData);
    switch (accountingPerspective) {
      // 連結＋持分
      case 1:
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // 連結
      case 2:
        optOrgDataCopy.tgc = optOrgDataCopy.tgc.filter((item: any) => item.attribute1 !== 'equity');
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // Toyo-J連結
      case 3:
        optOrgDataCopy.tgc = optOrgDataCopy.tgc.filter((item: any) => item.attribute1 !== 'equity');
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[0]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // 拠点単体
      case 4:
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[1]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: handleTgcType(optOrgDataCopy.tgc, ['Toyo-J', 'Toyo-I']),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      default:
        setFiltersData((fData) => ({
          ...fData,
          tgc: handleTgcType(optOrgDataCopy.tgc, ['Toyo-J']),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
    }
    setOptData(optOrgDataCopy);
  }, [optOrgData, accountingPerspective]);

  const [dxOn, setDxOn] = useState(false);

  useEffect(() => {
    setTableDataNeedFilter(
      tableDataNeedFilter.map((t) => {
        return {
          ...t,
          dxApplicable: dxOn ? t.dxAvailable : false,
        };
      })
    );
  }, [dxOn]);

  useEffect(() => {
    if (!isNil(consolidated)) {
      getChartData();
    }
  }, [consolidated, snapshot, snapshotVersion, fiscalQuarter, periodSwitch, dxOn]);

  const handleAccountPerspectiveChange = (v: number) => {
    setAccountingPerspective(v);
    if (v !== 3) {
      setFiltersData((fData) => ({ ...fData, department: [] }));
    }
  };

  // about filter
  const handleFilterChange = (k, v) => {
    setFiltersData((fData) => {
      if (k === 'tgc' && v.length !== 1 && v[0] !== 'Toyo-J') {
        return { ...fData, [k]: v, department: [] };
      }
      return { ...fData, [k]: v };
    });
  };

  const [pageSize, setPageSize] = useState(50);
  const handlePageSizeChange = (pZ: number) => {
    setPageSize(pZ);
  };

  const handleSiderFilterChange = debounce(async (changedValue) => {
    // メモ
    if (['memoKeyWord', 'creator', 'memoTag'].includes(Object.keys(changedValue)[0])) {
      setSearchData((sData) => ({ ...sData, ...changedValue }));
    }
    if (['createdAtStart', 'createdAtEnd'].includes(Object.keys(changedValue)[0])) {
      const newChangeValue = Object.keys(changedValue).reduce((acc, cur) => {
        if (changedValue[cur]) {
          acc[cur] = dayjs(changedValue[cur]).format('YYYY-MM-DD');
        } else {
          acc[cur] = '';
        }
        return acc;
      }, {});
      setSearchData((sData) => ({ ...sData, ...newChangeValue }));
    } else {
      setFiltersData((fData) => ({ ...fData, ...changedValue }));
      const key = Object.keys(changedValue)[0];
      if (['demandYearMonth', 'mhDepartment'].includes(key)) {
        const otherKey = ['demandYearMonth', 'mhDepartment'].find((k) => k !== key);
        const opts = {
          ...changedValue,
          [otherKey]: filtersData[otherKey],
        };
        if (key === 'mhDepartment') {
          await initKpiData([['ResourceSimulation']], !!consolidated, {
            isDXMode: dxOn,
            division: changedValue.mhDepartment[0] === 'All' ? '' : changedValue.mhDepartment[0],
            discipline: changedValue.mhDepartment[1] === 'All' ? '' : changedValue.mhDepartment[1],
            partial: true,
          });
        }
        if (key === 'demandYearMonth') {
          await changeRenderList(opts);
        }
      }
    }
  }, 300);

  const [form] = Form.useForm();

  const [imbalancedMhOpt] = useState(
    [
      { label: '需給均衡', value: '0' },
      { label: '需給不足', value: '1' },
    ].map((item) => ({
      ...item,
      label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
    }))
  );

  const [priorityMatchingOpt] = useState([
    { label: `${t('aipcmcty.page.have')}`, value: true },
    { label: `${t('aipcmcty.page.no')}`, value: false },
  ]);

  const [mhDepartmentOpts, setMHDepartmentOpts] = useState([]);
  const budgetCategoryLabel = `${t('aipcmcty.page.resSimulation.budget')}${t('aipcmcty.page.resSimulation.Category')}`;
  const tabs = [
    {
      key: '1',
      label: t('aipcmcty.page.refinement'),
      children: (
        <CustomerScrollBar style={{ height: 'calc(100% - 16px)', padding: '0 10px' }}>
          <Form {...layout} onValuesChange={handleSiderFilterChange} form={form} initialValues={filtersData}>
            <Divider>MH</Divider>
            <Form.Item label={t('aipcmcty.page.resSimulation.demandYearMonth')} name="demandYearMonth">
              <Select allowClear options={demandYearMonthRange.map((ym) => ({ label: ym, key: ym, value: ym }))} />
            </Form.Item>
            <Form.Item label={t('aipcmcty.page.resSimulation.mhDepartment')} name="mhDepartment">
              <Cascader allowClear={false} options={mhDepartmentOpts} />
            </Form.Item>
            {useCase === 1 && (
              <>
                <Divider>{t('aipcmcty.page.orderGrossProfit')}</Divider>
                <Form.Item label={t('aipcmcty.page.resSimulation.orderYearMonth')} name="orderScheduledDate">
                  <Select allowClear options={orderYMOpts} />
                </Form.Item>
              </>
            )}
            <Divider>{t('aipcmcty.page.projectDivider')}</Divider>
            <Form.Item name="search" wrapperCol={{ span: 24 }}>
              <AutoComplete
                options={tableDataNeedFilter.map((t) => ({
                  label: t.projectName,
                  value: t.projectName,
                }))}
              >
                <Input.Search placeholder={`${t('aipcmcty.page.projectCaseName')} / ${t('aipcmcty.page.projectCaseId')}`} allowClear />
              </AutoComplete>
            </Form.Item>
            <Form.Item label={t('aipcmcty.page.resSimulation.ppReport')} name="isForPpReport">
              <Select
                allowClear
                options={[
                  { label: t('aipcmcty.page.resSimulation.isReportObj'), value: true, key: t('aipcmcty.page.resSimulation.isReportObj') },
                  {
                    label: t('aipcmcty.page.resSimulation.isNotReportObj'),
                    value: false,
                    key: t('aipcmcty.page.resSimulation.isNotReportObj'),
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label={`${t('aipcmcty.page.fiscalYear')}`} name="accountingYear">
              <Select allowClear mode="multiple" maxTagCount={2} options={optData?.accountingYear} />
            </Form.Item>
            <Form.Item
              label={
                <div title={budgetCategoryLabel} className="ellipsis" style={{ lineHeight: '1.2' }}>
                  {t('aipcmcty.page.resSimulation.budget')}
                  <br />
                  {t('aipcmcty.page.resSimulation.Category')}
                </div>
              }
              name="budgetCategoryCustom"
            >
              <Select allowClear mode="multiple" options={optData?.budgetCategory} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.serviceScope')} className="ellipsis">
                  {t('aipcmcty.page.serviceScope')}
                </div>
              }
              name="scope"
            >
              <Select allowClear mode="multiple" maxTagCount={1} options={optData?.scope} />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`} className="ellipsis">
                  {`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`}
                </div>
              }
              name="isExisted"
            >
              <Select
                allowClear
                options={optData?.isExisted.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.nonEPC')}/EPC`} className="ellipsis">
                  {`${t('aipcmcty.page.nonEPC')}/EPC`}
                </div>
              }
              name="isEpc"
            >
              <Select
                allowClear
                options={optData?.isEpc.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.businessType')} className="ellipsis">
                  {t('aipcmcty.page.businessType')}
                </div>
              }
              name="businessType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.businessType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.productType')} className="ellipsis">
                  {t('aipcmcty.page.productType')}
                </div>
              }
              name="goodsType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.goodsType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.priorityLevel')} className="ellipsis">
                  {t('aipcmcty.page.priorityLevel')}
                </div>
              }
              name="priorityDefault"
            >
              <Slider {...optData?.priorityDefaultSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.orderAmount')} className="ellipsis">
                  {t('aipcmcty.page.orderAmount')}
                </div>
              }
              name="orderAmount"
            >
              <Select
                allowClear
                mode="multiple"
                options={optData?.orderAmount.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
                maxTagCount={1}
              />
            </Form.Item>
            {/* <Form.Item
              label={
                <div title={t('aipcmcty.page.grossProfitRate')} className="ellipsis">
                  {t('aipcmcty.page.grossProfitRate')}
                </div>
              }
              name="grossMargin"
            >
              <Slider {...optData?.grossMarginSliderOpts} />
            </Form.Item> */}
            <Form.Item
              label={
                <div title={t('aipcmcty.page.demandSupplyFilterMH')} className="ellipsis">
                  {t('aipcmcty.page.demandSupplyFilterMH')}
                </div>
              }
              name="imbalancedMh"
            >
              <Select allowClear options={imbalancedMhOpt} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title="Won%" className="ellipsis">
                  Won%
                </div>
              }
              name="won"
            >
              <Slider {...optData?.wonSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.phase')} className="ellipsis">
                  {t('aipcmcty.page.phase')}
                </div>
              }
              name="phase"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.phase.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.accuracy')} className="ellipsis">
                  {t('aipcmcty.page.accuracy')}
                </div>
              }
              name="certainty"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.certainty.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.startDate')} className="ellipsis">
                  {t('aipcmcty.page.startDate')}
                </div>
              }
              name="projectStartDate"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.endDate')} className="ellipsis">
                  {t('aipcmcty.page.endDate')}
                </div>
              }
              name="projectEndDate"
            >
              <DatePicker />
            </Form.Item>
            <Divider>{t('aipcmcty.page.memoDivider')}</Divider>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.keyWord')} className="ellipsis">
                  {t('aipcmcty.page.memo.keyWord')}
                </div>
              }
              name="memoKeyWord"
            >
              <Input.Search allowClear />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.author')} className="ellipsis">
                  {t('aipcmcty.page.memo.author')}
                </div>
              }
              name="creator"
            >
              <Select allowClear mode="multiple" options={optData?.memoCreators} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.creationPeriod')} className="ellipsis">
                  {t('aipcmcty.page.memo.creationPeriod')}
                </div>
              }
            >
              <Form.Item name="createdAtStart">
                <DatePicker />
              </Form.Item>
              <Form.Item name="createdAtEnd">
                <DatePicker />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.memo.tag')} className="ellipsis">
                  {t('aipcmcty.page.memo.tag')}
                </div>
              }
              name="memoTag"
            >
              <Select allowClear mode="multiple" options={optData?.memoTag} />
            </Form.Item>
          </Form>
        </CustomerScrollBar>
      ),
    },
  ];

  // about formations modal and table
  const [isFormationModalOpen, setFormationModalOpen] = useState(false);
  const [curTableItem, setCurTableItem] = useState<ResourceRegulationTableItem>(null);
  const [formationTableLoading, setFormationTableLoading] = useState(false);

  const calcFeedActive = (item: ResourceRegulationTableItem) => {
    if (!item) {
      return false;
    }
    const { projectId, scope, demandForecastType } = item;
    const pIds = projectId.split(',');
    switch (pIds.length) {
      case 1:
        return scope.includes('FEED');
      case 2:
        return demandForecastType.split(',')[0] === 'effimate';
      default:
        return false;
    }
  };

  const calcEpcActive = (item: ResourceRegulationTableItem) => {
    if (!item) {
      return false;
    }
    const { projectId, scope, demandForecastType } = item;
    const pIds = projectId.split(',');
    switch (pIds.length) {
      case 1:
        return !scope.includes('FEED');
      case 2:
        return demandForecastType.split(',')[1] === 'effimate';
      default:
        return false;
    }
  };

  const isFeedActive = calcFeedActive(curTableItem);
  const isEpcActive = calcEpcActive(curTableItem);

  const getRatio = (ratioStr: string | null | undefined) => {
    if (!ratioStr) {
      return [null, null];
    }
    const reg = /^Toyo-J:(\d+)\|Toyo-I:(\d+)$/;
    const { 1: japanRatio, 2: indiaRatio } = ratioStr.match(reg);
    return [Number(japanRatio), Number(indiaRatio)];
  };
  const [tgcFeedJE, tgcFeedIE] = getRatio(curTableItem?.formTgcFeedE);
  const [tgcEpcJE, tgcEpcIE] = getRatio(curTableItem?.formTgcEpcE);
  const [tgcFeedJP, tgcFeedIP] = getRatio(curTableItem?.formTgcFeedP);
  const [tgcEpcJP, tgcEpcIP] = getRatio(curTableItem?.formTgcEpcP);
  const [tgcFeedJC, tgcFeedIC] = getRatio(curTableItem?.formTgcFeedC);
  const [tgcEpcJC, tgcEpcIC] = getRatio(curTableItem?.formTgcEpcC);

  const formationTableData = [
    {
      formType: 'E',
      jvFeed: curTableItem?.formJvFeedE,
      tgcFeedJ: tgcFeedJE,
      tgcFeedI: tgcFeedIE,
      jvEpc: curTableItem?.formJvEpcE,
      tgcEpcJ: tgcEpcJE,
      tgcEpcI: tgcEpcIE,
      isFeedActive,
      isEpcActive,
    },
    {
      formType: 'P',
      jvFeed: curTableItem?.formJvFeedP,
      tgcFeedJ: tgcFeedJP,
      tgcFeedI: tgcFeedIP,
      jvEpc: curTableItem?.formJvEpcP,
      tgcEpcJ: tgcEpcJP,
      tgcEpcI: tgcEpcIP,
      isFeedActive,
      isEpcActive,
    },
    {
      formType: 'C',
      jvFeed: curTableItem?.formJvFeedC,
      tgcFeedJ: tgcFeedJC,
      tgcFeedI: tgcFeedIC,
      jvEpc: curTableItem?.formJvEpcC,
      tgcEpcJ: tgcEpcJC,
      tgcEpcI: tgcEpcIC,
      isFeedActive,
      isEpcActive,
    },
  ];

  const [cachedFormation, setCachedFormation] = useState(null);

  const handleFormationJVChange = (e, type, formType) => {
    const newItem = { ...curTableItem };
    switch (true) {
      case type === 'JV_FEED' && formType === 'E':
        newItem.formJvFeedE = e;
        break;
      case type === 'JV_FEED' && formType === 'P':
        newItem.formJvFeedP = e;
        break;
      case type === 'JV_FEED' && formType === 'C':
        newItem.formJvFeedC = e;
        break;
      case type === 'JV_FEED' && formType === 'PJT':
        newItem.formJvFeedPjt = e;
        break;
      case type === 'JV_EPC' && formType === 'E':
        newItem.formJvEpcE = e;
        break;
      case type === 'JV_EPC' && formType === 'P':
        newItem.formJvEpcP = e;
        break;
      case type === 'JV_EPC' && formType === 'C':
        newItem.formJvEpcC = e;
        break;
      case type === 'JV_EPC' && formType === 'PJT':
        newItem.formJvEpcPjt = e;
        break;
      default:
        break;
    }
    setCurTableItem(newItem);
  };

  const handleFormationTGCChange = (e, type, formType) => {
    const newItem = { ...curTableItem };
    const testNil = e === null || e === undefined || e === '';
    switch (true) {
      case type === 'TGC_FEED' && formType === 'E':
        newItem.formTgcFeedE = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'P':
        newItem.formTgcFeedP = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'C':
        newItem.formTgcFeedC = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'PJT':
        newItem.formTgcFeedPjt = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'E':
        newItem.formTgcEpcE = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'P':
        newItem.formTgcEpcP = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'C':
        newItem.formTgcEpcC = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'PJT':
        newItem.formTgcEpcPjt = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      default:
        break;
    }
    setCurTableItem(newItem);
  };

  const formationTableCols: ColumnsType<any> = [
    {
      dataIndex: 'formType',
      key: 'formType',
      align: 'center',
      width: 50,
    },
    {
      title: 'FEED',
      dataIndex: 'FEED',
      key: 'FEED',
      children: [
        {
          title: 'JV',
          dataIndex: 'jvFeed',
          key: 'jvFeed',
          width: 100,
          align: 'center',
          render(val, record) {
            const type = 'JV_FEED';
            const { isFeedActive: feedActive } = record;
            return (
              <InputNumber
                className="project-number right"
                disabled={!feedActive || !tableCanOperation || record.formType === 'P'}
                min={0}
                max={100}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                value={val}
                onChange={(e) => handleFormationJVChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-J',
          dataIndex: 'tgcFeedJ',
          key: 'tgcFeedJ',
          width: 220,
          align: 'center',
          render(val, record) {
            const type = 'TGC_FEED';
            const { isFeedActive: feedActive } = record;
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled={!feedActive || !tableCanOperation || record.formType === 'P'}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                onChange={(e) => handleFormationTGCChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-I',
          dataIndex: 'tgcFeedI',
          key: 'tgcFeedI',
          width: 220,
          align: 'center',
          render(val) {
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
              />
            );
          },
        },
      ],
    },
    {
      title: 'EPC',
      dataIndex: 'EPC',
      key: 'EPC',
      children: [
        {
          title: 'JV',
          dataIndex: 'jvEpc',
          key: 'jvEpc',
          width: 100,
          align: 'center',
          render(val, record) {
            const type = 'JV_EPC';
            const { isEpcActive: epcActive } = record;
            return (
              <InputNumber
                className="project-number right"
                disabled={!epcActive || !tableCanOperation || record.formType === 'P'}
                min={0}
                max={100}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                value={val}
                onChange={(e) => handleFormationJVChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-J',
          dataIndex: 'tgcEpcJ',
          key: 'tgcEpcJ',
          width: 220,
          align: 'center',
          render(val, record) {
            const type = 'TGC_EPC';
            const { isEpcActive: epcActive } = record;
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled={!epcActive || !tableCanOperation || record.formType === 'P'}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                onChange={(e) => handleFormationTGCChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-I',
          dataIndex: 'tgcEpcI',
          key: 'tgcEpcI',
          width: 220,
          align: 'center',
          render(val) {
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                disabled
              />
            );
          },
        },
      ],
    },
  ];

  const demandForecastAction = (item) => {
    setTableLoading(true);
    setKpiLoadingStatus(true);
    APIList.demandForecastAction()
      .post({
        projectId: item.projectId,
        snapshotVersion,
        snapshot,
      })
      .then((res) => {
        const { sessionId } = res as any;
        let timeoutTimer = setTimeout(async () => {
          if (timer) {
            clearInterval(timer);
            timer = null;
            message.error('Connection timeout.');
            setTableLoading(false);
            setKpiLoadingStatus(false);
          }
        }, timeout);
        let timer = setInterval(() => {
          APIList.getSimulationStatus()
            .get({ sessionId })
            .then(async (r: any) => {
              if (r.status === 'forecastDone') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                getChartData();
                await initializeTableData();
                setTableLoading(false);
                message.success(t('aipcmcty.page.updateScheduleProjectId').replace('${item.projectId}', item.projectId));
              } else if (r.status === 'forecastError') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                setTableLoading(false);
                setKpiLoadingStatus(false);
                message.error('Forecast Failed.');
              }
            })
            .catch(() => {
              if (timer) {
                clearInterval(timer);
                timer = null;
              }
              if (timeoutTimer) {
                clearTimeout(timeoutTimer);
                timeoutTimer = null;
              }
              setTableLoading(false);
              setKpiLoadingStatus(false);
              message.error('Forecast Failed.');
              console.log('forecastError');
            });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        message.error('Connecting timeout.');
        setTableLoading(false);
        setKpiLoadingStatus(false);
      });
  };

  // about table
  const projectDateTransfer = (item, beforeItem) => {
    const { p, sb } = dateTransferProjects;
    if (useCase === 0) {
      const pItemIndex = p.findIndex((d) => d.projectId === item.projectId);
      let tempItemP = {
        ...(pItemIndex >= 0 ? p[pItemIndex] : {}),
        changeType: 'DATE_CHANGE',
        id: item.id,
        projectId: item.projectId,
        projectName: item.projectName,
        value: item.projectStartDate,
        beforeValue: beforeItem.projectStartDate,
        scheduledDate: item.orderScheduledDate,
        beforeScheduledDate: beforeItem.orderScheduledDate,
      };
      let pItems = [...p];
      pItems = pItems.filter((tp) => tp.projectId !== item.projectId);
      if (tempItemP.value !== beforeItem.projectStartDate) {
        pItems.push(tempItemP);
      }
      // update transfer items
      setDateTransferProjects({
        p: pItems,
        sb: [],
      });
    } else if (useCase === 1) {
      const index = sb.findIndex((d) => d.subProjectId === item.subProjectId);
      let tempItemP = {
        ...(index >= 0 ? sb[index] : {}),
        changeType: 'DATE_CHANGE',
        id: item.id,
        subProjectId: item.subProjectId,
        projectName: item.subProjectName,
        value: item.projectStartDate,
        beforeValue: beforeItem.projectStartDate,
        scheduledDate: item.orderScheduledDate,
        beforeScheduledDate: beforeItem.orderScheduledDate,
      };
      let pItems = [...p];
      pItems = pItems.filter((tp) => tp.subProjectId !== item.subProjectId);
      if (tempItemP.value !== beforeItem.projectStartDate) {
        pItems.push(tempItemP);
      }
      // update transfer items
      setDateTransferProjects({
        p: [],
        sb: pItems,
      });
    }
  };

  const projectProfitTransfer = (item, beforeItem, elem, beforeGrossProfit?) => {
    const typeMapping = {
      orderAmount: 'ORDER_AMOUNT',
      orderAmountStandalone: 'ORDER_AMOUNT',
      grossMargin: 'GROSS_MARGIN',
      grossMarginStandalone: 'GROSS_MARGIN',
    };
    const isStandalone = accountingPerspective === 4;
    const orderAmountKey = isStandalone ? 'orderAmountStandalone' : 'orderAmount';
    const grossProfitKey = isStandalone ? 'grossProfitStandalone' : 'grossProfit';
    const grossMargin = isStandalone ? 'grossMarginStandalone' : 'grossMargin';
    setProfitTransferProjects((prev) => {
      const { p, sb } = prev;
      // pItem logic
      let pItems = p.filter((tp) => (tp.projectId === item.projectId ? tp.changeType !== `${typeMapping[elem]}_CHANGE` : true));
      const pItemIndex = pItems.findIndex((d) => d.projectId === item.projectId && d.changeType === `${typeMapping[elem]}_CHANGE`);
      let tempItem = {
        ...(pItemIndex >= 0 ? pItems[pItemIndex] : {}),
        changeType: `${typeMapping[elem]}_CHANGE`,
        id: item.id,
        projectId: item.projectId,
        projectName: item.projectName,
        value: item[elem],
        beforeValue: beforeItem[elem],
        grossProfit: item[grossProfitKey],
        beforeGrossProfit: beforeItem[grossProfitKey],
      };
      if (tempItem.value !== beforeItem[elem]) {
        pItems.push(tempItem);
      }
      let sbItems = sb.filter((s) => s.projectId !== item.projectId);
      if (
        item[orderAmountKey] !== beforeItem[orderAmountKey] ||
        // || item.grossMargin !== beforeItem.grossMargin
        item[grossMargin] !== beforeItem[grossMargin]
      ) {
        const cRatios = beforeItem.children.map((c) =>
          beforeItem[orderAmountKey] !== 0 ? c[grossProfitKey] / beforeItem[grossProfitKey] : 0
        );
        const cGrossProfits = beforeItem.children.map((c, i) => ({
          grossProfit: item[grossProfitKey] * cRatios[i],
          beforeGrossProfit: c[grossProfitKey],
          subProjectId: c.subProjectId,
          subProjectName: c.subProjectId,
          projectId: c.projectId,
        }));
        cGrossProfits.forEach((cg) => {
          sbItems.push({
            changeType: `${typeMapping[elem]}_CHANGE`,
            grossProfit: cg.grossProfit,
            beforeGrossProfit: cg.beforeGrossProfit,
            subProjectId: cg.subProjectId,
            subProjectName: cg.subProjectId,
            projectId: cg.projectId,
          });
        });
      }
      console.log('=======================', { p: pItems, sb: sbItems });
      return { p: pItems, sb: sbItems };
    });
  };

  const projectProfitTransferCase2 = (beforeGrossProfit, grossProfit, subProjectId) => {
    const index = profitTransferSubProjects.findIndex((d) => d.subProjectId === subProjectId);
    const temp = {
      ...(index >= 0 ? profitTransferSubProjects[index] : {}),
      beforeGrossProfit,
      grossProfit,
      subProjectId,
    };
    const pItems = filter([...profitTransferSubProjects], (tp) => tp.subProjectId !== subProjectId);
    pItems.push(temp);
    console.log('projectProfitTransferCase2', pItems);
    setProfitTransferSubProjects(pItems);
  };

  const handleTableChange = (id: string, changed: { [key: string]: any }, isReset?: boolean) => {
    if (isReset) {
      const target = useCase === 0 ? tableDataNeedFilter : sbTableData;
      const cacheProjectId = cloneDeep(find(target, (i) => i.id === id))[useCase === 0 ? 'projectId' : 'subProjectId'];
      if (cacheProjectId) {
        setDateTransferProjects((prev) => {
          return {
            p: prev.p.filter((e) => e.projectId !== cacheProjectId),
            sb: prev.sb.filter((e) => e.subProjectId !== cacheProjectId),
          };
        });
        setProfitTransferProjects((prev) => {
          return {
            p: prev.p.filter((e) => e.projectId !== cacheProjectId),
            sb: prev.sb.filter((e) => e.projectId !== cacheProjectId),
          };
        });
      }
      const data = omit(tableDataChangeCache, id);
      setTableDataChangeCache(data);
      return;
    }
    if (isEmpty(changed)) return;
    const source = useCase === 0 ? useCase1ForProject(id, changed) : useCase2ForSubProject(id, changed);
    const cacheChanged = dataRollbackByChanged(id, changed);
    if (isEmpty(cacheChanged)) {
      const data = omit(tableDataChangeCache, id);
      setTableDataChangeCache(data);
      return;
    }
    const newChangeData = getNewChangedData(id, cacheChanged, source);
    setTableDataChangeCache(newChangeData);
  };

  const getNewChangedData = (id: string, cacheChanged: any, source: any) => {
    const newChangeData = { ...tableDataChangeCache };
    const type = accountingPerspective === 4 ? 'Standalone' : 'UnStandalone';
    const grossProfitKey = StandaloneKeyMapping.GROSS_PROFIT[type];
    const grossMarginKey = StandaloneKeyMapping.GROSS_MARGIN[type];
    const orderAmountKey = StandaloneKeyMapping.ORDER_AMOUNT[type];
    if (has(cacheChanged, orderAmountKey) || has(cacheChanged, grossMarginKey)) {
      cacheChanged[grossProfitKey] = source[grossProfitKey];
      if (accountingPerspective !== 4) {
        cacheChanged['grossProfitPerMh'] = source['grossProfitPerMh'];
      }
    }
    set(newChangeData, id, { source, changed: { ...cacheChanged }, useCase, type: accountingPerspective });
    return newChangeData;
  };

  const dataRollbackByChanged = (id: string, changed: { [key: string]: any }) => {
    const target = useCase === 0 ? tableDataNeedFilter : sbTableData;
    const cache = cloneDeep(find(target, (i) => i.id === id));
    const changedCache = cloneDeep(get(tableDataChangeCache, [id, 'changed']));
    const type = accountingPerspective === 4 ? 'Standalone' : 'UnStandalone';
    const orderAmountKey = StandaloneKeyMapping.ORDER_AMOUNT[type];
    const grossProfitKey = StandaloneKeyMapping.GROSS_PROFIT[type];
    const grossMarginKey = StandaloneKeyMapping.GROSS_MARGIN[type];
    const noChangeKeys = chain(changed)
      .map((v, k) => {
        if (isDayjs(v)) {
          const date = v.format('YYYY-MM-01');
          let nk = k;
          if (k === 'offsetMonths') {
            nk = 'orderScheduledDate';
          }
          const cacheDate = cache[nk] ? dayjs(cache[nk]).format('YYYY-MM-01') : null;
          if (cacheDate == date) {
            return k;
          }
        }
        if (cache[k] == v) {
          return k;
        }
      })
      .compact()
      .value();
    const margeChanged = omit({ ...changedCache, ...changed }, noChangeKeys);
    if (!has(margeChanged, orderAmountKey) && !has(margeChanged, grossMarginKey)) {
      delete margeChanged[grossProfitKey];
      delete margeChanged['grossProfitPerMh'];
    }
    return margeChanged;
  };

  const useCase1ForProject = (id: string, changed: { [key: string]: any }) => {
    const cache = cloneDeep(find(tableDataNeedFilter, (i) => i.id === id));
    const cacheChildren = sbTableData.filter((s) => s.projectId === cache.projectId);
    cache.children = cloneDeep(cacheChildren);
    const uptChangeData = cloneDeep(get(tableDataChangeCache, [id, 'source'], cache));
    if (!uptChangeData) return;
    const margeChanged = dataRollbackByChanged(id, changed);
    const type = accountingPerspective === 4 ? 'Standalone' : 'UnStandalone';
    const orderAmountKey = StandaloneKeyMapping.ORDER_AMOUNT[type];
    const grossProfitKey = StandaloneKeyMapping.GROSS_PROFIT[type];
    const grossMarginKey = StandaloneKeyMapping.GROSS_MARGIN[type];
    if (!has(margeChanged, orderAmountKey) && !has(margeChanged, grossMarginKey)) {
      uptChangeData[orderAmountKey] = cache[orderAmountKey];
      uptChangeData[grossMarginKey] = cache[grossMarginKey];
      uptChangeData[grossProfitKey] = cache[grossProfitKey];
      uptChangeData['grossProfitPerMh'] = cache['grossProfitPerMh'];
    }
    forEach(changed, (value, key) => {
      set(uptChangeData, key, value);
      const handleFunc = tableChangeMap()[key];
      if (!handleFunc) return;
      if (value != cache[key]) {
        handleFunc(uptChangeData, cache);
      }
      switch (true) {
        case key === 'offsetMonths':
          projectDateTransfer(uptChangeData, cache);
          break;
        case key.startsWith('orderAmount') || key.startsWith('grossMargin'):
          projectProfitTransfer(uptChangeData, cache, key);
          break;
        default:
          break;
      }
    });
    return uptChangeData;
  };

  const useCase2ForSubProject = (id: string, changed: { [key: string]: any }) => {
    const cache = find(sbTableData, (i) => i.id === id);
    const uptChangeData = cloneDeep(get(tableDataChangeCache, [id, 'source'], cache));
    if (!uptChangeData) return;
    const margeChanged = dataRollbackByChanged(id, changed);
    const type = accountingPerspective === 4 ? 'Standalone' : 'UnStandalone';
    // 受注金额
    const orderAmountKey = StandaloneKeyMapping.ORDER_AMOUNT[type];
    // 粗利率
    const grossMarginKey = StandaloneKeyMapping.GROSS_MARGIN[type];
    // 粗利额
    const grossProfitKey = StandaloneKeyMapping.GROSS_PROFIT[type];
    if (!has(margeChanged, orderAmountKey) && !has(margeChanged, grossMarginKey)) {
      uptChangeData[orderAmountKey] = cache[orderAmountKey];
      uptChangeData[grossMarginKey] = cache[grossMarginKey];
      uptChangeData[grossProfitKey] = cache[grossProfitKey];
      uptChangeData['grossProfitPerMh'] = cache['grossProfitPerMh'];
    }
    forEach(changed, (value, key) => set(uptChangeData, key, value));
    // 受注金额 && !粗利率
    if (has(margeChanged, orderAmountKey) && !has(margeChanged, grossMarginKey)) {
      if (!isNil(margeChanged[orderAmountKey]) && !isNil(uptChangeData[grossMarginKey])) {
        set(uptChangeData, grossProfitKey, margeChanged[orderAmountKey] * uptChangeData[grossMarginKey]);
      }
    }
    // !受注金额 && 粗利率
    else if (!has(margeChanged, orderAmountKey) && has(margeChanged, grossMarginKey)) {
      if (!isNil(margeChanged[grossMarginKey]) && !isNil(uptChangeData[orderAmountKey])) {
        set(uptChangeData, grossProfitKey, margeChanged[grossMarginKey] * uptChangeData[orderAmountKey]);
      }
    }
    // 受注金额 && 粗利率
    else if (has(margeChanged, orderAmountKey) && has(margeChanged, grossMarginKey)) {
      if (!isNil(margeChanged[orderAmountKey]) && !isNil(margeChanged[grossMarginKey])) {
        set(uptChangeData, grossProfitKey, margeChanged[grossMarginKey] * margeChanged[orderAmountKey]);
      }
    }
    if (uptChangeData[grossProfitKey] === cache[grossProfitKey]) {
      projectProfitTransferCase2(uptChangeData[grossProfitKey], cache[grossProfitKey], cache.subProjectId);
    }
    // 粗利/MH
    if (type === 'Standalone' && has(margeChanged, grossProfitKey)) {
      const grossProfit = get(margeChanged, grossProfitKey);
      const demandMh = uptChangeData.demandMh;
      if (demandMh) {
        set(uptChangeData, 'grossProfitPerMh', grossProfit / demandMh);
      }
    }
    // 受注月调整
    if (has(changed, 'offsetMonths') && cache.orderScheduledDate) {
      const startDate = dayjs(cache.orderScheduledDate).format('YYYY-MM-01');
      const offsetMonths = uptChangeData.offsetMonths.format('YYYY-MM-01');
      const diffMonths = dayjs(offsetMonths).diff(dayjs(startDate), 'month');
      const orderScheduledDate = dayjs(cache.orderScheduledDate).add(diffMonths, 'month').format('YYYY-MM-DD');
      set(uptChangeData, 'orderScheduledDate', orderScheduledDate);
      const projectStartDate = dayjs(cache.projectStartDate).add(diffMonths, 'month').format('YYYY-MM-DD');
      set(uptChangeData, 'projectStartDate', projectStartDate);
      const projectEndDate = dayjs(cache.projectEndDate).add(diffMonths, 'month').format('YYYY-MM-DD');
      set(uptChangeData, 'projectEndDate', projectEndDate);
      // 会计年度 accounting_year
      const accountingYearDiffMonth = includes(['Toyo-J', 'Toyo-I', 'TPS'], cache.tgc) ? -3 : 0;
      const accountingYear = 'FY' + dayjs(orderScheduledDate).add(accountingYearDiffMonth, 'month').format(`YY`);
      set(uptChangeData, 'accountingYear', accountingYear);

      if (diffMonths) {
        projectDateTransfer(uptChangeData, cache);
      }
    }
    return uptChangeData;
  };

  const calcProjectBySubProject = (record: ResourceRegulationTableItem, changedKeys: string[]) => {
    if (isEmpty(changedKeys)) return record;
    const sbProject = cloneDeep(get(record, 'children'));
    if (isEmpty(sbProject)) return record;
    // 予算カテゴリ的下拉框
    if (includes(changedKeys, 'budgetCategoryCustom') && !includes(['Awarded', 'Budget'], record.budgetCategoryCustom)) {
      const budgetCategoryMapping = keyBy(optData.budgetCategory, 'value');
      const lastCategory = chain(sbProject)
        .sortBy((row) => get(budgetCategoryMapping, [row.budgetCategoryCustom, 'order'], -1))
        .first()
        .get('budgetCategoryCustom')
        .value();
      record.budgetCategoryCustom = lastCategory;
    }
    // 受注金额 || 粗利率
    const type = accountingPerspective === 4 ? 'Standalone' : 'UnStandalone';
    // 受注金额
    const orderAmountKey = StandaloneKeyMapping.ORDER_AMOUNT[type];
    // 粗利率
    const grossMarginKey = StandaloneKeyMapping.GROSS_MARGIN[type];
    // 粗利额
    const grossProfitKey = StandaloneKeyMapping.GROSS_PROFIT[type];
    if (includes(changedKeys, orderAmountKey) || includes(changedKeys, grossMarginKey)) {
      // 受注金额
      if (includes(changedKeys, orderAmountKey)) {
        set(record, orderAmountKey, sumBy(sbProject, orderAmountKey));
      }
      const orderAmount = get(record, orderAmountKey);
      // 粗利额
      const grossProfit = sumBy(sbProject, grossProfitKey);
      set(record, grossProfitKey, grossProfit);
      // 粗利率
      if (orderAmount && grossProfit) {
        set(record, grossMarginKey, grossProfit / orderAmount);
      }
      // 粗利/MH
      const demandMh = record.demandMh;
      if (type === 'UnStandalone' && grossProfit && demandMh) {
        set(record, 'grossProfitPerMh', grossProfit / demandMh);
      }
    }
    // 受注月调整
    if (includes(changedKeys, 'offsetMonths')) {
      const orderScheduledDate = chain(sbProject).minBy('orderScheduledDate').get('orderScheduledDate').value();
      set(record, 'orderScheduledDate', orderScheduledDate);
      // 会计年度
      const accountingYearDiffMonth = includes(['Toyo-J', 'Toyo-I', 'TPS'], record.tgc) ? -3 : 0;
      record.accountingYear = 'FY' + dayjs(orderScheduledDate).add(accountingYearDiffMonth, 'month').format(`YY`);
    }
    return record;
  };

  const calcSProjectByProject = (
    source: ResourceRegulationTableItem[],
    changed: any,
    pRecordCatch: ResourceRegulationTableItem,
    type: 'Standalone' | 'UnStandalone'
  ) => {
    const orderAmountKey = StandaloneKeyMapping.ORDER_AMOUNT[type];
    const sumOrderAmount = sumBy(source, orderAmountKey);
    const grossProfitKey = StandaloneKeyMapping.GROSS_PROFIT[type];
    const sumGrossProfit = sumBy(source, grossProfitKey);
    const grossMarginKey = StandaloneKeyMapping.GROSS_MARGIN[type];
    let diffMounths = 0;
    if (changed.offsetMonths) {
      const startDate = dayjs(pRecordCatch.orderScheduledDate).format('YYYY-MM-01');
      const offsetMonths = changed.offsetMonths.format('YYYY-MM-01');
      diffMounths = dayjs(offsetMonths).diff(dayjs(startDate), 'month');
    }
    return map(source, (rowChildren) => {
      const result: any = { id: rowChildren.id };
      let orderAmountRatio = null;
      let grossProfitRatio = null;
      if (sumOrderAmount && has(changed, orderAmountKey)) {
        orderAmountRatio = rowChildren[orderAmountKey] / sumOrderAmount;
        const orderAmount = changed[orderAmountKey] * orderAmountRatio;
        set(result, orderAmountKey, orderAmount);
      }
      if (sumGrossProfit && has(changed, grossProfitKey)) {
        grossProfitRatio = rowChildren[grossProfitKey] / sumGrossProfit;
        set(result, grossProfitKey, changed[grossProfitKey] * grossProfitRatio);
      }
      if (has(result, orderAmountKey) && has(result, grossProfitKey)) {
        const orderAmount = get(result, orderAmountKey);
        const grossProfit = get(result, grossProfitKey);
        if (orderAmount) {
          set(result, grossMarginKey, grossProfit / orderAmount);
        }
      }
      const demandMh = rowChildren.demandMh;
      if (has(result, StandaloneKeyMapping.GROSS_PROFIT.Standalone) && demandMh) {
        const grossProfitStandalone = get(result, StandaloneKeyMapping.GROSS_PROFIT.Standalone);
        set(result, 'grossProfitPerMh', grossProfitStandalone / demandMh);
      }
      if (diffMounths !== 0) {
        const orderScheduledDate = dayjs(rowChildren.orderScheduledDate).add(diffMounths, 'month').format('YYYY-MM-DD');
        set(result, 'orderScheduledDate', orderScheduledDate);
        const projectStartDate = dayjs(rowChildren.projectStartDate).add(diffMounths, 'month').format('YYYY-MM-DD');
        set(result, 'projectStartDate', projectStartDate);
        const projectEndDate = dayjs(rowChildren.projectEndDate).add(diffMounths, 'month').format('YYYY-MM-DD');
        set(result, 'projectEndDate', projectEndDate);
        const accountingYearDiffMounth = includes(['Toyo-J', 'Toyo-I', 'TPS'], rowChildren.tgc) ? -3 : 0;
        const accountingYear = 'FY' + dayjs(orderScheduledDate).add(accountingYearDiffMounth, 'month').format(`YY`);
        set(result, 'accountingYear', accountingYear);
      }
      return result;
    });
  };

  const changeViewForTableInfo = (record: ResourceRegulationTableItem) => {
    if (useCase === 0) {
      const changeCache = cloneDeep(get(tableDataChangeCache, [record.id, 'source'], {}));
      const sData = cloneDeep(sbTableData.filter((s) => s.projectId === record.projectId));
      sData.forEach((sd) => {
        sd.isChildren = true;
        sd.pid = sd.projectId;
        sd.projectId = sd.subProjectId;
        sd.pName = record.projectName;
        sd.projectName = sd.subProjectName;
        sd.pBudgetCategoryCustom = record.budgetCategoryCustom;
      });
      if (!isEmpty(sData) && !isEmpty(changeCache)) {
        const changed = get(tableDataChangeCache, [record.id, 'changed'], {});
        const type = accountingPerspective === 4 ? 'Standalone' : 'UnStandalone';
        const childChanged = calcSProjectByProject(sData, changed, /* changeCache,  */ record, type);
        const childChangedMap = keyBy(childChanged, 'id');
        const children = map(sData, (item) => {
          const changed = childChangedMap[item.id];
          return { ...item, ...changed };
        }).filter((c) => record.children?.map((cd) => cd.projectId).includes(c.projectId));
        set(changeCache, 'children', children);
      }
      return changeCache;
    } else if (useCase === 1) {
      const validHasChanged = !chain(record.children).map('id').intersection(keys(tableDataChangeCache)).isEmpty().value();
      if (!validHasChanged) return record;
      const changedKeys = [];
      const children = map(record.children, (item) => {
        const changed = get(tableDataChangeCache, [item.id, 'changed']);
        changedKeys.push(...keys(changed));
        const result = {
          ...get(tableDataChangeCache, [item.id, 'source'], item),
          isChildren: true,
          pid: item.projectId,
          projectId: item.subProjectId,
          pName: record.projectName,
          projectName: item.subProjectName,
          pBudgetCategoryCustom: record.budgetCategoryCustom,
        };
        set(result, 'forceEdit', has(changed, 'budgetCategoryCustom'));
        return result;
      });
      // 根据上方计算结果，计算P信息
      return calcProjectBySubProject({ ...record, children }, uniq(changedKeys));
    }
    return {};
  };

  const filteredTableData = useMemo(() => {
    const newTableData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method ? tData.filter((t) => method(t, filtersData[cur], cur, filtersData)) : tData;
      },
      [...listNeedRender]
    );
    let dxItems = [];
    if (dxOn) {
      const orgMart = getOrgKpiData('GrossProfitResource')?.data?.mart ?? [];
      dxItems = orgMart.filter((o) => o.budgetCategoryCustom.startsWith('DX_'));
    }
    return newTableData
      .map((d, i) => {
        if (i === 0) {
          d.orderAccumulation = d.orderAmount;
          d.profitAccumulation = d.grossProfit;
        } else {
          d.orderAccumulation = d.orderAmount + newTableData[i - 1].orderAccumulation;
          d.profitAccumulation = d.grossProfit + newTableData[i - 1].profitAccumulation;
        }
        if (dxOn) {
          const dxGrossProfit = dxItems.filter((dx) => d.projectId === dx.projectId).reduce((pr, c) => pr + c.grossProfit, 0);
          d.dxGrossProfit = Number(dxGrossProfit.toFixed(0));
        }
        const cache = changeViewForTableInfo(d);
        return { ...d, ...cache };
      })
      .sort((a, b) => a?.priorityDefault - b?.priorityDefault);
  }, [filtersData, listNeedRender, tableDataChangeCache]);

  useEffect(() => {
    if (useCase === 0) {
      setSelectedProjects(
        tableDataNeedFilter.filter(
          (d) =>
            d.budgetCategoryCustom === 'Awarded' ||
            (d.budgetCategoryCustom === 'Budget' && !unselectBudgets.map((u) => u.projectId).includes(d.projectId)) ||
            selectedProjects.map((s) => s.projectId).includes(d.projectId)
        )
      );
    } else if (useCase === 1) {
      setDefalutSelectedWithSbInfo();
    }
  }, [tableDataNeedFilter]);

  const setDefalutSelectedWithSbInfo = () => {
    setSelectedProjects(
      chain(sbTableData)
        .filter((d) => {
          return (
            d.budgetCategoryCustom === 'Awarded' ||
            (d.budgetCategoryCustom === 'Budget' && !unselectBudgets.map((u) => u.subProjectId).includes(d.subProjectId)) ||
            selectedProjects.map((s) => s.subProjectId).includes(d.subProjectId)
          );
        })
        .map((i) => ({ ...i, projectId: i.subProjectId }))
        .value()
    );
  };

  // about modal
  const [curModalItem, setCurModalItem] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // about chart
  const { kpiInfos, initKpiData, getKpiData, setKpiLoadingStatus, filterKpiData, orgKpiInfos, removeOrgKpiData } =
    useContext(AipcmctyContext);
  const [kpiOrder, setKpiOrder] = useState(initialKpiOrder);

  const [resSimCacheData, setResSimCacheData] = useState<any>({});
  useEffect(() => {
    const resSim = getKpiData('ResourceSimulation')?.data;
    if (resSim) {
      const { projectDemand, projectIds } = resSim;
      const pids = convertToJSON(projectIds);
      const pds = convertToJSON(projectDemand);
      let { tgc } = filtersData;
      if (!tgc?.length) {
        tgc = map(optOrgData?.tgc, 'value');
      }
      const ids = pids.filter((p) => !tgc.length || tgc.includes(p.tgc));
      const dms = pds.filter((p) => !tgc.length || tgc.includes(p.tgc));
      setResSimCacheData({
        projectDemand: dms,
        resSimIds: ids,
      });
    }
  }, [kpiInfos, filtersData.tgc]);

  const combineGraphIdsAndTgcs = useMemo(() => {
    const resSim = getKpiData('ResourceSimulation')?.data;
    const orderGProfit = getKpiData('OrderGrossProfitResource')?.data;
    const gProfit = getKpiData('GrossProfitResource')?.data;
    let { tgc } = filtersData;
    if (!tgc?.length) {
      tgc = map(optOrgData?.tgc, 'value');
    }
    let resSimData = [];
    let orderGProfitData = [];
    let gProfitData = [];

    if (resSim) {
      const { projectIds } = resSim;
      const pids = convertToJSON(projectIds);
      const filteredData = pids.filter((p) => !tgc.length || tgc.includes(p.tgc)).map((p) => p.projectId);
      resSimData = filteredData.reduce((pr, id) => {
        const tgcItems = pids.filter((p) => p.projectId === id).map((p) => p.tgc);
        pr[id] = tgcItems;
        return pr;
      }, {} as any);
    }
    if (orderGProfit) {
      const { mart } = orderGProfit;
      const orgMart = getOrgKpiData('OrderGrossProfitResource')?.data?.mart ?? [];
      const martIds = mart.map((m) => m.projectId);
      const idTgcs = orgMart.reduce((pr, c) => {
        if (pr[c.projectId] && !pr[c.projectId].includes(c.tgc)) {
          pr[c.projectId].push(c.tgc);
        } else {
          pr[c.projectId] = [c.tgc];
        }
        return pr;
      }, {} as any);
      orderGProfitData = martIds.reduce((pr, c) => {
        pr[c] = idTgcs[c];
        return pr;
      }, {} as any);
    }
    if (gProfit) {
      const { mart } = gProfit;
      const orgMart = getOrgKpiData('GrossProfitResource')?.data?.mart ?? [];
      const martIds = mart.map((m) => m.projectId);
      const idTgcs = orgMart.reduce((pr, c) => {
        if (pr[c.projectId] && !pr[c.projectId].includes(c.tgc)) {
          pr[c.projectId].push(c.tgc);
        } else {
          pr[c.projectId] = [c.tgc];
        }
        return pr;
      }, {} as any);
      gProfitData = martIds.reduce((pr, c) => {
        pr[c] = idTgcs[c];
        return pr;
      }, {} as any);
    }

    return Object.keys({
      ...resSimData,
      ...orderGProfitData,
      ...gProfitData,
    }).reduce((pr, id) => {
      const rTgc = resSimData[id] ?? [];
      const oTgc = orderGProfitData[id] ?? [];
      const gTgc = gProfitData[id] ?? [];
      const mergedTgc = Array.from(new Set([...rTgc, ...oTgc, ...gTgc]));
      pr[id] = mergedTgc;
      return pr;
    }, {} as any);
  }, [kpiInfos, filtersData.tgc]);

  useEffect(() => {
    const {
      demandYearMonth,
      mhDepartment: [div, dis],
    } = filtersData;
    if (!demandYearMonth && div === 'All' && dis === 'All') {
      const combineGraphIds = Object.keys(combineGraphIdsAndTgcs);
      const listRendering = tableDataNeedFilter.filter((t) => combineGraphIds.includes(t.projectId));
      listRendering.forEach((l) => {
        const mh = (resSimCacheData.resSimIds || []).filter((r) => r.projectId === l.projectId).reduce((pr, c) => pr + c.MhPeak, 0);
        const tgc = combineGraphIdsAndTgcs[l.projectId] ?? [];
        l.monthDemandMH = Number(mh.toFixed(0));
        l.relatedTgc = tgc.length ? tgc.sort().join(', ') : null;
        const children = cloneDeep(sbTableData.filter((sb) => sb.projectId === l.projectId && filtersData.tgc.includes(sb.tgc)));
        children.forEach((sd) => {
          sd.isChildren = true;
          sd.pid = sd.projectId;
          sd.projectId = sd.subProjectId;
          sd.pName = l.projectName;
          sd.projectName = sd.subProjectName;
          sd.pBudgetCategoryCustom = l.budgetCategoryCustom;
        });
        if (children && children.length) {
          l.children = children;
        }
      });
      setListNeedRender(listRendering);
    }
  }, [combineGraphIdsAndTgcs, filtersData.mhDepartment, filtersData.demandYearMonth, tableDataNeedFilter, resSimCacheData]);

  const changeRenderList = async ({ demandYearMonth, mhDepartment }: { demandYearMonth?: string; mhDepartment?: [string, string] }) => {
    if (!resSimCacheData.projectDemand) {
      return;
    }
    const [div, dis] = mhDepartment;
    let { tgc } = filtersData;
    if (!tgc?.length) {
      tgc = map(optOrgData?.tgc, 'value');
    }
    if (!demandYearMonth && div === 'All' && dis === 'All') {
      return;
    }
    // select year month or div/dis
    const getDemandInfo = async () => {
      let year = '';
      let month = '';
      if (demandYearMonth) {
        [year, month] = demandYearMonth?.split('-');
      }
      setTableLoading(true);
      const {
        p: { demandProjectIds, demandMhInfo } = { demandProjectIds: [], demandMhInfo: {} },
        sub: { demandProjectIds: subDemandProjectIds, demandMhInfo: subDemandMhInfo } = { demandProjectIds: [], demandMhInfo: {} },
      } = await APIList.getDemandProjects().get({
        snapshot,
        snapshotVersion,
        year,
        month,
        division: div === 'All' ? '' : div,
        discipline: dis === 'All' ? '' : dis,
        tgc: tgc.join(','),
        isSub: useCase === 1,
      });
      const { resSimIds, projectDemand } = resSimCacheData;
      const groupedProjectDemand = groupBy(projectDemand, (x) => x.projectId);
      const budgetStep2 = Object.keys(groupedProjectDemand).reduce((prev, cur) => {
        demandYearMonthRange.forEach((ym) => {
          const list = groupedProjectDemand[cur].filter((g) => g.yearMonth === ym);
          const total = list.reduce((pr, c) => pr + (c.resourceDemandMH ?? 0), 0);
          if (prev[cur]) {
            prev[cur][ym] = total;
          } else {
            prev[cur] = {
              [ym]: total,
            };
          }
        });
        return prev;
      }, {} as any);
      const gAllData = Object.entries(budgetStep2).map(([k, v]) => ({
        projectId: k,
        mh: Number(
          demandYearMonth
            ? v[demandYearMonth].toFixed(0)
            : Object.values(v)
                .reduce((pr, c) => pr + c, 0)
                .toFixed(0)
        ),
      }));
      const orgSimIds = convertToJSON(getOrgKpiData('ResourceSimulation').data.projectIds || []);
      const tgcGroupById = resSimIds.reduce((pr, c) => {
        const { projectId } = c;
        const tgcs = orgSimIds?.filter((o) => o.projectId === projectId).map((o) => o.tgc);
        pr[projectId] = Array.from(new Set(tgcs));
        return pr;
      }, {} as any);
      const targetProjectId =
        useCase === 0
          ? demandProjectIds
          : chain(sbTableData)
              .filter((item) => includes(subDemandProjectIds, item.subProjectId))
              .map('projectId')
              .uniq()
              .value();
      if (useCase === 0) {
        const projects = tableDataNeedFilter
          .filter((s) => targetProjectId.includes(s.projectId))
          .map((s) => {
            const mh = useCase === 0 ? gAllData.find((a) => a.projectId === s.projectId)?.mh : s.monthDemandMH;
            const demandMh = useCase === 0 ? get(demandMhInfo?.[s.projectId], 'resourceDemandMh', s.demandMh) : s.demandMh;
            const tgc = tgcGroupById[s.projectId] ?? [];
            const children = cloneDeep(sbTableData.filter((sb) => sb.projectId === s.projectId && filtersData.tgc.includes(sb.tgc)));
            children.forEach((sd) => {
              console.error('TODO: sub需要追加 mh计算，参考上方P的MH计算逻辑, 并且根据case不同重新计算`gAllData`');
              const subDemandMh = useCase === 0 ? sd.demandMh : get(subDemandMhInfo?.[sd.subProjectId], 'resourceDemandMh', sd.demandMh);
              sd.isChildren = true;
              sd.pid = sd.projectId;
              sd.projectId = sd.subProjectId;
              sd.pName = s.projectName;
              sd.projectName = sd.subProjectName;
              sd.demandMh = subDemandMh;
            });
            const newP = {
              ...s,
              offsetMonths: s.orderScheduledDate,
              monthDemandMH: mh,
              demandMh,
              relatedTgc: tgc.length ? tgc.sort().join(', ') : null,
            };
            if (children && children.length) {
              newP.children = children;
            }
            return newP;
          });
        setListNeedRender(projects);
      } else if (useCase === 1) {
        // TODO: 计算MH
      }
      setTableLoading(false);
      setMenuCollapsed(true);
      setFilterCollapsed(false);
    };
    await getDemandInfo();
  };

  useEffect(() => {
    changeRenderList({
      demandYearMonth: filtersData.demandYearMonth,
      mhDepartment: filtersData.mhDepartment as [string, string],
    });
  }, [filtersData.tgc, tableDataNeedFilter, resSimCacheData.projectDemand]);

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const { tgc, demandYearMonth, mhDepartment, department } = filtersData;

  const kpiMapping = useMemo(() => {
    return {
      33: (
        <ResourceSimulationChart
          kpiCode="ResourceSimulation"
          data={getKpiData('ResourceSimulation').data}
          loading={getKpiData('ResourceSimulation').loading}
          projects={selectedProjects}
          unselectBudgets={unselectBudgets}
          tgcs={handleTgcType(optOrgData?.tgc, tgc, true, includes([2, 3], accountingPerspective))}
          height={330}
          yearMonthRange={demandYearMonthRange}
          curYearMonth={demandYearMonth}
          onDemandSelect={handleDemandProjects}
          setMHDepartmentOpts={setMHDepartmentOpts}
          selectedDivDis={mhDepartment}
          isDxMode={dxOn}
          idKey={useCase === 0 ? 'projectId' : 'subProjectId'}
          dateTransferProjects={useCase === 0 ? dateTransferProjects.p : dateTransferProjects.sb}
          department={department}
          title={customChartGroupTitle(
            t('aipcmcty.page.resource'),
            t('aipcmcty.page.resSimulation.mhForecast') + (useCase === 0 ? ' (P)' : ' (SB)'),
            color.warningColor
          )}
        />
      ),
      35: (
        <TurnoverResource
          consolidated={consolidated}
          height={330}
          kpiCode="OrderGrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('OrderGrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('OrderGrossProfitResource').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderGrossProfit'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          department={department}
          dateTransferProjects={dateTransferProjects.p}
          profitTransferProjects={profitTransferProjects.sb}
        />
      ),
      36: (
        <TurnoverResource
          consolidated={consolidated}
          height={330}
          kpiCode="GrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('GrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('GrossProfitResource').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            `${t('aipcmcty.page.resSimulation.grossProfitRebound')}・${t('aipcmcty.page.resSimulation.grossRate')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          department={department}
          idKey={useCase === 0 ? 'projectId' : 'subProjectId'}
          dateTransferProjects={useCase === 0 ? dateTransferProjects.p : dateTransferProjects.sb}
          profitTransferProjects={useCase === 0 ? profitTransferProjects.sb : profitTransferSubProjects}
          showGrossProfitRateLine={true}
        />
      ),
      37: (
        <TurnoverFlowChart
          consolidated={consolidated}
          height={330}
          kpiCode="OrderGrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('OrderGrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('OrderGrossProfitResource').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderGrossProfit'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          department={department}
          onOrderYearMonthChange={handleOrderScheduledDateChange}
          orderYearMonths={orderYearMonths}
          onOrderFYChange={handleOrderFYChange}
          idKey="subProjectId"
          dateTransferProjects={dateTransferProjects.sb}
          profitTransferProjects={profitTransferSubProjects}
          selectedFiscalYear={orderFiscalYear}
        />
      ),
    };
  }, [
    kpiInfos,
    tgc,
    demandYearMonth,
    mhDepartment,
    department,
    periodSwitch,
    selectedProjects,
    dxOn,
    consolidated,
    orderFiscalYear[0],
    dateTransferProjects,
    profitTransferProjects,
    profitTransferSubProjects,
    useCase,
    optOrgData,
    accountingPerspective,
  ]);

  useEffect(() => {
    setTimeout(() => {
      filterKpiData({
        tgc: handleTgcType(optOrgData?.tgc, filtersData.tgc, true, includes([2, 3], accountingPerspective)),
        japanInvolved: filtersData.japanInvolved,
        consolidated,
        tgcCanEmpty: true,
        departments: {
          kpiCode: 'OrderGrossProfitResource',
          items: filtersData.department,
        },
      });
    }, 0);
  }, [orgKpiInfos, filtersData.tgc, filtersData.japanInvolved, filtersData.department, accountingPerspective]);

  const getChartData = () => {
    initKpiData([['OrderGrossProfitResource', 'GrossProfitResource'], ['ResourceSimulation']], !!consolidated, {
      isDXMode: dxOn,
      division: filtersData.mhDepartment[0] === 'All' ? '' : filtersData.mhDepartment[0],
      discipline: filtersData.mhDepartment[1] === 'All' ? '' : filtersData.mhDepartment[1],
    });
  };

  // confirm select and unselect modal
  const confirmTableColumns = [
    {
      title: t('aipcmcty.page.projectCaseId'),
      key: 'projectId',
      dataIndex: 'projectId',
    },
    {
      title: t('aipcmcty.page.projectCaseName'),
      key: 'projectName',
      dataIndex: 'projectName',
    },
    {
      title: `变更类型`,
      key: 'changeType',
      dataIndex: 'changeType',
      render: (value) => confirmChangeTypeMap[value] ?? '',
    },
    {
      title: `${t('aipcmcty.page.resSimulation.before')}`,
      key: 'changeBefore',
      dataIndex: 'changeBefore',
      render: (value, item) => {
        switch (item.changeType) {
          case 'ORDER_AMOUNT_CHANGE':
            return value.toLocaleString();
          case 'GROSS_MARGIN_CHANGE':
            return `${(value * 100).toFixed(1)}%`;
          default:
            return item.cBeforeItem ? item.cBeforeItem : value;
        }
      },
    },
    {
      title: `${t('aipcmcty.page.resSimulation.after')}`,
      key: 'changeAfter',
      dataIndex: 'changeAfter',
      render: (value, item) => {
        switch (item.changeType) {
          case 'ORDER_AMOUNT_CHANGE':
            return value.toLocaleString();
          case 'GROSS_MARGIN_CHANGE':
            return `${(value * 100).toFixed(1)}%`;
          default:
            return item.cAfterItem ? item.cAfterItem : value;
        }
      },
    },
  ];
  const confirmChangeTypeMap = {
    BUDGET_CHANGE: t('aipcmcty.page.budgetCategory'),
    DATE_CHANGE: '受注月調整',
    ORDER_AMOUNT_CHANGE: t('aipcmcty.page.orderAmount'),
    GROSS_MARGIN_CHANGE: t('aipcmcty.page.grossProfitRate'),
  };
  const [confirmUpdateModal, setConfirmUpdateModel] = useState(false);
  const [confirmSelectIds, setConfirmSelectIds] = useState([]);
  const confirmTableData = useMemo(() => {
    if (useCase === 0) {
      const asBudgets = selectedProjects
        .filter((s) => ['IF', 'Others'].includes(s.budgetCategoryCustom))
        .map((p) => {
          return {
            id: p.id,
            projectId: p.projectId,
            projectName: p.projectName,
            changeType: 'BUDGET_CHANGE',
            changeBefore: p.budgetCategoryCustom,
            changeAfter: 'Budget',
          };
        });
      const asIFs = unselectBudgets.map((u) => {
        return {
          id: u.id,
          projectId: u.projectId,
          projectName: u.projectName,
          changeType: 'BUDGET_CHANGE',
          changeBefore: u.budgetCategoryCustom,
          changeAfter: 'IF',
        };
      });
      const { p: dateP, sb: dateSB } = dateTransferProjects;
      const dateTransfers = dateP.map((d) => {
        return {
          id: d.id,
          projectId: d.projectId,
          projectName: d.projectName,
          changeType: d.changeType,
          // changeBefore: d.beforeValue,
          // changeAfter: d.value,
          changeBefore: d.beforeScheduledDate,
          changeAfter: d.scheduledDate,
        };
      });
      const { p: profitP, sb: profitSB } = profitTransferProjects;
      const amountTransfers = profitP.map((d) => {
        return {
          id: d.id,
          projectId: d.projectId,
          projectName: d.projectName,
          changeType: d.changeType,
          changeBefore: d.beforeValue,
          changeAfter: d.value,
        };
      });
      return [...asBudgets, ...asIFs, ...dateTransfers, ...amountTransfers];
    } else if (useCase === 1) {
      return chain(tableDataChangeCache)
        .map((configure, id) => {
          const changed = get(configure, 'changed', {});
          const rowSubProjectInfo = find(sbTableData, ['id', id]);
          return chain(changed)
            .map((v, k) => {
              k = k === 'offsetMonths' ? 'orderScheduledDate' : k;
              return {
                id,
                projectId: rowSubProjectInfo.subProjectId,
                projectName: rowSubProjectInfo.subProjectName,
                changeType: ChangeKeyMapping[k],
                changeBefore: rowSubProjectInfo[k],
                changeAfter: isDayjs(v) ? dayjs(v).format('YYYY-MM-DD') : v,
              };
            })
            .filter((r) => r.changeType)
            .value();
        })
        .flattenDeep()
        .value();
    }
  }, [selectedProjects, unselectBudgets, tableDataChangeCache]);

  const handleConfirmModal = () => {
    setConfirmUpdateModel(true);
    setConfirmSelectIds(confirmTableData.map((c) => `${c.id}-${c.changeType}`));
  };

  const handleConfirmTableSelect = (item: any, selected: boolean) => {
    if (selected) {
      setConfirmSelectIds([...confirmSelectIds, `${item.id}-${item.changeType}`]);
      return;
    }
    setConfirmSelectIds(confirmSelectIds.filter((c) => c !== `${item.id}-${item.changeType}`));
  };

  const handleCTableSelectAll = (selected: boolean, selectedRows: any[]) => {
    if (selected) {
      setConfirmSelectIds(selectedRows.map((s) => `${s.id}-${s.changeType}`));
      return;
    }
    setConfirmSelectIds([]);
  };

  const handleConfirmModalOK = async () => {
    await handleDemandUpdate();
  };

  const handleConfirmModalCancel = () => {
    setConfirmUpdateModel(false);
    setConfirmSelectIds([]);
  };

  const handleDemandUpdate = async () => {
    const demandProjects = confirmTableData.filter((c) => confirmSelectIds.includes(`${c.id}-${c.changeType}`));
    const update = demandProjects
      .map((dp) => {
        const type = tableDataChangeCache[dp.id]?.type === 4 ? 'Standalone' : 'UnStandalone';
        const grossMarginKey = StandaloneKeyMapping.GROSS_MARGIN[type];
        const orderAmountKey = StandaloneKeyMapping.ORDER_AMOUNT[type];
        let source: any = {};
        switch (dp.changeType) {
          case 'GROSS_MARGIN_CHANGE':
            source[grossMarginKey] = dp.changeAfter;
            break;
          case 'ORDER_AMOUNT_CHANGE':
            source[orderAmountKey] = dp.changeAfter;
            break;
          case 'DATE_CHANGE':
            source.orderScheduledDate = dp.changeAfter;
            break;
          case 'BUDGET_CHANGE':
            source.budgetCategoryCustom = dp.changeAfter;
            break;
        }
        return {
          id: dp.id,
          type,
          source,
        };
      })
      .reduce((prev, cur) => {
        const exist = prev.find((p) => p.id === cur.id);
        if (exist) {
          exist.source = {
            ...exist.source,
            ...cur.source,
          };
        } else {
          prev.push(cur);
        }
        return prev;
      }, []);
    if (useCase === 1 && changeValidationFunc(update)) {
      return;
    }
    setConfirmUpdateModel(false);
    setConfirmSelectIds([]);
    setTableLoading(true);
    await APIList.updateProjectForCase()
      .post({
        snapshot,
        snapshotVersion,
        update,
        useCase,
      })
      .then((updateStr) => {
        if (updateStr === 'SUCCESS') {
          setKpiLoadingStatus(true);
          APIList.budgetCategoryUpdate()
            .post({
              mode: '10',
              snapshot,
              snapshotVersion,
            })
            .then((isUpdateSuccess) => {
              if (isUpdateSuccess) {
                message.success('予算カテゴリの意思入れ値が正常に保存されました。');
                getChartData();
                initializeTableData();
              }
            })
            .catch((err) => {
              console.error(err);
              message.error('budget category update error');
            })
            .finally(() => {
              setTableLoading(false);
              setKpiLoadingStatus(false);
            });
        }
      })
      .catch((err) => {
        setTableLoading(false);
        setKpiLoadingStatus(false);
        console.error(err);
        message.error('update project info error');
      });
  };

  const changeValidationFunc = (source: any[]): boolean => {
    const updated = cloneDeep(source);
    const projectKeyByProjectId = keyBy(tableDataNeedFilter, 'projectId');
    const subProjectById = keyBy(sbTableData, 'id');
    const subProjectByProjectId = groupBy(sbTableData, 'projectId');
    const updatedByProject = chain(updated)
      .filter((item) => has(item.source, 'budgetCategoryCustom'))
      .map((item) => ({
        id: item.id,
        projectId: get(subProjectById, item.id)?.projectId,
        budgetCategoryCustom: item.source.budgetCategoryCustom,
      }))
      .groupBy('projectId')
      .mapValues((item) => keyBy(item, 'id'))
      .value();
    for (const key in updatedByProject) {
      const pInfo = projectKeyByProjectId[key];
      if (pInfo.budgetCategoryCustom === 'Budget') {
        const changed = updatedByProject[key];
        const subInfo = subProjectByProjectId[key];
        const category = map(subInfo, (i) => get(changed, [i.id, 'budgetCategoryCustom'], i.budgetCategoryCustom));
        if (!includes(category, 'Budget')) {
          message.error('PカテゴリがBudgetの場合、BudgetのSBを0個にする操作はできません。');
          return true;
        }
      }
    }
    return false;
  };

  const hasNotValueChanged = useMemo(() => {
    return unselectBudgets.length === 0 && !selectedProjects.some((s) => ['IF', 'Others'].includes(s.budgetCategoryCustom));
  }, [selectedProjects, unselectBudgets]);
  usePageLeaveConfirm(!hasNotValueChanged, canLeave, setCanLeave, () => confirm('Are you sure leaving this page without save?'));

  useEffect(() => {
    return () => removeOrgKpiData();
  }, []);

  /** table expand change */
  const [expandedRowKeys, setExpandedRows] = useState([]);
  const onExpandChange = async (projectId: string, expanded: boolean, record: ResourceRegulationTableItem) => {
    if (!expanded) {
      setExpandedRows((pre: any) => filter(pre, (i) => i !== projectId));
      return;
    }
    setExpandedRows((pre) => [...pre, projectId]);
  };
  // /** with memo collapsed component */
  // const [memoCollapsed, setMemoCollapsed] = useState(true);
  // const [memoInfo, setMemoInfo] = useState({
  //   projectId: null,
  //   projectName: '',
  // });
  // const [searchData, setSearchData] = useState({
  //   memoKeyWord: '',
  //   creator: [],
  //   createdAtStart: '',
  //   createdAtEnd: '',
  //   memoTag: [],
  // });
  // useEffect(() => {
  //   initializeTableData();
  // }, [searchData]);

  const clearAllOfChanged = () => {
    setDateTransferProjects({ p: [], sb: [] });
    setProfitTransferProjects({ p: [], sb: [] });
    setUnselectBudgets([]);

    if (useCase === 0) {
      setSelectedProjects(
        tableDataNeedFilter.filter(
          (d) =>
            d.budgetCategoryCustom === 'Awarded' ||
            (d.budgetCategoryCustom === 'Budget' && ![].map((u) => u.projectId).includes(d.projectId)) ||
            selectedProjects.map((s) => s.projectId).includes(d.projectId)
        )
      );
    } else if (useCase === 1) {
      setSelectedProjects(
        chain(sbTableData)
          .filter((d) => {
            return (
              d.budgetCategoryCustom === 'Awarded' ||
              (d.budgetCategoryCustom === 'Budget' && ![].map((u) => u.subProjectId).includes(d.subProjectId)) ||
              selectedProjects.map((s) => s.subProjectId).includes(d.subProjectId)
            );
          })
          .map((i) => ({ ...i, projectId: i.subProjectId }))
          .value()
      );
    }
    setTableDataChangeCache({});
  };

  const showUseCaseChangeConfirm = (v: number, callBack: (value: any) => void, type: 1 | 2) => {
    const { confirm } = Modal;
    if (type === 2) {
      if (includes([1, 2, 3], accountingPerspective) && includes([1, 2, 3], v)) {
        callBack?.(v);
        return;
      }
    }
    if (isEmpty(confirmTableData) && isEmpty(tableDataChangeCache)) {
      callBack?.(v);
      return;
    }
    confirm({
      title: 'Confirm Reminder',
      content: 'This will clear all edited items !',
      icon: <ExclamationCircleFilled />,
      onOk() {
        clearAllOfChanged();
        callBack?.(v);
      },
      cancelText: 'Cancel',
    });
  };

  const getExportInfoFunc = () => {
    const projectData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method ? tData.filter((t) => method(t, filtersData[cur], cur, filtersData)) : tData;
      },
      [...listNeedRender]
    );
    const projectIds = map(projectData, 'projectId');
    const filterForSub = cloneDeep(filtersData);
    delete filterForSub.accountingYear;
    const accountingYear = filtersData.accountingYear;
    if (accountingPerspective === 4) {
      set(filterForSub, 'fiscalYearStandalone', accountingYear);
    } else {
      set(filterForSub, 'fiscalYearConsolidated', accountingYear);
    }
    const subProjectData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method ? tData.filter((t) => method(t, filterForSub[cur], cur, filterForSub)) : tData;
      },
      [...sbTableData]
    );
    const subProjectIds = map(subProjectData, 'subProjectId');
    return { projectIds, subProjectIds };
  };

  return (
    <ProjectSetupContext.Provider
      value={{
        handleTableChange,
        setIsModalOpen,
        setMemoCollapsed,
        setMemoInfo,
        setMenuCollapsed,
        setFilterCollapsed,
        setFormationModalOpen,
        setCurTableItem,
        optData,
        setCurModalItem,
        calcFeedActive,
        setCachedFormation,
        filtersData,
        accountingPerspective,
        imbalancedMhOpt,
        priorityMatchingOpt,
      }}
    >
      <div className="dashboard project-setup resource-regulation" style={{ height: 'calc(100vh - 85px)' }}>
        <div
          style={{
            // marginRight: filterCollapsed ? 0 : drawerOpts.maxWidth,
            marginRight: filterCollapsed && memoCollapsed ? 0 : 320,
          }}
        >
          <Row justify="space-between" className="operation-container" style={{ backgroundColor: 'white', padding: '0 16px' }}>
            <Col style={{ padding: '5px 0' }}>
              <Space>
                {`${t('aipcmcty.page.resSimulation.useCase')}:`}
                <Select
                  style={{ width: 200 }}
                  value={useCase}
                  options={useCaseOpts}
                  onChange={(v) => {
                    showUseCaseChangeConfirm(v, handleUseCaseChange, 1);
                  }}
                />
                {`${t('aipcmcty.page.accountingPerspective')}:`}
                <Select
                  style={{ width: 200 }}
                  value={accountingPerspective}
                  options={accountingPerspectiveOpt}
                  onChange={(v) => {
                    showUseCaseChangeConfirm(v, handleAccountPerspectiveChange, 2);
                  }}
                />
                TGC:
                <Select
                  allowClear
                  style={{ width: 180 }}
                  value={filtersData.tgc}
                  mode="multiple"
                  maxTagCount="responsive"
                  options={optData?.tgc}
                  onChange={(vs) => {
                    handleFilterChange('tgc', vs);
                  }}
                />
                本部:
                <Tooltip title="会計観点: Toyo-J連結のみ指定可">
                  <Select
                    allowClear
                    style={{ width: 200 }}
                    value={filtersData.department}
                    mode="multiple"
                    maxTagCount="responsive"
                    disabled={accountingPerspective !== 3}
                    options={departmentOpts}
                    onChange={(vs) => {
                      handleFilterChange('department', vs);
                    }}
                  />
                </Tooltip>
                {/* DX:
                <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={dxOn} onChange={(e) => setDxOn(e)} /> */}
              </Space>
            </Col>
            <Col style={{ padding: '5px 0' }}>
              <Space>
                <Button
                  onClick={() => {
                    setMenuCollapsed(filterCollapsed);
                    setMemoCollapsed(true);
                    setFilterCollapsed(!filterCollapsed);
                  }}
                >
                  <BarsOutlined />
                </Button>
              </Space>
            </Col>
          </Row>
          <ChartTableLayout viewMode={viewMode}>
            {kpiMapping ? (
              <ChartTableLayout.Chart>
                <Row className="chart-group-container">
                  {kpiOrder.map((kpi) => (
                    <Col span={kpi === 33 ? 10 : 7} key={`chartTable-${kpi}`}>
                      <Card style={{ height: 342 }} className="chart-card">
                        {kpiMapping[kpi]}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </ChartTableLayout.Chart>
            ) : (
              <></>
            )}
            <ChartTableLayout.Table>
              <ResourceRegulationTable
                isDxMode={dxOn}
                data={filteredTableData}
                loading={tableLoading}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                tableColsToShow={tableColsToShow}
                needDisable={tableCanOperation}
                selectedProjects={selectedProjects}
                onSelected={handleTableRowSelected}
                demandMonth={filtersData.demandYearMonth}
                selectedDivDis={filtersData.mhDepartment as [string, string]}
                onDemandUpdate={handleConfirmModal}
                consolidated={consolidated}
                onExpandChange={onExpandChange}
                viewMode={viewMode}
                expandedRowKeys={expandedRowKeys}
                accountingPerspective={accountingPerspective}
                useCase={useCase}
                filtersData={filtersData}
                memoFilter={searchData}
                departmentOpts={departmentOpts}
                useCaseOpts={useCaseOpts}
                accountingPerspectiveOpt={accountingPerspectiveOpt}
                getExportInfoFunc={getExportInfoFunc}
              />
            </ChartTableLayout.Table>
          </ChartTableLayout>
        </div>
        <DrawerContainer {...drawerOpts} collapsed={filterCollapsed}>
          {!filterCollapsed && <Tabs type="card" size="small" style={{ height: '100%' }} items={tabs} />}
        </DrawerContainer>
        <DrawerContainer
          {...drawerOpts}
          collapsed={memoCollapsed}
          closeBtnFunc={() => {
            setMenuCollapsed(false);
            setMemoCollapsed(true);
          }}
        >
          <MemoContainer
            collapsed={memoCollapsed}
            snapshot={snapshot}
            snapshotVersion={snapshotVersion}
            projectId={memoInfo.projectId}
            projectName={memoInfo.projectName}
            searchData={searchData}
          />
        </DrawerContainer>
      </div>
      <Modal
        className="aipcmc"
        title={`${curModalItem?.projectId} ${curModalItem?.projectName} ${t('aipcmcty.page.projectDemandSupplyList')}`}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setCurModalItem(null);
        }}
        footer={null}
        width="90%"
        styles={{
          body: {
            height: document.body.clientHeight * 0.8,
          },
        }}
        maskClosable={false}
        centered
      >
        <ProjectSetupDetail useAuth={true} project={curModalItem} />
      </Modal>
      <Modal
        className="formation-table"
        title="Formation Table"
        open={isFormationModalOpen}
        okText={t('aipcmcty.page.save')}
        cancelText={t('aipcmcty.page.cancel')}
        onCancel={() => {
          setFormationModalOpen(false);
          setCurTableItem(null);
          setCachedFormation(null);
        }}
        onOk={() => {
          if (!tableCanOperation) {
            setFormationModalOpen(false);
            setCurTableItem(null);
            setCachedFormation(null);
            return;
          }
          const diff = Object.keys(cachedFormation).reduce((acc, cur) => {
            if (cachedFormation[cur] !== curTableItem[cur]) {
              acc.push({
                projectId: curTableItem.projectId,
                field: cur,
                type: 'FORMATION',
                before: cachedFormation[cur],
                after: curTableItem[cur],
                snapshot,
                snapshotVersion,
                createdBy: user,
                projectUuid: curTableItem.id,
                hasEffimate: curTableItem.demandForecastType?.includes('effimate'),
              });
            }
            return acc;
          }, [] as RecordItem[]);
          setFormationTableLoading(true);
          APIList.demandForecast()
            .put({ ...curTableItem, snapshot, snapshotVersion })
            .then(() => {
              const itemIndex = tableDataNeedFilter.findIndex((t) => t.id === curTableItem.id);
              setTableDataNeedFilter([
                ...tableDataNeedFilter.slice(0, itemIndex),
                curTableItem,
                ...tableDataNeedFilter.slice(itemIndex + 1),
              ]);
              setFormationTableLoading(false);
              setFormationModalOpen(false);
              setCurTableItem(null);
              APIList.postOperationHistory().post({ histories: diff });
              setCachedFormation(null);
              message.success(t('aipcmcty.page.updateFormationProjectId').replace('${curTableItem.projectId}', curTableItem.projectId));
              demandForecastAction(curTableItem);
            })
            .finally(() => {
              setFormationTableLoading(false);
            });
        }}
        width="50%"
        maskClosable={false}
      >
        <div style={{ padding: '0 12px 12px' }}>
          <b>{`${t('aipcmcty.page.projectCaseId')}: `}</b>
          {curTableItem?.projectId}
        </div>
        <div style={{ padding: '0 12px 12px' }}>
          <b>{`${t('aipcmcty.page.projectCaseName')}:`}</b> {curTableItem?.projectName}
        </div>
        <Table
          className="project-setup-form"
          columns={formationTableCols}
          dataSource={formationTableData}
          rowKey="index"
          pagination={false}
          size="small"
          loading={formationTableLoading}
        />
      </Modal>
      <Modal
        title={t('aipcmcty.page.resSimulation.confirmUpdateProject')}
        open={confirmUpdateModal}
        onOk={handleConfirmModalOK}
        onCancel={handleConfirmModalCancel}
        width="80%"
        wrapClassName="res-sim-modal"
        okButtonProps={{
          disabled: !confirmSelectIds.length,
        }}
      >
        <Table
          className="res-sim-table"
          dataSource={confirmTableData}
          columns={confirmTableColumns}
          size="small"
          pagination={false}
          rowKey={(row) => `${row.id}-${row.changeType}`}
          rowSelection={{
            selectedRowKeys: confirmSelectIds,
            onSelect: handleConfirmTableSelect,
            onSelectAll: handleCTableSelectAll,
          }}
          rowClassName={(record) => {
            if (confirmSelectIds.includes(`${record.id}-${record.changeType}`)) {
              return 'light-selected';
            }
            return '';
          }}
        />
      </Modal>
    </ProjectSetupContext.Provider>
  );
};

export default ResourceRegulation;
