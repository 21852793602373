import React from 'react';
import { Table, Progress } from 'antd';

const formatAmount = (value) => Math.round(value / 1000000);

const formatPercentage = (value) => (value * 100).toFixed(1);

const getCellStyle = () => ({
  style: {
    borderRight: '1px solid #888',
  },
});
const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

const renderProgressCell = (text) => {
  if (text === null || text === undefined) {
    return <div style={{ textAlign: 'center' }}>-</div>;
  }
  const progress = formatPercentage(text);
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Progress
        percent={Number(progress)}
        size="small"
        showInfo={false}
        strokeColor="rgb(26, 79, 153)"
        strokeWidth={18}
        trailColor="#bbb"
        style={{ borderRadius: 0 }}
      />
      <span
        style={{
          position: 'absolute',
          left: '50%',
          top: '40%',
          transform: 'translate(-50%, -50%)',
          fontSize: '14px',
          color: '#fff',
        }}
      >
        {progress}%
      </span>
    </div>
  );
};

const columns = [
  {
    title: '単位：百万円',
    children: [
      {
        title: 'ビジネスユニット',
        dataIndex: 'category',
        key: 'category',
        width: 250,
        render: (text, record) => {
          return (
            <div>
              {record.no}.{text}
            </div>
          );
        },
      },
    ],
  },
  {
    title: '受注金額',
    // onHeaderCell: getCellStyle,
    children: [
      {
        title: '進捗率',
        dataIndex: 'orderAmountProgressActual',
        key: 'orderAmountProgressActual',
        align: 'center',
        render: renderProgressCell,
      },
      {
        title: '目標',
        dataIndex: 'orderAmountGoal',
        key: 'orderAmountGoal',
        align: 'center',
        render: (text) => {
          if (text === null || text === undefined) {
            return <div style={{ textAlign: 'right' }}>-</div>;
          }
          return <div style={{ textAlign: 'right' }}>{fixed(formatAmount(text)).toLocaleString()}</div>;
        },
      },
      {
        title: '実績',
        dataIndex: 'orderAmountActual',
        key: 'orderAmountActual',
        align: 'center',
        render: (text) => <div style={{ textAlign: 'right' }}>{fixed(formatAmount(text)).toLocaleString()}</div>,
      },
      {
        title: '見込み',
        dataIndex: 'orderAmountForecast',
        key: 'orderAmountForecast',
        align: 'center',
        // onHeaderCell: getCellStyle,
        // onCell: getCellStyle,
        render: (text) => <div style={{ textAlign: 'right' }}>{fixed(formatAmount(text)).toLocaleString()}</div>,
      },
    ],
  },
  {
    title: '受注粗利',
    children: [
      {
        title: '進捗率',
        dataIndex: 'orderGrossProfitProgressActual',
        key: 'orderGrossProfitProgressActual',
        align: 'center',

        render: renderProgressCell,
      },
      {
        title: '目標',
        dataIndex: 'orderGrossProfitGoal',
        align: 'center',
        key: 'orderGrossProfitGoal',
        render: (text) => {
          if (text === null || text === undefined) {
            return <div style={{ textAlign: 'right' }}>-</div>;
          }
          return <div style={{ textAlign: 'right' }}>{fixed(formatAmount(text)).toLocaleString()}</div>;
        },
      },
      {
        title: '実績',
        dataIndex: 'orderGrossProfitActual',
        align: 'center',
        key: 'orderGrossProfitActual',
        render: (text) => <div style={{ textAlign: 'right' }}>{fixed(formatAmount(text)).toLocaleString()}</div>,
      },
      {
        title: '見込み',
        dataIndex: 'orderGrossProfitForecast',
        align: 'center',
        key: 'orderGrossProfitForecast',
        render: (text) => <div style={{ textAlign: 'right' }}>{fixed(formatAmount(text)).toLocaleString()}</div>,
      },
      {
        title: '粗利率',
        dataIndex: 'grossMarginActual',
        align: 'center',
        key: 'grossMarginActual',
        render: (text) => <div style={{ textAlign: 'right' }}>{formatPercentage(text)}</div>,
      },
    ],
  },
];

const MonthlyBusinessReportTable = ({ data, loading }) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      size="small"
      loading={loading}
      bordered={true}
      scroll={{ x: 'max-content' }}
      style={{ borderCollapse: 'collapse' }}
    />
  );
};

export default MonthlyBusinessReportTable;
