/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-shadow */
import {
  Button,
  Modal,
  Space,
  message,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Slider,
  Tabs,
  AutoComplete,
  Input,
  Tag,
  Tooltip,
  Divider,
  Card,
  Cascader,
  Table,
} from 'antd';
import React, { useCallback, useContext, useEffect, useState, useMemo, useRef } from 'react';
import { BarsOutlined } from '@ant-design/icons';
import { cloneDeep, debounce, filter, groupBy, includes, map, omit, set, size } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import APIList from '../../http/ApiList';
import DrawerContainer, { DrawerProperties } from '../../components/widget/drawer-container';
import { localeMapping } from '../../consts/locale.mapping';
import CustomerScrollBar from '../../components/widget/customer-scrollbar';
import { convertToJSON } from '../../utils/commonUtil';
import ResourceSimulationChart from '../../components/charts/resource-simulation.chart';
import TurnoverResource from '../../components/charts/turnover-resource.chart';
import usePageLeaveConfirm from '../../hooks/usePageLeaveConfirm';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import { ChartTableLayout } from '@meteor/frontend-core';
import ProjectSetupDetail from '../../components/project-setup-detail';
import TurnoverFlowChart from '../../components/charts/turnover-flow.chart';
import ResourceSimulationTable, { ResourceSimulationTableItem } from '../../components/tables/resource-simulation.table';
import { useAccountPerspective } from '../../hooks/useAccountPerspective';
import dayjs from 'dayjs';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import LayoutSwitcher from '../../components/common/layout/layout-switcher';
import useAuth from '../../hooks/useAuth';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// generate demand year month options
const genYearMonths = () => {
  const currentYear = Number(dayjs().subtract(1, 'year').format('YYYY'));
  return Array.from({ length: 5 }, (x, i) => (currentYear + i).toString()).reduce((prev, cur) => {
    prev.push(
      ...Array.from({ length: 12 }, (_, i) => {
        if (i + 1 < 10) {
          return `${cur}-0${i + 1}`;
        }
        return `${cur}-${i + 1}`;
      })
    );
    return prev;
  }, [] as string[]);
};

/**
 * 根据权限过滤默认TGC
 * @param tgc 有权限的TGC
 * @param target 目标设定的TGC
 */
const handleTgcType = (tgc: any[], target: string[], useDefault?: boolean, useIgnore?: boolean) => {
  const mapping = map(tgc, 'value');
  const f = filter(target, (v) => includes(mapping, v));
  if (useDefault && !size(f)) {
    if (useIgnore) {
      return map(
        filter(tgc, (item: any) => item.attribute1 !== 'equity'),
        'value'
      );
    }
    return mapping;
  }
  return f;
};

// generate fiscal month range
// sample: FY24 Apr ~ Dec, FY25 Jan ~ Mar
export const orderMonthRange = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

// relative item change maps
export const tableChangeMap = {
  orderAmount: (item) => {
    const cachedGrossProfit = item.grossProfit;
    item.grossProfit = Math.round(item.orderAmount * item.grossMargin);
    return cachedGrossProfit;
  },
  grossMargin: (item) => {
    const cachedGrossProfit = item.grossProfit;
    item.grossProfit = Math.round(item.orderAmount * item.grossMargin);
    return cachedGrossProfit;
  },
  orderScheduledDate: (item, offsetMonths) => {
    const startDate = dayjs(item.orderScheduledDate);
    const diffMonths = startDate.diff(dayjs(offsetMonths), 'month');
    item.orderScheduledDate = startDate.format('YYYY-MM-DD');
    item.projectStartDate = dayjs(item.projectStartDateDefault).add(diffMonths, 'month').format('YYYY-MM-DD');
    item.projectEndDate = dayjs(item.projectEndDateDefault).add(diffMonths, 'month').format('YYYY-MM-DD');
  },
  duration: (item) => {
    if (item.duration <= 0) {
      item.duration = 1;
    }
    item.projectEndDate = dayjs(item.projectStartDate).add(item.duration, 'months').format('YYYY-MM-DD');
  },
  phase: (item, opts = []) => {
    const filterStr = item.certainty + item.phase;
    item.won = Number(opts.find((w) => w.label === filterStr).value);
  },
  reset: (item) => {
    const isOrderReset = item.orderAmount !== item.orderAmountDefault;
    const isMarginReset = item.grossMargin !== item.grossMarginDefault;
    item.orderAmount = item.orderAmountDefault;
    item.grossMargin = item.grossMarginDefault;
    item.grossProfit = item.grossProfitDefault;
    item.projectStartDate = item.projectStartDateDefault;
    item.projectEndDate = item.projectEndDateDefault;
    item.orderScheduledDate = item.orderScheduledDateDefault;
    return { isOrderReset, isMarginReset };
  },
};

// filter rules for table fields
const filterMethods = {
  search: (item, v = '') => item.subProjectName?.includes(v) || item.subProjectId.includes(v) || item.projectId.includes(v),
  accountingYear: (item, v) => !v?.length || v.some((i) => i.includes(item.accountingYear)),
  fiscalYearStandalone: (item, v) => !v?.length || v.some((i) => i.includes(item.fiscalYearStandalone)),
  fiscalYearConsolidated: (item, v) => !v?.length || v.some((i) => i.includes(item.fiscalYearConsolidated)),
  // tgc: (item, v) => !v?.length || v.includes(item.tgc),
  japanInvolved: (item, v) => {
    if (v) {
      return item.japanInvolved === 'Toyo-J関与';
    }
    return true;
  },
  scope: (item, v) => !v?.length || v.includes(item.scope),
  isExisted: (item, v) => (v ? item.isExisted === v : true),
  isEpc: (item, v) => (v ? item.isEpc === v : true),
  businessType: (item, v) => !v?.length || v.includes(item.businessType),
  goodsType: (item, v) => !v?.length || v.includes(item.goodsType),
  budgetCategoryCustom: (item, v) => !v?.length || v.includes(item.budgetCategoryCustom ?? item.budgetCategory),
  priorityMatching: (item, v, k) => [undefined, null].includes(v) || item[k] === v,
  priorityDefault: (item, v, k) => !v || (item[k] >= v[0] && item[k] <= v[1]),
  orderAmount: (item, v, k) => {
    const minLimit = 5000000000;
    const maxLimit = 60000000000;
    const sortedVString = v?.sort().join('');
    switch (sortedVString) {
      case 'lt50':
        return item[k] < minLimit;
      case 'gt600':
        return item[k] > maxLimit;
      case '50_600':
        return item[k] >= minLimit && item[k] <= maxLimit;
      case '50_600gt600':
        return item[k] >= minLimit;
      case '50_600lt50':
        return item[k] <= maxLimit;
      case 'gt600lt50':
        return item[k] > maxLimit || item[k] < minLimit;
      default:
        return true;
    }
  },
  // grossMargin: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
  imbalancedMh: (item, v, k) => {
    switch (v) {
      case '0':
        return item[k] === 0;
      case '1':
        return item[k] > 0;
      default:
        return true;
    }
  },
  won: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
  phase: (item, v) => !v?.length || v.includes(item.phase),
  certainty: (item, v) => !v?.length || v.includes(item.certainty),
  projectStartDate: (item, v) => {
    if (!v) {
      return true;
    }
    const { projectStartDate } = item;
    return dayjs(projectStartDate).isSameOrAfter(dayjs(v));
  },
  projectEndDate: (item, v) => {
    if (!v) {
      return true;
    }
    const { projectEndDate } = item;
    return dayjs(projectEndDate).isSameOrBefore(dayjs(v));
  },
  dxApplicable: (item, v, k) => !v || item[k] === Boolean(Number(v)),
  orderScheduledDate: (item, v, k) => !v || item[k].startsWith(v),
  isForPpReport: (item, v, k) => [undefined, null].includes(v) || item[k] === v,
  department: (item, v, k) => !v?.length || v.includes(item[k]),
};

// TODO: get items from database
export const departmentOpts = [
  { label: 'CN本部', value: 'CN本部', key: 'CN本部' },
  { label: '国内営業', value: '国内', key: '国内' },
  { label: 'OFS本部', value: 'OFS', key: 'OFS' },
  { label: '海外営業', value: '海外', key: '海外' },
  { label: 'その他：過去分', value: 'その他: 過去分', key: 'その他: 過去分' },
];

const initialKpiOrder = [33, 37, 36];

const ResourceSimulation: React.FC = () => {
  const { isFinancialInfoVisible } = useAuth();
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('chart-table');
  // about drawer
  const { fiscalQuarter, periodSwitch, setMenuCollapsed, color, locale, canLeave, setCanLeave } = useContext(AppContext);

  const { snapshot, snapshotVersion, tableCanOperation, getOrgKpiData } = useContext(AipcmctyContext);

  const [filterCollapsed, setFilterCollapsed] = useState(true);
  const drawerOpts: Omit<DrawerProperties, 'collapsed' | 'children'> = {
    maxWidth: 320,
    minWidth: 0,
  };
  // about drawer form
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  // initialize the table data list and options
  const initializeTableData = async () => {
    const [data, options] = await Promise.all([
      APIList.getSimSubProjects().post({
        snapshot,
        snapshotVersion,
        budgetRange: ['Awarded', 'Budget', 'IF', 'Others'],
      }) as Promise<ResourceSimulationTableItem[]>,
      APIList.getCmcOptions().get({
        category: 'all',
        snapshot,
        snapshotVersion,
      }) as Promise<any>,
    ]);
    setTableDataNeedFilter(data);
    tableDataCached.current = cloneDeep(data);
    setUnselectBudgets([]);
    setSelectedProjects([]);
    setDateTransferProjects([]);
    setProfitTransferProjects([]);
    setResSimCacheData({});

    // 日本関与有無
    const japanInvolved = [
      {
        key: 1,
        value: 1,
        label: t('aipcmcty.page.have'),
      },
      {
        key: 0,
        value: 0,
        label: t('aipcmcty.page.no'),
      },
    ];

    // 会計対象
    const consolidated = [
      {
        key: t('aipcmcty.page.consolidated'),
        value: 1,
        label: t('aipcmcty.page.consolidated'),
      },
      {
        key: t('aipcmcty.page.single'),
        value: 0,
        label: t('aipcmcty.page.single'),
      },
    ];

    const initialFiltersData = { ...initialFormData.current, tgc: handleTgcType(options.tgc, ['Toyo-J', 'Toyo-I']) };
    const sliderProps = data.reduce(
      (prev, cur, index) => {
        prev.priorityDefault.push(cur.priorityDefault ?? index + 1);
        prev.grossMargin.push(cur.grossMargin * 100);
        prev.won.push(cur.won * 100);
        return prev;
      },
      {
        priorityDefault: [],
        grossMargin: [],
        won: [],
      }
    );
    const sliderOpts = Object.keys(sliderProps).reduce((prev, k) => {
      let min = 0;
      let max = 100;
      if (k === 'priorityDefault') {
        const range = sliderProps[k].length > 0 ? sliderProps[k] : [1, 100];
        min = Math.round(Math.min(...range));
        max = Math.round(Math.max(...range));
      }
      const stepLen = max.toFixed(0).length - 2;
      const step = ['won', 'grossMargin'].includes(k) ? 1 : Number(1 + Array.from({ length: stepLen }, () => 0).join(''));
      const markMin = ['won', 'grossMargin'].includes(k) ? `${min}%` : min;
      const markMax = ['won', 'grossMargin'].includes(k) ? `${max}%` : max;
      initialFiltersData[k] = [min, max];
      prev[`${k}SliderOpts`] = {
        min,
        max,
        range: true,
        step,
        marks: {
          [min]: markMin,
          [max]: markMax,
        },
      };
      return prev;
    }, {});
    initialFiltersData.mhDepartment = ['All', 'All'];
    initialFiltersData.demandYearMonth = null;
    form.setFieldsValue(initialFiltersData);
    setOptOrgData({
      ...options,
      japanInvolved,
      consolidated,
      ...sliderOpts,
    });
  };

  const [pageSize, setPageSize] = useState(50);
  const handlePageSizeChange = (pZ: number) => {
    setPageSize(pZ);
  };
  const [tableDataNeedFilter, setTableDataNeedFilter] = useState([]);
  const [optOrgData, setOptOrgData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const tableDataCached = useRef([]);

  // header filter
  // 会計観点
  const { perspectiveOptions, accountingPerspective, setAccountPerspective, perspectiveState, filterTgcOptions, selectedTgc } =
    useAccountPerspective(optOrgData?.tgc ?? [], 2);
  // PP連絡報告
  const useCaseOpts = [
    /** PP連絡会議 */
    t('aipcmcty.page.resSimulation.ppContact'),
  ].map((u, i) => ({
    label: u,
    value: i,
  }));
  const calculateFiscalYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const fiscalYear = month < 4 ? year - 1 : year;
    const fiscalYearShort = `FY${fiscalYear.toString().slice(-2)}`;
    return fiscalYearShort;
  };
  const convertFiscalYearToNumber = (fiscalYear) => {
    return Number(`20${fiscalYear.slice(2)}`);
  };
  const defaultFiscalYear = calculateFiscalYear();
  const defaultFiscalYearNumber = convertFiscalYearToNumber(defaultFiscalYear);
  const [orderFiscalYear, setOrderFiscalYear] = useState([defaultFiscalYearNumber]);
  const orderYearMonths = orderMonthRange.map((m) => `FY${orderFiscalYear[0].toString().slice(-2)}-${m}`);
  const orderYMOpts = orderYearMonths.map((ym) => {
    const [year, month] = ym.replace('FY', '20').split('-');
    let numYear = Number(year);
    if (Number(month) <= 3) {
      numYear += 1;
    }
    return {
      label: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
      key: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
      value: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
    };
  });
  const handleOrderFYChange = (e: number[]) => {
    setOrderFiscalYear(e);
    const accountingYears = form.getFieldValue('accountingYear');
    const acYCur = `FY${e[0].toString().slice(-2)}`;
    const formChanges = {
      orderScheduledDate: null,
    } as any;
    if (!accountingYears.includes(acYCur)) {
      const accountingYearsNew = [...accountingYears, acYCur];
      formChanges.accountingYear = accountingYearsNew;
    }
    form.setFieldsValue(formChanges);
  };
  const handleOrderScheduledDateChange = (yearMonth: string) => {
    form.setFieldsValue({ orderScheduledDate: yearMonth });
    setMenuCollapsed(true);
    setFilterCollapsed(false);
  };
  const [useCase, setUseCase] = useState(useCaseOpts[0].value);
  const handleUseCaseChange = (v: (typeof useCaseOpts)[number]['value']) => {
    setUseCase(v);
    switch (v) {
      case 0:
        setAccountPerspective(2);
        setKpiOrder(initialKpiOrder);
        setDepartments([]);
        form.setFieldsValue({
          accountingYear: ['FY24', 'FY25', 'FY26'],
          budgetCategoryCustom: ['Awarded', 'Budget'],
        });
        break;
      default:
        break;
    }
  };
  const [tgc, setTgc] = useState(selectedTgc);
  const handleTgcChange = (tgc: string[]) => {
    setTgc(tgc);
  };
  useEffect(() => {
    if (selectedTgc.length) {
      setTgc(selectedTgc);
    }
  }, [selectedTgc]);
  const [departments, setDepartments] = useState([]);
  const [dxOn, setDxOn] = useState(false);

  const initialFormData = useRef({
    accountingYear: ['FY24', 'FY25', 'FY26'],
    scope: [],
    isExisted: null,
    isEpc: null,
    businessType: [],
    goodsType: [],
    priorityMatching: null,
    priorityDefault: [],
    budgetCategoryCustom: ['Awarded', 'Budget'],
    orderAmount: [],
    // grossMargin: [],
    imbalancedMh: null,
    won: [],
    phase: [],
    certainty: [],
    projectStartDate: null,
    projectEndDate: null,
    dxApplicable: null,
    demandYearMonth: null,
    mhDepartment: ['All', 'All'],
    orderScheduledDate: null,
    isForPpReport: null,
  });

  const demandYearMonthRange = genYearMonths();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [unselectBudgets, setUnselectBudgets] = useState([]);
  const [dateTransferProjects, setDateTransferProjects] = useState([]);
  const [profitTransferProjects, setProfitTransferProjects] = useState([]);
  const [listNeedRender, setListNeedRender] = useState([]);
  const handleTableRowSelected = (record, selected) => {
    setSelectedProjects(
      selected ? selectedProjects.concat(record) : selectedProjects.filter((s) => s.subProjectId !== record.subProjectId)
    );
    if (record.budgetCategoryCustom === 'Budget') {
      if (selected) {
        setUnselectBudgets((prev) => prev.filter((p) => p.subProjectId !== record.subProjectId));
      } else {
        setUnselectBudgets((prev) => [...prev, record]);
      }
    }
  };

  const handleDemandProjects = async (yearMonth: string) => {
    form.setFieldsValue({ demandYearMonth: yearMonth });
    changeRenderList({
      demandYearMonth: yearMonth,
      mhDepartment: form.getFieldValue('mhDepartment'),
    });
  };

  useEffect(() => {
    const getTableData = async () => {
      setTableLoading(true);
      await initializeTableData();
      setTableLoading(false);
    };
    getTableData();
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    setTableDataNeedFilter(
      tableDataNeedFilter.map((t) => {
        return {
          ...t,
          dxApplicable: dxOn ? t.dxAvailable : false,
        };
      })
    );
  }, [dxOn]);

  const handleAccountPerspectiveChange = (v: number) => {
    setAccountPerspective(v);
    if (v !== 3) {
      setDepartments([]);
    }
  };

  // about filter
  const handleFormChange = debounce(async (changedValue) => {
    const key = Object.keys(changedValue)[0];
    if (['demandYearMonth', 'mhDepartment'].includes(key)) {
      const otherKey = ['demandYearMonth', 'mhDepartment'].find((k) => k !== key);
      const opts = {
        ...changedValue,
        [otherKey]: form.getFieldValue(otherKey),
      };
      if (key === 'mhDepartment') {
        await initKpiData([['ResourceSimulationSub']], !!perspectiveState.consolidated, {
          isDXMode: dxOn,
          division: changedValue.mhDepartment[0] === 'All' ? '' : changedValue.mhDepartment[0],
          discipline: changedValue.mhDepartment[1] === 'All' ? '' : changedValue.mhDepartment[1],
          partial: true,
        });
      }
      if (key === 'demandYearMonth') {
        await changeRenderList(opts);
      }
    }
  }, 300);

  const [form] = Form.useForm();
  const formWatch = Form.useWatch((value) => value, { form });
  const mhDepartment = Form.useWatch('mhDepartment', form);
  const demandYearMonth = Form.useWatch('demandYearMonth', form);

  const [imbalancedMhOpt] = useState(
    [
      { label: '需給均衡', value: '0' },
      { label: '需給不足', value: '1' },
    ].map((item) => ({
      ...item,
      label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
    }))
  );

  const ppReportOpts = [
    { label: t('aipcmcty.page.resSimulation.isReportObj'), value: true, key: t('aipcmcty.page.resSimulation.isReportObj') },
    {
      label: t('aipcmcty.page.resSimulation.isNotReportObj'),
      value: false,
      key: t('aipcmcty.page.resSimulation.isNotReportObj'),
    },
  ];

  const [mhDepartmentOpts, setMHDepartmentOpts] = useState([]);
  const budgetCategoryLabel = `${t('aipcmcty.page.resSimulation.budget')}<br />${t('aipcmcty.page.resSimulation.Category')}`;
  const tabs = [
    {
      key: '1',
      label: t('aipcmcty.page.refinement'),
      children: (
        <CustomerScrollBar style={{ height: 'calc(100% - 16px)', padding: '0 10px' }}>
          <Form {...layout} onValuesChange={handleFormChange} form={form} initialValues={initialFormData}>
            <Divider>MH</Divider>
            <Form.Item label={t('aipcmcty.page.resSimulation.demandYearMonth')} name="demandYearMonth">
              <Select allowClear options={demandYearMonthRange.map((ym) => ({ label: ym, key: ym, value: ym }))} />
            </Form.Item>
            <Form.Item label={t('aipcmcty.page.resSimulation.mhDepartment')} name="mhDepartment">
              <Cascader allowClear={false} options={mhDepartmentOpts} />
            </Form.Item>
            <Divider>{t('aipcmcty.page.orderGrossProfit')}</Divider>
            <Form.Item label={t('aipcmcty.page.resSimulation.orderYearMonth')} name="orderScheduledDate">
              <Select allowClear options={orderYMOpts} />
            </Form.Item>
            <Divider>{t('aipcmcty.page.projectDivider')}</Divider>
            <Form.Item name="search" wrapperCol={{ span: 24 }}>
              <AutoComplete
                options={Array.from(new Set(tableDataNeedFilter.map((t) => t.subProjectName)), (x) => ({
                  label: x,
                  value: x,
                }))}
              >
                <Input.Search placeholder={`${t('aipcmcty.page.projectCaseName')} / ${t('aipcmcty.page.projectCaseId')}`} allowClear />
              </AutoComplete>
            </Form.Item>
            <Form.Item label={t('aipcmcty.page.resSimulation.ppReport')} name="isForPpReport">
              <Select allowClear options={ppReportOpts} />
            </Form.Item>
            <Form.Item label={`${t('aipcmcty.page.fiscalYear')}`} name="accountingYear">
              <Select allowClear mode="multiple" maxTagCount={1} options={optOrgData?.accountingYear} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.serviceScope')} className="ellipsis">
                  {t('aipcmcty.page.serviceScope')}
                </div>
              }
              name="scope"
            >
              <Select allowClear mode="multiple" maxTagCount={1} options={optOrgData?.scope} />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`} className="ellipsis">
                  {`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`}
                </div>
              }
              name="isExisted"
            >
              <Select
                allowClear
                options={optOrgData?.isExisted.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.nonEPC')}/EPC`} className="ellipsis">
                  {`${t('aipcmcty.page.nonEPC')}/EPC`}
                </div>
              }
              name="isEpc"
            >
              <Select
                allowClear
                options={optOrgData?.isEpc.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.businessType')} className="ellipsis">
                  {t('aipcmcty.page.businessType')}
                </div>
              }
              name="businessType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optOrgData?.businessType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.productType')} className="ellipsis">
                  {t('aipcmcty.page.productType')}
                </div>
              }
              name="goodsType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optOrgData?.goodsType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.priorityLevel')} className="ellipsis">
                  {t('aipcmcty.page.priorityLevel')}
                </div>
              }
              name="priorityDefault"
            >
              <Slider {...optOrgData?.priorityDefaultSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={budgetCategoryLabel} className="ellipsis" style={{ lineHeight: '1.2' }}>
                  {t('aipcmcty.page.resSimulation.budget')}
                  <br />
                  {t('aipcmcty.page.resSimulation.Category')}
                </div>
              }
              name="budgetCategoryCustom"
            >
              <Select allowClear mode="multiple" options={optOrgData?.budgetCategory} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.orderAmount')} className="ellipsis">
                  {t('aipcmcty.page.orderAmount')}
                </div>
              }
              name="orderAmount"
            >
              <Select
                allowClear
                mode="multiple"
                options={optOrgData?.orderAmount.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
                maxTagCount={1}
              />
            </Form.Item>
            {/* <Form.Item
              label={
                <div title={t('aipcmcty.page.grossProfitRate')} className="ellipsis">
                  {t('aipcmcty.page.grossProfitRate')}
                </div>
              }
              name="grossMargin"
            >
              <Slider {...optOrgData?.grossMarginSliderOpts} />
            </Form.Item> */}
            <Form.Item
              label={
                <div title={t('aipcmcty.page.demandSupplyFilterMH')} className="ellipsis">
                  {t('aipcmcty.page.demandSupplyFilterMH')}
                </div>
              }
              name="imbalancedMh"
            >
              <Select allowClear options={imbalancedMhOpt} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title="Won%" className="ellipsis">
                  Won%
                </div>
              }
              name="won"
            >
              <Slider {...optOrgData?.wonSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.phase')} className="ellipsis">
                  {t('aipcmcty.page.phase')}
                </div>
              }
              name="phase"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optOrgData?.phase.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.accuracy')} className="ellipsis">
                  {t('aipcmcty.page.accuracy')}
                </div>
              }
              name="certainty"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optOrgData?.certainty.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.startDate')} className="ellipsis">
                  {t('aipcmcty.page.startDate')}
                </div>
              }
              name="projectStartDate"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.endDate')} className="ellipsis">
                  {t('aipcmcty.page.endDate')}
                </div>
              }
              name="projectEndDate"
            >
              <DatePicker />
            </Form.Item>
          </Form>
        </CustomerScrollBar>
      ),
    },
  ];

  // about table
  const projectDateTransfer = (item, beforeItem) => {
    const itemIndex = dateTransferProjects.findIndex((d) => d.subProjectId === item.subProjectId);
    let tempItem = {
      ...(itemIndex >= 0 ? dateTransferProjects[itemIndex] : {}),
      changeType: 'DATE_CHANGE',
      projectId: item.projectId,
      subProjectId: item.subProjectId,
      subProjectName: item.subProjectName,
      value: item.projectStartDate,
      beforeValue: beforeItem.projectStartDate,
      scheduledDate: item.orderScheduledDate,
      beforeScheduledDate: beforeItem.orderScheduledDate,
    };
    let tProjects = [...dateTransferProjects];
    tProjects = tProjects.filter((tp) => tp.subProjectId !== item.subProjectId);
    if (tempItem.value !== beforeItem.projectStartDate) {
      tProjects.push(tempItem);
    }
    setDateTransferProjects(tProjects);
  };

  const projectProfitTransfer = (item, beforeItem, elem?, beforeGrossProfit?) => {
    const typeMapping = {
      orderAmount: 'ORDER_AMOUNT',
      grossMargin: 'GROSS_MARGIN',
    };
    setProfitTransferProjects((prev) => {
      let tProjects = prev.filter((tp) => (tp.subProjectId === item.subProjectId ? tp.changeType !== `${typeMapping[elem]}_CHANGE` : true));
      const itemIndex = tProjects.findIndex((d) => d.subProjectId === item.subProjectId && d.changeType === `${typeMapping[elem]}_CHANGE`);
      let tempItem = {
        ...(itemIndex >= 0 ? tProjects[itemIndex] : {}),
        changeType: `${typeMapping[elem]}_CHANGE`,
        projectId: item.projectId,
        subProjectId: item.subProjectId,
        subProjectName: item.subProjectName,
        value: item[elem],
        beforeValue: beforeItem[elem],
        grossProfit: item.grossProfit,
        beforeGrossProfit,
      };
      if (tempItem.value !== beforeItem[elem]) {
        tProjects.push(tempItem);
      }
      return tProjects;
    });
  };

  const handleTableChange = (elem: string, value: any, id: string) => {
    const newTableData = [...tableDataNeedFilter];
    const item = newTableData.find((t) => t.id === id);
    const cachedItem = tableDataCached.current.find((t) => t.id === id);
    const cachedValue = cachedItem[elem];
    item[elem] = value;
    const changeEffect = tableChangeMap[elem];
    if (changeEffect) {
      switch (elem) {
        case 'orderScheduledDate':
          changeEffect(item, cachedValue);
          projectDateTransfer(item, cachedItem);
          break;
        case 'orderAmount':
        case 'grossMargin':
          const cachedProfit = changeEffect(item);
          projectProfitTransfer(item, cachedItem, elem, cachedProfit);
          break;
        case 'reset':
          const { isMarginReset, isOrderReset } = changeEffect(item);
          projectDateTransfer(item, cachedItem);
          if (isMarginReset) {
            projectProfitTransfer(item, cachedItem, 'grossMargin');
          }
          if (isOrderReset) {
            projectProfitTransfer(item, cachedItem, 'orderAmount');
          }
          break;
      }
    }
    setTableDataNeedFilter(newTableData);
  };

  const filteredTableData = useMemo(() => {
    const filtersData = {
      ...form.getFieldsValue(),
      department: departments,
    };
    const newTableData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method ? tData.filter((t) => method(t, filtersData[cur], cur, filtersData)) : tData;
      },
      [...listNeedRender]
    );
    let dxItems = [];
    if (dxOn) {
      const orgMart = getOrgKpiData('GrossProfitResource')?.data?.mart ?? [];
      dxItems = orgMart.filter((o) => o.budgetCategoryCustom.startsWith('DX_'));
    }
    return newTableData
      .map((d, i) => {
        if (i === 0) {
          d.orderAccumulation = d.orderAmount;
          d.profitAccumulation = d.grossProfit;
        } else {
          d.orderAccumulation = d.orderAmount + newTableData[i - 1].orderAccumulation;
          d.profitAccumulation = d.grossProfit + newTableData[i - 1].profitAccumulation;
        }
        if (dxOn) {
          const dxGrossProfit = dxItems.filter((dx) => d.subProjectId === dx.subProjectId).reduce((pr, c) => pr + c.grossProfit, 0);
          d.dxGrossProfit = Number(dxGrossProfit.toFixed(0));
        }
        return { ...d };
      })
      .sort((a, b) => a.priorityDefault - b.priorityDefault);
  }, [formWatch, listNeedRender, departments]);

  const getExportData = (): any[] => {
    const filtersData = {
      ...omit(form.getFieldsValue(), 'accountingYear'),
      department: departments,
    };
    // 会計年度」过滤字段由accounting_year改为fiscal_year_consolidated/fiscal_year_standalone
    const accountingYear = form.getFieldValue('accountingYear');
    if (accountingPerspective === 4) {
      set(filtersData, 'fiscalYearStandalone', accountingYear);
    } else {
      set(filtersData, 'fiscalYearConsolidated', accountingYear);
    }
    const newTableData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method ? tData.filter((t) => method(t, filtersData[cur], cur, filtersData)) : tData;
      },
      [...listNeedRender]
    );
    let dxItems = [];
    if (dxOn) {
      const orgMart = getOrgKpiData('GrossProfitResource')?.data?.mart ?? [];
      dxItems = orgMart.filter((o) => o.budgetCategoryCustom.startsWith('DX_'));
    }
    return newTableData
      .map((d, i) => {
        if (i === 0) {
          d.orderAccumulation = d.orderAmount;
          d.profitAccumulation = d.grossProfit;
        } else {
          d.orderAccumulation = d.orderAmount + newTableData[i - 1].orderAccumulation;
          d.profitAccumulation = d.grossProfit + newTableData[i - 1].profitAccumulation;
        }
        if (dxOn) {
          const dxGrossProfit = dxItems.filter((dx) => d.subProjectId === dx.subProjectId).reduce((pr, c) => pr + c.grossProfit, 0);
          d.dxGrossProfit = Number(dxGrossProfit.toFixed(0));
        }
        return { ...d };
      })
      .sort((a, b) => a.priorityDefault - b.priorityDefault);
  };

  useEffect(() => {
    setSelectedProjects(
      tableDataNeedFilter.filter(
        (d) =>
          d.budgetCategoryCustom === 'Awarded' ||
          (d.budgetCategoryCustom === 'Budget' && !unselectBudgets.map((u) => u.subProjectId).includes(d.subProjectId)) ||
          selectedProjects.map((s) => s.subProjectId).includes(d.subProjectId)
      )
    );
  }, [tableDataNeedFilter]);

  // about modal
  const [curModalItem, setCurModalItem] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // about chart
  const { kpiInfos, initKpiData, getKpiData, setKpiLoadingStatus, filterKpiData, orgKpiInfos, removeOrgKpiData } =
    useContext(AipcmctyContext);
  const [kpiOrder, setKpiOrder] = useState(initialKpiOrder);

  const [resSimCacheData, setResSimCacheData] = useState<any>({});
  useEffect(() => {
    const resSim = getKpiData('ResourceSimulationSub')?.data;
    if (resSim) {
      const newTgc = !tgc?.length ? map(optOrgData?.tgc, 'value') : tgc;
      const { projectDemand, projectIds } = resSim;
      const pids = convertToJSON(projectIds);
      const pds = convertToJSON(projectDemand);
      const ids = pids.filter((p) => !newTgc.length || newTgc.includes(p.tgc));
      const dms = pds.filter((p) => !newTgc.length || newTgc.includes(p.tgc));
      setResSimCacheData({
        projectDemand: dms,
        resSimIds: ids,
      });
    }
  }, [kpiInfos, tgc]);

  const combineGraphIdsAndTgcs = useMemo(() => {
    const resSim = getKpiData('ResourceSimulationSub')?.data;
    const orderGProfit = getKpiData('OrderGrossProfitResource')?.data;
    const gProfit = getKpiData('GrossProfitResource')?.data;
    let resSimData = [];
    let orderGProfitData = [];
    let gProfitData = [];
    if (resSim) {
      const newTgc = !tgc?.length ? map(optOrgData?.tgc, 'value') : tgc;
      const { projectIds } = resSim;
      const pids = convertToJSON(projectIds);
      const filteredData = pids.filter((p) => !newTgc.length || newTgc.includes(p.tgc)).map((p) => p.subProjectId);
      resSimData = filteredData.reduce((pr, id) => {
        const tgcItems = pids.filter((p) => p.subProjectId === id).map((p) => p.tgc);
        pr[id] = tgcItems;
        return pr;
      }, {} as any);
    }
    if (orderGProfit) {
      const { mart } = orderGProfit;
      const orgMart = getOrgKpiData('OrderGrossProfitResource')?.data?.mart ?? [];
      const martIds = mart.map((m) => m.subProjectId);
      const idTgcs = orgMart.reduce((pr, c) => {
        if (pr[c.subProjectId] && !pr[c.subProjectId].includes(c.tgc)) {
          pr[c.subProjectId].push(c.tgc);
        } else {
          pr[c.subProjectId] = [c.tgc];
        }
        return pr;
      }, {} as any);
      orderGProfitData = martIds.reduce((pr, c) => {
        pr[c] = idTgcs[c];
        return pr;
      }, {} as any);
    }
    if (gProfit) {
      const { mart } = gProfit;
      const orgMart = getOrgKpiData('GrossProfitResource')?.data?.mart ?? [];
      const martIds = mart.map((m) => m.subProjectId);
      const idTgcs = orgMart.reduce((pr, c) => {
        if (pr[c.subProjectId] && !pr[c.subProjectId].includes(c.tgc)) {
          pr[c.subProjectId].push(c.tgc);
        } else {
          pr[c.subProjectId] = [c.tgc];
        }
        return pr;
      }, {} as any);
      gProfitData = martIds.reduce((pr, c) => {
        pr[c] = idTgcs[c];
        return pr;
      }, {} as any);
    }
    return Object.keys({
      ...resSimData,
      ...orderGProfitData,
      ...gProfitData,
    }).reduce((pr, id) => {
      const rTgc = resSimData[id] ?? [];
      const oTgc = orderGProfitData[id] ?? [];
      const gTgc = gProfitData[id] ?? [];
      const mergedTgc = Array.from(new Set([...rTgc, ...oTgc, ...gTgc]));
      pr[id] = mergedTgc;
      return pr;
    }, {} as any);
  }, [kpiInfos, tgc]);

  useEffect(() => {
    const { demandYearMonth, mhDepartment } = form.getFieldsValue();
    if (!demandYearMonth && !mhDepartment) {
      return;
    }
    if (!demandYearMonth && mhDepartment[0] === 'All' && mhDepartment[1] === 'All') {
      const combineGraphIds = Object.keys(combineGraphIdsAndTgcs);
      const listRendering = tableDataNeedFilter.filter((t) => combineGraphIds.includes(t.subProjectId));
      listRendering.forEach((l) => {
        const mh = (resSimCacheData.resSimIds || []).filter((r) => r.subProjectId === l.subProjectId).reduce((pr, c) => pr + c.MhPeak, 0);
        const tgc = combineGraphIdsAndTgcs[l.subProjectId] ?? [];
        l.monthDemandMH = Number(mh.toFixed(0));
        l.relatedTgc = tgc.length ? tgc.sort().join(', ') : null;
      });
      setListNeedRender(listRendering);
    }
  }, [combineGraphIdsAndTgcs, mhDepartment, demandYearMonth, tableDataNeedFilter, resSimCacheData]);

  const changeRenderList = async ({ demandYearMonth, mhDepartment }: { demandYearMonth?: string; mhDepartment?: [string, string] }) => {
    if (!resSimCacheData.projectDemand) {
      return;
    }
    const [div, dis] = mhDepartment;
    if (!demandYearMonth && div === 'All' && dis === 'All') {
      return;
    }
    const newTgc = !tgc?.length ? map(optOrgData?.tgc, 'value') : tgc;
    // select year month or div/dis
    const getDemandInfo = async () => {
      let year = '';
      let month = '';
      if (demandYearMonth) {
        [year, month] = demandYearMonth?.split('-');
      }
      setTableLoading(true);
      const {
        sub: { demandProjectIds },
      } = await APIList.getDemandProjects().get({
        snapshot,
        snapshotVersion,
        year,
        month,
        division: div === 'All' ? '' : div,
        discipline: dis === 'All' ? '' : dis,
        tgc: newTgc.join(','),
        isSub: true,
      });
      const { resSimIds, projectDemand } = resSimCacheData;
      const groupedProjectDemand = groupBy(projectDemand, (x) => x.subProjectId);
      const budgetStep2 = Object.keys(groupedProjectDemand).reduce((prev, cur) => {
        demandYearMonthRange.forEach((ym) => {
          const list = groupedProjectDemand[cur].filter((g) => g.yearMonth === ym);
          const total = list.reduce((pr, c) => pr + (c.resourceDemandMH ?? 0), 0);
          if (prev[cur]) {
            prev[cur][ym] = total;
          } else {
            prev[cur] = {
              [ym]: total,
            };
          }
        });
        return prev;
      }, {} as any);
      const gAllData = Object.entries(budgetStep2).map(([k, v]) => ({
        subProjectId: k,
        mh: Number(
          demandYearMonth
            ? v[demandYearMonth].toFixed(0)
            : Object.values(v)
                .reduce((pr, c) => pr + c, 0)
                .toFixed(0)
        ),
      }));
      const orgSimIds = convertToJSON(getOrgKpiData('ResourceSimulationSub').data.projectIds);
      const tgcGroupById = resSimIds.reduce((pr, c) => {
        const { subProjectId } = c;
        const tgcs = orgSimIds.filter((o) => o.subProjectId === subProjectId).map((o) => o.tgc);
        pr[subProjectId] = Array.from(new Set(tgcs));
        return pr;
      }, {} as any);
      const projects = tableDataNeedFilter
        .filter((s) => demandProjectIds.includes(s.subProjectId))
        .map((s) => {
          const mh = gAllData.find((a) => a.subProjectId === s.subProjectId)?.mh;
          const tgc = tgcGroupById[s.subProjectId] ?? [];
          return {
            ...s,
            monthDemandMH: mh,
            relatedTgc: tgc.length ? tgc.sort().join(', ') : null,
          };
        });
      setListNeedRender(projects);
      setTableLoading(false);
      setMenuCollapsed(true);
      setFilterCollapsed(false);
    };
    await getDemandInfo();
  };

  useEffect(() => {
    const { demandYearMonth, mhDepartment } = form.getFieldsValue();
    changeRenderList({
      demandYearMonth,
      mhDepartment,
    });
  }, [tgc, tableDataNeedFilter, resSimCacheData.projectDemand]);

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const kpiMapping = useMemo(() => {
    return {
      33: (
        <ResourceSimulationChart
          kpiCode="ResourceSimulationSub"
          data={getKpiData('ResourceSimulationSub').data}
          loading={getKpiData('ResourceSimulationSub').loading}
          projects={selectedProjects}
          unselectBudgets={unselectBudgets}
          tgcs={handleTgcType(optOrgData?.tgc, tgc, true, includes([2, 3], accountingPerspective))}
          height={330}
          yearMonthRange={demandYearMonthRange}
          curYearMonth={demandYearMonth}
          onDemandSelect={handleDemandProjects}
          setMHDepartmentOpts={setMHDepartmentOpts}
          selectedDivDis={mhDepartment}
          isDxMode={dxOn}
          department={departments}
          dateTransferProjects={dateTransferProjects}
          idKey="subProjectId"
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.resSimulation.mhForecast'), color.warningColor)}
        />
      ),
      36: (
        <TurnoverResource
          consolidated={perspectiveState.consolidated}
          height={330}
          kpiCode="GrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('GrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('GrossProfitResource').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            `${t('aipcmcty.page.resSimulation.grossProfitRebound')}・${t('aipcmcty.page.resSimulation.grossRate')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          dateTransferProjects={dateTransferProjects}
          profitTransferProjects={profitTransferProjects}
          department={departments}
          idKey="subProjectId"
          showGrossProfitRateLine={true}
        />
      ),
      37: (
        <TurnoverFlowChart
          consolidated={perspectiveState.consolidated}
          height={330}
          kpiCode="OrderGrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('OrderGrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('OrderGrossProfitResource').loading}
          title={[t('aipcmcty.page.orderGrossProfit'), t('aipcmcty.page.orderAmount')]}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          department={departments}
          dateTransferProjects={dateTransferProjects}
          profitTransferProjects={profitTransferProjects}
          onOrderYearMonthChange={handleOrderScheduledDateChange}
          orderYearMonths={orderYearMonths}
          onOrderFYChange={handleOrderFYChange}
          selectedFiscalYear={orderFiscalYear}
          idKey="subProjectId"
          defaultFY={defaultFiscalYearNumber}
        />
      ),
    };
  }, [
    kpiInfos,
    tgc,
    demandYearMonth,
    mhDepartment,
    departments,
    periodSwitch,
    selectedProjects,
    dxOn,
    perspectiveState.consolidated,
    orderFiscalYear[0],
  ]);

  useEffect(() => {
    setTimeout(() => {
      filterKpiData({
        tgc: handleTgcType(optOrgData?.tgc, tgc, true, includes([2, 3], accountingPerspective)),
        japanInvolved: perspectiveState.japanInvolved,
        consolidated: perspectiveState.consolidated,
        tgcCanEmpty: true,
        departments: {
          kpiCode: 'OrderGrossProfitResource',
          items: departments,
        },
      });
    }, 0);
  }, [orgKpiInfos, tgc, perspectiveState, departments]);

  useEffect(() => {
    if (!!optOrgData) {
      getChartData();
    }
  }, [optOrgData, snapshot, snapshotVersion, fiscalQuarter, periodSwitch, dxOn, perspectiveState.consolidated]);

  const getChartData = () => {
    const mhDepartment = form.getFieldValue('mhDepartment');
    initKpiData([['OrderGrossProfitResource'], ['GrossProfitResource'], ['ResourceSimulationSub']], !!perspectiveState.consolidated, {
      isDXMode: dxOn,
      division: mhDepartment[0] === 'All' ? '' : mhDepartment[0],
      discipline: mhDepartment[1] === 'All' ? '' : mhDepartment[1],
    });
  };

  // confirm select and unselect modal
  const confirmTableColumns = [
    {
      title: t('aipcmcty.page.projectCaseId'),
      key: 'subProjectId',
      dataIndex: 'subProjectId',
    },
    {
      title: t('aipcmcty.page.projectCaseName'),
      key: 'subProjectName',
      dataIndex: 'subProjectName',
    },
    {
      title: `变更类型`,
      key: 'changeType',
      dataIndex: 'changeType',
      render: (value) => confirmChangeTypeMap[value] ?? '',
    },
    {
      title: `${t('aipcmcty.page.resSimulation.before')}`,
      key: 'changeBefore',
      dataIndex: 'changeBefore',
      render: (value, item) => {
        switch (item.changeType) {
          case 'ORDER_AMOUNT_CHANGE':
            return value.toLocaleString();
          case 'GROSS_MARGIN_CHANGE':
            return `${(value * 100).toFixed(1)}%`;
          default:
            return item.cBeforeItem ? item.cBeforeItem : value;
        }
      },
    },
    {
      title: `${t('aipcmcty.page.resSimulation.after')}`,
      key: 'changeAfter',
      dataIndex: 'changeAfter',
      render: (value, item) => {
        switch (item.changeType) {
          case 'ORDER_AMOUNT_CHANGE':
            return value.toLocaleString();
          case 'GROSS_MARGIN_CHANGE':
            return `${(value * 100).toFixed(1)}%`;
          default:
            return item.cAfterItem ? item.cAfterItem : value;
        }
      },
    },
  ];
  const confirmChangeTypeMap = {
    BUDGET_CHANGE: t('aipcmcty.page.budgetCategory'),
    DATE_CHANGE: '受注月調整',
    ORDER_AMOUNT_CHANGE: t('aipcmcty.page.orderAmount'),
    GROSS_MARGIN_CHANGE: t('aipcmcty.page.grossProfitRate'),
  };
  const [confirmUpdateModal, setConfirmUpdateModel] = useState(false);
  const [confirmSelectIds, setConfirmSelectIds] = useState([]);
  const confirmTableData = useMemo(() => {
    const asBudgets = selectedProjects
      .filter((s) => ['IF', 'Others'].includes(s.budgetCategoryCustom))
      .map((p) => {
        return {
          projectId: p.projectId,
          subProjectId: p.subProjectId,
          subProjectName: p.subProjectName,
          changeType: 'BUDGET_CHANGE',
          changeBefore: p.budgetCategoryCustom,
          changeAfter: 'Budget',
        };
      });
    const asIFs = unselectBudgets.map((u) => {
      return {
        projectId: u.projectId,
        subProjectId: u.subProjectId,
        subProjectName: u.subProjectName,
        changeType: 'BUDGET_CHANGE',
        changeBefore: u.budgetCategoryCustom,
        changeAfter: 'IF',
      };
    });
    const dateTransfers = dateTransferProjects.map((d) => {
      return {
        projectId: d.projectId,
        subProjectId: d.subProjectId,
        subProjectName: d.subProjectName,
        changeType: d.changeType,
        changeBefore: d.beforeValue,
        changeAfter: d.value,
        cBeforeItem: d.beforeScheduledDate,
        cAfterItem: d.scheduledDate,
      };
    });
    const amountTransfers = profitTransferProjects.map((d) => {
      return {
        projectId: d.projectId,
        subProjectId: d.subProjectId,
        subProjectName: d.subProjectName,
        changeType: d.changeType,
        changeBefore: d.beforeValue,
        changeAfter: d.value,
      };
    });
    return [...asBudgets, ...asIFs, ...dateTransfers, ...amountTransfers];
  }, [selectedProjects, unselectBudgets, dateTransferProjects, profitTransferProjects]);
  const handleConfirmModal = () => {
    setConfirmUpdateModel(true);
    setConfirmSelectIds(confirmTableData.map((c) => `${c.subProjectId}-${c.changeType}`));
  };

  const handleConfirmTableSelect = (item: any, selected: boolean) => {
    const id = `${item.subProjectId}-${item.changeType}`;
    if (selected) {
      setConfirmSelectIds([...confirmSelectIds, id]);
      return;
    }
    setConfirmSelectIds(confirmSelectIds.filter((c) => c !== id));
  };

  const handleCTableSelectAll = (selected: boolean, selectedRows: any[]) => {
    if (selected) {
      setConfirmSelectIds(selectedRows.map((s) => `${s.subProjectId}-${s.changeType}`));
      return;
    }
    setConfirmSelectIds([]);
  };

  const handleConfirmModalOK = async () => {
    setConfirmUpdateModel(false);
    await handleDemandUpdate();
    setConfirmSelectIds([]);
  };

  const handleConfirmModalCancel = () => {
    setConfirmUpdateModel(false);
    setConfirmSelectIds([]);
  };

  const handleDemandUpdate = async () => {
    const subProjects = confirmTableData.filter((c) => confirmSelectIds.includes(`${c.subProjectId}-${c.changeType}`));
    subProjects.forEach((sp) => {
      if (Reflect.has(sp, 'cBeforeItem')) {
        Reflect.deleteProperty(sp, 'cBeforeItem');
        Reflect.deleteProperty(sp, 'cAfterItem');
      }
    });
    setTableLoading(true);
    const updateStr = await APIList.updateDemandSubProjects().post({
      snapshot,
      snapshotVersion,
      subProjects,
    });
    if (updateStr === 'SUCCESS') {
      // TODO： set websocket address link and open it
      setKpiLoadingStatus(true);
      const isUpdateSuccess = await APIList.budgetCategoryUpdate().post({
        mode: '10',
        snapshot,
        snapshotVersion,
      });
      if (isUpdateSuccess) {
        getChartData();
        await initializeTableData();
        message.success('予算カテゴリの意思入れ値が正常に保存されました。');
      }
      setTableLoading(false);
    } else {
      message.error('ERROR');
      setTableLoading(false);
    }
  };

  const hasNotValueChanged = useMemo(
    () =>
      unselectBudgets.length === 0 &&
      !selectedProjects.some((s) => ['IF', 'Others'].includes(s.budgetCategoryCustom)) &&
      dateTransferProjects.length === 0 &&
      profitTransferProjects.length === 0,
    [selectedProjects, unselectBudgets, dateTransferProjects, profitTransferProjects]
  );
  usePageLeaveConfirm(!hasNotValueChanged, canLeave, setCanLeave, () => confirm('Are you sure leaving this page without save?'));

  useEffect(() => {
    return () => removeOrgKpiData();
  }, []);

  return (
    <ProjectSetupContext.Provider
      value={{
        handleTableChange,
        setIsModalOpen,
        setMenuCollapsed,
        setFilterCollapsed,
        setCurModalItem,
        optOrgData: {
          ...optOrgData,
          perspectiveOptions,
          imbalancedMhOpt,
          useCaseOpts,
          filterTgcOptions,
          departmentOpts,
          ppReportOpts,
          dhYearMonthOpts: demandYearMonthRange.map((ym) => ({ label: ym, key: ym, value: ym })),
          mhDepartmentOpts,
          orderYMOpts,
        },
        accountingPerspective,
        perspectiveState,
        filtersData: {
          ...form.getFieldsValue(),
          tgc,
          useCase,
          accountingPerspective,
          departments,
        },
      }}
    >
      <div className="dashboard project-setup resource-regulation" style={{ height: 'calc(100vh - 85px)' }}>
        <div
          style={{
            marginRight: filterCollapsed ? 0 : drawerOpts.maxWidth,
          }}
        >
          <Row justify="space-between" className="operation-container" style={{ backgroundColor: 'white', padding: '0 16px' }}>
            <Col style={{ padding: '5px 0' }}>
              <Space>
                {`${t('aipcmcty.page.resSimulation.useCase')}:`}
                <Select
                  style={{ width: 200 }}
                  value={useCase}
                  options={useCaseOpts}
                  onChange={(v) => {
                    handleUseCaseChange(v);
                  }}
                />
                {`${t('aipcmcty.page.accountingPerspective')}:`}
                <Select
                  style={{ width: 200 }}
                  value={accountingPerspective}
                  options={perspectiveOptions}
                  onChange={(v) => {
                    handleAccountPerspectiveChange(v);
                  }}
                />
                TGC:
                <Select
                  allowClear
                  style={{ width: 180 }}
                  value={tgc}
                  mode="multiple"
                  maxTagCount="responsive"
                  options={filterTgcOptions}
                  onChange={(tgc) => {
                    handleTgcChange(tgc);
                  }}
                />
                本部:
                <Tooltip title="会計観点: Toyo-J連結のみ指定可">
                  <Select
                    allowClear
                    style={{ width: 200 }}
                    value={departments}
                    mode="multiple"
                    maxTagCount="responsive"
                    disabled={accountingPerspective !== 3}
                    options={departmentOpts}
                    onChange={(departments) => setDepartments(departments)}
                  />
                </Tooltip>
                {/* DX:
                <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={dxOn} onChange={(e) => setDxOn(e)} /> */}
              </Space>
            </Col>
            <Col style={{ padding: '5px 0' }}>
              <Space>
                <LayoutSwitcher viewMode={viewMode} setViewMode={setViewMode} />
                <Button
                  onClick={() => {
                    setMenuCollapsed(filterCollapsed);
                    setFilterCollapsed(!filterCollapsed);
                  }}
                >
                  <BarsOutlined />
                </Button>
              </Space>
            </Col>
          </Row>
          <ChartTableLayout viewMode={viewMode}>
            {kpiMapping ? (
              <ChartTableLayout.Chart>
                <Row className="chart-group-container">
                  {kpiOrder.map((kpi) => (
                    <Col span={kpi === 33 ? 10 : 7} key={`chartTable-${kpi}`}>
                      <Card style={{ height: 342 }} className="chart-card">
                        {kpiMapping[kpi]}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </ChartTableLayout.Chart>
            ) : (
              <></>
            )}
            <ChartTableLayout.Table>
              <ResourceSimulationTable
                isDxMode={dxOn}
                data={filteredTableData}
                loading={tableLoading}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                needDisable={tableCanOperation}
                selectedProjects={selectedProjects}
                onSelected={handleTableRowSelected}
                demandMonth={demandYearMonth}
                selectedDivDis={mhDepartment as [string, string]}
                onDemandUpdate={handleConfirmModal}
                consolidated={perspectiveState.consolidated}
                viewMode={viewMode}
                getExportData={getExportData}
              />
            </ChartTableLayout.Table>
          </ChartTableLayout>
        </div>
        <DrawerContainer {...drawerOpts} collapsed={filterCollapsed}>
          <Tabs type="card" size="small" style={{ height: '100%' }} items={tabs} />
        </DrawerContainer>
      </div>
      <Modal
        className="aipcmc"
        title={`${curModalItem?.subProjectId} ${curModalItem?.subProjectName} ${t('aipcmcty.page.projectDemandSupplyList')}`}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setCurModalItem(null);
        }}
        footer={null}
        width="90%"
        styles={{
          body: {
            height: document.body.clientHeight * 0.8,
          },
        }}
        maskClosable={false}
        centered
      >
        <ProjectSetupDetail useAuth={true} project={curModalItem} />
      </Modal>
      <Modal
        title={t('aipcmcty.page.resSimulation.confirmUpdateProject')}
        open={confirmUpdateModal}
        onOk={handleConfirmModalOK}
        onCancel={handleConfirmModalCancel}
        width="80%"
        wrapClassName="res-sim-modal"
        okButtonProps={{
          disabled: !confirmSelectIds.length,
        }}
      >
        <Table
          className="res-sim-table"
          dataSource={confirmTableData}
          columns={confirmTableColumns}
          size="small"
          pagination={false}
          rowKey={(item) => `${item.subProjectId}-${item.changeType}`}
          rowSelection={{
            selectedRowKeys: confirmSelectIds,
            onSelect: handleConfirmTableSelect,
            onSelectAll: handleCTableSelectAll,
          }}
          rowClassName={(record) => {
            if (confirmSelectIds.includes(`${record.subProjectId}-${record.changeType}`)) {
              return 'light-selected';
            }
            return '';
          }}
        />
      </Modal>
    </ProjectSetupContext.Provider>
  );
};

export default ResourceSimulation;
