// chart title: Buffer Suitability Evaluation
import React, { ReactElement, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, cloneDeep, groupBy, sumBy } from 'lodash';
import { Tooltip } from 'antd';
import moment from 'moment';
import Ribbon from '../widget/ribbon';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { convertToJSON, getSizeByMap, round } from '../../utils/commonUtil';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { DateTransferItem } from './turnover-flow.chart';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

type ChartProps = {
  kpiCode: string;
  data: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  tableOptions?: TableOptions;
  projects: any[];
  unselectBudgets: any[];
  tgcs: string[];
  yearMonthRange: string[];
  curYearMonth: string;
  selectedDivDis: string[];
  department: string[];
  isDxMode: boolean;
  idKey?: string;
  dateTransferProjects?: DateTransferItem[];
  onDemandSelect: (yearMonth: string) => void;
  setMHDepartmentOpts: (opts: any[]) => void;
};

const ResourceSimulationChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode,
    data,
    height,
    loading,
    title,
    ribbonText,
    ribbonColor,
    tableOptions,
    titleLoading = false,
    coverLoading,
    projects,
    tgcs,
    unselectBudgets,
    onDemandSelect,
    yearMonthRange,
    curYearMonth,
    setMHDepartmentOpts,
    selectedDivDis,
    isDxMode,
    department,
    dateTransferProjects,
    idKey = 'projectId'
  } = props;

  const { color, sizeMode } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 80,
      gridBottom: 10,
    },
    big: {
      ...sizeMapBase.big,
    },
  };

  const [legendSelected, setLegendSelected] = useState({
    一般: true,
    'R&D': true,
    OFS: true,
    Proposal: true,
    Awarded: true,
    Projects: true,
  });

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      legend: {
        type: 'scroll',
        itemGap: 3,
        itemWidth: 10,
        itemHeight: 10,
        selected: legendSelected,
        data: [],
        textStyle: {
          fontSize: 10,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
        confine: true,
        appendToBody: true,
        order: 'seriesDesc',
        formatter: (params) => {
          if (params && params.length > 0) {
            params.reverse();
            let tooltip = '';
            const { axisValue } = params[0];
            tooltip += `${axisValue} <br/>`;
            for (let index = 0; index < params.length; index++) {
              const { marker, value, seriesName } = params[index];
              const v = round(seriesName === 'Total MH' ? value : value[1]).toLocaleString();
              let mk = marker;
              if (seriesName === 'DX削減MH') {
                mk = `<span class="square-marker">${marker}</span>`;
              }
              tooltip += `${mk}${seriesName}: ${v} <br/>`;
            }
            return tooltip;
          }
          return null;
        },
      },
      grid: {
        left: Number(getSizeByMap(sizeMode, sizeMap, 'gridLeft')) - 35,
        right: Number(getSizeByMap(sizeMode, sizeMap, 'gridRight')),
        bottom:
          sizeMode === 'big'
            ? Number(getSizeByMap(sizeMode, sizeMap, 'gridBottom')) - 15
            : Number(getSizeByMap(sizeMode, sizeMap, 'gridBottom')) + 30,
        top: Number(getSizeByMap(sizeMode, sizeMap, 'gridTop')) - 45,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          fontSize: getSizeByMap('small', sizeMap, 'xAxis'),
        },
      },
      yAxis: {
        name: t('aipcmcty.page.tenThousand'),
        type: 'value',
        axisLabel: {
          fontSize: getSizeByMap('small', sizeMap, 'yAxis'),
          formatter: (value) => Number((value / 10000).toFixed(2)),
        },
        nameTextStyle: {
          fontSize: getSizeByMap('small', sizeMap, 'yAxis'),
        },
      },
      dataZoom: [
        {
          type: 'inside',
          startValue: 0,
          endValue: 59,
        },
        {
          type: 'slider',
          textStyle: {
            fontSize: 11,
          },
          bottom: 24,
          height: 12,
        },
      ],
      series: [],
    },
    height: 220,
  });

  let orgProjectDemand = [];
  let orgResourceDemandData = [];
  let orgSupplyData = [];
  let orgDiscipline = [];
  if (data) {
    const { projectDemand: p, resourceDemandData: r, supplyData: s, disciplines: d } = data;
    orgProjectDemand = convertToJSON(p);
    orgResourceDemandData = convertToJSON(r);
    orgSupplyData = convertToJSON(s);
    orgDiscipline = convertToJSON(d);
    if (dateTransferProjects?.length) {
      dateTransferProjects.forEach(dp => {
        const opds = orgProjectDemand.filter(cp => cp[idKey] === dp[idKey]);
        const ords = orgResourceDemandData.filter(cp => cp[idKey] === dp[idKey]);
        const newYM = dayjs(dp.value);
        const diffMonths = newYM.diff(dayjs(dp.beforeValue), 'month');
        opds.forEach(opd => {
          opd.yearMonth = dayjs(opd.yearMonth).add(diffMonths, 'month').format('YYYY-MM');
        });
        ords.forEach(ord => {
          ord.yearMonth = dayjs(ord.yearMonth).add(diffMonths, 'month').format('YYYY-MM');
        });
      });
    }
  }

  useEffect(() => {
    const disciplinesByDiv = orgDiscipline.reduce(
      (prev, cur) => {
        if (!prev.divisions.includes(cur.division)) {
          prev.divisions.push(cur.division);
        }
        if (!prev.disciplines.includes(cur.discipline)) {
          prev.disciplines.push(cur.discipline);
        }
        if (prev[cur.division] && !prev[cur.division].includes(cur.discipline)) {
          prev[cur.division].push(cur.discipline);
        } else {
          prev[cur.division] = [cur.discipline];
        }
        return prev;
      },
      {
        divisions: [],
        disciplines: [],
      }
    );
    const initialDivisionOptions = [
      {
        label: 'All Div.',
        value: 'All',
        key: 'All',
        children: [
          {
            label: 'All Dis.',
            value: 'All',
            key: 'All',
          },
          ...disciplinesByDiv.disciplines.map((dis) => ({
            label: dis ?? 'NULL',
            value: dis,
            key: dis ?? 'null',
          })),
        ],
      },
      ...disciplinesByDiv.divisions.map((div) => ({
        label: div === 'Project' ? 'PJT' : `${div.slice(0, 3)}.`,
        value: div,
        key: div,
        children: [
          {
            label: 'All Dis.',
            value: 'All',
            key: 'All',
          },
          ...(disciplinesByDiv[div] ?? []).map((dis) => ({
            label: dis ?? 'NULL',
            value: dis,
            key: dis ?? 'null',
          })),
        ],
      })),
    ];
    setMHDepartmentOpts(initialDivisionOptions);
  }, [data]);

  const handleChartEvent = (e) => {
    if (!e || e.chartTitle.value.key !== title.key) {
      return;
    }
    const { eventType, event, value } = e;
    if (eventType === ECHART_EVENT.CHART_LEGEND_SELECTED) {
      const { selected } = event;
      setLegendSelected(selected);
    }
    if (eventType === ECHART_EVENT.CHART_CLEAR_SELECTED) {
      const initialSelected = {
        一般: true,
        'R&D': true,
        OFS: true,
        Proposal: true,
        Awarded: true,
        Projects: true,
      };
      setLegendSelected(initialSelected);
      updateChartData(initialSelected);
    }
    if (eventType === ECHART_EVENT.CHART_ITEM_SELECTED) {
      onDemandSelect(value[0]);
    }
  };

  const filteredResDemandData = useMemo(
    () =>
      orgResourceDemandData
        .filter((pi) => !tgcs.length || tgcs.includes(pi.tgc))
        .filter((pi) => !department.length || department.includes(pi.department)),
    [tgcs, orgResourceDemandData, department]
  );

  const filteredSupplyDemands = useMemo(
    () =>
      orgSupplyData
        .filter((pi) => !tgcs.length || tgcs.includes(pi.tgc)),
    [tgcs, data, department]
  );

  const filteredProjectDemands = useMemo(
    () =>
      orgProjectDemand
        .filter((pi) => !tgcs.length || tgcs.includes(pi.tgc))
        .filter((pi) => !department.length || department.includes(pi.department)),
    [tgcs, orgProjectDemand, department]
  );

  useEffect(() => {
    updateChartData();
  }, [projects, unselectBudgets, selectedDivDis, data, curYearMonth, dateTransferProjects]);

  const updateChartData = (initialSelected?: any) => {
    if (!data) {
      return;
    }
    // 图上红线 Red Line
    const totalMHStep2 = groupBy(filteredSupplyDemands, (x) => x.jobType);
    const totalMHStep3 = Object.entries(totalMHStep2).reduce((prev, [k, v]) => {
      yearMonthRange.forEach((ym) => {
        const total = v
          .filter((vl) => vl.yearMonth === ym)
          .reduce((pr, c) => pr + (c.actualSupplyMH ?? 0), 0)
          .toFixed(0);
        const capitalizedKey = k === 'execution' ? capitalize(k) : k;
        if (prev[capitalizedKey]) {
          prev[capitalizedKey][ym] = Number(total);
        } else {
          prev[capitalizedKey] = {
            [ym]: Number(total),
          };
        }
      });
      return prev;
    }, {} as any);
    const totalMH = Object.values<number>(totalMHStep3).reduce(
      (prev, v) => prev.map((pr, i) => pr + Object.values(v)[i]),
      Array.from({ length: yearMonthRange.length }, (_) => 0)
    );
    const totalMHSeries = {
      id: 'totalMH',
      name: 'Total MH',
      type: 'line',
      data: totalMH,
      emphasis: {
        focus: 'series',
      },
      itemStyle: {
        color: '#DD6B66',
      },
      symbol: 'none',
    };

    // 面积图 base
    // IT_Operation R&D 一般 OFS Proposal Awarded Budget 数据
    // IF, Others 增加，Budget 减少
    const totalDemand = genDemandData(filteredResDemandData, yearMonthRange);
    const demandSeriesSeq = [
      ['normal', '一般'],
      ['rd', 'R&D'],
      // ['ofs', 'OFS'],
      ['proposal', 'Proposal'],
      ['itOperation', 'ITO'],
      ['awarded', 'Awarded'],
      ['budget', 'Budget'],
    ];
    const groupedProjectDemand = groupBy(filteredProjectDemands, (x) => x[idKey]);
    const unselectIds = unselectBudgets.map((u) => u[idKey]);
    const budgetStep1 = Object.keys(groupedProjectDemand).reduce((prev, cur) => {
      if (unselectIds.includes(cur)) {
        prev[cur] = groupedProjectDemand[cur];
      }
      return prev;
    }, {} as any);
    const budgetStep2 = Object.keys(budgetStep1).reduce((prev, cur) => {
      yearMonthRange.forEach((ym) => {
        const list = budgetStep1[cur].filter((g) => g.yearMonth === ym);
        const total = Number(list.reduce((pr, c) => pr + (c.resourceDemandMH ?? 0), 0).toFixed(0));
        if (prev[cur]) {
          prev[cur][ym] = total;
        } else {
          prev[cur] = {
            [ym]: total,
          };
        }
      });
      return prev;
    }, {} as any);
    const budgetStep3 = Object.values<number>(budgetStep2).reduce(
      (prev, cur) => prev.map((pr, i) => pr + Object.values(cur)[i]),
      Array.from({ length: yearMonthRange.length }, (_) => 0)
    );
    const demandSeries = demandSeriesSeq.map((sq) => {
      let demandData = Object.entries<number>(totalDemand[sq[0]] ?? {});
      if (sq[0] === 'budget') {
        demandData = demandData.map((d, i) => [d[0], d[1] - budgetStep3[i]]);
      }
      let bgColor = '#3299D9';
      switch (sq[0]) {
        case 'normal':
          bgColor = '#1A4F99';
          break;
        case 'rd':
          bgColor = '#507FA2';
          break;
        case 'ofs':
          bgColor = '#195D87';
          break;
        case 'proposal':
          bgColor = '#619BC5';
          break;
        case 'itOperation':
          bgColor = '#B0CEDB';
          break;
        case 'awarded':
          bgColor = '#093B5E';
          break;
        case 'budget':
          bgColor = '#3299D9';
          break;
        default:
          break;
      }
      return {
        id: sq[0],
        name: sq[1],
        type: 'bar',
        stack: 'Total',
        data: demandData,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: bgColor,
        },
      };
    });
    const ifData = genProjectsDemandData(groupedProjectDemand, yearMonthRange, 'IF');
    const othersData = genProjectsDemandData(groupedProjectDemand, yearMonthRange, 'Others');
    const ifSeries = {
      id: 'if',
      name: 'IF',
      type: 'bar',
      stack: 'Total',
      data: Object.entries(ifData),
      emphasis: {
        focus: 'series',
      },
      itemStyle: {
        color: '#9FD9F6',
      },
    };
    const othersSeries = {
      id: 'others',
      name: 'Others',
      type: 'bar',
      stack: 'Total',
      data: Object.entries(othersData),
      emphasis: {
        focus: 'series',
      },
      itemStyle: {
        color: '#B0CEDB',
      },
    };

    const newOptions = cloneDeep(options);
    newOptions.chartOptions.series = [...demandSeries, ifSeries, othersSeries];
    if (isDxMode) {
      const ifDxIds = projects.filter((pj) => pj.budgetCategoryCustom === 'IF' && pj.dxApplicable).map((pj) => pj[idKey]);
      const othersDxIds = projects.filter((pj) => pj.budgetCategoryCustom === 'Others' && pj.dxApplicable).map((pj) => pj[idKey]);
      const budgetIds = unselectBudgets.filter((pj) => pj.budgetCategoryCustom === 'Budget' && pj.dxApplicable).map((pj) => pj[idKey]);
      const diffData = yearMonthRange.reduce((prev, ym) => {
        const diffItems = filteredResDemandData.filter((f) => f.yearMonth === ym);
        const dxIf = filteredProjectDemands
          .filter((f) => f.yearMonth === ym && ifDxIds.includes(f[idKey]))
          .reduce((pr, c) => pr + c.resourceDemandMH - c.dxResourceDemandMH, 0);
        const dxOthers = filteredProjectDemands
          .filter((f) => f.yearMonth === ym && othersDxIds.includes(f[idKey]))
          .reduce((pr, c) => pr + c.resourceDemandMH - c.dxResourceDemandMH, 0);
        const dxBudgets = filteredProjectDemands
          .filter((f) => f.yearMonth === ym && budgetIds.includes(f[idKey]))
          .reduce((pr, c) => pr + c.resourceDemandMH - c.dxResourceDemandMH, 0);
        prev[ym] = Number((diffItems.reduce((pr, d) => pr + d.demandMHDiff, 0) + dxIf + dxOthers - dxBudgets).toFixed(0));
        return prev;
      }, {} as any);
      const diffSeries = {
        id: 'dxDiff',
        name: 'DX削減MH',
        type: 'bar',
        stack: 'Total',
        data: Object.entries(diffData),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#a8a8a8',
          decal: {
            symbol: 'rect',
            color: '#fff',
            dashArrayX: [1, 0],
            dashArrayY: [1, 3],
            symbolSize: 1,
            rotation: -Math.PI / 4,
          },
        },
      };
      newOptions.chartOptions.series.push(diffSeries);
    }
    newOptions.chartOptions.series.push(totalMHSeries);
    newOptions.chartOptions.xAxis.data = yearMonthRange;
    if (curYearMonth) {
      const curYMIndex = yearMonthRange.indexOf(curYearMonth);
      const startIndex = newOptions.chartOptions.dataZoom[0].startValue;
      const endIndex = newOptions.chartOptions.dataZoom[0].endValue;
      if (curYMIndex < startIndex) {
        newOptions.chartOptions.dataZoom[0].startValue = curYMIndex;
      }
      if (curYMIndex > endIndex) {
        newOptions.chartOptions.dataZoom[0].endValue = curYMIndex;
      }
    } else {
      newOptions.chartOptions.dataZoom[0].startValue = yearMonthRange.indexOf(moment().format('yyyy-MM'));
    }
    newOptions.chartOptions.legend = {
      ...newOptions.chartOptions.legend,
      selected: initialSelected ?? legendSelected,
      data: ['Total MH', ...newOptions.chartOptions.series.slice(0, -1)],
    };
    setOptions(newOptions);
  };

  const genDemandData = (demandData: any[], yms: string[]) => {
    const typedDemand = demandData.reduce((prev, cur) => {
      let key = '';
      switch (cur.jobCategory) {
        case 'Awarded':
          key = 'awarded';
          break;
        case 'Budget':
          key = 'budget';
          break;
        case 'Proposal':
          key = 'proposal';
          break;
        case '一般':
          key = 'normal';
          break;
        case 'R&D':
          key = 'rd';
          break;
        case 'OFS':
          key = 'ofs';
          break;
        case 'IT_Operation':
          key = 'itOperation';
          break;
        default:
          break;
      }
      if (prev[key]) {
        prev[key].push(cur);
      } else {
        prev[key] = [cur];
      }
      return prev;
    }, {} as any);
    return Object.entries(typedDemand).reduce((prev, [curK, curV]) => {
      yms.forEach((ym) => {
        const tData = (curV as any[]).filter((c) => c.yearMonth === ym);
        const total = Number(tData.reduce((pr, td) => pr + (td.resourceDemandMH ?? 0), 0).toFixed(0));
        if (prev[curK]) {
          prev[curK][ym] = total;
        } else {
          prev[curK] = {
            [ym]: total,
          };
        }
      });
      return prev;
    }, {} as any);
  };

  const genProjectsDemandData = (demandData: any, yms: string[], budgetCategory: string) => {
    const projectIds = projects.filter((pj) => pj.budgetCategoryCustom === budgetCategory).map((pj) => pj[idKey]);
    const pDemands = Object.keys(demandData).reduce((prev, cur) => {
      if (projectIds.includes(cur)) {
        prev[cur] = demandData[cur];
      }
      return prev;
    }, {} as any);
    return Object.values<any>(pDemands).reduce((prev, v) => {
      yms.forEach((ym) => {
        const total = v.filter((vl) => vl.yearMonth === ym).reduce((pr, c) => pr + (c.resourceDemandMH ?? 0), 0);
        if (prev[ym]) {
          prev[ym] += Number(total.toFixed(0));
        } else {
          prev[ym] = Number(total.toFixed(0));
        }
      });
      return prev;
    }, {} as any);
  };

  return (
    <>
      {ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} color={ribbonColor || color.primaryColor} /> : <></>}
      <TableChart
        showTable={false}
        tableColumns={[]}
        tableData={[]}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
                value: title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : options.title
        }
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default ResourceSimulationChart;
