/* eslint-disable no-template-curly-in-string */
import { CheckOutlined, CloseOutlined, ConsoleSqlOutlined, EditOutlined, FileExclamationOutlined, FormOutlined, RollbackOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import { AppContext } from '../../contexts/AppContext';
import { round } from '../../utils/commonUtil';
import dayjs, { Dayjs } from 'dayjs';
import { exportTableFromExcelTemp } from '../../utils/xlsxReader';
import { localeMapping } from '../../consts/locale.mapping';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { chain, eq, filter, forEach, get, includes, isArray, isEmpty, isNil, map, pick, set } from 'lodash';
import useAuth from '../../hooks/useAuth';
import APIList from '../../http/ApiList';

export type ProjectAttrsType = {
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
};

export type ProjectKPIType = {
  key: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: string;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  won: number;
};

/* @noflow */
export interface ResourceRegulationTableItem {
  key: string;
  // 案件 UUID
  id: string;
  // 案件ID
  projectId: string;
  pid?: string;
  // SUB案件ID
  subProjectId?: string;
  // 案件名
  projectName: string;
  pName?: string;
  // SUB案件名
  subProjectName?: string;

  // TGC
  tgc: string;
  // 予算カテゴリ
  budgetCategory: string;
  budgetCategoryDefault: string;
  budgetCategoryCustom: string;
  // 最優先
  priorityMatching: boolean;
  // 優先度
  priorityDefault: number;
  // 受注金額
  orderAmount: number;
  // 受注金額_default
  orderAmountDefault: number;
  orderAccumulation: number;
  // 粗利率
  grossMargin: number;
  grossMarginDefault: number;
  grossMarginStandalone: number;
  grossMarginStandaloneDefault: number;
  grossProfit: number;
  grossProfitDefault: number;
  profitAccumulation: number;
  // 需要Mh
  demandMh: number;
  // Won%
  won: number;
  // フェーズ / ステージ
  phase: string;
  phaseDefault: string;
  // 確度
  certainty: string;
  // 開始予定日
  projectStartDate: string;
  projectStartDateDefault: string;
  // 終了予定日
  projectEndDate: string;
  projectEndDateDefault: string;
  // 期間
  duration: number;
  durationDefault: number;
  // Feed開始予定日
  feedStartDate: Date;
  feedStartDateDefault: Date;
  // Feed終了予定日
  feedEndDate: Date;
  feedEndDateDefault: Date;
  // Feed期間
  feedDuration: number;
  feedDurationDefault: number;
  // DX適用
  dxAvailable: boolean;
  dxApplicable: boolean;
  formJvFeedE: number;
  formJvFeedP: number;
  formJvFeedC: number;
  formJvFeedPjt: number;
  formJvEpcE: number;
  formJvEpcP: number;
  formJvEpcC: number;
  formJvEpcPjt: number;
  formTgcFeedE: string;
  formTgcFeedP: string;
  formTgcFeedC: string;
  formTgcFeedPjt: string;
  formTgcEpcE: string;
  formTgcEpcP: string;
  formTgcEpcC: string;
  formTgcEpcPjt: string;
  // アクション / アラート
  action: string[];
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: number;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  demandForecastType: string;
  constructionLocation: string;
  memoCount: number;
  monthDemandMH: number;
  dxDemandMh: number;
  dxGrossProfit: number;
  orderScheduledDate: string;
  orderScheduledDateDefault: string;
  proposalDxAllowGrossAllJpy?: number;
  presalesDxAllowGrossAllJpy?: number;
  orderAmountStandalone: number;
  orderAmountStandaloneDefault: number;
  grossProfitStandalone: number;
  grossProfitStandaloneDefault: number;
  offsetMonths: Dayjs;
  isForPpReport?: boolean;
  isChildren?: boolean;
  // 强制打开编辑按钮
  forceEdit?: boolean;
  // 记录P的BudgetCategoryCustom
  pBudgetCategoryCustom: string;
  children: ResourceRegulationTableItem[];
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: string;
  record: ResourceRegulationTableItem;
  index: number;
  selectOptions?: any[];
}

const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

const EditComponentCreator = (type: string, selectOptions?: any[]) => {
  if (type === 'datepicker') {
    return <DatePicker allowClear={false} minDate={dayjs() as any} picker="month" />;
  }
  if (type === 'amount') {
    return (
      <InputNumber controls={false} formatter={(value) => fixed(value).toLocaleString()} parser={(value) => value!.replace(/(,*)/g, '')} />
    );
  }
  if (type === 'percent') {
    return (
      <InputNumber
        controls={false}
        min={0}
        max={100}
        formatter={(value) => `${round(value * 100).toFixed(1)}%`}
        parser={(value) => (Number(value.replace('%', '')) / 100) as any}
      />
    );
  }
  if (type === 'selector') {
    return <Select style={{ width: 120 }} options={selectOptions} />;
  }
  return <Input />;
};

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = (props: EditableCellProps) => {
  const { record, editing, inputType, children, dataIndex, selectOptions, ...restProps } = props;
  const inputNode = EditComponentCreator(inputType, selectOptions);
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

// 只记载变更框的字段key
const getHandleKeys = (accountingPerspective: number, useCase: number) => {
  const keys = ['offsetMonths'];
  if (useCase === 1) {
    keys.push('budgetCategoryCustom');
  }
  if (accountingPerspective === 4) {
    keys.push('orderAmountStandalone');
    keys.push('grossMarginStandalone');
  } else {
    keys.push('orderAmount');
    keys.push('grossMargin');
  }
  return keys;
};

const ResourceRegulationTable: React.FC<{
  loading: boolean;
  data: ResourceRegulationTableItem[];
  viewMode?: string;
  columnFilter?: {
    update: string[];
    query: string[];
    hidden: string[];
  };
  onPageSizeChange: (pageSize: number) => void;
  tableColsToShow: string[];
  pageSize: number;
  needDisable: boolean;
  onSelected: (record: ResourceRegulationTableItem, selected: boolean) => void;
  selectedProjects: any[];
  demandMonth: string;
  selectedDivDis: [string, string];
  onDemandUpdate: () => void;
  isDxMode: boolean;
  consolidated: 0 | 1;
  onExpandChange?: (projectId: string, expanded: boolean, record: ResourceRegulationTableItem) => void;
  expandedRowKeys?: string[];
  // 会計観点: 1, 2, 3, 4-拠点単体
  accountingPerspective?: number;
  // 0: 中期受注計画, 1: 単年度計画
  useCase?: number;
  accountingPerspectiveOpt?: any;
  optData?: any;
  filtersData?: any;
  departmentOpts?: any;
  memoFilter?: any;
  useCaseOpts?: any;
  getExportInfoFunc?: () => any;
}> = (props) => {
  // Common Config
  const {
    data,
    viewMode,
    loading,
    onPageSizeChange,
    pageSize,
    needDisable,
    onSelected,
    selectedProjects,
    onDemandUpdate,
    isDxMode,
    consolidated,
    tableColsToShow,
    onExpandChange,
    expandedRowKeys,
    accountingPerspective,
    accountingPerspectiveOpt,
    useCase,
    filtersData,
    memoFilter,
    useCaseOpts,
    getExportInfoFunc,
  } = props;

  const { selectorHeight4Table } = useWindowSize({
    selector: '.project-table .cmcty-table-body',
    viewMode,
    watcherSelector: ['.operation-container'],
  });
  const {
    setIsModalOpen,
    optData,
    setCurModalItem,
    handleTableChange,
    setMenuCollapsed,
    setFilterCollapsed,
    setMemoCollapsed,
    setMemoInfo,
  } = useContext(ProjectSetupContext);

  const { t } = useTranslation();

  const { certainty } = useContext(AppContext);

  const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

  // 计算是否满足P或S案件的展示形态，足满况情下，返回true
  const isPOrSCheck = useCallback((record: ResourceRegulationTableItem, useCase: number) => {
    return (useCase === 0 && !record.isChildren) || (useCase === 1 && record.isChildren);
  }, []);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: ResourceRegulationTableItem) => record.id === editingKey;
  const [form] = Form.useForm();
  const cachedItem = useRef(null);

  // Table Config
  const columns: ColumnType<ResourceRegulationTableItem>[] = [
    {
      title: t('aipcmcty.page.priorityLevel'),
      dataIndex: 'priorityDefault',
      key: 'priorityDefault',
      fixed: 'left',
      width: 80,
      className: 'align-right small-column',
      render: (val, record) => !record.isChildren && <>{val}</>,
    },
    {
      title: t('aipcmcty.page.projectCaseId'),
      dataIndex: 'projectId',
      key: 'projectId',
      width: 80,
      fixed: 'left',
      className: 'align-right small-column',
      sorter: (a, b) => a.projectId.localeCompare(b.projectId),
      render: (val) => {
        const ids = val ? val.split(',') : '-';
        return ids.map((id) => (
          <div key={id} style={{ lineHeight: '16px' }}>
            {id}
          </div>
        ));
      },
    },
    {
      title: t('aipcmcty.page.projectCaseName'),
      dataIndex: 'projectName',
      key: 'projectName',
      fixed: 'left',
      width: 240,
      className: 'align-left small-column',
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      render(val, item) {
        const attrSource = {
          accountingYear: item.accountingYear ?? '-',
          scope: item.scope ?? '-',
          isExisted: item.isExisted ?? '-',
          isEpc: item.isEpc ?? '-',
          businessType: item.businessType ?? '-',
          goodsType: item.goodsType ?? '-',
          strategic: item.strategic ?? '-',
          customerRelationship: item.customerRelationship ?? '-',
        };
        const grossProfitPerMh =
          !!Number(item.grossProfitPerMh) && Number(item.grossProfitPerMh) !== 0
            ? Number(item.grossProfitPerMh).toFixed(2)
            : item.grossProfitPerMh;
        const kpiSource = {
          grossProfitPerMh: grossProfitPerMh ? Number(Number(grossProfitPerMh).toFixed(0)).toLocaleString() : '-',
          npvGrossMargin: item.npvGrossMargin ? `${round(item.npvGrossMargin * 100).toFixed(1)}%` : '-',
          totalWorkload: item.totalWorkload ? Number(item.totalWorkload).toLocaleString() : '-',
          expectedProductivity: item.expectedProductivity ? Number(item.totalWorkload).toLocaleString() : '-',
          resourceDemandMh: item.resourceDemandMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          resourceSupplyMh: item.resourceSupplyMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          imbalancedMh: item.imbalancedMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          dxFullPotential: item.dxFullPotential
            ? certainty === 1
              ? Math.round(item.dxFullPotential * item.won)
              : Number(item.dxFullPotential).toLocaleString()
            : '-',
          dxDiscountRate: item.dxDiscountRate ?? '-',
          dxDiscounted: item.dxDiscounted
            ? certainty === 1
              ? Math.round(item.dxDiscounted * item.won)
              : Number(item.dxDiscounted).toLocaleString()
            : '-',
          won: item.won,
        };
        const contentPop = (
          <div className="project-info-pop">
            <h4 style={{ fontWeight: 'bold' }}>{t('aipcmcty.page.projectAttribute')}</h4>
            <div className="grid-table">
              <div className="table-name">
                <div>{t('aipcmcty.page.fiscalYear')}</div>
                <div>{t('aipcmcty.page.serviceScope')}</div>
                <div>{`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`}</div>
                <div>{`${t('aipcmcty.page.nonEPC')}/EPC`}</div>
                <div>{t('aipcmcty.page.businessType')}</div>
                <div>{t('aipcmcty.page.productType')}</div>
                <div>{t('aipcmcty.page.strategic')}</div>
                <div>{t('aipcmcty.page.customerRelationship')}</div>
              </div>
              <div className="table-value">
                <div style={{ maxWidth: 240 }}>{attrSource.accountingYear}</div>
                <Tooltip title={attrSource.scope}>
                  <div style={{ maxWidth: 240 }}>{attrSource.scope}</div>
                </Tooltip>
                <div style={{ maxWidth: 240 }}>{attrSource.isExisted}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.isEpc}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.businessType}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.goodsType}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.strategic}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.customerRelationship}</div>
              </div>
            </div>
            <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>{t('aipcmcty.page.projectKpi')}</h4>
            <div className="grid-table" style={{ width: '100%' }}>
              <div className="table-name" style={{ minWidth: 200 }}>
                <div>{t('aipcmcty.page.grossProfitPerMH')}</div>
                <div>{t('aipcmcty.page.NPVgrossProfitRate')}</div>
                <div>{t('aipcmcty.page.totalWorkVolume')}</div>
                <div>{t('aipcmcty.page.assumedProductivity')}</div>
                <div>{t('aipcmcty.page.resourceDemandMH')}</div>
                <div>{t('aipcmcty.page.resourceSupplyMH')}</div>
                <div>{t('aipcmcty.page.demandSupplyGapMH')}</div>
                <div>{t('aipcmcty.page.dxAllowanceFullPot')}</div>
                <div>{t('aipcmcty.page.DXallowanceDiscountRate')}</div>
                <div>{t('aipcmcty.page.dxAllowanceAmount')}</div>
              </div>
              <div className="table-value">
                <div style={{ maxWidth: 240 }}>{kpiSource.grossProfitPerMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.npvGrossMargin}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.totalWorkload}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.expectedProductivity}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.resourceDemandMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.resourceSupplyMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.imbalancedMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxFullPotential}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxDiscountRate}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxDiscounted}</div>
              </div>
            </div>
          </div>
        );
        return (
          <Row align="middle" style={{ width: '100%' }}>
            <Col span={22}>
              <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val} placement="topLeft">
                {!item.isChildren ? (
                  <Button
                    className="project-name multi-ellipsis-btn"
                    type="link"
                    onClick={() => {
                      setIsModalOpen(true);
                      setCurModalItem({ ...item });
                    }}
                  >
                    {val}
                  </Button>
                ) : (
                  val
                )}
              </Tooltip>
            </Col>
            {!item.isChildren && (
              <Col span={2}>
                <Popover content={contentPop} trigger="hover" placement="right">
                  <FileExclamationOutlined />
                </Popover>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: t('aipcmcty.page.year'),
      dataIndex: 'accountingYear',
      key: 'accountingYear',
      width: 60,
      className: 'align-left small-column',
      sorter: (a, b) => a.accountingYear.localeCompare(b.accountingYear),
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.resSimulation.orderScheduledDate'),
      dataIndex: 'orderScheduledDate',
      key: 'orderScheduledDate',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => {
        if (moment(a.orderScheduledDate).isBefore(b.orderScheduledDate)) {
          return -1;
        }
        if (moment(a.orderScheduledDate).isAfter(b.orderScheduledDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const defaultMoment = moment(item.orderScheduledDateDefault);
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}>
            <div style={{ textAlign: 'center' }}>{moment(val).format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: 'TGC',
      dataIndex: 'tgc',
      key: 'tgc',
      width: 90,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aTgc = a.tgc ?? '';
        const bTgc = b.tgc ?? '';
        return aTgc.localeCompare(bTgc);
      },
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.resSimulation.relatedTgc'),
      dataIndex: 'relatedTgc',
      key: 'relatedTgc',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aTgc = a.tgc ?? '';
        const bTgc = b.tgc ?? '';
        return aTgc.localeCompare(bTgc);
      },
      render: (val) => val ?? '-',
    },
    // 受注計画カテゴリ
    {
      title: t('aipcmcty.page.budgetCategoryCustom'),
      dataIndex: 'budgetCategoryCustom',
      key: 'budgetCategoryCustom',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => a.budgetCategoryCustom?.localeCompare(b.budgetCategoryCustom),
      render(val, item) {
        const content = (
          <>
            <span>{`SFDC: ${item.budgetCategoryDefault}`}</span>
            <br />
            <span>{`CMC: ${item.budgetCategory}`}</span>
          </>
        );
        return !item.isChildren ? (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={content}>
            <div style={{ textAlign: 'center' }}>{val ?? item.budgetCategory}</div>
          </Tooltip>
        ) : (
          <div style={{ textAlign: 'center' }}>-</div>
        );
      },
    },
    // 予算算入カテゴリ
    {
      title: '予算算入カテゴリ',
      dataIndex: 'budgetCategoryCustom',
      key: 'budgetCategoryCustom2',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => a.budgetCategoryCustom?.localeCompare(b.budgetCategoryCustom),
      onCell: (record) => ({
        record,
        inputType: 'selector',
        dataIndex: 'budgetCategoryCustom',
        title: '予算算入カテゴリ',
        editing: record.isChildren && useCase === 1 && isEditing(record),
        selectOptions: chain(optData.budgetCategory)
          .filter((item) => includes(['Awarded', 'Budget', 'IF', 'Others'], item.value))
          .filter((item) => {
            if (includes(['IF', 'Others'], record.pBudgetCategoryCustom)) {
              return includes(['IF', 'Others'], item.value);
            }
            // if (includes(['Budget'], record.pBudgetCategoryCustom)) {
            //   return includes(['Budget', 'IF', 'Others'], item.value);
            // }
            return true;
          })
          .value(),
      }),
      render(val, item) {
        const content = (
          <>
            <span>{`SFDC: ${item.budgetCategoryDefault}`}</span>
            <br />
            <span>{`CMC: ${item.budgetCategory}`}</span>
          </>
        );
        return item.isChildren ? (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={content}>
            <div style={{ textAlign: 'center' }}>{val ?? item.budgetCategory}</div>
          </Tooltip>
        ) : (
          <div style={{ textAlign: 'center' }}>-</div>
        );
      },
    },
    {
      title: t('aipcmcty.page.orderAmount'),
      dataIndex: 'orderAmount',
      key: 'orderAmount',
      width: 150,
      className: 'align-right small-column',
      sorter: (a, b) => {
        if (accountingPerspective == 4) {
          return a.orderAmountStandalone - b.orderAmountStandalone;
        }
        return a.orderAmount - b.orderAmount;
      },
      onCell: (record) => ({
        record,
        inputType: 'amount',
        dataIndex: accountingPerspective == 4 ? 'orderAmountStandalone' : 'orderAmount',
        title: t('aipcmcty.page.orderAmount'),
        editing: isEditing(record),
      }),
      render(val, item) {
        let orderDefault = item.orderAmountDefault;
        if (item.children && item.children.length) {
          if (accountingPerspective === 4) {
            val = item.children.reduce((prev, c) => prev + c.orderAmountStandalone, 0);
            orderDefault = item.orderAmountStandaloneDefault;
          } else {
            val = item.children.reduce((prev, c) => prev + c.orderAmount, 0);
          }
        } else {
          if (accountingPerspective === 4) {
            val = item.orderAmountStandalone;
            orderDefault = item.orderAmountStandaloneDefault;
          }
        }
        const toolTip = (
          <>
            <span>{`SFDC: ${fixed(orderDefault).toLocaleString()}`}</span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < orderDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > orderDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={toolTip} trigger="hover" placement="topRight">
            <div>{fixed((val || 0) / 1000000).toLocaleString()}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.orderAmountDetail'),
      dataIndex: 'orderAmountStandalone',
      key: 'orderAmountStandalone',
      width: 120,
      className: 'align-right small-column',
      sorter: (a, b) => {
        return a.orderAmountStandalone - b.orderAmountStandalone;
      },
      render(val, record) {
        val = val || 0;
        const content = (
          <>
            <span>{`SFDC: ${record.orderAmountStandaloneDefault?.toLocaleString()}`}</span>
          </>
        );
        val = val / 1000000;
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={content}>
            <div style={{ textAlign: 'right' }}>{fixed(val).toLocaleString() || '-'}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.grossProfitAmt'),
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      width: 130,
      className: 'align-right small-column',
      onCell: (record) => ({
        record,
        dataIndex: accountingPerspective == 4 ? 'grossProfitStandalone' : 'grossProfit',
        title: t('aipcmcty.page.grossProfitAmt'),
      }),
      sorter: (a, b) => {
        if (accountingPerspective == 4) {
          return a.grossProfitStandalone - b.grossProfitStandalone;
        }
        return a.grossProfit - b.grossProfit;
      },
      render: (val, item) => {
        let grossDefault = item.grossProfitDefault;
        if (item.children && item.children.length) {
          if (accountingPerspective === 4) {
            val = item.children.reduce((prev, c) => prev + c.grossProfitStandalone, 0);
            grossDefault = item.grossProfitStandaloneDefault;
          } else {
            val = item.children.reduce((prev, c) => prev + c.grossProfit, 0);
          }
        } else {
          if (accountingPerspective === 4) {
            val = item.grossProfitStandalone;
            grossDefault = item.grossProfitStandaloneDefault;
          }
        }
        let classNames = 'project-number';
        const toolTip = (
          <>
            <span>{`SFDC: ${!isNil(grossDefault) ? fixed(grossDefault).toLocaleString() : grossDefault}`}</span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={toolTip} trigger="hover" placement="top">
            <span className={classNames}>{fixed((val || 0) / 1000000).toLocaleString()}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.grossProfitRate'),
      dataIndex: 'grossMargin',
      key: 'grossMargin',
      width: 90,
      className: 'align-right small-column',
      sorter: (a, b) => a.grossMargin - b.grossMargin,
      onCell: (record) => ({
        record,
        inputType: 'percent',
        dataIndex: accountingPerspective === 4 ? 'grossMarginStandalone' : 'grossMargin',
        title: t('aipcmcty.page.grossProfitRate'),
        editing: isEditing(record),
      }),
      render(val, item) {
        let defalutValue = item.grossMarginDefault;
        if (accountingPerspective === 4) {
          val = item.grossMarginStandalone;
          defalutValue = item.grossMarginStandaloneDefault;
          if (isNil(val)) {
            return '-';
          }
        }
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < defalutValue) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > defalutValue) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${round(defalutValue * 100).toFixed(1)}%`}>
            <div>
              <span>{`${round(val * 100).toFixed(1)}%`}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.resSimulation.demandForecastType'),
      dataIndex: 'demandForecastType',
      key: 'demandForecastType',
      className: 'align-center small-column',
      width: 120,
      render: (val) => <div>{val || '-'}</div>,
    },
    {
      title: `${t('aipcmcty.page.demand')}MH`,
      dataIndex: 'demandMh',
      key: 'demandMh',
      className: 'align-right small-column',
      width: 100,
      sorter: (a, b) => a.demandMh - b.demandMh,
      render: (val) => <div>{val ? Number(Number(val).toFixed(0)).toLocaleString() : val || '-'}</div>,
    },
    {
      title: t('aipcmcty.page.resSimulation.stackMH'),
      dataIndex: 'monthDemandMH',
      key: 'monthDemandMH',
      className: 'align-right small-column',
      width: 100,
      sorter: (a, b) => Number(a.monthDemandMH ?? 0) - Number(b.monthDemandMH ?? 0),
      render: (val) => <div>{val ? Number(val).toLocaleString() : val || '-'}</div>,
    },
    {
      title: t('aipcmcty.page.startDate'),
      dataIndex: 'projectStartDate',
      key: 'projectStartDate',
      width: 130,
      className: 'align-right small-column',
      sorter: (a, b) => {
        if (moment(a.projectStartDate).isBefore(b.projectStartDate)) {
          return -1;
        }
        if (moment(a.projectStartDate).isAfter(b.projectStartDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const curMoment = moment(val);
        const defaultMoment = moment(item.projectStartDateDefault);
        let classNames = '';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (curMoment.isBefore(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date less';
        }
        if (curMoment.isAfter(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date greater';
        }
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}>
            <div style={{ textAlign: 'center' }}>{curMoment.format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.endDate'),
      dataIndex: 'projectEndDate',
      key: 'projectEndDate',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => {
        if (moment(a.projectEndDate).isBefore(b.projectEndDate)) {
          return -1;
        }
        if (moment(a.projectEndDate).isAfter(b.projectEndDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const defaultMoment = moment(item.projectEndDateDefault);
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}>
            <div style={{ textAlign: 'center' }}>{moment(val).format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.duration'),
      dataIndex: 'duration',
      key: 'duration',
      width: 70,
      className: 'align-right small-column',
      sorter: (a, b) => a.duration - b.duration,
      render(val, item) {
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }

        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${item.durationDefault}`}>
            <div>{val}</div>
          </Tooltip>
        );
      },
    },
    {
      title: `${t('aipcmcty.page.grossProfit')}/MH`,
      dataIndex: 'grossProfitPerMh',
      key: 'grossProfitPerMh',
      width: 90,
      className: 'align-right small-column',
      sorter: (a, b) => a.grossProfitPerMh - b.grossProfitPerMh,
      render: (val) => (val ? fixed(val).toLocaleString() : val || '-'),
    },
    {
      title: 'Won%',
      dataIndex: 'won',
      key: 'won',
      width: 80,
      className: 'align-right small-column',
      sorter: (a, b) => a.won - b.won,
      render: (val, record) => (!record.isChildren ? `${round(val * 100)}%` : `-`),
    },
    {
      title: t('aipcmcty.page.accuracy'),
      dataIndex: 'certainty',
      key: 'certainty',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => a.phase.localeCompare(b.certainty),
      render: (val, record) =>
        !record.isChildren ? (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val}>
            <div className="multi-ellipsis-text">{val || '-'}</div>
          </Tooltip>
        ) : (
          <>-</>
        ),
    },
    {
      title: `${t('aipcmcty.page.constructionSite')}`,
      dataIndex: 'constructionLocation',
      key: 'constructionLocation',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aLoc = a.constructionLocation ?? '';
        const bLoc = b.constructionLocation ?? '';
        return aLoc.localeCompare(bLoc);
      },
      render: (val) => (
        <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val ?? '-'} placement="topLeft">
          <div className="multi-ellipsis-text">{val ?? '-'}</div>
        </Tooltip>
      ),
    },
    {
      title: 'EPC/非EPC',
      dataIndex: 'isEpc',
      key: 'isEpc',
      width: 100,
      className: 'small-column',
      sorter: (a, b) => a.phase?.localeCompare(b.certainty),
      render: (val, record) =>
        !record.isChildren ? (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val ?? '-'} placement="topLeft">
            <div className="multi-ellipsis-text">{val ?? '-'}</div>
          </Tooltip>
        ) : (
          <>-</>
        ),
    },
    {
      title: '新規/既存',
      dataIndex: 'isExisted',
      key: 'isExisted',
      width: 90,
      className: 'small-column',
      sorter: (a, b) => a.phase?.localeCompare(b.certainty),
      render: (val, record) =>
        !record.isChildren ? (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val ?? '-'} placement="topLeft">
            <div className="multi-ellipsis-text">{val ?? '-'}</div>
          </Tooltip>
        ) : (
          <>-</>
        ),
    },
    {
      title: '役務範囲',
      dataIndex: 'scope',
      key: 'scope',
      width: 120,
      className: 'small-column',
      sorter: (a, b) => a.phase?.localeCompare(b.certainty),
      render: (val) => (
        <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val ?? '-'} placement="topLeft">
          <div className="multi-ellipsis-text">{val ?? '-'}</div>
        </Tooltip>
      ),
    },
    {
      title: 'DX Allow. Grossの総額',
      dataIndex: 'proposalDxAllowGrossAllJpy',
      key: 'proposalDxAllowGrossAllJpy',
      width: 190,
      className: 'align-right small-column',
      sorter: (a, b) => {
        const value1 = !isNil(a.proposalDxAllowGrossAllJpy) ? a.proposalDxAllowGrossAllJpy : a.presalesDxAllowGrossAllJpy;
        const value2 = !isNil(b.proposalDxAllowGrossAllJpy) ? b.proposalDxAllowGrossAllJpy : b.presalesDxAllowGrossAllJpy;
        return (value1 || 0) - (value2 || 0);
      },
      render: (_, record) => {
        if (record.isChildren) {
          return <>-</>;
        }
        const value = !isNil(record.proposalDxAllowGrossAllJpy) ? record.proposalDxAllowGrossAllJpy : record.presalesDxAllowGrossAllJpy;
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={value ?? '-'} placement="topLeft">
            <div className="multi-ellipsis-text">{!isNil(value) ? fixed(value).toLocaleString() : '-'}</div>
          </Tooltip>
        );
      },
    },
    {
      title: 'DX Allow.積込率',
      dataIndex: 'DXAllow',
      key: 'DXAllow',
      width: 130,
      className: 'align-right small-column',
      sorter: (a, b, sortOrder) => {
        const hasAValues = !isNil(a.proposalDxAllowGrossAllJpy) && a.presalesDxAllowGrossAllJpy;
        const hasBValues = !isNil(b.proposalDxAllowGrossAllJpy) && b.presalesDxAllowGrossAllJpy;
    
        // 昇順の場合
        if (sortOrder === 'ascend') {
          if (!hasAValues && !hasBValues) return 0;
          if (!hasAValues) return 1;
          if (!hasBValues) return -1;
    
          const num1 = a.proposalDxAllowGrossAllJpy / a.presalesDxAllowGrossAllJpy;
          const num2 = b.proposalDxAllowGrossAllJpy / b.presalesDxAllowGrossAllJpy;
          return num1 - num2;
        }
    
        // 降順の場合
        if (sortOrder === 'descend') {
          if (!hasAValues && !hasBValues) return 1;
          if (!hasAValues) return -1;
          if (!hasBValues) return 1;
    
          const num1 = a.proposalDxAllowGrossAllJpy / a.presalesDxAllowGrossAllJpy;
          const num2 = b.proposalDxAllowGrossAllJpy / b.presalesDxAllowGrossAllJpy;
          return num1- num2;
        }
    
        // デフォルトのソート順（昇順）
        if (!hasAValues && !hasBValues) return 0;
        if (!hasAValues) return 1;
        if (!hasBValues) return -1;
    
        const num1 = a.proposalDxAllowGrossAllJpy / a.presalesDxAllowGrossAllJpy;
        const num2 = b.proposalDxAllowGrossAllJpy / b.presalesDxAllowGrossAllJpy;
        return num1 - num2;
      },
      render: (_, record) => {
        if (record.isChildren || isNil(record.proposalDxAllowGrossAllJpy) || !record.presalesDxAllowGrossAllJpy) {
          return <>-</>;
        }
        const num = record.proposalDxAllowGrossAllJpy / record.presalesDxAllowGrossAllJpy;
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={num} placement="topLeft">
            <div className="multi-ellipsis-text">{!isNil(num) ? `${(num * 100).toFixed(2)}%` : '-'}</div>
          </Tooltip>
        );
      },
    },
    {
      title: '受注月調整',
      dataIndex: 'offsetMonths',
      key: 'offsetMonths',
      fixed: 'right',
      width: 130,
      className: 'small-column',
      onCell: (record) => {
        record.offsetMonths = dayjs(record.orderScheduledDate);
        return {
          record,
          inputType: 'datepicker',
          dataIndex: 'offsetMonths',
          title: '受注月調整',
          editing: isEditing(record),
        };
      },
      render(_, record) {
        const defaultMoment = dayjs(record.orderScheduledDateDefault);
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM')}`}>
            <div style={{ textAlign: 'center' }}>{dayjs(record.orderScheduledDate).format('YYYY-MM')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 90,
      render: (_, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <>
              <Button size="small" type="link" icon={<CheckOutlined />} shape="circle" onClick={() => rowSaveClickFunc(record.id)} />
              <Button size="small" type="link" icon={<CloseOutlined />} shape="circle" onClick={() => rowCancelClickFunc()} />
            </>
          );
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {((isPOrSCheck(record, useCase) && record.budgetCategoryCustom !== 'Awarded') || record.forceEdit) && (
              <>
                <Button
                  size="small"
                  type="link"
                  icon={<EditOutlined />}
                  shape="circle"
                  disabled={editingKey && editingKey !== record.id}
                  onClick={() => rowEditClickFunc(record)}
                />
                <Popconfirm
                  placement="topRight"
                  title={t('aipcmcty.page.resetProjectDefault').replace('${item.projectId}', record.projectId)}
                  onConfirm={() => {
                    handleTableChange(record.id, null, true);
                  }}
                  okText={t('aipcmcty.page.yes')}
                  cancelText={t('aipcmcty.page.cancel')}
                >
                  <Button
                    size="small"
                    type="link"
                    icon={<RollbackOutlined />}
                    shape="circle"
                    disabled={editingKey && editingKey !== record.id}
                  />
                </Popconfirm>
              </>
            )}
            {!record.isChildren && (
              <Badge dot={record.memoCount > 0} size="small" offset={[-4, 4]}>
                <Button
                  size="small"
                  type="link"
                  icon={<FormOutlined />}
                  shape="circle"
                  disabled={!!editingKey}
                  onClick={() => handleMemoClick(record)}
                />
              </Badge>
            )}
          </div>
        );
      },
    },
    // =========================================================
    // {
    //   title: `DX${t('aipcmcty.page.demand')}MH`,
    //   dataIndex: 'dxDemandMh',
    //   key: 'dxDemandMh',
    //   className: 'align-right small-column',
    //   width: 120,
    //   sorter: (a, b) => a.dxDemandMh - b.dxDemandMh,
    //   render: (val) => <div>{val ? Number(Number(val).toFixed(0)).toLocaleString() : val || '-'}</div>
    // },
    // {
    //   title: `DX${t('aipcmcty.page.grossProfitAmt')}`,
    //   dataIndex: 'dxGrossProfit',
    //   key: 'dxGrossProfit',
    //   width: 130,
    //   className: 'align-right small-column',
    //   sorter: (a, b) => a.dxGrossProfit - b.dxGrossProfit,
    //   render: (val) => fixed(val).toLocaleString() ?? '-'
    // },
    // {
    //   title: t('aipcmcty.page.phase'),
    //   dataIndex: 'phase',
    //   key: 'phase',
    //   width: 150,
    //   className: 'small-column',
    //   sorter: (a, b) => a.phase.localeCompare(b.phase),
    //   render(val, item) {
    //     const curOrder = optData?.phase.find((p) => p.value === val)?.order;
    //     const defaultOrder = optData?.phase.find(
    //       (p) => p.value === item.phaseDefault
    //     )?.order;
    //     let classNames = 'project-select left';
    //     if (!needDisable) {
    //       classNames += ' table-item-disable';
    //     }
    //     if (curOrder > defaultOrder) {
    //       classNames = classNames.replace(' table-item-disable', '');
    //       classNames += ' sfdc-select greater';
    //     }
    //     if (curOrder < defaultOrder) {
    //       classNames = classNames.replace(' table-item-disable', '');
    //       classNames += ' sfdc-select less';
    //     }

    //     return (
    //       <Tooltip
    //         mouseLeaveDelay={0}
    //         mouseEnterDelay={0.8}
    //         title={`SFDC: ${item.phaseDefault}`}
    //       >
    //         <div style={{ textAlign: 'center' }}>{val}</div>
    //       </Tooltip>
    //     );
    //   },
    // },
    // {
    //   title: t('aipcmcty.page.accumulatedOrderAmount'),
    //   dataIndex: 'orderAccumulation',
    //   key: 'orderAccumulation',
    //   fixed: 'right',
    //   width: 150,
    //   className: 'align-right small-column',
    //   render: (val) => fixed(val).toLocaleString(),
    // },
    // {
    //   title: t('aipcmcty.page.accumulatedGrossProfit'),
    //   dataIndex: 'profitAccumulation',
    //   key: 'profitAccumulation',
    //   fixed: 'right',
    //   width: 130,
    //   className: 'align-right small-column',
    //   render: (val) => fixed(val).toLocaleString()
    // },
    // {
    //   title: t('aipcmcty.page.dxApplicable'),
    //   dataIndex: 'dxApplicable',
    //   key: 'dxApplicable',
    //   fixed: 'right',
    //   width: 80,
    //   className: 'small-column',
    //   sorter: (a, b) => Number(a.dxApplicable) - Number(b.dxApplicable),
    //   render: (val, item) => (
    //     <Switch
    //       // disabled={!needDisable || !isDxMode || !item.dxAvailable || item.budgetCategoryCustom.endsWith('Awarded')}
    //       disabled={!isDxMode || !item.dxAvailable || item.budgetCategoryCustom.endsWith('Awarded')}
    //       checked={val}
    //       onChange={e => handleTableChange('dxApplicable', e, item.id)}
    //     />
    //   )
    // },
  ];

  /**
   * Table高度Map
   */
  const tableHeightMap = {
    'chart-table': selectorHeight4Table - 40,
    'table-only': selectorHeight4Table,
    'chart-only': 0,
  };

  const financialColumns = [
    'orderAmount',
    'grossProfit',
    'dxGrossProfit',
    'grossProfitPerMh',
    'grossMargin',
    'grossMarginDefault',
    'grossProfitDefault',
    'profitAccumulation',
    'orderAmountStandalone',
    'won',
    'certainty',
    'proposalDxAllowGrossAllJpy',
    'DXAllow',
  ];

  const colsFixedLeft = columns.filter((c) => c.fixed === 'left');
  const colsFixedRight = columns.filter((c) => c.fixed === 'right');
  const colsShow = columns.filter((c) => tableColsToShow.includes(c.key as string));
  const colsCombine = [...colsFixedLeft, ...colsShow, ...colsFixedRight];
  const filteredColumns = colsCombine.filter((c) => (isDxMode && consolidated ? true : c.key !== 'dxGrossProfit'));
  // const filteredColumns = colsCombine
  //   .filter((c) => !financialColumns.includes(c.key as string) || isFinancialInfoVisible())
  //   .filter((c) => (isDxMode && consolidated ? true : c.key !== 'dxGrossProfit'));
  const scrollX = filteredColumns.reduce((prev: number, curCol) => prev + (curCol.width as number), 0);

  const handleMemoClick = (record: ResourceRegulationTableItem) => {
    setMenuCollapsed(true);
    setFilterCollapsed(true);
    setMemoCollapsed(false);
    setMemoInfo({ projectId: record.projectId, projectName: record.projectName });
  };

  const rowEditClickFunc = (record: ResourceRegulationTableItem) => {
    const keys = getHandleKeys(accountingPerspective, useCase);
    const data = pick(record, keys);
    form.setFieldsValue({ ...data });
    cachedItem.current = { ...data };
    setEditingKey(record.id);
  };

  const rowCancelClickFunc = () => {
    setEditingKey('');
    cachedItem.current = null;
  };

  // 订阅清空Editor
  useEffect(() => {
    rowCancelClickFunc();
  }, [data]);

  const rowSaveClickFunc = (id: string) => {
    const keys = getHandleKeys(accountingPerspective, useCase);
    const formData = form.getFieldsValue();
    const cache = cachedItem.current;
    // const changed = [];
    const changed = {};
    forEach(keys, (key) => {
      const valueInForm = get(formData, key, 0);
      const valueInCache = get(cache, key, 0);
      const isDayjsOfForm = dayjs.isDayjs(valueInForm);
      const isDayjsOfCache = dayjs.isDayjs(valueInCache);
      if (
        (isDayjsOfForm && isDayjsOfCache && !valueInForm.isSame(valueInCache, 'month')) ||
        (!isDayjsOfForm && !isDayjsOfCache && !eq(valueInForm, valueInCache))
      ) {
        // changed.push({ key, value: valueInForm });
        set(changed, key, valueInForm);
      }
    });
    if (!isEmpty(changed)) {
      handleTableChange(id, changed);
    }
    rowCancelClickFunc();
  };

  const { snapshot, snapshotVersion } = useContext(AipcmctyContext);
  const { imbalancedMhOpt } = useContext(ProjectSetupContext);
  const { locale } = useContext(AppContext);
  const excelAoaDataSetup = () => {
    const filterList: any[] = [];
    const getValue = (value, SP = ', ') => {
      const EMPTY = '-';
      if (isArray(value)) {
        if (value.length === 0 || value.every((item) => isNil(item) || item === '')) {
          return EMPTY;
        }
        return value.join(SP);
      }
      if (isNil(value) || value === '') {
        return EMPTY;
      }

      return value;
    };
    filterList.push(['Snapshot Version', 'snapshot', getValue(snapshot)]);
    filterList.push(['', 'snapshot version', getValue(snapshotVersion)]);
    filterList.push(['Head Filter', 'ユースケース', getValue(useCaseOpts.find((item) => useCase === item.value)?.label)]);
    filterList.push(['', '会計観点', getValue(accountingPerspectiveOpt.find((item) => accountingPerspective === item.value)?.label)]);
    filterList.push(['', 'TGC', getValue(filtersData.tgc)]);
    filterList.push(['', '本部', getValue(filtersData.department)]);
    filterList.push(['', 'DX', getValue(filtersData.dxApplicable)]);
    filterList.push([
      'Sider Filter',
      `${t('aipcmcty.page.projectCaseName')} / ${t('aipcmcty.page.projectCaseId')}`,
      getValue(filtersData.search),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.resSimulation.ppReport'),
      getValue(
        getValue(
          [
            { label: t('aipcmcty.page.resSimulation.isReportObj'), value: true, key: t('aipcmcty.page.resSimulation.isReportObj') },
            {
              label: t('aipcmcty.page.resSimulation.isNotReportObj'),
              value: false,
              key: t('aipcmcty.page.resSimulation.isNotReportObj'),
            },
          ].find((item) => item.value === filtersData.isForPpReport)?.label
        )
      ),
    ]);
    filterList.push(['', t('aipcmcty.page.fiscalYear'), getValue(filtersData.accountingYear)]);
    filterList.push(['', t('aipcmcty.page.budgetCategory'), getValue(filtersData.budgetCategoryCustom)]);
    filterList.push(['', t('aipcmcty.page.serviceScope'), getValue(filtersData.scope)]);
    filterList.push([
      '',
      `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`,
      getValue(locale.locale === 'ja' ? filtersData.isExisted : localeMapping[filtersData.isExisted]),
    ]);
    filterList.push(['', `${t('aipcmcty.page.nonEPC')}/EPC}`, getValue(filtersData.isEpc)]);
    filterList.push([
      '',
      t('aipcmcty.page.businessType'),
      getValue(filtersData.businessType.map((businessType) => (locale.locale === 'ja' ? businessType : localeMapping[businessType]))),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.productType'),
      getValue(filtersData.goodsType.map((goodsType) => (locale.locale === 'ja' ? goodsType : localeMapping[goodsType]))),
    ]);
    filterList.push(['', t('aipcmcty.page.priorityLevel'), getValue(filtersData.priorityDefault, ' ～ ')]);
    filterList.push(['', t('aipcmcty.page.orderAmount'), getValue(filtersData.orderAmount)]);
    filterList.push([
      '',
      t('aipcmcty.page.demandSupplyFilterMH'),
      getValue(imbalancedMhOpt.find((item) => item.value === filtersData.imbalancedMh)?.label),
    ]);
    filterList.push(['', 'Won%', getValue(filtersData.won, ' ～ ')]);
    filterList.push([
      '',
      t('aipcmcty.page.phase'),
      getValue(filtersData.phase.map((phase) => (locale.locale === 'ja' ? phase : localeMapping[phase]))),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.accuracy'),
      getValue(filtersData.certainty.map((certainty) => (locale.locale === 'ja' ? certainty : localeMapping[certainty]))),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.startDate'),
      getValue(filtersData.projectStartDate ? moment(filtersData.projectStartDate).format('YYYY-MM-DD') : null),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.endDate'),
      getValue(filtersData.projectEndDate ? moment(filtersData.projectEndDate).format('YYYY-MM-DD') : null),
    ]);

    filterList.push(['Memo Filter', t('aipcmcty.page.memo.keyWord'), getValue(memoFilter.memoKeyWord)]);
    filterList.push([
      '',
      t('aipcmcty.page.memo.author'),
      getValue(optData.memoCreators.filter((item) => memoFilter.creator.includes(item.value)).map((item) => item.label)),
    ]);
    filterList.push(['', t('aipcmcty.page.memo.creationPeriod'), getValue([memoFilter.createdAtStart, memoFilter.createdAtEnd], ' ～ ')]);
    filterList.push(['', t('aipcmcty.page.memo.tag'), getValue(memoFilter.memoTag)]);
    return {
      filterList,
    };
  };

  const [downloadLoading, setDownloadLoading] = useState(false);
  const excelAwaitList = async () => {
    setDownloadLoading(true);
    const projectInfoRequest = getExportInfoFunc();
    const [source, memo] = await Promise.all([
      APIList.getProjectAndSubExportInfo().post({
        snapshot,
        snapshotVersion,
        ...projectInfoRequest,
        consolidated: accountingPerspective !== 4,
      }),
      APIList.getExportProjectSetup().get({
        ...memoFilter,
        snapshot,
        snapshotVersion,
      }),
    ]).finally(() => {
      setDownloadLoading(false);
    });
    const mList = memo?.memoList
      ?.map((memo: any) => {
        const item = {
          ...memo,
          createdAt: new Date(memo.createdAt).toLocaleString('ja-JP', {
            hour12: false,
          }),
          updatedAt: new Date(memo.updatedAt).toLocaleString('ja-JP', {
            hour12: false,
          }),
        };
        return item;
      })
      .filter((m: any) => data.some((d: any) => m.projectId === d.projectId));
    return {
      projectData: source.project,
      subProjectData: source.subProject,
      memoList: mList,
    };
  };

  return (
    <div className="table-container" style={{ position: 'relative' }}>
      <Form form={form} component={false}>
        <Table
          loading={loading}
          className="project-table"
          style={{ marginTop: 5, paddingBottom: 40, backgroundColor: '#fff' }}
          columns={filteredColumns}
          components={{
            body: { cell: EditableCell },
          }}
          rowSelection={{
            hideSelectAll: true,
            checkStrictly: true,
            selectedRowKeys: selectedProjects.map((s) => s.projectId),
            getCheckboxProps: (record) => ({
              disabled: record.budgetCategoryCustom === 'Awarded' && !record.forceEdit,
            }),
            onCell: (record) => {
              const configure = { className: null };
              if (!isPOrSCheck(record, useCase)) {
                configure.className = 'cmcty-table-checkbox-hidden';
              }
              return configure;
            },
            onSelect: onSelected,
          }}
          expandable={{
            onExpand(expanded, record) {
              const { projectId } = record;
              onExpandChange?.(projectId, expanded, record);
            },
            expandedRowKeys,
          }}
          dataSource={data}
          scroll={{ x: scrollX, y: tableHeightMap[viewMode] }}
          rowKey="projectId"
          size="small"
          pagination={{
            position: ['bottomRight'],
            pageSize: pageSize,
            pageSizeOptions: [50, 100, 200, 500, 1000],
          }}
          rowClassName={(record) => {
            // const addBgKeys = ['Toyo-J', 'Toyo-I'];
            // const {
            //   imbalancedMh, budgetCategoryCustom, tgc, resourceDemandMh
            // } = record;
            // if (record.budgetCategoryCustom === 'Exclusion') {
            //   return 'light-gray';
            // }
            // if (
            //   addBgKeys.includes(tgc)
            //   && imbalancedMh > (resourceDemandMh * demandMhPercent) / 100
            // ) {
            //   return 'light-red';
            // }
            // if (
            //   addBgKeys.includes(tgc)
            //   && ['Awarded', 'Budget'].includes(budgetCategoryCustom)
            //   && imbalancedMh < (resourceDemandMh * demandMhPercent) / 100
            // ) {
            //   return 'light-green';
            // }
            if (selectedProjects.map((s) => s.projectId).includes(record.projectId)) {
              return 'light-selected';
            }
            return '';
          }}
          locale={{
            emptyText: (
              <Empty
                style={{ height: selectorHeight4Table - 105 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('aipcmcty.page.noData')}
              />
            ),
          }}
          onChange={(pagination) => {
            onPageSizeChange(pagination.pageSize);
          }}
        />
      </Form>
      <Space
        style={{
          position: 'absolute',
          left: 4,
          zIndex: 100,
          bottom: 6,
        }}
      >
        <Button type="primary" disabled={!needDisable || loading} onClick={onDemandUpdate}>
          {t('aipcmcty.page.resSimulation.saveChanges')}
        </Button>
        <Button
          type="primary"
          disabled={loading}
          loading={downloadLoading}
          onClick={async () => {
            const { projectData, subProjectData, memoList } = await excelAwaitList();
            const { filterList } = excelAoaDataSetup();
            exportTableFromExcelTemp({
              fileName: `project_list_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`,
              templateFilePath: '/assets/project_list2.xlsx',
              sheetData: [
                {
                  sheet: '案件一覧',
                  dataType: 'json',
                  data: projectData,
                  options: {
                    templateMappingKeyRowIndex: 3,
                    insertRowIndex: 3,
                  },
                },
                {
                  sheet: 'サブ案件一覧',
                  dataType: 'json',
                  data: subProjectData,
                  options: {
                    templateMappingKeyRowIndex: 2,
                  },
                },
                {
                  sheet: 'メモ一覧',
                  dataType: 'json',
                  data: memoList,
                  options: {
                    templateMappingKeyRowIndex: 2,
                  },
                },
                {
                  sheet: 'フィルタ設定',
                  dataType: 'aoa',
                  data: filterList,
                  options: {
                    merge: [
                      {
                        start: [0, 0],
                        end: [1, 0],
                      },
                      {
                        start: [2, 0],
                        end: [6, 0],
                      },
                      {
                        start: [7, 0],
                        end: [23, 0],
                      },
                      {
                        start: [24, 0],
                        end: [27, 0],
                      },
                    ],
                  },
                },
              ],
            });
          }}
        >
          Export to Excel
        </Button>
      </Space>
    </div>
  );
};

export default ResourceRegulationTable;
