import { cloneDeep } from 'lodash';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiffResponse } from '../../pages/comparison/project';
import { AppContext } from '../../contexts/AppContext';
import { TableChart } from '@meteor/frontend-core';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';

type DiffChartProps = {
  data: DiffResponse;
  loading: boolean;
  graphLoading: boolean;
  graphHeight?: number;
  title?: string | ReactNode;
};
const roundToTwoDecimalPlaces = (value) => {
  return (Math.round((value / 100000000) * 100) / 100).toFixed(2);
};
const PhaseDiffChart = (props: DiffChartProps) => {
  const { data, loading, graphLoading, graphHeight = 340, title } = props;

  const { sizeMode, color } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);

  const { t } = useTranslation();

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
      xAxis: 10,
      yAxis: 10,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const mapPhaseType = (phaseStr: string) => {
    switch (true) {
      case ['プロポーザル'].includes(phaseStr):
        return 'Proposal';
      case ['ネゴシエーション'].includes(phaseStr):
        return 'Negotiation';
      case ['案件受注'].includes(phaseStr):
        return 'Awarded';
      case ['初期計画段階', '投資検討中', '投資方針決定', 'FID手続き中'].includes(phaseStr):
      default:
        return 'Pre-Sales';
    }
  };
  const legendOrder = ['Awarded', 'Negotiation', 'Proposal', 'Pre-Sales'];

  const [phaseGraphOptions, setPhaseGraphOptions] = useState({
    title: {
      value: t('aipcmcty.page.comparison.grossProfitDiffTitle'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
        data: [],
      },
      yAxis: {
        type: 'value',
        name: '億',
        position: 'left',
        axisLine: {
          show: true,
        },
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
      },
      series: [],
    },
    height: 220,
  });
  const updatePhaseGraphOptions = () => {
    const bars = {
      Awarded: 'rgb(9,59,94)',
      Negotiation: 'rgb(26,79,153)',
      Proposal: 'rgb(50,153,217)',
      'Pre-Sales': 'rgb(159,217,246)',
    };
    const sortedChartData = data.phaseAndOrderGroup.sort((a, b) => {
      const aPhase = mapPhaseType(a.phase);
      const bPhase = mapPhaseType(b.phase);
      return legendOrder.indexOf(aPhase) - legendOrder.indexOf(bPhase);
    });
    const newSeriesData = sortedChartData.map((item) => {
      const phaseType = mapPhaseType(item.phase);
      return {
        name: phaseType,
        type: 'bar',
        stack: 'one',
        data: [roundToTwoDecimalPlaces(item.targetGp), roundToTwoDecimalPlaces(item.currentGp)],
        itemStyle: {
          color: bars[phaseType],
        },
        barWidth: '50px',
      };
    });

    // const goalLine = {
    //   name: t('aipcmcty.page.comparison.goal'),
    //   type: 'line',
    //   step: 'middle',
    //   data: [15, 15],
    //   symbol: 'none',
    //   lineStyle: {
    //     color: color.warningColor,
    //     width: 2,
    //     type: 'dashed',
    //   },
    //   itemStyle: {
    //     color: color.warningColor,
    //   },
    // };

    const mergedPhaseOptions = cloneDeep(phaseGraphOptions);

    mergedPhaseOptions.chartOptions.series = [...newSeriesData];

    mergedPhaseOptions.chartOptions.xAxis.data = ['前回', '今回'];

    setPhaseGraphOptions(mergedPhaseOptions);
  };
  useEffect(() => {
    updatePhaseGraphOptions();
  }, [data]);

  return (
    <>
      <TableChart
        showTable={false}
        chartOptions={phaseGraphOptions.chartOptions}
        title={
          title
            ? {
                value: title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : phaseGraphOptions.title
        }
        height={graphHeight}
        loading={loading}
        titleLoading={!loading && graphLoading}
      />
    </>
  );
};

export default PhaseDiffChart;
