import React, { ReactNode, useContext, useEffect, useState } from 'react';
import MonthlyBusinessReportTable from '../../components/tables/monthly-business-report.table';
import { Row, Col, Select, Space, DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import APIList from '../../http/ApiList';
import { exportTableFromExcelTemp } from '../../utils/xlsxReader';

const MonthlyBusinessReport: React.FC = () => {
  const { fiscalQuarter } = useContext(AppContext);
  const { snapshot, snapshotVersion } = useContext(AipcmctyContext);
  const calculateFiscalYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const fiscalYear = month < 4 ? year - 1 : year;
    const fiscalYearShort = `FY${fiscalYear.toString().slice(-2)}`;
    return fiscalYearShort;
  };
  const [fiscalYear, setFiscalYear] = useState(calculateFiscalYear());
  const [startYear, setStartYear] = useState(dayjs().format('YYYY'));
  const [startMonth, setStartMonth] = useState(dayjs().format('MM'));
  const [accountingPerspective, setAccountingPerspective] = useState('1');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const accountingPerspectiveOpt = [
    { value: '1', label: '連結＋持分 ' },
    { value: '2', label: '拠点単体 ' },
  ];
  const fiscalYearOpts = fiscalQuarter.map((f) => ({
    label: f,
    value: f,
    key: f,
  }));
  const fetchData = async () => {
    setLoading(true);
    const { monthlyReportList } = await APIList.getMonthlyReport().post({
      snapshot,
      version: snapshotVersion,
      fiscalYear: fiscalYear,
      currentYear: startYear,
      currentMonth: startMonth,
      type: accountingPerspective,
    });
    setLoading(false);
    setTableData(monthlyReportList);
  };
  useEffect(() => {
    fetchData();
  }, [snapshot, snapshotVersion, fiscalYear, startYear, startMonth, accountingPerspective]);
  const categoryToNoMap = {
    'Toyo-Japan': '1',
    営業統括本部: '1-1',
    CN本部: '1-2',
    海外営業: '1-3',
    国内営業: '1-4',
    OFS本部: '1-5',
    'Toyo-India': '2',
    'Toyo-Korea': '3',
    'Toyo-Malaysia': '4',
    'Toyo-China': '5',
    IKPT: '6',
    TPS: '7',
    TSPI: '8',
    OFS: '9',
  };

  const transformData = (apiResponse, categoryToNoMap) => {
    const result = {};

    apiResponse.forEach((item) => {
      const category = item.category;
      const no = categoryToNoMap[category];
      item.no = no;
      result[category] = item;
    });

    return result;
  };
  const transformedData = transformData(tableData, categoryToNoMap);
  const generateCellData = (transformedData) => {
    const cellData = {};

    const categoryOrder = [
      'Toyo-Japan',
      '営業統括本部',
      'CN本部',
      '海外営業',
      '国内営業',
      'OFS本部',
      'Toyo-India',
      'Toyo-Korea',
      'Toyo-Malaysia',
      'Toyo-China',
      'IKPT',
      'TPS',
      'TSPI',
      'OFS',
    ];

    const fieldMap = {
      D: 'orderAmountGoal',
      E: 'orderAmountActual',
      F: 'orderAmountForecast',
      H: 'orderGrossProfitGoal',
      I: 'orderGrossProfitActual',
      J: 'orderGrossProfitForecast',
      K: 'grossMarginActual',
    };

    categoryOrder.forEach((category, index) => {
      const row = 3 + index;
      const categoryData = transformedData[category];
      for (const [column, field] of Object.entries(fieldMap)) {
        const cellKey = `${column}${row}`;
        cellData[cellKey] = categoryData ? categoryData[field] : null;
      }
    });

    return cellData;
  };

  const handleExport = async () => {
    setExportLoading(true);
    await exportTableFromExcelTemp({
      fileName: `business_report_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`,
      templateFilePath: '/assets/month_business_report_template.xlsx',
      cellData: [
        { sheet: '連結+持分', data: generateCellData(transformedData) },
        { sheet: '拠点単体', data: generateCellData(transformedData) },
      ],
    });
    setExportLoading(false);
  };
  return (
    <>
      <Row style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Col style={{ padding: '5px 5px' }}>
          <Space>
            財務年度:
            <Select style={{ width: 200 }} options={fiscalYearOpts} defaultValue={calculateFiscalYear} onChange={(v) => setFiscalYear(v)} />
          </Space>
        </Col>
        <Col style={{ padding: '5px 5px' }}>
          <Space>
          カットオフ:
            <DatePicker
              onChange={(date, dateString: string) => {
                const [y, m] = dateString.split('-');
                setStartYear(y);
                setStartMonth(m);
              }}
              defaultValue={dayjs()}
              picker="month"
              style={{ width: 180 }}
              renderExtraFooter={() => null}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px 5px' }}>
          <Space>
            会計観点:
            <Select
              style={{ width: 200 }}
              options={accountingPerspectiveOpt}
              defaultValue={'1'}
              onChange={(v) => {
                setAccountingPerspective(v);
              }}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px 5px', marginLeft: 'auto' }}>
          <Button type="primary" loading={exportLoading} onClick={handleExport}>
            Export
          </Button>
        </Col>
      </Row>
      <MonthlyBusinessReportTable data={tableData.map((e) => ({ ...e, no: categoryToNoMap[e.category] }))} loading={loading} />
    </>
  );
};

export default MonthlyBusinessReport;
