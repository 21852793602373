import { useContext, useMemo, useState } from 'react';
import { AipcmctyContext } from '../contexts/aipcmcty.context';
import { cloneDeep, isEmpty } from 'lodash';
import menuRoutes from '../routes';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import APIList from '../http/ApiList';

const useAuth = () => {
  // 現在のルートキーを取得
  const location = useLocation();
  const currRouteKey = useMemo(() => location?.pathname?.split('/')?.at(-1) ?? '', [location]);
  const { authInfo } = useContext(AppContext);
  const { user } = authInfo || {};
  const { role = [] } = user || {};

  const auth = useMemo(() => {
    return role.reduce((curr, next) => {
      const { route, auth } = next;
      return {
        ...curr,
        [route]: {
          authorized: auth !== '' ? 1 : 0,
          isfinancialinfovisible: !auth.includes('mh') ? 1 : 0,
          isalltgcisible: auth.includes('all') ? 1 : 0,
        },
      };
    }, {});
  }, [role]);

  /**
   * ロートに権限情報を追加する
   * @param routes ルートリスト
   * @returns 権限情報付け加えたルート
   */
  const addAuthToRoute = (routes: any) => {
    const authedRoutes = [];
    for (let route of routes) {
      if (auth[route.path]) {
        route = {
          ...route,
          ...auth[route.path],
        };
      }
      if (route?.children?.length > 0) {
        route.children = addAuthToRoute(route.children);
      }
      authedRoutes.push(route);
    }
    return authedRoutes;
  };

  // 権限情報付け加えたルート取得
  const authRoutes = useMemo(() => {
    if (isEmpty(role)) {
      return {};
    }
    return {
      ...menuRoutes,
      // routes: menuRoutes.routes.map((route: any) => {
      //   route.authorized = 1;
      //   route.isalltgcisible = 1;
      //   route.isfinancialinfovisible = 1;
      //   if (route?.children?.length > 0) {
      //     route.children = route.children.map((child) => {
      //       child.authorized = 1;
      //       child.isalltgcisible = 1;
      //       child.isfinancialinfovisible = 1;
      //       return child;
      //     });
      //   }
      //   return route;
      // }),
      routes: addAuthToRoute(cloneDeep(menuRoutes.routes)),
    };
  }, [auth]);

  /**
   * 財務情報が表示されるかどうかを判定する
   */
  const isFinancialInfoVisible = () => {
    return auth[currRouteKey]?.isfinancialinfovisible ? true : false;
  };

  return {
    authRoutes,
    isFinancialInfoVisible,
  };
};

export default useAuth;
