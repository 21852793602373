import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Radio, RadioChangeEvent, Row, Select, Space, Table } from 'antd';
import { groupBy, isFunction, isNil, map, mergeWith, sum } from 'lodash';
import APIList from '../../http/ApiList';
import { ChartGroup, ChartTableLayout } from '@meteor/frontend-core';
import InvestmentReturnChart from '../../components/charts/investment-return.chart';
import { AppContext } from '../../contexts/AppContext';
import { useWindowSize } from '../../hooks/useWindowSize';
import useVersion from '../../hooks/useVersion';
import { useTranslation } from 'react-i18next';
import Paramter from '../../components/paramter';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';

type Option = {
  label: ReactNode;
  value: any;
  key?: any;
  [key: string]: any;
};

type InvestmentReturnItem = {
  /** 财务年度 */
  fiscalYear: string;
  /** DX効果（Allow.+課金）_Plan❶当初計画 */
  dxAPlan01: number;
  /** Plan❶ DX Allowance */
  dxAllowNetPlan01: number;
  /** Plan❶ DX 課金 */
  dxChargePlan01: number;
  /** DX効果（Allow.+課金）_Plan❷期初計画 */
  dxAPlan02: number;
  /** Plan❷ DX Allowance */
  dxAllowNetPlan02: number;
  /** Plan❷ DX 課金 */
  dxChargePlan02: number;
  /** DX効果（Allow.+課金）_最新計画 */
  dxAPlanLatest: number;
  /** DX効果（Allow.+課金）_最新計画 DX Allowance */
  dxAllowNetPlanLatest: number;
  /** DX効果（Allow.+課金）_最新計画 DX 課金 */
  dxChargePlanLatest: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル前 */
  dxAPlanLatestPreSale: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル前 DX Allowance */
  dxAllowNetPlanLatestPreSale: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル前 DX 課金 */
  dxChargePlanLatestPreSale: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル済み */
  dxAPlanLatestProposal: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル済み DX Allowance */
  dxAllowNetPlanLatestProposal: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル済み DX 課金 */
  dxChargePlanLatestProposal: number;
  /** DX効果（Allow.+課金）_最新計画 受注済み */
  dxAPlanLatestExecuted: number;
  /** DX効果（Allow.+課金）_最新計画 受注済み DX Allowance */
  dxAllowNetPlanLatestExecution: number;
  /** DX効果（Allow.+課金）_最新計画 受注済み DX 課金 */
  dxChargePlanLatestExecution: number;
  /** DX効果（Allow.+課金）_見込み+実績 */
  dxAForecastActualSum: number;
  /** DX効果（Allow.+課金）_見込み+実績 DX Allowance */
  dxAllowNetForecastActualSum: number;
  /** DX効果（Allow.+課金）_見込み+実績 DX 課金 */
  dxChargeForecastActualSum: number;
  /** DX効果（Allow.+課金）_見込み */
  dxAForecastSum: number;
  /** DX効果（Allow.+課金）_見込み DX Allowance */
  dxAllowNetForecastSum: number;
  /** DX効果（Allow.+課金）_見込み DX 課金 */
  dxChargeForecastSum: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル前 */
  dxAForecastPreSale: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル前 DX Allowance */
  dxAllowNetForecastPreSale: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル前 DX 課金 */
  dxChargeForecastPreSale: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル済み */
  dxAForecastProposal: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル済み DX Allowance */
  dxAllowNetForecastProposal: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル済み DX 課金 */
  dxChargeForecastProposal: number;
  /** DX効果（Allow.+課金）_見込み 受注済み */
  dxAForecastExecution: number;
  /** DX効果（Allow.+課金）_見込み 受注済み DX Allowance */
  dxAllowNetForecastExecution: number;
  /** DX効果（Allow.+課金）_見込み 受注済み DX 課金 */
  dxChargeForecastExecution: number;
  /** DX効果（Allow.+課金）_実績  */
  dxAActual: number;
  /** DX効果（Allow.+課金）_実績 DX Allowance */
  dxAllowNetActual: number;
  /** DX効果（Allow.+課金）_実績 DX 課金 */
  dxChargeActual: number;
  /** DX効果（Allow.+課金）_見込み+実績（コンテ込み） */
  dxAForecastActualSumCont: number;
  /** DX効果（Allow.+課金）_見込み（コンテ込み） */
  dxAForecastSumCont: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル前（コンテ込み） */
  dxAForecastPreSaleCont: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル済み（コンテ込み） */
  dxAForecastProposalCont: number;
  /** DX効果（Allow.+課金）_見込み 受注済み（コンテ込み） */
  dxAForecastExecutionCont: number;
  /** DX効果（Allow.+課金）_実績（コンテ込み） */
  dxAActualCont: number;
  /** DX投資額_Plan❶当初計画 */
  dxIPlan01: number;
  /** DX投資額_最新計画 */
  dxIPlanLatest: number;
  /** DX投資額_見込み+実績 */
  dxIForecastActualSum: number;
  /** Cumulative_Plan❶当初計画 */
  cumPlan01: number;
  /** Cumulative_Plan❷期初計画 */
  cumPlan02: number;
  /** Cumulative_最新計画 */
  cumPlanLatest: number;
  /** Cumulative_見込み+実績 */
  cumForecastActualSum: number;
  /** Cumulative_見込み+実績（コンテ込み） */
  cumForecastActualSumCont: number;
};

export type TransformToFiscalYear = {
  [key in keyof Omit<InvestmentReturnItem, 'fiscalYear'>]: {
    [P in InvestmentReturnItem['fiscalYear'] as `FY${number}`]: InvestmentReturnItem[key];
  };
};

const InvestmentReturnPage: React.FC = () => {
  const { t } = useTranslation();
  const { menuCollapsed, scrollCount, setScrollCount } = useContext(AppContext);
  const { paramData, setParamData } = useContext(AipcmctyContext);
  // chart container
  const { selectorTop } = useWindowSize({
    selector: '.chart-section',
  });

  const { orgAllSnapshots, snapshot, snapshotVersion } = useVersion(false);
  const orgVersions = orgAllSnapshots
    .filter((o) => o.tag === 0)
    .map((o) => ({
      key: o.id,
      label: `${o.snapshot} ${o.snapshotVersionName}`,
      value: `${o.snapshot} ${o.snapshotVersion}`,
    }));
  const defaultOrgVersion = orgVersions[0];
  const [filterOpts, setFilterOpts] = useState({
    budgetOpts: [],
    tgcOpts: [],
    orgVersions: orgVersions,
  });
  const [filter, setFilter] = useState({
    budgets: ['Awarded', 'Budget' /* , 'IF' */],
    tgc: [],
    orgVersion: defaultOrgVersion.value,
  });
  const handleFilterChange = (e, key: keyof typeof filter) => {
    setFilter((prev) => ({ ...prev, [key]: e }));
  };

  const [tableCategory, setTableCategory] = useState(TABLE_CATEGORY.DX_EFFECT);
  const [tableData, setTableData] = useState({
    tableSource: [],
    tableColumns: [],
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [transformedData, setTransformedData] = useState<TransformToFiscalYear | null>(null);

  useEffect(() => {
    initializePage();
  }, [snapshot, snapshotVersion]);

  const handleSearch = async () => {
    setTableLoading(true);
    await getChartAndTableData(filter.tgc, filter.budgets, ...(filter.orgVersion.split(' ') as [string, string]));
    setTableLoading(false);
  };

  const initializePage = async () => {
    try {
      setTableLoading(true);
      const [selectedTgc, selectedBudgets] = await Promise.all([getTgcOptions(), getBudgetsOptions()]);
      const [orgSnapshot, orgVersion] = filter.orgVersion.split(' ');
      await getChartAndTableData(selectedTgc, selectedBudgets, orgSnapshot, orgVersion);
    } finally {
      setTableLoading(false);
    }
  };

  const getBudgetsOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'budgetCategory',
      snapshot,
      snapshotVersion,
    })) as Option[];
    const budgetOpts = res
      .filter((r) => r.value !== 'Exclusion')
      .map((r) => ({
        key: r.value,
        label: r.value,
        value: r.value,
      }));
    setFilterOpts((prev) => ({
      ...prev,
      budgetOpts,
    }));
    const selectedBudgets = budgetOpts.filter((b) => ['Awarded', 'Budget' /* , 'IF' */].includes(b.value)).map((b) => b.value);
    setFilter((prev) => ({ ...prev, budgets: selectedBudgets }));
    return selectedBudgets;
  };

  const getTgcOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'tgc',
      snapshot,
      snapshotVersion,
    })) as Option[];
    const tgcOpts = res
      .filter((r) => r.attribute1 === 'subsidiary')
      .map((r) => ({
        key: r.value,
        label: r.value,
        value: r.value,
      }));
    setFilterOpts((prev) => ({
      ...prev,
      tgcOpts,
    }));
    const selectedTgc = tgcOpts.map((r) => r.value);
    setFilter((prev) => ({ ...prev, tgc: selectedTgc }));
    return selectedTgc;
  };

  const getChartAndTableData = async (tgc: string[], budgets: string[], orgSnapshot, orgVersion) => {
    const res = (await APIList.getInvestsAndRetrieves().get({
      curSnapshot: snapshot,
      curVersion: snapshotVersion,
      orgSnapshot,
      orgVersion,
      tgc,
      budgets,
    })) as InvestmentReturnItem[];
    const groupByFY = groupBy(res, 'fiscalYear');
    const data = Object.keys(groupByFY).reduce((prev, cur) => {
      Object.keys(groupByFY[cur][0]).forEach((k) => {
        if (k === 'fiscalYear') {
          return;
        }
        if (prev[k]) {
          prev[k][cur] = Number(groupByFY[cur][0][k]);
        } else {
          prev[k] = {
            [cur]: Number(groupByFY[cur][0][k]),
          };
        }
      });
      return prev;
    }, {} as TransformToFiscalYear);
    setTransformedData(data);
    switch (tableCategory) {
      case TABLE_CATEGORY.DX_EFFECT:
        initDXEffectTableData(data);
        break;
      case TABLE_CATEGORY.DX_INVESTMENT:
        initDXInvestmentTableData(data);
        break;
      case TABLE_CATEGORY.CUMULATIVE:
        initCumulativeTableData(data);
        break;
    }
  };

  const rateDataFunc = (precessData, param, type: RATE_TYPE) => {
    const { progressRate, accumulateRate, cuteEate } = param;
    return Object.entries(precessData).reduce((acc, [key, value]) => {
      acc[key] =
        typeof value === 'number'
          ? (() => {
              if (type === 0) {
                return value * progressRate * accumulateRate * cuteEate;
              }
              if (type === 1) {
                return value * cuteEate;
              }
              if (type === 2) {
                return value * progressRate * accumulateRate;
              }
              if (type === 3) {
                return value;
              }
              return value;
            })()
          : value;
      return acc;
    }, {});
  };

  const initDXEffectTableData = (data: TransformToFiscalYear) => {
    if (!data) {
      return;
    }

    // 计算 最新計画第二级 DX Allow
    const computeSumForDxAllow = (param) => {
      return mergeWith(
        {},
        // プロポーサル前 - DX Allow
        rateDataFunc(data.dxAllowNetPlanLatestPreSale, param, RATE_TYPE.ALL),
        // プロポーサル済み - DX Allow
        rateDataFunc(data.dxAllowNetPlanLatestProposal, param, RATE_TYPE.CUTE),
        // 受注済み - DX Allow
        rateDataFunc(data.dxAllowNetPlanLatestExecution, param, RATE_TYPE.CUTE),
        (objValue, srcValue) => {
          return sum([objValue, srcValue]);
        }
      );
    };

    // 计算 最新計画第二级 DX 課金
    const computeSumForDxCharge = (param) => {
      return mergeWith(
        {},
        // プロポーサル前 - DX 課金
        rateDataFunc(data.dxChargePlanLatestPreSale, param, RATE_TYPE.CRAZY),
        // プロポーサル済み - DX 課金
        rateDataFunc(data.dxChargePlanLatestProposal, param, RATE_TYPE.NONE),
        // 受注済み - DX 課金
        rateDataFunc(data.dxChargePlanLatestExecution, param, RATE_TYPE.NONE),
        (objValue, srcValue) => {
          return sum([objValue, srcValue]);
        }
      );
    };

    // 计算 合计
    const computeSum = (param, ...target) => {
      const source = map(target, (f) => {
        if (isFunction(f)) {
          return f?.(param);
        }
        return f;
      });
      return mergeWith({}, ...source, (objValue, srcValue) => {
        return sum([objValue, srcValue]);
      });
    };

    const columnYears = Object.keys(data.dxAPlan01);
    const columnTemplate = [
      {
        title: t('aipcmcty.page.investAndRepay.breakDown'),
        key: 'node',
        dataIndex: 'node',
        width: 300,
        className: 'text-left',
        fixed: 'left',
      },
      ...columnYears.map((year) => ({
        title: year,
        key: year,
        dataIndex: year,
        className: 'text-right',
        render: (val) => {
          if (isNil(val)) {
            return <>-</>;
          }
          return Number((val / 1000000).toFixed(Math.abs(val / 1000000) < 1 ? 2 : 0)).toLocaleString();
        },
      })),
    ];
    const dataTemplate = [
      {
        key: 'dxAPlan01',
        node: t('aipcmcty.page.investAndRepay.plan01'),
        ...data.dxAPlan01,
        children: [
          {
            key: 'dxAllowNetPlan01',
            node: t('aipcmcty.page.investAndRepay.dxAllowance'),
            ...data.dxAllowNetPlan01,
          },
          {
            key: 'dxChargePlan01',
            node: t('aipcmcty.page.dxBilling'),
            ...data.dxChargePlan01,
          },
        ],
      },
      {
        key: 'dxAPlan02',
        node: t('aipcmcty.page.investAndRepay.plan02'),
        ...data.dxAPlan02,
        children: [
          {
            key: 'dxAllowNetPlan02',
            node: t('aipcmcty.page.investAndRepay.dxAllowance'),
            ...data.dxAllowNetPlan02,
          },
          {
            key: 'dxBilling0-2',
            node: t('aipcmcty.page.dxBilling'),
            ...data.dxChargePlan02,
          },
        ],
      },
      {
        // 最新計画(パラメータ1)
        // dxAPlanLatest
        key: 'dxAPlanLatest1',
        node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}1)`,
        ...computeSum(paramData.param1, computeSumForDxAllow, computeSumForDxCharge),
        children: [
          {
            key: 'dxAllowNetPlanLatest01',
            node: t('aipcmcty.page.investAndRepay.dxAllowance'),
            ...computeSumForDxAllow(paramData.param1),
          },
          {
            key: 'dxChargePlanLatest01',
            node: t('aipcmcty.page.dxBilling'),
            ...computeSumForDxCharge(paramData.param1),
          },
          {
            key: 'dxAPlanLatestPreSale1',
            node: t('aipcmcty.page.investAndRepay.preSale'),
            ...computeSum(
              paramData.param1,
              rateDataFunc(data.dxAllowNetPlanLatestPreSale, paramData.param1, RATE_TYPE.ALL),
              rateDataFunc(data.dxChargePlanLatestPreSale, paramData.param1, RATE_TYPE.CRAZY)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestPreSale01',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestPreSale, paramData.param1, RATE_TYPE.ALL),
              },
              {
                key: 'dxChargePlanLatestPreSale01',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestPreSale, paramData.param1, RATE_TYPE.CRAZY),
              },
            ],
          },
          {
            key: 'dxAPlanLatestProposal1',
            node: t('aipcmcty.page.investAndRepay.proposal'),
            ...computeSum(
              paramData.param1,
              rateDataFunc(data.dxAllowNetPlanLatestProposal, paramData.param1, RATE_TYPE.CUTE),
              rateDataFunc(data.dxChargePlanLatestProposal, paramData.param1, RATE_TYPE.NONE)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestProposal01',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestProposal, paramData.param1, RATE_TYPE.CUTE),
              },
              {
                key: 'dxChargePlanLatestProposal01',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestProposal, paramData.param1, RATE_TYPE.NONE),
              },
            ],
          },
          {
            key: 'dxAPlanLatestExecuted1',
            node: t('aipcmcty.page.investAndRepay.execution'),
            ...computeSum(
              paramData.param1,
              rateDataFunc(data.dxAllowNetPlanLatestExecution, paramData.param1, RATE_TYPE.CUTE),
              rateDataFunc(data.dxChargePlanLatestExecution, paramData.param1, RATE_TYPE.NONE)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestExecution01',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestExecution, paramData.param1, RATE_TYPE.CUTE),
              },
              {
                key: 'dxChargePlanLatestExecution01',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestExecution, paramData.param1, RATE_TYPE.NONE),
              },
            ],
          },
        ],
      },
      {
        // 最新計画(パラメータ2)
        // dxAPlanLatest
        key: 'dxAPlanLatest2',
        node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}2)`,
        ...computeSum(paramData.param2, computeSumForDxAllow, computeSumForDxCharge),
        children: [
          {
            key: 'dxAllowNetPlanLatest02',
            node: t('aipcmcty.page.investAndRepay.dxAllowance'),
            ...computeSumForDxAllow(paramData.param2),
          },
          {
            key: 'dxChargePlanLatest02',
            node: t('aipcmcty.page.dxBilling'),
            ...computeSumForDxCharge(paramData.param2),
          },
          {
            key: 'dxAPlanLatestPreSale2',
            node: t('aipcmcty.page.investAndRepay.preSale'),
            ...computeSum(
              paramData.param2,
              rateDataFunc(data.dxAllowNetPlanLatestPreSale, paramData.param2, RATE_TYPE.ALL),
              rateDataFunc(data.dxChargePlanLatestPreSale, paramData.param2, RATE_TYPE.CRAZY)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestPreSale02',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestPreSale, paramData.param2, RATE_TYPE.ALL),
              },
              {
                key: 'dxChargePlanLatestPreSale02',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestPreSale, paramData.param2, RATE_TYPE.CRAZY),
              },
            ],
          },
          {
            key: 'dxAPlanLatestProposal2',
            node: t('aipcmcty.page.investAndRepay.proposal'),
            ...computeSum(
              paramData.param2,
              rateDataFunc(data.dxAllowNetPlanLatestProposal, paramData.param2, RATE_TYPE.CUTE),
              rateDataFunc(data.dxChargePlanLatestProposal, paramData.param2, RATE_TYPE.NONE)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestProposal02',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestProposal, paramData.param2, RATE_TYPE.CUTE),
              },
              {
                key: 'dxChargePlanLatestProposal02',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestProposal, paramData.param2, RATE_TYPE.NONE),
              },
            ],
          },
          {
            key: 'dxAPlanLatestExecuted2',
            node: t('aipcmcty.page.investAndRepay.execution'),
            ...computeSum(
              paramData.param2,
              rateDataFunc(data.dxAllowNetPlanLatestExecution, paramData.param2, RATE_TYPE.CUTE),
              rateDataFunc(data.dxChargePlanLatestExecution, paramData.param2, RATE_TYPE.NONE)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestExecution02',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestExecution, paramData.param2, RATE_TYPE.CUTE),
              },
              {
                key: 'dxChargePlanLatestExecution02',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestExecution, paramData.param2, RATE_TYPE.NONE),
              },
            ],
          },
        ],
      },
      {
        // 最新計画(パラメータ3)
        // dxAPlanLatest
        key: 'dxAPlanLatest3',
        node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}3)`,
        ...computeSum(paramData.param3, computeSumForDxAllow, computeSumForDxCharge),
        children: [
          {
            key: 'dxAllowNetPlanLatest03',
            node: t('aipcmcty.page.investAndRepay.dxAllowance'),
            ...computeSumForDxAllow(paramData.param3),
          },
          {
            key: 'dxChargePlanLatest03',
            node: t('aipcmcty.page.dxBilling'),
            ...computeSumForDxCharge(paramData.param3),
          },
          {
            key: 'dxAPlanLatestPreSale3',
            node: t('aipcmcty.page.investAndRepay.preSale'),
            ...computeSum(
              paramData.param3,
              rateDataFunc(data.dxAllowNetPlanLatestPreSale, paramData.param3, RATE_TYPE.ALL),
              rateDataFunc(data.dxChargePlanLatestPreSale, paramData.param3, RATE_TYPE.CRAZY)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestPreSale03',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestPreSale, paramData.param3, RATE_TYPE.ALL),
              },
              {
                key: 'dxChargePlanLatestPreSale03',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestPreSale, paramData.param3, RATE_TYPE.CRAZY),
              },
            ],
          },
          {
            key: 'dxAPlanLatestProposal3',
            node: t('aipcmcty.page.investAndRepay.proposal'),
            ...computeSum(
              paramData.param3,
              rateDataFunc(data.dxAllowNetPlanLatestProposal, paramData.param3, RATE_TYPE.CUTE),
              rateDataFunc(data.dxChargePlanLatestProposal, paramData.param3, RATE_TYPE.NONE)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestProposal03',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestProposal, paramData.param3, RATE_TYPE.CUTE),
              },
              {
                key: 'dxChargePlanLatestProposal03',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestProposal, paramData.param3, RATE_TYPE.NONE),
              },
            ],
          },
          {
            key: 'dxAPlanLatestExecuted3',
            node: t('aipcmcty.page.investAndRepay.execution'),
            ...computeSum(
              paramData.param3,
              rateDataFunc(data.dxAllowNetPlanLatestExecution, paramData.param3, RATE_TYPE.CUTE),
              rateDataFunc(data.dxChargePlanLatestExecution, paramData.param3, RATE_TYPE.NONE)
            ),
            children: [
              {
                key: 'dxAllowNetPlanLatestExecution03',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...rateDataFunc(data.dxAllowNetPlanLatestExecution, paramData.param3, RATE_TYPE.CUTE),
              },
              {
                key: 'dxChargePlanLatestExecution03',
                node: t('aipcmcty.page.dxBilling'),
                ...rateDataFunc(data.dxChargePlanLatestExecution, paramData.param3, RATE_TYPE.NONE),
              },
            ],
          },
        ],
      },
      {
        key: 'dxAForecastActualSum',
        node: t('aipcmcty.page.investAndRepay.forecastAndActual'),
        ...data.dxAForecastActualSum,
        children: [
          {
            key: 'dxAllowNetForecastActualSum',
            node: t('aipcmcty.page.investAndRepay.dxAllowance'),
            ...data.dxAllowNetForecastActualSum,
          },
          {
            key: 'dxChargeForecastActualSum',
            node: t('aipcmcty.page.dxBilling'),
            ...data.dxChargeForecastActualSum,
          },
          {
            key: 'dxAForecastSum',
            node: t('aipcmcty.page.investAndRepay.forecast'),
            ...data.dxAForecastSum,
            children: [
              {
                key: 'dxAllowNetForecastSum',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...data.dxAllowNetForecastSum,
              },
              {
                key: 'dxChargeForecastSum',
                node: t('aipcmcty.page.dxBilling'),
                ...data.dxChargeForecastSum,
              },
              {
                key: 'dxAForecastPreSale',
                node: t('aipcmcty.page.investAndRepay.preSale'),
                ...data.dxAForecastPreSale,
                children: [
                  {
                    key: 'dxAllowNetForecastPreSale',
                    node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                    ...data.dxAllowNetForecastPreSale,
                  },
                  {
                    key: 'dxBilling4-1',
                    node: t('aipcmcty.page.dxBilling'),
                    ...data.dxChargeForecastPreSale,
                  },
                ],
              },
              {
                key: 'dxAForecastProposal',
                node: t('aipcmcty.page.investAndRepay.proposal'),
                ...data.dxAForecastProposal,
                children: [
                  {
                    key: 'dxAllowNetForecastProposal',
                    node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                    ...data.dxAllowNetForecastProposal,
                  },
                  {
                    key: 'dxChargeForecastProposal',
                    node: t('aipcmcty.page.dxBilling'),
                    ...data.dxChargeForecastProposal,
                  },
                ],
              },
              {
                key: 'dxAForecastExecution',
                node: t('aipcmcty.page.investAndRepay.execution'),
                ...data.dxAForecastExecution,
                children: [
                  {
                    key: 'dxAllowNetForecastExecution',
                    node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                    ...data.dxAllowNetForecastExecution,
                  },
                  {
                    key: 'dxChargeForecastExecution',
                    node: t('aipcmcty.page.dxBilling'),
                    ...data.dxChargeForecastExecution,
                  },
                ],
              },
            ],
          },
          {
            key: 'dxAActual',
            node: t('aipcmcty.page.investAndRepay.actual'),
            ...data.dxAActual,
            children: [
              {
                key: 'dxAllowNetActual',
                node: t('aipcmcty.page.investAndRepay.dxAllowance'),
                ...data.dxAllowNetActual,
              },
              {
                key: 'dxBilling4-4',
                node: t('aipcmcty.page.dxBilling'),
                ...data.dxChargeActual,
              },
            ],
          },
        ],
      },
      {
        key: 'dxAForecastActualSumCont',
        node: `${t('aipcmcty.page.investAndRepay.forecastAndActual')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        ...data.dxAForecastActualSumCont,
        children: [
          {
            key: 'dxAForecastSumCont',
            node: t('aipcmcty.page.investAndRepay.forecast'),
            ...data.dxAForecastSumCont,
            children: [
              {
                key: 'dxAForecastPreSaleCont',
                node: t('aipcmcty.page.investAndRepay.preSale'),
                ...data.dxAForecastPreSaleCont,
              },
              {
                key: 'dxAForecastProposalCont',
                node: t('aipcmcty.page.investAndRepay.proposal'),
                ...data.dxAForecastProposalCont,
              },
              {
                key: 'dxAForecastExecutionCont',
                node: t('aipcmcty.page.investAndRepay.execution'),
                ...data.dxAForecastExecutionCont,
              },
            ],
          },
          {
            key: 'dxAActualCont',
            node: t('aipcmcty.page.investAndRepay.actual'),
            ...data.dxAActualCont,
          },
        ],
      },
    ];

    setTableData({
      tableColumns: columnTemplate,
      tableSource: dataTemplate,
    });
  };

  const initDXInvestmentTableData = (data: TransformToFiscalYear) => {
    if (!data) {
      return;
    }
    const columnYears = Object.keys(data.dxIPlan01);
    const columnTemplate = [
      {
        title: t('aipcmcty.page.investAndRepay.breakDown'),
        key: 'node',
        dataIndex: 'node',
        width: 300,
        className: 'text-left',
        fixed: 'left',
      },
      ...columnYears.map((year) => ({
        title: year,
        key: year,
        dataIndex: year,
        className: 'text-right',
        render: (val) => Number((val / 1000000).toFixed(Math.abs(val / 1000000) < 1 ? 2 : 0)).toLocaleString(),
      })),
    ];

    const dataTemplate = [
      {
        key: 'dxAPlan01',
        node: t('aipcmcty.page.investAndRepay.plan01'),
        ...data.dxIPlan01,
      },
      {
        key: 'dxAPlanLatest',
        node: `${t('aipcmcty.page.investAndRepay.planLatest')}`,
        ...data.dxIPlanLatest,
      },
      // {
      //   key: 'dxAPlanLatest1',
      //   node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}1)`,
      //   ...rateDataFunc(data.dxIPlanLatest, paramData.param1, RATE_TYPE.ALL),
      // },
      // {
      //   key: 'dxAPlanLatest2',
      //   node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}2)`,
      //   ...rateDataFunc(data.dxIPlanLatest, paramData.param2, RATE_TYPE.ALL),
      // },
      // {
      //   key: 'dxAPlanLatest3',
      //   node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}3)`,
      //   ...rateDataFunc(data.dxIPlanLatest, paramData.param3, RATE_TYPE.ALL),
      // },
      {
        key: 'dxAForecastActualSum',
        node: t('aipcmcty.page.investAndRepay.forecastAndActual'),
        ...data.dxIForecastActualSum,
      },
    ];
    setTableData({
      tableColumns: columnTemplate,
      tableSource: dataTemplate,
    });
  };

  const initCumulativeTableData = (data: TransformToFiscalYear) => {
    if (!data) {
      return;
    }

    const computeSum = (param) => {
      return mergeWith(
        {},
        // プロポーサル前 - DX Allow
        rateDataFunc(data.dxAllowNetPlanLatestPreSale, param, RATE_TYPE.ALL),
        // プロポーサル済み - DX Allow
        rateDataFunc(data.dxAllowNetPlanLatestProposal, param, RATE_TYPE.CUTE),
        // 受注済み - DX Allow
        rateDataFunc(data.dxAllowNetPlanLatestExecution, param, RATE_TYPE.CUTE),
        // プロポーサル前 - DX 課金
        rateDataFunc(data.dxChargePlanLatestPreSale, param, RATE_TYPE.CRAZY),
        // プロポーサル済み - DX 課金
        rateDataFunc(data.dxChargePlanLatestProposal, param, RATE_TYPE.NONE),
        // 受注済み - DX 課金
        rateDataFunc(data.dxChargePlanLatestExecution, param, RATE_TYPE.NONE),
        (objValue, srcValue) => {
          return sum([objValue, srcValue]);
        }
      );
    };

    const columnYears = Object.keys(data.cumPlan01);
    const columnTemplate = [
      {
        title: t('aipcmcty.page.investAndRepay.breakDown'),
        key: 'node',
        dataIndex: 'node',
        width: 300,
        className: 'text-left',
        fixed: 'left',
      },
      ...columnYears.map((year) => ({
        title: year,
        key: year,
        dataIndex: year,
        className: 'text-right',
        render: (val) => Number((val / 1000000).toFixed(Math.abs(val / 1000000) < 1 ? 2 : 0)).toLocaleString(),
      })),
    ];

    const sumCumPlanLatest = (dxSum) => {
      let cachedYear = null;
      return Object.entries<number>(dxSum)
        .sort((a, b) => (a[0] < b[0] ? -1 : 1))
        .reduce((pr, [k, v]) => {
          pr[k] = v + data.dxIPlanLatest[k] + (cachedYear ? pr[cachedYear] : 0);
          cachedYear = k;
          return pr;
        }, {});
    };

    const cumPlanLatest1 = sumCumPlanLatest(computeSum(paramData.param1));
    const cumPlanLatest2 = sumCumPlanLatest(computeSum(paramData.param2));
    const cumPlanLatest3 = sumCumPlanLatest(computeSum(paramData.param3));

    const dataTemplate = [
      {
        key: 'dxAPlan01',
        node: t('aipcmcty.page.investAndRepay.plan01'),
        ...data.cumPlan01,
      },
      {
        key: 'dxAPlan02',
        node: t('aipcmcty.page.investAndRepay.plan02'),
        ...data.cumPlan02,
      },
      {
        key: 'dxAPlanLatest1',
        node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}1)`,
        ...cumPlanLatest1,
      },
      {
        key: 'dxAPlanLatest2',
        node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}2)`,
        ...cumPlanLatest2,
      },
      {
        key: 'dxAPlanLatest3',
        node: `${t('aipcmcty.page.investAndRepay.planLatest')}(${t('aipcmcty.page.paramter')}3)`,
        ...cumPlanLatest3,
      },
      {
        key: 'dxAForecastActualSum',
        node: t('aipcmcty.page.investAndRepay.forecastAndActual'),
        ...data.cumForecastActualSum,
      },
      {
        key: 'dxAForecastActualSumCont',
        node: `${t('aipcmcty.page.investAndRepay.forecastAndActual')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        ...data.cumForecastActualSumCont,
      },
    ];
    setTableData({
      tableColumns: columnTemplate,
      tableSource: dataTemplate,
    });
  };

  const changeTabHandler = (e: RadioChangeEvent) => {
    const checkValue = e.target.value;
    setTableCategory(checkValue);
    switch (checkValue) {
      case TABLE_CATEGORY.DX_EFFECT:
        initDXEffectTableData(transformedData);
        break;
      case TABLE_CATEGORY.DX_INVESTMENT:
        initDXInvestmentTableData(transformedData);
        break;
      case TABLE_CATEGORY.CUMULATIVE:
        initCumulativeTableData(transformedData);
        break;
    }
  };

  useEffect(() => {
    switch (tableCategory) {
      case TABLE_CATEGORY.DX_EFFECT:
        initDXEffectTableData(transformedData);
        break;
      case TABLE_CATEGORY.DX_INVESTMENT:
        initDXInvestmentTableData(transformedData);
        break;
      case TABLE_CATEGORY.CUMULATIVE:
        initCumulativeTableData(transformedData);
        break;
    }
  }, [paramData]);

  return (
    <>
      <Row justify="space-between" className="operation-container" style={{ backgroundColor: 'white', padding: '0 16px', marginBottom: 2 }}>
        <Col style={{ padding: '5px 0' }}>
          <Space>
            {t('aipcmcty.page.budgetCategoryCustom')}:
            <Select
              allowClear
              mode="multiple"
              style={{ width: 220 }}
              maxTagCount="responsive"
              options={filterOpts.budgetOpts}
              value={filter.budgets}
              onChange={(e) => handleFilterChange(e, 'budgets')}
            />
            <Button type="primary" onClick={handleSearch}>
              {t('aipcmcty.page.gapAnalyze.compare')}
            </Button>
          </Space>
        </Col>
      </Row>
      <ChartTableLayout viewMode={'chart-table'}>
        <ChartTableLayout.Chart>
          <Row>
            <Col span={18}>
              <ChartGroup
                defaultItemCount={1}
                defaultHeightCount={2}
                loading={false}
                syncScroll
                height={340}
                viewMode={'chart-table'}
                isScrollChart
                menuCollapsed={menuCollapsed}
                scrollCount={scrollCount}
                setScrollCount={setScrollCount}
                selectorTop={selectorTop}
                sizeMode={'small'}
              >
                <ChartGroup.Item>
                  <InvestmentReturnChart data={transformedData} title="" loading={tableLoading} />
                </ChartGroup.Item>
              </ChartGroup>
            </Col>
            <Col span={6}>
              <Paramter
                defaultValue={paramData}
                onChange={(data) => {
                  setParamData(data);
                }}
              />
            </Col>
          </Row>
        </ChartTableLayout.Chart>
        <ChartTableLayout.Table>
          <Card style={{ height: document.body.clientHeight - 510 }}>
            <div style={{ paddingBottom: 30, marginBottom: 20 }}>
              <div style={{ marginRight: 20, float: 'right' }}>
                <span style={{ marginRight: 15 }}>{t('aipcmcty.page.investAndRepay.unit')}</span>
                <Radio.Group defaultValue="2024" buttonStyle="solid" onChange={changeTabHandler} value={tableCategory}>
                  <Radio.Button value={TABLE_CATEGORY.DX_EFFECT}>{t('aipcmcty.page.investAndRepay.dxEffect')}</Radio.Button>
                  <Radio.Button value={TABLE_CATEGORY.DX_INVESTMENT}>{t('aipcmcty.page.investAndRepay.dxInvest')}</Radio.Button>
                  <Radio.Button value={TABLE_CATEGORY.CUMULATIVE}>{t('aipcmcty.page.investAndRepay.cum')}</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <Table
              expandable={{
                defaultExpandedRowKeys: [
                  'dxAPlanLatest1',
                  'dxAPlanLatest2',
                  'dxAPlanLatest3',
                  'dxAForecastActualSum',
                  'dxAForecastSum',
                  'dxAForecastActualSumCont',
                  'dxAForecastSumCont',
                  'dxAPlanLatestPreSale1',
                  'dxAPlanLatestPreSale2',
                  'dxAPlanLatestPreSale3',
                  'dxAActual',
                  'dxAForecastExecution',
                  'dxAForecastProposal',
                  'dxAForecastPreSale',
                  'dxAPlanLatestExecuted3',
                  'dxAPlanLatestProposal3',
                  'dxAPlanLatestExecuted2',
                  'dxAPlanLatestProposal2',
                  'dxAPlanLatestExecuted1',
                  'dxAPlanLatestProposal1',
                  'dxAPlan01',
                  'dxAPlan02',
                ],
              }}
              style={{ background: '#FFF' }}
              scroll={{ x: 1920 - 550 - 100 - 240, y: document.body.clientHeight - 635 }}
              pagination={false}
              columns={tableData.tableColumns}
              dataSource={tableData.tableSource}
              loading={tableLoading}
              className="scroll-table"
              size={'small'}
              bordered
            />
          </Card>
        </ChartTableLayout.Table>
      </ChartTableLayout>
    </>
  );
};

enum TABLE_CATEGORY {
  DX_EFFECT,
  DX_INVESTMENT,
  CUMULATIVE,
}

export enum RATE_TYPE {
  // Value * 施策進捗率 * 積込率 * 刈取率
  ALL,
  // Value * 刈取率
  CUTE,
  // Value * 施策進捗率 * 積込率
  CRAZY,
  // Value
  NONE,
}

export default InvestmentReturnPage;
