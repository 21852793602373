import { chain } from 'lodash';
import { useMemo, useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Option = {
  label: ReactNode;
  value: any;
  key?: any;
  [key: string]: any;
};

export const useAccountPerspective = (tgcOptions: Option[] = [], initialPerspective = 2, initialSelectedTgc?: string[]) => {
  const { t } = useTranslation();
  const perspectiveOptions: Option[] = useMemo(
    () =>
      [
        `${t('aipcmcty.page.consolidated')}＋${t('aipcmcty.page.equity')}`,
        t('aipcmcty.page.consolidated'),
        t('aipcmcty.page.toyoJConsolidated'),
        t('aipcmcty.page.baseUnit'),
      ].map((p, i) => ({
        label: p,
        key: i + 1,
        value: i + 1,
      })),
    []
  );

  const [accountingPerspective, setAccountPerspective] = useState(initialPerspective);
  const [perspectiveState, setPerspectiveState] = useState<{ japanInvolved: 0 | 1; consolidated: 0 | 1 }>({
    japanInvolved: 0,
    consolidated: 1,
  });

  const filterOptsAndSelected = (acPerspective, tgcOpts, initial?: string[]) => {
    switch (acPerspective) {
      case 2:
      case 3:
        return [
          tgcOpts.filter((item) => item.attribute1 !== 'equity'),
          initialPerspective === acPerspective && initial
            ? initial
            : tgcOpts.filter((item) => item.attribute1 !== 'equity').map((item) => item.value),
        ];
      case 4:
        const defaultTgc = chain(tgcOpts).orderBy(['order'], ['asc']).first().value()?.value;
        return [tgcOpts, initialPerspective === acPerspective && initial ? initial : defaultTgc ? [defaultTgc] : []];
      case 1:
      default:
        return [tgcOpts, initialPerspective === acPerspective && initial ? initial : tgcOpts.map((item) => item.value)];
    }
  };

  const [filterTgcOptions, selectedTgc] = useMemo(
    () => filterOptsAndSelected(accountingPerspective, tgcOptions, initialSelectedTgc),
    [accountingPerspective, tgcOptions]
  );

  useEffect(() => {
    const newPerspectiveState = { ...perspectiveState };
    switch (accountingPerspective) {
      case 1:
        newPerspectiveState.japanInvolved = 0;
        newPerspectiveState.consolidated = 1;
        break;
      case 2:
        newPerspectiveState.japanInvolved = 0;
        newPerspectiveState.consolidated = 1;
        break;
      case 3:
        newPerspectiveState.japanInvolved = 1;
        newPerspectiveState.consolidated = 1;
        break;
      case 4:
        newPerspectiveState.japanInvolved = 0;
        newPerspectiveState.consolidated = 0;
        break;
      default:
        break;
    }
    setPerspectiveState(newPerspectiveState);
  }, [accountingPerspective]);

  return {
    perspectiveOptions,
    accountingPerspective,
    setAccountPerspective,
    perspectiveState,
    filterTgcOptions,
    selectedTgc,
    filterOptsAndSelected,
  };
};
