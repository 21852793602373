import {
  AimOutlined, CheckCircleOutlined, CopyOutlined, DeleteOutlined, SaveOutlined, ScheduleOutlined, SearchOutlined, StopOutlined
} from '@ant-design/icons';
import {
  Select, Button, Modal, Input, Space, Checkbox, Table, Tooltip, Tag, message, Popconfirm, Spin, Switch,
  Row,
  Col,
  AutoComplete,
  Divider,
  Drawer,
  Progress
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';
import useStorage from '../hooks/useStorage';
import { AppContext } from '../contexts/AppContext';
import APIList from '../http/ApiList';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { filter, isNil, isNumber, minBy } from 'lodash';

type SaveModalData = {
  snapshotVersionN: string;
  comment: string;
  isMaster: boolean;
  tag: number
};

type VersionManagement = {
  id: string;
  snapshot: string;
  snapshotVersion: string;
  comment: string;
  updatedAt: Date;
  isDeleted: boolean;
  isMaster: boolean;
  isEdit: boolean;
  isPublic: boolean;
  copyFrom: string;
  createdBy: string;
};

const VersionManager: React.FC<any> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setItem } = useStorage();
  const {
    // authInfo: { user: { username, attributes: { email } } },
    authInfo: { user },
    setAipcmctyVersion, aipcmctyVersion, cmcSelectedVersion, setCmcSelectedVersion, versionList, setVersionList, color
  } = useContext(AppContext);
  const {
    snapshot, snapshotVersion, snapshotVersionName, userId
  } = JSON.parse(aipcmctyVersion);
  const {
    snapshot: selectedSnapshot,
    snapshotVersion: selectedVersion
  } = cmcSelectedVersion;

  const initialSaveVerData = useRef({
    snapshotVersionN: '',
    comment: '',
    isMaster: false,
    tag: null
  });

  const [saveVerModal, setSaveVerModel] = useState(false);
  const [saveConfirmLoading, setSaveConfirmLoading] = useState(false);
  const [saveVerData, setSaveVerData] = useState<SaveModalData>(initialSaveVerData.current);

  const [manageVerModal, setManageVerModal] = useState(false);
  const [manageTableLoading, setManageTableLoading] = useState(false);
  const [registerPercent, setRegisterPercent] = useState<number>(0);
  const [isProgressShow, setIsProgressShow] = useState(false);
  const [tableSpinFlag, setTableSpinFlag] = useState(false);
  const [progressStatus, setProgressStatus] = useState<"active" | "success" | "normal" | "exception">('active');
  let maxValue = 75;
  let intervalList = [];
  const beginProgress = ()  =>  {
    setTableSpinFlag(true);
    let interFirst = setInterval(() => {
      increase(maxValue);
    }, 2500);
    intervalList.push(interFirst);
  }

  const clearIntervals = () => {
    setTableSpinFlag(false);
    intervalList.forEach((iv: any) => {
      clearInterval(iv);
    });
    intervalList = []
  }

  const increase = (mv) => {
    setRegisterPercent((prevPercent) => {
      const newPercent = prevPercent + 1;
      if (newPercent > mv) {
        return mv;
      }
      if (newPercent === 100) {
        setTableSpinFlag(false);
        clearIntervals();
      }
      return newPercent;
    });
  };

  const getVersionList = async () => {
    const res = (await APIList.getVersions().get()) as any[];
    res.forEach(r => {
      r.originM = r.isMaster;
      r.isShared = r.isPublic && !r.isEdit;
    });
    setVersionList(res);
    if (!snapshot && !snapshotVersion) {
      const masterItem = res.sort((a, b) => {
        if (b.snapshot > a.snapshot) {
          return 1;
        }
        if (b.snapshot < a.snapshot) {
          return -1;
        }
        return 0;
      }).find(r => r.isMaster);
      setCmcSelectedVersion({
        snapshot: masterItem.snapshot,
        snapshotVersion: masterItem.snapshotVersion,
        snapshotVersionName: masterItem.snapshotVersionName,
        userId: masterItem.createdBy,
        isShared: masterItem.isShared
      });
    } else {
      const selectedItem = res.find(r => r.snapshot === snapshot && r.snapshotVersion === snapshotVersion);
      setCmcSelectedVersion({
        snapshot: snapshot,
        snapshotVersion: snapshotVersion,
        snapshotVersionName: snapshotVersionName,
        userId: userId,
        isShared: selectedItem?.isShared ?? false
      });
    }
    setManageTableLoading(false);
  };

  useEffect(() => {
    getVersionList();
  }, [aipcmctyVersion]);

  const genVersionBySnapshot = (list: { snapshot: string }[]) => list.reduce((acc, cur) => {
    if (acc[cur.snapshot]) {
      acc[cur.snapshot].push(cur);
    } else {
      acc[cur.snapshot] = [cur];
    }
    return acc;
  }, {} as { [key: string]: any });

  const updateSaveVerData = (key: keyof SaveModalData, value: SaveModalData[keyof SaveModalData]) => {
    setSaveVerData({
      ...saveVerData,
      [key]: value
    });
  };

  const handleSaveVerData = async () => {
    const { snapshotVersionN } = saveVerData;
    const reg = /^\w{1,24}$/g;
    if (!reg.test(snapshotVersionN)) {
      message.error(t('aipcmcty.page.versionFormatError'));
      return;
    }
    setSaveConfirmLoading(true);
    APIList.createSnapshotVersions().post({
      ...saveVerData,
      snapshot: selectedSnapshot,
      // snapshotVersionの名
      snapshotVersion: selectedVersion,
      userName: user?.username,
      createdBy: user?.attributes?.email
    }).then(({ status, data }: any) => {
      if (status === 'EXIST') {
        message.error(t('aipcmcty.page.versionExists'));
        setSaveConfirmLoading(false);
        return;
      }
      if (status === 'ERROR') {
        message.error(t('aipcmcty.page.createVersionFailure').replace('${saveVerData.snapshotVersionN}', saveVerData.snapshotVersionN));
        setSaveConfirmLoading(false);
        return;
      }
      navigate('/aipcmcty/dashboard/overall', { replace: true });
      setTimeout(() => {
        message.success(t('aipcmcty.page.createVersionSuccess').replace('${saveVerData.snapshotVersionN}', saveVerData.snapshotVersionN));
        setSaveConfirmLoading(false);
        setSaveVerModel(false);
        setSaveVerData(initialSaveVerData.current);
        setItem(
          'cmcVersion',
          JSON.stringify({
            snapshot: selectedSnapshot,
            snapshotVersion: data.snapshotVersion,
            snapshotVersionName: saveVerData.snapshotVersionN,
            userId: user?.attributes?.email,
            isShared: false
          }),
          'local'
        );
        setAipcmctyVersion(JSON.stringify({
          snapshot: selectedSnapshot,
          snapshotVersion: data.snapshotVersion,
          snapshotVersionName: saveVerData.snapshotVersionN,
          userId: user?.attributes?.email,
          isShared: false
        }));
      }, 2500);
    }).catch(() => {
      message.error(t('aipcmcty.page.createVersionFailure').replace('${saveVerData.snapshotVersionN}', saveVerData.snapshotVersionN));
      setSaveConfirmLoading(false);
    });
  };

  const handleOperations = (id: string, operation: string) => {
    const newVerList = [...versionList];
    const operationItem = newVerList.find(l => l.id === id);
    const msgMap = {
      master: {
        success: t('aipcmcty.page.updateMasterSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updateMasterFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      delete: {
        success: t('aipcmcty.page.deleteVersionSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.deleteVersionFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      public: {
        success: t('aipcmcty.page.updatePublicSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updatePublicFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      edit: {
        success: t('aipcmcty.page.updateEditSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updateEditFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      share: {
        success: t('aipcmcty.page.updateSharingSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updateSharingFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      }
    };
    switch (operation) {
      case 'master':
        newVerList.forEach(l => { l.isMaster = false; });
        operationItem.isMaster = true;
        break;
      case 'delete':
        operationItem.isDeleted = true;
        break;
      case 'public':
        operationItem.isPublic = !operationItem.isPublic;
        break;
      case 'edit':
        operationItem.isEdit = !operationItem.isEdit;
        break;
      case 'share':
        operationItem.isShared = !operationItem.isShared;
        operationItem.isPublic = operationItem.isShared;
        operationItem.isEdit = !operationItem.isShared;
        break;
      default:
        return;
    }
    setManageTableLoading(true);
    APIList.updateVersions().put({ ...operationItem }).then(async res => {
      if (res === 'SUCCESS') {
        await getVersionList();
        message.success(msgMap[operation].success);
      } else {
        message.error(msgMap[operation].error);
      }
    });
  };

  const columns: ColumnsType<VersionManagement> = [
    {
      title: 'Version Name',
      dataIndex: 'snapshotVersionName',
      key: 'snapshotVersionName',
      render: (val, { isMaster, snapshotVersion: version, snapshot: s }) => (
        <Space>
          {val}
          {selectedSnapshot === s && selectedVersion === version && <Tag color="blue">In Use</Tag>}
          {isMaster && <Tag color="red">Master</Tag>}
        </Space>
      )
    },
    {
      title: 'Origin Version',
      dataIndex: 'copyFrom',
      key: 'copyFrom',
      render: (_, item) => versionList.find(e => e.snapshotVersion === item.copyFrom)?.snapshotVersionName
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Author',
      dataIndex: 'userName',
      key: 'userName',
      width: 150
    },
    {
      title: 'Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      render: val => moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: `${t('aipcmcty.page.shared')}/${t('aipcmcty.page.nonShared')}`,
      dataIndex: 'isShared',
      key: 'isShared',
      width: 100,
      render: (
        val,
        { id, createdBy }
      ) => (
        <Switch
          disabled={createdBy !== user?.attributes?.email}
          checkedChildren={t('aipcmcty.page.shared')}
          unCheckedChildren={t('aipcmcty.page.nonShared')}
          checked={val}
          onChange={() => handleOperations(id, 'share')}
        />
      )
    },
    {
      title: 'Operations',
      width: 100,
      render: ({
        id, isMaster, snapshotVersion: version, createdBy, isPublic
      }) => {
        const showDelete = !isMaster && selectedVersion !== version && user?.attributes?.email === createdBy;
        return (
          <Space>
            {
              !isMaster && isPublic && (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure to set as Master?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleOperations(id, 'master')}
                >
                  <Tooltip title="Set as Master">
                    <AimOutlined />
                  </Tooltip>
                </Popconfirm>
              )
            }
            {
              showDelete && (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure to delete?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleOperations(id, 'delete')}
                >
                  <Tooltip title="Delete">
                    <DeleteOutlined />
                  </Tooltip>
                </Popconfirm>
              )
            }
          </Space>
        );
      }
    },
  ];

  const versionOpts = useMemo(() => (genVersionBySnapshot(versionList)[selectedSnapshot] ?? []).reduce(
    (acc, cur) => {
      let groupNum = 3;
      const iconContent = cur.isShared
        ? <CheckCircleOutlined style={{ marginRight: 6, color: color.successColor }} />
        : <StopOutlined style={{ marginRight: 6, color: color.errorColor }} />;
      if (cur.createdBy === 'system') {
        groupNum = 1;
      } else if (cur.createdBy === user?.attributes?.email) {
        groupNum = 2;
      }
      if (cur.isMaster && acc[0].options.length === 0) {
        acc[0].options.push({
          label: (
            <span title={cur.snapshotVersionName}>
              {iconContent}
              {cur.snapshotVersionName}
            </span>
          ),
          key: 'master',
          value: cur.snapshotVersion,
          tag: cur.tag
        });
      }
      acc[groupNum].options.push({
        label: (
          <span title={cur.snapshotVersionName}>
            {iconContent}
            {cur.snapshotVersionName}
          </span>
        ),
        key: cur.snapshotVersion,
        value: cur.snapshotVersion,
        tag: cur.tag
      });
      return acc;
    },
    [
      {
        label: 'Master',
        options: []
      },
      {
        label: 'System',
        options: []
      },
      {
        label: 'My Versions',
        options: []
      },
      {
        label: 'Others',
        options: []
      }
    ]
  ), [versionList, selectedSnapshot, user?.attributes?.email]);

  const tableData = versionList
    .filter(v => v.snapshot === selectedSnapshot)
    .reduce((acc, cur) => {
      if (cur.createdBy === 'system') {
        acc.system.push(cur);
      } else if (cur.createdBy === user?.attributes?.email) {
        acc.mine.push(cur);
      } else {
        acc.others.push(cur);
      }
      return acc;
    }, { system: [], mine: [], others: [] });

  const getTag = (tag) => {
    const styles: any = {
      height: 17,
      lineHeight: '15px',
      fontSize: 10,
      width: 18,
      padding: 0,
      textAlign: 'center',
      marginLeft: 2
    }
    switch (tag) {
      case 0: return <Tag color='blue' style={styles}>原</Tag>;
      case 1: return <Tag color='volcano' style={styles}>修</Tag>;
      default: return <></>
    }
  }

  /** about triple table  */
  // triple modal filters config and handler
  const [caseNames, setCaseNames] = useState({
    case1: '',
    case2: '',
    case3: ''
  });
  const handleCaseNameChange = (caseType: keyof typeof caseNames, caseName: string) => {
    setCaseNames(cns => ({
      ...cns,
      [caseType]: caseName
    }));
  };
  const [tripleFilter, setTripleFilter] = useState({
    sid: '',
    pid: '',
    name: '',
    scopes: [],
    years: [],
    tgcs: [],
    budgets: ['Budget', 'IF', 'Others'],
    isDiff: false
  });
  const [tripleOpts, setTripleOpts] = useState({
    sIds: [],
    pIds: [],
    names: [],
    scopes: [],
    years: [],
    tgcs: [],
    budgets: []
  });
  const handleFilterChange = (fElem: string, val: any) => {
    setTripleFilter(filter => ({ ...filter, [fElem]: val }));
  };

  // triple table config and handler
  const [triplePageSize, setTriplePageSize] = useState(50);
  const [tripleLoading, setTripleLoading] = useState(false);
  const [tripleTableData, setTripleTableData] = useState([]);
  const getTripleData = async () => {
    setTripleLoading(true);
    const getAccountingYearOpts = APIList.getCmcOptions().get({
      category: 'accountingYear', snapshot, snapshotVersion
    });
    const getTgcOpts = APIList.getCmcOptions().get({
      category: 'tgc', snapshot, snapshotVersion
    });
    const getBudgetOpts = APIList.getCmcOptions().get({
      category: 'budgetCategory', snapshot, snapshotVersion
    });
    const getSubProjects = APIList.getSimSubProjects().post({
      snapshot, snapshotVersion
    });
    const [yearOpts, tgcOpts, budgetOpts, tripleData] = await Promise.all([
      getAccountingYearOpts,
      getTgcOpts,
      getBudgetOpts,
      getSubProjects
    ]);
    const { pIdOpts, sIdOpts, scopeOpts, nameOpts, caseInitialData } = tripleData.reduce((pr, d) => {
      // gen options
      pr.sIdOpts.push({ text: d.subProjectId, value: d.subProjectId });
      if (!pr.pIdOpts.map(p => p.value).includes(d.projectId)) {
        pr.pIdOpts.push({ text: d.projectId, value: d.projectId });
      }
      if (!pr.nameOpts.map(n => n.value).includes(d.subProjectName)) {
        pr.nameOpts.push({ text: d.subProjectName, value: d.subProjectName });
      }
      if (!pr.scopeOpts.map(sp => sp.value).includes(d.scope)) {
        pr.scopeOpts.push({ text: d.scope, value: d.scope });
      }
      // add case to triple table data
      pr.caseInitialData.push({
        ...d,
        case1: ['Awarded', 'Budget'].includes(d.budgetCategoryCustom),
        case2: ['Awarded', 'Budget'].includes(d.budgetCategoryCustom),
        case3: ['Awarded', 'Budget'].includes(d.budgetCategoryCustom),
      });
      return pr;
    }, { pIdOpts: [], sIdOpts: [], nameOpts: [], scopeOpts: [],  caseInitialData: [] });
    setTripleOpts(opts => ({
      ...opts,
      sIds: sIdOpts,
      pIds: pIdOpts,
      names: nameOpts,
      scopes: scopeOpts,
      years: yearOpts.map(y => ({ value: y.value, text: y.value })),
      budgets: budgetOpts.filter(b => b.value !== 'Exclusion').map(y => ({ value: y.value, text: y.value })),
      tgcs: tgcOpts.map(y => ({ value: y.value, text: y.value }))
    }));
    setTripleTableData(caseInitialData);
    setTripleLoading(false);
  };

  const searchInput = useRef(null);
  const getColumnSearchProps = (dataIndex) => {
    let fieldName = '';
    let inputWidth = 140;
    let optFieldName = '';
    switch (dataIndex) {
      case 'subProjectId':
        fieldName = 'sid';
        optFieldName = 'sIds';
        inputWidth = 140;
        break;
      case 'projectId':
        fieldName = 'pid';
        optFieldName = 'pIds';
        inputWidth = 140;
        break;
      case 'subProjectName':
        fieldName = 'name';
        optFieldName = 'names';
        inputWidth = 380;
        break;
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <AutoComplete
            allowClear
            ref={searchInput}
            options={tripleOpts[optFieldName].filter(id => id.value.includes(tripleFilter[fieldName]))}
            style={{ width: inputWidth }}
            onChange={(text) => handleFilterChange(fieldName, text)}
          />
        </div>
      ),
      filtered: !!tripleFilter[fieldName],
      filterIcon: <SearchOutlined style={{ color: !!tripleFilter[fieldName] ? color.primaryColor : undefined }} />,
      onFilterDropdownOpenChange: open => {
        if (open) {
          setTimeout(() => {
            const elem = searchInput.current.nativeElement.querySelector('input');
            elem?.select();
          }, 100);
        }
      }
    };
  };

  const tripleColumns = [
    {
      title: 'Case 1',
      dataIndex: 'case1',
      key: 'case1',
      width: 70,
      align: 'center',
      filteredValue: [],
      render: (val, item) => (
        <Checkbox
          checked={val}
          disabled={item.budgetCategoryCustom === 'Awarded'}
          onChange={e => handleTripleCaseChange(item.subProjectId, 'case1', e.target.checked)} />
      )
    },
    {
      title: 'Case 2',
      dataIndex: 'case2',
      key: 'case2',
      width: 70,
      align: 'center',
      filteredValue: [],
      render: (val, item) => (
        <Checkbox
          checked={val}
          disabled={item.budgetCategoryCustom === 'Awarded'}
          onChange={e => handleTripleCaseChange(item.subProjectId, 'case2', e.target.checked)} />
      )
    },
    {
      title: 'Case 3',
      dataIndex: 'case3',
      key: 'case3',
      width: 70,
      align: 'center',
      filteredValue: [],
      render: (val, item) => (
        <Checkbox
          checked={val}
          disabled={item.budgetCategoryCustom === 'Awarded'}
          onChange={e => handleTripleCaseChange(item.subProjectId, 'case3', e.target.checked)} />
      )
    },
    {
      title: 'P案件ID',
      dataIndex: 'projectId',
      key: 'projectId',
      align: 'center',
      width: 120,
      ...getColumnSearchProps('projectId'),
      filteredValue: [],
      sorter: (a, b) => a.projectId.localeCompare(b.projectId),
      render: val => <div style={{ display: 'flex', justifyContent: 'flex-start' }}>{val}</div>
    },
    {
      title: 'SB案件ID',
      dataIndex: 'subProjectId',
      key: 'subProjectId',
      align: 'center',
      width: 120,
      ...getColumnSearchProps('subProjectId'),
      filteredValue: [],
      sorter: (a, b) => a.subProjectId.localeCompare(b.subProjectId),
      render: val => <div style={{ display: 'flex', justifyContent: 'flex-start' }}>{val}</div>
    },
    {
      title: '案件名',
      dataIndex: 'subProjectName',
      key: 'subProjectName',
      align: 'center',
      ...getColumnSearchProps('subProjectName'),
      filteredValue: [],
      sorter: (a, b) => a.subProjectName.localeCompare(b.subProjectName),
      render: val => (
        <Tooltip title={val}>
          <div style={{ textAlign: 'left', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{val}</div>
        </Tooltip>
      )
    },
    {
      title: '年度',
      dataIndex: 'accountingYear',
      key: 'accountingYear',
      width: 100,
      align: 'center',
      filters: tripleOpts.years,
      filteredValue: tripleFilter.years,
      sorter: (a, b) => a.accountingYear.localeCompare(b.accountingYear)
    },
    {
      title: '役務範囲',
      dataIndex: 'scope',
      key: 'scope',
      width: 100,
      align: 'center',
      filters: tripleOpts.scopes,
      filteredValue: tripleFilter.scopes,
      render: val => (
        <Tooltip title={val}>
          <div style={{ textAlign: 'left', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{val}</div>
        </Tooltip>
      )
    },
    {
      title: 'TGC',
      dataIndex: 'tgc',
      key: 'tgc',
      width: 100,
      align: 'center',
      filters: tripleOpts.tgcs,
      filteredValue: tripleFilter.tgcs,
      sorter: (a, b) => a.tgc.localeCompare(b.tgc)
    },
    {
      title: '予算カテゴリ',
      dataIndex: 'budgetCategoryCustom',
      key: 'budgetCategoryCustom',
      width: 160,
      align: 'center',
      filters: tripleOpts.budgets,
      filteredValue: tripleFilter.budgets,
      sorter: (a, b) => a.budgetCategoryCustom.localeCompare(b.budgetCategoryCustom)
    },
    {
      title: '受注予定日',
      dataIndex: 'orderScheduledDate',
      key: 'orderScheduledDate',
      width: 120,
      align: 'center',
      sorter: (a, b) => a.orderScheduledDate < b.orderScheduledDate ? -1 : 1,
    },
    {
      title: '受注金額',
      dataIndex: 'orderAmount',
      key: 'orderAmount',
      align: 'center',
      width: 160,
      filteredValue: [],
      sorter: (a, b) => a.orderAmount - b.orderAmount,
      render: val => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 10px'
          }}
        >
          {!isNil(val) ? Number(val.toFixed(0)).toLocaleString(): '-'}
        </div>
      )
    },
    {
      title: '粗利額',
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      align: 'center',
      width: 160,
      filteredValue: [],
      sorter: (a, b) => a.grossProfit - b.grossProfit,
      render: val => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 10px'
          }}
        >
          {!isNil(val) ? Number(val.toFixed(0)).toLocaleString(): '-'}
        </div>
      )
    },
  ];
  const handleTripleCaseChange = (subProjectId: string, caseNum: `case${number}`, e: boolean) => {
    const newTriple = [...tripleTableData];
    const subItem = newTriple.find(t => t.subProjectId === subProjectId);
    subItem[caseNum] = e;
    setTripleTableData(newTriple);
  };

  // triple modal config and handler
  const [tripleConfirm, setTripleConfirm] = useState(false);
  const [tripleModalShow, setTripleModalShow] = useState(false);
  useEffect(() => {
    setTripleModalShow(false);
  }, [snapshot, snapshotVersion]);
  const tripleDiffData = useMemo(() => {
    const baseDiffData = Object.keys(caseNames).map(cn => ({
      caseNo: cn, caseName: caseNames[cn], selected: [], cancelled: []
    }));
    return tripleTableData.reduce((pr, td) => {
      const case1Data = pr.find(p => p.caseNo === 'case1');
      const case2Data = pr.find(p => p.caseNo === 'case2');
      const case3Data = pr.find(p => p.caseNo === 'case3');
      if (['IF', 'Others'].includes(td.budgetCategoryCustom)) {
        if (td.case1) {
          case1Data.selected.push({
            subProjectId: td.subProjectId,
            projectId: td.projectId
          });
        }
        if (td.case2) {
          case2Data.selected.push({
            subProjectId: td.subProjectId,
            projectId: td.projectId
          });
        }
        if (td.case3) {
          case3Data.selected.push({
            subProjectId: td.subProjectId,
            projectId: td.projectId
          });
        }
      } else if (td.budgetCategoryCustom === 'Budget') {
        if (!td.case1) {
          case1Data.cancelled.push({
            subProjectId: td.subProjectId,
            projectId: td.projectId
          });
        }
        if (!td.case2) {
          case2Data.cancelled.push({
            subProjectId: td.subProjectId,
            projectId: td.projectId
          });
        }
        if (!td.case3) {
          case3Data.cancelled.push({
            subProjectId: td.subProjectId,
            projectId: td.projectId
          });
        }
      }
      return pr;
    }, baseDiffData);
  }, [caseNames, tripleTableData]);

  // Triple Modal Ok Confirm logic
  const handleTripleModalOk = async () => {
    setProgressStatus('active');
    setRegisterPercent(0);
    // 1. local check version name rules and if version have save names
    const isInvalid = validateTripleData();
    if (isInvalid) {
      return;
    }
    setTripleConfirm(true);

    // 2.1 Send Triple Copy Request
    const copyVerPromises = Object.values(caseNames).map(cn => ({
      snapshotVersionN: cn,
      comment: '',
      isMaster: false,
      tag: null,
      snapshot: selectedSnapshot,
      // snapshotVersionの名
      snapshotVersion: selectedVersion,
      userName: user?.username,
      createdBy: user?.attributes?.email
    })).map(vInfo => APIList.createSnapshotVersions().post(vInfo));
    maxValue = 75;
    setIsProgressShow(true);
    beginProgress();
    const copyResults = await Promise.all(copyVerPromises);

    // 2.2. Processing Copy Triple Exception Logic Exist|Error
    const existVersions = copyResults.filter(cr => cr.status === 'EXIST');
    const errIndexes = copyResults.reduce((pr, c, i) => {
      if (c.status === 'ERROR') {
        pr.push(i);
      }
      return pr;
    }, []);
    if (existVersions.length || errIndexes.length) {
      const deletePromises = copyResults
        .filter(cr => cr.status === 'SUCCESS')
        .map(cr => APIList.updateVersions().put({ ...cr.data, isDeleted: true }));
      const delResults = await Promise.all(deletePromises);
      if (delResults.every(dr => dr === 'SUCCESS')) {
        if (existVersions.length) {
          clearIntervals();
          setProgressStatus('exception');
          message.error(t('aipcmcty.page.versionExists'));
        }
        if (errIndexes.length) {
          Object.values(caseNames).forEach((name, i) => {
            if (errIndexes.includes(i)) {
              clearIntervals();
              setProgressStatus('exception');
              message.error(t('aipcmcty.page.createVersionFailure').replace('${saveVerData.snapshotVersionN}', name));
            }
          })
        }
      }
      clearIntervals();
      setProgressStatus('exception');
      message.error('All Versions have been reverted by errors.');
      setTripleConfirm(false);
      return;
    }
    // 2.3 Show Copy Success Message
    message.success('Three Cases have been created.');

    // 3.1 Update the sub-projects which user checked or cancelled
    maxValue = 85;
    const updatePromises = copyResults.map(cr => {
      const { data: { snapshot, snapshotVersion, snapshotVersionName } } = cr;
      const changedItem = tripleDiffData.find(td => td.caseName === snapshotVersionName);
      const subProjects = [
        ...changedItem.selected.map(sItem => ({
          projectId: sItem.projectId,
          subProjectId: sItem.subProjectId,
          subProjectName: '',
          changeType: "BUDGET_CHANGE",
          changeBefore: '',
          changeAfter: 'Budget',
        })),
        ...changedItem.cancelled.map(cItem => ({
          projectId: cItem.projectId,
          subProjectId: cItem.subProjectId,
          subProjectName: '',
          changeType: "BUDGET_CHANGE",
          changeBefore: '',
          changeAfter: 'IF',
        })),
      ];
      return APIList.updateDemandSubProjects().post({
        snapshot,
        snapshotVersion,
        subProjects
      });
    });
    const updateResults = await Promise.all(updatePromises);

    // 3.2 Processing Update Triple Exception Logic Error
    const updateErrIndexes = updateResults.reduce((pr, c, i) => {
      if (c !== 'SUCCESS') {
        pr.push(i);
      }
      return pr;
    }, []);
    if (updateErrIndexes.length) {
      const deletePromises = copyResults
        .map(cr => APIList.updateVersions().put({ ...cr.data, isDeleted: true }));
      const delResults = await Promise.all(deletePromises);
      if (delResults.every(dr => dr === 'SUCCESS')) {
        if (updateErrIndexes.length) {
          Object.values(caseNames).forEach((name, i) => {
            if (updateErrIndexes.includes(i)) {
              clearIntervals();
              setProgressStatus('exception');
              message.error(`${name}'s Data update failed.`);
            }
          })
        }
      }
      clearIntervals();
      setProgressStatus('exception');
      message.error('All Versions have been reverted by errors.');
      setTripleConfirm(false);
      return;
    }

    // 3.3 Show Update Success Message
    message.success('Three Cases data have been updated.');

    // 4.1 Send Budget Category Simulation Request
    maxValue = 99;
    const simPromises = copyResults.map(cr => APIList.budgetCategoryUpdate().post({
      mode: '10',
      snapshot: cr.data.snapshot,
      snapshotVersion: cr.data.snapshotVersion,
    }));
    const simResults = await Promise.all(simPromises);

    // 4.2 Processing Simulation Triple Exception Logic Error
    const simErrIndexes = simResults.reduce((pr, c, i) => {
      if (!c) {
        pr.push(i);
      }
      return pr;
    }, []);
    if (simErrIndexes.length) {
      const deletePromises = copyResults
        .map(cr => APIList.updateVersions().put({ ...cr.data, isDeleted: true }));
      const delResults = await Promise.all(deletePromises);
      if (delResults.every(dr => dr === 'SUCCESS')) {
        if (simErrIndexes.length) {
          Object.values(caseNames).forEach((name, i) => {
            if (simErrIndexes.includes(i)) {
              clearIntervals();
              setProgressStatus('exception');
              message.error(`${name}'s Data simulation failed.`);
            }
          })
        }
      }
      clearIntervals();
      setProgressStatus('exception');
      message.error('All Versions have been reverted by errors.');
      setTripleConfirm(false);
      return;
    }

    // 4.3 Show Simulation Success Message
    maxValue = 100;
    setRegisterPercent(100);
    setTableSpinFlag(false);
    clearIntervals();
    message.success('予算カテゴリの意思入れ値が正常に保存されました。');
    setTripleConfirm(false);
  };

  const validateTripleData = () => {
    const { case1, case2, case3 } = caseNames;
    const reg = /^\w{1,24}$/;
    if ([case1, case2, case3].some(c => !reg.test(c))) {
      message.error(t('aipcmcty.page.versionFormatError'));
      return true;
    }
    if (
      case1 === case2
      || case1 === case3
      || case2 === case3
    ) {
      message.error(t('aipcmcty.page.versionExists'));
      return true;
    }
    return false;
  };

  const handleTripleModalShowStatus = isTripleOpen => {
    if (!isTripleOpen) {
      setTripleFilter({
        sid: '',
        pid: '',
        name: '',
        scopes: [],
        years: [],
        tgcs: [],
        budgets: ['Budget', 'IF', 'Others'],
        isDiff: false
      });
      setCaseNames({
        case1: '',
        case2: '',
        case3: ''
      });
      setTripleTableData([]);
      return;
    }
    getTripleData();
  };

  // filter table data base triple filters
  const cancelledTripleItems = tripleDiffData.reduce((pr, c) => pr.concat(c.cancelled.map(cc => cc.subProjectId)), []);
  const selectedTripleItems = tripleDiffData.reduce((pr, c) => pr.concat(c.selected.map(cs => cs.subProjectId)), []);
  const filteredTripleData = useMemo(
    () => tripleTableData
      .filter(tp => !tripleFilter.sid || tp.subProjectId.includes(tripleFilter.sid))
      .filter(tp => !tripleFilter.pid || tp.projectId.includes(tripleFilter.pid))
      .filter(tp => !tripleFilter.name || tp.subProjectName.includes(tripleFilter.name))
      .filter(tp => !tripleFilter.years.length || tripleFilter.years.includes(tp.accountingYear))
      .filter(tp => !tripleFilter.scopes.length || tripleFilter.scopes.includes(tp.scope))
      .filter(tp => !tripleFilter.tgcs.length || tripleFilter.tgcs.includes(tp.tgc))
      .filter(tp => !tripleFilter.budgets.length || tripleFilter.budgets.includes(tp.budgetCategoryCustom))
      .filter(tp => !tripleFilter.isDiff || cancelledTripleItems.includes(tp.subProjectId) || selectedTripleItems.includes(tp.subProjectId)),
      // .filter(tp => !tripleFilter.isDiff || (tp.case1 !== tp.case2 || tp.case1 !== tp.case3 || tp.case2 !== tp.case3)),
    [tripleTableData, tripleFilter]
  );

  return (
    <>
      <div style={{ width: '100%', padding: '12px' }}>
        <div style={{ paddingBottom: '6px' }}>
          <div>Snapshot</div>
          <Select
            style={{ width: '100%' }}
            value={selectedSnapshot}
            onChange={e => {
              const versionMap = genVersionBySnapshot(versionList);
              const isExist = versionMap[e].find(v => v.snapshot === snapshot && v.snapshotVersion === snapshotVersion);
              const masterItem = versionMap[e].find(v => v.isMaster);
              let defaultSelected = versionMap[e].find(v => v.snapshotVersionName === 'default');
              if (isExist?.snapshotVersion) {
                defaultSelected = isExist;
              } else if (masterItem?.snapshotVersion) {
                defaultSelected = masterItem;
              }
              const versionInfo = {
                snapshot: defaultSelected.snapshot,
                snapshotVersion: defaultSelected.snapshotVersion,
                snapshotVersionName: defaultSelected.snapshotVersionName,
                userId: defaultSelected.createdBy,
                isShared: defaultSelected.isShared
              };
              setCmcSelectedVersion(versionInfo);
              setItem(
                'cmcVersion',
                JSON.stringify(versionInfo),
                'local'
              );
              setAipcmctyVersion(JSON.stringify(versionInfo));
            }}
          >
            {
              Object.keys(genVersionBySnapshot(versionList))
                .sort((a, b) => {
                  if (b > a) {
                    return 1;
                  }
                  if (b < a) {
                    return -1;
                  }
                  return 0;
                })
                .map(o => (
                  <Select.Option style={{ display: 'flex' }} key={o} label={o} value={o}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="ellipsis">{o}</div> {getTag(minBy<any>(genVersionBySnapshot(versionList)[o], 'tag')?.tag)}
                    </div>
                  </Select.Option>)
                )
            }
          </Select>
        </div>
        <div style={{ paddingBottom: '12px' }}>
          <div>Version</div>
          <div style={{ display: 'flex' }}>
            <Select
              className='record-element-manage-versions'
              style={{ width: '100%' }}
              value={selectedVersion}
              onChange={e => {
                const item = versionList.find(v => v.snapshotVersion === e && v.snapshot === selectedSnapshot);
                const versionInfo = {
                  snapshot: item.snapshot,
                  snapshotVersion: item.snapshotVersion,
                  snapshotVersionName: item.snapshotVersionName,
                  userId: item.createdBy,
                  isShared: item.isShared
                };
                setCmcSelectedVersion(versionInfo);
                setItem(
                  'cmcVersion',
                  JSON.stringify(versionInfo),
                  'local'
                );
                setAipcmctyVersion(JSON.stringify(versionInfo));
              }}
            >
              {
                versionOpts.map(
                  optGroup => (
                    <Select.OptGroup {...optGroup} key={optGroup.label}>
                      {optGroup.options.map(
                        (opt) => <Select.Option {...opt}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="ellipsis">{opt.label}</div>
                            {getTag(opt.tag)}
                          </div>
                        </Select.Option>
                      )}
                    </Select.OptGroup>
                  )
                )
              }
            </Select>
          </div>
        </div>
        <Button
          className="version-button"
          disabled={
            Object.keys(genVersionBySnapshot(versionList))
              .sort((a, b) => {
                if (b > a) {
                  return 1;
                }
                if (b < a) {
                  return -1;
                }
                return 0;
              })[0] !== selectedSnapshot
            || !versionList.find(v => v.snapshotVersion === selectedVersion && v.snapshot === selectedSnapshot)?.isShared
          }
          icon={<SaveOutlined />}
          style={{ width: '100%', marginBottom: '12px'}}
          onClick={() => setSaveVerModel(true)}
        >
          Save as New Version
        </Button>
        <Button
          className="version-button"
          style={{ width: '100%', marginBottom: '12px' }}
          onClick={() => {
            setRegisterPercent(0);
            setTableSpinFlag(false);
            setIsProgressShow(false);
            setProgressStatus('active');
            setTripleModalShow(true)
          }}
          icon={<CopyOutlined />}
          disabled={
            Object.keys(genVersionBySnapshot(versionList))
              .sort((a, b) => {
                if (b > a) {
                  return 1;
                }
                if (b < a) {
                  return -1;
                }
                return 0;
              })[0] !== selectedSnapshot
            || !versionList.find(v => v.snapshotVersion === selectedVersion && v.snapshot === selectedSnapshot)?.isShared
          }
        >
          Create Multiple Versions
        </Button>
        <Button
          className="version-button"
          icon={<ScheduleOutlined />}
          style={{ width: '100%', marginBottom: '12px' }}
          onClick={() => {
            setManageVerModal(true);
            setManageTableLoading(true);
            getVersionList();
          }}
        >
          Manage Versions
        </Button>
      </div>
      <Modal
        title="Create Multiple Versions"
        open={tripleModalShow}
        closable={false}
        maskClosable={false}
        okText={t('aipcmcty.page.confirm')}
        cancelText={t('aipcmcty.page.cancel')}
        onOk={handleTripleModalOk}
        onCancel={() => {
          setRegisterPercent(0);
          setTableSpinFlag(false);
          setTripleModalShow(false);
          setProgressStatus('active');
          clearIntervals();
          maxValue = 75;
        }}
        cancelButtonProps={{
          disabled: tripleConfirm
        }}
        okButtonProps={{
          disabled: tripleConfirm,
          loading: tripleConfirm
        }}
        afterOpenChange={handleTripleModalShowStatus}
        width="86%"
      >
        <Row style={{ marginBottom: 14 }}>
          <Col>
            <Space>
              Case1:
              <Input
                style={{ width: 240 }}
                value={caseNames.case1}
                placeholder="Input your version name for Case1"
                onChange={e => handleCaseNameChange('case1', e.target.value)} />
              Case2:
              <Input
                style={{ width: 240 }}
                value={caseNames.case2}
                placeholder="Input your version name for Case2"
                onChange={e => handleCaseNameChange('case2', e.target.value)} />
              Case3:
              <Input
                style={{ width: 240 }}
                value={caseNames.case3}
                placeholder="Input your version name for Case3"
                onChange={e => handleCaseNameChange('case3', e.target.value)} />
              <Switch
                checkedChildren="差分"
                unCheckedChildren="全量"
                checked={tripleFilter.isDiff}
                onChange={e => handleFilterChange('isDiff', e)}
              />
            </Space>
          </Col>
        </Row>
        <Divider />
        {isProgressShow?
        <Progress percent={registerPercent} status={progressStatus} strokeColor={{ from: '#108ee9', to: '#87d068' }} />:<></>}
        <Spin spinning={tableSpinFlag}>
        <div className="sub-table">
          <Table
            loading={tripleLoading}
            bordered
            size="small"
            rowKey="subProjectId"
            dataSource={filteredTripleData}
            scroll={{ y: 400 }}
            columns={tripleColumns as any[]}
            pagination={{
              size: 'small',
              pageSize: triplePageSize,
              pageSizeOptions: [50, 100, 200],
              onChange: (_, pageSize) => {
                setTriplePageSize(pageSize);
              }
            }}
            onChange={(_, filter) => {
              if (filter) {
                Object.entries(filter).forEach(([k, v]) => {
                  let elem = null;
                  switch (k) {
                    case 'accountingYear':
                      elem = 'years';
                      break;
                    case 'tgc':
                      elem = 'tgcs';
                      break;
                    case 'budgetCategoryCustom':
                      elem = 'budgets';
                      break;
                    case 'scope':
                      elem = 'scopes';
                      break;
                  }
                  handleFilterChange(elem, v ?? []);
                });
              }
            }}
          />
        </div>
        </Spin>
      </Modal>
      <Modal
        title="Save as a new version"
        open={saveVerModal}
        closable={false}
        maskClosable={false}
        okText={t('aipcmcty.page.confirm')}
        cancelText={t('aipcmcty.page.cancel')}
        confirmLoading={saveConfirmLoading}
        onOk={handleSaveVerData}
        onCancel={() => {
          setSaveVerData(initialSaveVerData.current);
          setSaveVerModel(false);
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <h4 style={{ margin: 0 }}>
            Name of New Version
            <br />
            <span style={{ color: color.errorColor, fontSize: 12, fontWeight: 'normal' }}>{t('aipcmcty.page.versionFormatError')}</span>
          </h4>
          <Input
            placeholder="Name of New Version" value={saveVerData.snapshotVersionN}
            onChange={e => updateSaveVerData('snapshotVersionN', e.target.value)}
          />
          <h4 style={{ margin: 0 }}>Comment</h4>
          <TextArea
            rows={4} value={saveVerData.comment}
            onChange={e => updateSaveVerData('comment', e.target.value)}
          />
          {
            /@accenture.(cn|com)$/.test(user?.attributes?.email)
              ? (
                <>
                  <Checkbox
                    checked={saveVerData.isMaster}
                    onChange={e => updateSaveVerData('isMaster', e.target.checked)}
                  >
                    Set as Master
                  </Checkbox>
                  <Row>
                    <Col>
                      <Checkbox checked={saveVerData.tag === 0} onChange={e => updateSaveVerData('tag', e.target.checked ? 0 : null)}>原受注計画</Checkbox>
                      <Checkbox checked={saveVerData.tag === 1} onChange={e => updateSaveVerData('tag', e.target.checked ? 1 : null)}>修正受注計画</Checkbox>
                    </Col>
                  </Row>
                </>
              )
              : null
          }
        </Space>
      </Modal>
      <Modal
        title="Manage Versions"
        width="85%"
        maskClosable={false}
        open={manageVerModal}
        className="manage-modal"
        onCancel={() => setManageVerModal(false)}
        footer={null}

      >
        <Table
          columns={columns}
          dataSource={tableData.system.sort((a, b) => (b.isMaster - a.isMaster))}
          pagination={false}
          rowKey="id"
          scroll={{ y: 120 }}
          size="small"
          showHeader
          title={() => <h3 style={{ marginBottom: 0 }}>System</h3>}
        />
        <Table
          columns={columns}
          dataSource={tableData.mine.sort((a, b) => (b.isMaster - a.isMaster))}
          pagination={false}
          rowKey="id"
          scroll={{ y: 120 }}
          size="small"
          showHeader
          title={() => <h3 style={{ marginBottom: 0 }}>My Versions</h3>}
        />
        <Table
          columns={columns}
          dataSource={tableData.others.sort((a, b) => (b.isMaster - a.isMaster))}
          pagination={false}
          rowKey="id"
          scroll={{ y: 120 }}
          size="small"
          showHeader
          title={() => <h3 style={{ marginBottom: 0 }}>Others</h3>}
        />
        {
          manageTableLoading
            ? (
              <div style={{
                position: 'absolute',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                margin: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }}
              >
                <Spin />
              </div>
            )
            : null
        }

      </Modal>
    </>
  );
};

export default VersionManager;
