import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Select, Space, Button, Form, Divider, Cascader, DatePicker, Input, Tabs, Modal, Spin, Tag, Switch } from 'antd';
import { BarsOutlined } from '@ant-design/icons';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { useAccountPerspective } from '../../hooks/useAccountPerspective';
import ExtractHeatChart from '../../components/charts/extract-heat.chart';
import ExtractResourcesChart from '../../components/charts/extract-resources.chart';
import ExtractResourcesTable from '../../components/tables/extract-resources.table';
import APIList from '../../http/ApiList';
import CustomerScrollBar from '../../components/widget/customer-scrollbar';
import DrawerContainer, { DrawerProperties } from '../../components/widget/drawer-container';
import moment from 'moment';
import { Loading } from '@meteor/frontend-core';
import dayjs from 'dayjs';
import ProjectSetupDetail from '../../components/project-setup-detail';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { debounce } from 'lodash';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const drawerOpts: Omit<DrawerProperties, 'collapsed' | 'children'> = {
  maxWidth: 320,
  minWidth: 0,
};
const ExtractResources = () => {
  const currentMonth = dayjs().format('MM');
  const currentYear = dayjs().format('YYYY');
  const { color } = useContext(AppContext);
  const { t } = useTranslation();
  const [filterCollapsed, setFilterCollapsed] = useState(true);
  const [optOrgData, setOptOrgData] = useState(null);
  const [divisionOpt, setDivisionOpt] = useState([]);
  const [division, setDivision] = useState([]);
  const [disciplineOpt, setDisciplineOpt] = useState([]);
  const [discipline, setDiscipline] = useState([]);
  const [roleOpt, setRoleOpt] = useState([]);
  const [roleFilterOpt, setRoleFilterOpt] = useState([]);
  const [adaptiveRoleOptions, setAdaptiveRoleOptions] = useState([]);
  const [role, setRole] = useState([]);
  const [tgc, setTgc] = useState(['Toyo-J', 'Toyo-I']);
  const [startYear, setStartYear] = useState(currentYear);
  const [startMonth, setStartMonth] = useState(currentMonth);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [hotChartData, setHotChartData] = useState([]);
  const [mhChartData, setMhChartData] = useState([]);
  const [mhChartLine, setMhChartLine] = useState([]);
  const { snapshot, snapshotVersion, filterKpiData } = useContext(AipcmctyContext);
  const [loading, setLoading] = useState(false);
  const [roleStatas, setRoleStatas] = useState(true);

  const [tableLoading, setTableLoading] = useState(false);
  const [tableFilterList, setTableFilterList] = useState([]);
  // const [year, setYear] = useState('');
  // const [month, setMonth] = useState('');
  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );
  const initializeTableData = async () => {
    const options = await APIList.getCmcOptions().get({
      category: 'all',
      snapshot,
      snapshotVersion,
    });
    setOptOrgData(options);
  };

  useEffect(() => {
    const getTableData = async () => {
      await initializeTableData();
    };
    getTableData();
  }, [snapshot, snapshotVersion]);

  // set selectable roles based on the switch status
  const calculateAdaptiveRoleOptions = (roleOpt, roleFilterOpt, switchStatus) => {
    if (!switchStatus) {
      return roleOpt;
    }
    // RoleOptとRoleFilterOptの共通部分を計算
    const filterValues = new Set(roleFilterOpt.map((opt) => opt.value));
    return roleOpt.filter((opt) => filterValues.has(opt.value));
  };
  useEffect(() => {
    const newAdaptiveRoleOptions = calculateAdaptiveRoleOptions(roleOpt, roleFilterOpt, switchStatus);
    setAdaptiveRoleOptions(newAdaptiveRoleOptions);
  }, [roleOpt, roleFilterOpt, switchStatus]);

  const { filterTgcOptions, selectedTgc } = useAccountPerspective(optOrgData?.tgc ?? [], 4);
  // set default TGC to Toyo-J and Toyo-I if they are available
  // useEffect(() => {
  //   const defaultTgcOptions = filterTgcOptions
  //     .filter((option) => ['Toyo-J', 'Toyo-I'].includes(option.value))
  //     .map((option) => option.value);

  //   if (defaultTgcOptions.length > 0 && tgc.length === 0) {
  //     setTgc(defaultTgcOptions);
  //   }
  // }, [filterTgcOptions]);

  useEffect(() => {
    setTimeout(() => {
      filterKpiData({
        tgc: tgc,
      });
    }, 0);
  }, [tgc]);

  const [tableList, setTableList] = useState([]);
  useEffect(() => {
    fetchData();
    setTableFilterList([]);
  }, [division, tgc, role, discipline, startYear, startMonth, snapshot, snapshotVersion]);

  // useEffect(() => {
  //   fetchTableFilter();
  // }, [year, month]);

  const fetchTableFilter = async (year, month) => {
    const req = {
      snapshot,
      version: snapshotVersion,
      year,
      month,
      tgc,
      role,
      division,
      discipline,
      startYear,
      startMonth,
    };
    setTableLoading(true);
    const { tableFilterList } = await APIList.getEXtractResourcesTable().post(req);
    setTableFilterList(tableFilterList);
    setTableLoading(false);
  };

  const fetchData = async () => {
    const req = {
      division,
      discipline,
      role,
      tgc,
      snapshot,
      version: snapshotVersion,
      startYear,
      startMonth,
    };
    setLoading(true);
    const {
      divisionOptions,
      disciplineOptions,
      roleOptions,
      roleFilterOptions,
      hotChartData,
      mhChartData,
      mhChartLine,
      tableList,
      tableChildrenList,
    } = await APIList.getEXtractResources().post(req);
    setLoading(false);
    setDivisionOpt(divisionOptions);
    setDisciplineOpt(disciplineOptions);
    setRoleOpt(roleOptions);
    setRoleFilterOpt(roleFilterOptions);
    setHotChartData(hotChartData);
    setMhChartData(mhChartData);
    setMhChartLine(mhChartLine);

    // adaptiveRoleOptionsはfetchDataの終了後に更新されるため、事前に更新後のRole一覧を作成して利用
    const adaptiveRoleOptions_temp = calculateAdaptiveRoleOptions(roleOptions, roleFilterOptions, switchStatus);
    if (discipline.length > 0 && adaptiveRoleOptions_temp.length > 0) {
      const allRoles = adaptiveRoleOptions_temp.map((option) => option.value);
      console.log('allroles', allRoles, roleOpt.length);
      // Roleが設定されていない場合は全て選択状態にする
      if (role.length === 0 && roleStatas) {
        setRole(allRoles);
      }
    }
    // role初期化
    if ((discipline.length === 0 || tgc.length === 0) && !roleStatas && role.length !== 0) setRole([]);

    setTableList(
      tableList.map((item) => {
        return {
          ...item,
          children: tableChildrenList
            .filter((i) => i.projectId === item.projectId)
            .map((e, index) => ({ ...e, projectId: `${e.projectId}-${index + 1}` })),
        };
      })
    );
  };

  const transformDataWithDefaultMonths = (originalData) => {
    const groupedData = {};
    originalData.forEach((item) => {
      const key = `${item.tgc || 'N/A'}-${item.division || 'N/A'}-${item.discipline || 'N/A'}-${item.role || 'N/A'}-${item.year || 'N/A'}`;

      if (!groupedData[key]) {
        groupedData[key] = {
          division: item.division,
          discipline: item.discipline,
          role: item.role,
          tgc: item.tgc,
          data: Array.from({ length: 12 }, (_, i) => ({
            month: `${i + 1}`.padStart(2, '0'),
            value: 0,
            percentValue: 0,
            year: '',
            division: '',
            discipline: '',
            role: '',
          })),
        };
      }

      const monthIndex = groupedData[key].data.findIndex((d) => d.month === item.month);
      if (monthIndex !== -1) {
        groupedData[key].data[monthIndex].value = Math.round(item.demandMh);
        groupedData[key].data[monthIndex].percentValue = Math.round(item.shartageRatio * 100 * 10) / 10;
        groupedData[key].data[monthIndex].year = item.year;
        groupedData[key].data[monthIndex].division = item.division;
        groupedData[key].data[monthIndex].discipline = item.discipline;
        groupedData[key].data[monthIndex].role = item.role;
      }
    });

    return Object.values(groupedData);
  };
  const transformData = (originalData) => {
    return originalData.map((item) => [
      item.tgc, // tgc
      item.jobCategory, // jobCategory
      item.yearMonth, // yearMonth
      Math.round(item.resourceDemandMh), // resourceDemandMh
    ]);
  };

  const onMhChartClick = (year, month) => {
    // console.log(year, month);
    // setYear(year);
    // setMonth(month);
    fetchTableFilter(year, month);
  };
  const handleSwitchChange = (a) => {
    setSwitchStatus(a);
    if (a) {
      setRole(adaptiveRoleOptions.map((i) => i.value));
      return;
    }
    setRole([]);
  };
  const [curModalItem, setCurModalItem] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCaseNameClick = (record) => {
    setCurModalItem(record);
    setIsModalOpen(true);
  };
  return (
    <div className="portfolio-monitor">
      <Row className="operation-container" style={{ backgroundColor: 'white', padding: '0 16px' }}>
        <Col style={{ padding: '5px' }}>
          <Space>
            ヒートマップ開始月:
            <DatePicker
              onChange={debounce((date, dateString: string) => {
                const [y, m] = dateString.split('-');
                setStartYear(y);
                setStartMonth(m);
              }, 800)}
              defaultValue={dayjs()}
              picker="month"
              style={{ width: 180 }}
              renderExtraFooter={() => null}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px' }}>
          <Space>
            工数TGC:
            <Select
              allowClear
              style={{ width: 180 }}
              mode="multiple"
              maxTagCount="responsive"
              value={tgc}
              options={filterTgcOptions}
              onChange={debounce((val) => setTgc(val), 800)}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px' }}>
          <Space>
            Division:
            <Select
              allowClear
              style={{ width: 180 }}
              mode="multiple"
              maxTagCount="responsive"
              options={divisionOpt}
              onChange={debounce((val) => setDivision(val), 800)}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px' }}>
          <Space>
            Discipline:
            <Select
              allowClear
              style={{ width: 180 }}
              mode="multiple"
              maxTagCount="responsive"
              options={disciplineOpt}
              onChange={debounce((val) => setDiscipline(val), 800)}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px' }}>
          <Space>
            Role:
            <Select
              allowClear
              style={{ width: 180 }}
              value={role}
              mode="multiple"
              maxTagCount="responsive"
              options={adaptiveRoleOptions}
              onChange={debounce((val) => {
                if (val === undefined || val.length === 0) {
                  setRoleStatas(false);
                  setRole([]);
                } else {
                  setRoleStatas(true);
                  setRole(val);
                }
              }, 800)}
            />
          </Space>
        </Col>

        <Col style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
          <Space>
            KeyRole絞り込み:
            <Switch onChange={(a) => handleSwitchChange(a)} />
          </Space>
        </Col>
      </Row>

      <Row justify="space-between" gutter={4} style={{ marginTop: 4 }}>
        <Col
          span={12}
          style={{
            justifyContent: 'center',
            display: 'flex',
            padding: 6,
            backgroundColor: 'white',
            // overflow: 'scroll',
            width: '100%',
          }}
        >
          <Spin spinning={loading} style={{ height: '100%' }}>
            <ExtractHeatChart data={transformDataWithDefaultMonths(hotChartData)} startMonth={startMonth} />
          </Spin>
        </Col>

        <Col span={12}>
          <Spin spinning={loading}>
            <div
              className="chart"
              style={{
                padding: 6,
                backgroundColor: 'white',
                height: 300,
                width: '100%',
              }}
            >
              <ExtractResourcesChart
                data={transformData(mhChartData)}
                mhChartLine={mhChartLine}
                height={300}
                handleClick={onMhChartClick}
                title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.resSimulation.mhForecast'), color.warningColor)}
              />
            </div>
          </Spin>
          <div
            className="table"
            style={{
              padding: 6,
              backgroundColor: 'white',
              width: '100%',
            }}
          >
            <ExtractResourcesTable
              data={tableList
                .map((parent) => {
                  if (tableFilterList.length === 0) return parent;
                  return {
                    ...parent,
                    children: tableFilterList.filter((i) => i.projectId === parent.projectId && i.mhPeak !== 0),
                  };
                })
                .filter((e) => e.children.length !== 0)}
              loading={tableLoading || loading}
              onCaseNameClick={onCaseNameClick}
            />
          </div>
        </Col>
      </Row>
      <Modal
        className="aipcmc"
        title={`${curModalItem?.projectId} ${curModalItem?.projectName}${t('aipcmcty.page.projectDemandSupplyList')}`}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setCurModalItem(null);
        }}
        footer={null}
        width="90%"
        styles={{
          body: {
            height: document.body.clientHeight * 0.8,
          },
        }}
        maskClosable={false}
        centered
      >
        <ProjectSetupDetail project={curModalItem} type="extract-resources" />
      </Modal>
      {/* <DrawerContainer {...drawerOpts} collapsed={filterCollapsed}>
        <Tabs type="card" size="small" style={{ height: '100%' }} items={tabs} />
      </DrawerContainer> */}
    </div>
  );
};

export default ExtractResources;
